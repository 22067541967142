export type InputFieldCheck = RegExp | ((input: string) => boolean)

export type InputFieldValidator<T extends InputFieldValidation = InputFieldValidation> = Record<
  T,
  InputFieldCheck | InputFieldCheck[]
>

export type ParsedInputFieldValidator<T extends InputFieldValidation = InputFieldValidation> =
  Record<T, InputFieldCheck[]>

export enum GenericValidation {
  NOT_EMPTY = '[GENERIC] NOT EMPTY',
}

export enum EmailValidation {
  CORRECT_FORMAT = '[EMAIL] CORRECT FORMAT',
  NOT_EMPTY = '[EMAIL] NOT EMPTY',
}

export enum PasswordValidation {
  ENOUGH_CHARACTERS = '[PASSWORD] CHARACTER COUNT',
  ENOUGH_DIGITS = '[PASSWORD] DIGIT COUNT',
  ENOUGH_UPPERCASE = '[PASSWORD] UPPERCASE COUNT',
  ENOUGH_LOWERCASE = '[PASSWORD] LOWERCASE COUNT',
  MATCHING = '[PASSWORD] MATCHING',
}

export enum OnlyNumberValidation {
  NOT_EMPTY = '[ONLYNUMBER] NOT EMPTY',
  IS_ONLY_NUMBER = '[ONLYNUMBER] IS ONLY NUMBER',
}

export type InputFieldValidation =
  | GenericValidation
  | EmailValidation
  | PasswordValidation
  | OnlyNumberValidation

const toArray = <T>(potentialArray: T | T[]): T[] => {
  return Array.isArray(potentialArray) ? potentialArray : [potentialArray]
}

export const parseValidators = <T extends InputFieldValidation>(
  validators: Partial<InputFieldValidator<T>> | Partial<InputFieldValidator<T>>[]
): ParsedInputFieldValidator<T>[] => {
  const allValidators = toArray(validators)

  return allValidators.map((currentValidator) =>
    parseSingleValidator(currentValidator)
  ) as ParsedInputFieldValidator<T>[]
}

const parseSingleValidator = <T extends InputFieldValidation>(
  validator: Partial<InputFieldValidator<T>>
) => {
  const validatorKeys = Object.keys(validator) as T[]
  return validatorKeys.reduce((allParsedValidators, currentValidatorKey) => {
    const checks = allParsedValidators[currentValidatorKey]
    if (checks) {
      allParsedValidators[currentValidatorKey] = toArray(checks)
    }
    return { ...allParsedValidators }
  }, validator)
}
