import translator from '@taktik/config/Translation/languages'
import { GroupNames } from '@taktik/config/Translation/utilities'
import { TaktikAlert } from './Alert'

export function isEmail(email: string | null | undefined) {
  if (!email) return false
  return /^([\w.%+-]+)@([\w-]+\.)+([\w]{1,})$/i.test(email)
}

export function isToday(date: Date) {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

export function notEmpty(text: string | null | undefined) {
  if (!text) return false
  return text.trim().length > 0
}

export function isColor(color: string | null | undefined) {
  if (!color) return false
  return /^#([0-9a-f]{6})$/i.test(color)
}

export class Field<Type> {
  private _value: Type
  private _name: string
  private validationFunction: (value: Type) => boolean
  constructor(name: string, isValidFunction: (value: Type) => boolean, startValue: Type) {
    this._value = startValue
    this._name = name
    this.validationFunction = isValidFunction
  }

  getName(): string {
    return this._name
  }

  getValue(): Type {
    return this._value
  }

  setValue(newValue: Type): void {
    this._value = newValue
  }

  isValid() {
    return this.validationFunction(this._value)
  }
}
export function isSpecifiedGroup(uniqueGroupToCheck: string, referenceGroup: GroupNames) {
  if (!uniqueGroupToCheck) {
    return false
  }

  switch (referenceGroup) {
    case GroupNames.Offense:
      return uniqueGroupToCheck.toLowerCase().indexOf('off') !== -1
    case GroupNames.Defense:
      return uniqueGroupToCheck.toLowerCase().indexOf('d') !== -1
    case GroupNames.Special:
      return uniqueGroupToCheck.toLowerCase().indexOf('cial') !== -1
    default:
      return false
  }
}

export function sortUniqueGroups(uniqueGroups: string[]) {
  let offenseIndex: number = -1
  let defenseIndex: number = -1
  let specialIndex: number = -1

  for (
    let uniqueGroupIndex: number = 0;
    uniqueGroupIndex < uniqueGroups.length;
    uniqueGroupIndex++
  ) {
    const uniqueGroup = uniqueGroups[uniqueGroupIndex]
    if (isSpecifiedGroup(uniqueGroup, GroupNames.Offense)) {
      offenseIndex = uniqueGroupIndex
    } else if (isSpecifiedGroup(uniqueGroup, GroupNames.Defense)) {
      defenseIndex = uniqueGroupIndex
    } else {
      specialIndex = uniqueGroupIndex
    }
  }

  const newUniqueGroup = [
    uniqueGroups[offenseIndex],
    uniqueGroups[defenseIndex],
    uniqueGroups[specialIndex],
  ]

  return newUniqueGroup
}

export const capitalizeFirstLetter = (text: string): string => {
  if (text.length <= 0) return text
  return text.toLowerCase().charAt(0).toUpperCase() + text.slice(1)
}

export function isValidSnapshot(snapshot: string): boolean {
  const emptyPlaybookWebHeader: string = 'data:text/html'

  return snapshot !== '' && !snapshot.startsWith(emptyPlaybookWebHeader)
}

export function hasValidSnapshot(playbook: any): boolean {
  return isValidSnapshot(playbook.snapshot)
}

export const userIsCoachAlert = () => {
  TaktikAlert.alert(
    translator.t('alert.coachAlertTitle'),
    translator.t('alert.coachAlertSubtitle'),
    { text: 'OK' }
  )
}
