import { StyleSheet, ScaledSize } from 'react-native'
import { StyleParameters } from '../Utilities'
import { StyledComponentsTheme } from '../Theme'

export default function getHamburgerIconStyles(
  _appSize: ScaledSize,
  _theme: StyledComponentsTheme,
  teamColor: string,
  _styleParameters: StyleParameters
) {
  return StyleSheet.create({
    hamburger: {
      display: 'none',
      height: 70,
      width: 70,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 999,
    },
    hamburgerLine: {
      backgroundColor: 'white',
      width: 30,
      height: 4,
      transform: [{ skewX: '-30deg' }],
    },
    hamburgerLineWrapper: {},
    hamburgerLineMiddle: {
      backgroundColor: 'white',
      width: 30,
      height: 4,
      transform: [{ skewX: '-30deg' }],
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 7,
    },
    hamburgerBackground: {
      width: 70,
      height: 70,
      marginBottom: 10,
      backgroundColor: teamColor,
    },
  })
}
