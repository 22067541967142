export enum PageName {
  Login = 'login',
  PlaybookList = 'playbookList',
  PlaybookPage = 'playbookPage',
  Loading = 'loading',
  Logout = 'login',
  ForgotPassword = 'forgotPassword',
  Success = 'success',
  ChangePassword = 'changePassword',
  PlayerStats = 'playerStatsPage',
  Leaderboard = 'leaderBoardPage',
  Settings = 'settingsPage',
  Home = 'homePage',
  GamificationPage = 'gamificationPage',
  TeamSelectionPage = 'teamSelectionPage',
}

export interface NavigationInterface {
  navigation: any
  route?: any
}
