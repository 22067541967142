import React from 'react'
import { Dimensions, Text, View, TouchableOpacity, ImageBackground } from 'react-native'
import { NavigationInterface, PageName } from './PageInfo'
import translator from '@taktik/config/Translation/languages'
import TopBarMenu from './TopBarMenu'
import {
  connectedToInternet,
  getLocalTeamInfo,
  getLocalUserInfo,
  storeLocalTeamInfo,
} from '../Utilitary/ConnectionUtils'
import BasicPage from './BasicPage'
import {
  getGlobalStyles,
  setTheme,
  setTeamColor,
  getHomeStyles,
  updateStyleParameters,
} from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { fetchTeam, axiosGetPlaybooks } from '../Utilitary/DataUtils'
import { DarkTheme, LightTheme } from '../Styles/Theme'
import Axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { store } from '../redux/store'
import {
  playbooksLoad,
  toggleImageLoad,
  togglePracticeModal,
  toggleLoadingModal,
  updateSelectedGroups,
  percentOfPlaybooksDownloaded,
  setSelectedTeam,
  setSelectedToken,
} from '../redux/actions'
import { synchronizeStudyTimes } from '../Services/StudyTimeService'
import { logger } from '../../lib/logger'
import { User, UserRole } from '../DataTypes/UserTypes'
import { RootState } from '../redux/reducers'
import { connect } from 'react-redux'
import LoadingModal from '../components/LoadingModal'
import PartialTaktikIcon from '../../assets/svg/PartialTaktikIcon'
import { hasValidSnapshot, userIsCoachAlert } from '@taktik/src/Utilitary/Utils'
import {
  checkTaktikServerVersion,
  getMasterTaktikServerVersion,
} from '../Utilitary/version/TaktikServerVersionUtils'
import { TaktikServerVersion } from '@taktik/config/TaktikServer/version'
import { ensureImagesDirectoryExists } from '../Utilitary/StorageUtils'
import UserTeamInviteModal from './UserTeamInviteModal'
import { StoreVersion } from '@taktik/config/Redux/version'
import { mustLogoutFromReduxStoreVersionChange } from '../Utilitary/version/ReduxStoreVersionUtils'
import { logout } from '../Services/AuthService'

interface HomeProps extends NavigationInterface {
  url: PageName
  updatePage: () => void
  userMustBeLoggedOut: boolean
  pageTitle: string
  isLoading: boolean
  user: User
  taktikServerVersion: TaktikServerVersion | null
  masterTaktikServerVersion: TaktikServerVersion | null | undefined
  teamSelected: number
  renderInvitationModal: boolean
  invitationToken: string | undefined
  storeVersion: StoreVersion | null | undefined
}

interface HomeState {
  styleParameters: StyleParameters
  backgroundImage: string
  userMustBeLoggedOut: boolean
  teamName: string
  hasTaktikPlaySubscription: boolean
}

class HomePage extends BasicPage<HomeProps, HomeState> {
  constructor(public props: HomeProps) {
    super(props)

    this.state = {
      styleParameters: getStyleParameters(),
      backgroundImage: '',
      userMustBeLoggedOut: false,
      hasTaktikPlaySubscription: true,
      teamName: translator.t('defaultTeamName'),
    }
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    // This is temporary (HomePage rerenders when its not used)
    if (nextProps.teamSelected !== this.props.teamSelected) {
      this.updateBackgroundImage()
        .then()
        .catch((error) => {
          logger.info(error)
        })

      this.updateUserInterface(nextProps.teamSelected)
        .then()
        .catch((error) => {
          logger.info(error)
        })
      synchronizeStudyTimes()
        .then()
        .catch((error) => {
          logger.info(error)
        })
    }

    const propsHaveChanged = JSON.stringify(this.props) !== JSON.stringify(nextProps)
    const stateHasChanged = JSON.stringify(this.state) !== JSON.stringify(nextState)
    if (propsHaveChanged) {
      return true
    } else if (stateHasChanged) {
      return true
    } else {
      return false
    }
  }

  dispatchAllTeamPlaybooksToRedux = (playbooks: object) => {
    store.dispatch(playbooksLoad(playbooks))
  }

  getDisplayablePlaybooks = (playbooks: any[]) => {
    const allPlaybooksWithSnapshot = playbooks.filter((playbook) => hasValidSnapshot(playbook))
    return allPlaybooksWithSnapshot
  }

  setPlaybooks = async () => {
    store.dispatch(toggleImageLoad(true))
    store.dispatch(percentOfPlaybooksDownloaded(0))

    await axiosGetPlaybooks()
      .then((axiosPlaybookResponse) => {
        // const displayablePlaybooks = this.getDisplayablePlaybooks(axiosPlaybookResponse.playbooks)
        const payload = {
          playbookFolders: axiosPlaybookResponse.folders,
          playbooks: axiosPlaybookResponse.playbooks,
        }
        this.dispatchAllTeamPlaybooksToRedux(payload)
        store.dispatch(toggleImageLoad(false))
        store.dispatch(percentOfPlaybooksDownloaded(100))
      })
      .catch((error) => {
        logger.info(error)
        logout()
      })
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount = async () => {
    const { storeVersion } = this.props

    Dimensions.addEventListener('change', this.onChange)
    store.dispatch(updateSelectedGroups([]))

    await ensureImagesDirectoryExists()
    await getMasterTaktikServerVersion(false)
    const isLoggingOutFromReduxStoreVersionChange: boolean =
      await mustLogoutFromReduxStoreVersionChange(storeVersion)

    if (isLoggingOutFromReduxStoreVersionChange) {
      return
    }

    const { masterTaktikServerVersion, taktikServerVersion, teamSelected } = this.props

    if (teamSelected) {
      try {
        const setupSteps = [
          this.updateBackgroundImage(),
          this.updateUserInterface(teamSelected),
          synchronizeStudyTimes(),
        ]
        await Promise.all(setupSteps)
      } catch (err: unknown) {
        logger.info(err)
      }
    }

    // The check cannot be made before the page is fully rendered, otherwise an error
    // with "No native splash screen registered" will be triggered
    setTimeout(() => {
      checkTaktikServerVersion(true, taktikServerVersion, masterTaktikServerVersion)
    }, 500)
  }

  renderBackgroundImage = () => {
    const { styleParameters } = this.state
    return (
      <ImageBackground
        source={{ uri: this.state.backgroundImage === '' ? undefined : this.state.backgroundImage }}
        style={getHomeStyles(styleParameters).backgroundImage}
      />
    )
  }

  renderLogo = () => {
    const { styleParameters } = this.state
    return (
      <View style={getHomeStyles(styleParameters).logoContainer}>
        <PartialTaktikIcon style={getHomeStyles(styleParameters).logo} />
      </View>
    )
  }

  renderTitleBlock = () => {
    const { styleParameters, teamName } = this.state
    const currentYear: string = new Date().getFullYear().toString()
    return (
      <View style={getHomeStyles(styleParameters).titleBlock}>
        <View style={getHomeStyles(styleParameters).titleContainer}>
          {this.renderLogo()}
          <Text adjustsFontSizeToFit style={getHomeStyles(styleParameters).titleText}>
            {teamName.toUpperCase()}
          </Text>
        </View>
        <View style={getHomeStyles(styleParameters).seasonContainer}>
          <Text adjustsFontSizeToFit style={getHomeStyles(styleParameters).seasonText}>
            {translator.t('home.season').toUpperCase() + currentYear}
          </Text>
        </View>
      </View>
    )
  }

  renderButton = (isProfileButton: boolean) => {
    const { styleParameters, hasTaktikPlaySubscription } = this.state
    const { navigation, user, isLoading } = this.props
    const text: string = isProfileButton
      ? translator.t('global.profile')
      : translator.t('global.practice')
    const subText: string = isProfileButton
      ? translator.t('home.profileSubText')
      : translator.t('home.practiceSubText')
    const onPressFunction: () => void = () => {
      if (isProfileButton) {
        const userIsNotLoadedYet: boolean = user.password === ''
        if (userIsNotLoadedYet) {
          return
        }

        if (user.role === UserRole.Coach) {
          userIsCoachAlert()
        } else {
          if (isLoading) {
            store.dispatch(toggleLoadingModal(true, PageName.PlayerStats))
          } else {
            navigation.navigate(PageName.PlayerStats)
          }
        }
      } else {
        if (hasTaktikPlaySubscription) {
          if (isLoading) {
            store.dispatch(toggleLoadingModal(true, PageName.GamificationPage))
          } else {
            store.dispatch(togglePracticeModal(true))
            store.dispatch(updateSelectedGroups([]))
          }
        }
      }
    }

    return (
      <View style={getHomeStyles(styleParameters, isProfileButton).ButtonContainer}>
        <TouchableOpacity
          style={getHomeStyles(styleParameters).touchableOpacityStyle}
          onPress={onPressFunction}
        >
          <View style={getHomeStyles(styleParameters, isProfileButton).buttonContentContainer}>
            <View style={getHomeStyles(styleParameters, isProfileButton).TextContainer}>
              <Text
                adjustsFontSizeToFit
                style={getHomeStyles(styleParameters, isProfileButton).ButtonText}
              >
                {text.toUpperCase()}
              </Text>
              <View style={getHomeStyles(styleParameters, isProfileButton).ButtonSubTextContainer}>
                <Text
                  adjustsFontSizeToFit
                  style={getHomeStyles(styleParameters, isProfileButton).ButtonSubText}
                >
                  {subText.toUpperCase()}
                </Text>
              </View>
              <View style={getHomeStyles(styleParameters, isProfileButton).ButtonSeparator} />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  renderButtonsRow = () => {
    return (
      <View style={getHomeStyles(this.state.styleParameters).buttonsRowContainer}>
        {this.renderButton(true)}
        {this.renderButton(false)}
      </View>
    )
  }

  setBackgroundImage = (teamInfo: any) => {
    if (teamInfo !== null) {
      this.setState({
        backgroundImage: teamInfo.background,
      })
    } else {
      this.setState({
        backgroundImage: '',
      })
    }
  }

  updateBackgroundImage_OnlineMode = () => {
    const { teamSelected } = this.props
    fetchTeam(teamSelected).then((teamInfo) => {
      if (teamInfo) {
        this.setBackgroundImage(teamInfo.team)
      }
    })
  }

  updateBackgroundImage_OfflineMode = () => {
    getLocalTeamInfo().then((teamInfo) => {
      this.setBackgroundImage(teamInfo)
    })
  }

  async updateBackgroundImage() {
    getLocalUserInfo().then(async (res) => {
      const isLoggedIn: boolean = this.props.url !== 'login' && res != null
      if (isLoggedIn) {
        const isConnected: boolean = !!(await connectedToInternet())
        if (isConnected) {
          this.updateBackgroundImage_OnlineMode()
        } else {
          this.updateBackgroundImage_OfflineMode()
        }
      } else {
        this.setState({
          backgroundImage: '',
        })
      }
    })
  }

  async updateUserInterface(teamSelected: number) {
    let userInfo: string | null = null
    try {
      userInfo = await AsyncStorage.getItem('userInfo')
    } catch (error) {
      logger.info(error)
    }
    if (userInfo) {
      const userMustBeLoggedOut: boolean = !JSON.parse(userInfo).accountActive
      this.updateLogoutCondition(userMustBeLoggedOut)

      const isConnected: boolean = !!(await connectedToInternet())
      let teamInfo

      if (isConnected) {
        const response = await Axios.get(`teams/${teamSelected}/`)
        if (response?.data?.team) {
          teamInfo = response.data.team
          await storeLocalTeamInfo(teamInfo)
        }
      } // Offline mode
      else {
        teamInfo = await getLocalTeamInfo()
      }

      const isLightTheme: boolean = teamInfo.primary === LightTheme.name
      setTheme(isLightTheme ? LightTheme : DarkTheme)
      setTeamColor(teamInfo.secondary)
      this.setTeamName(teamInfo.name)
      this.forceUpdate()
    }
  }

  setTeamName = (newTeamName: string) => {
    this.setState({ teamName: newTeamName })
  }

  updateLogoutCondition(userMustBeLoggedOut: boolean) {
    this.setState({ userMustBeLoggedOut })
  }

  renderLoadingPage = () => {
    const { navigation } = this.props
    const { userMustBeLoggedOut, styleParameters } = this.state

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        <TopBarMenu
          navigation={navigation}
          url={PageName.Home}
          updatePage={() => this.forceUpdate()}
          userMustBeLoggedOut={userMustBeLoggedOut}
          pageTitle=""
        />
        <View style={getHomeStyles(this.state.styleParameters).page}>
          {this.renderBackgroundImage()}
          {this.renderTitleBlock()}
          {this.renderButtonsRow()}
        </View>
        <LoadingModal navigation={navigation} />
      </View>
    )
  }

  selectTeam = async () => {
    const { navigation, teamSelected } = this.props
    const teamHasBeenSelected = teamSelected === null || teamSelected === -1
    if (teamHasBeenSelected) {
      const tokens = JSON.parse((await AsyncStorage.getItem('tokens')) as string)
      const numberOfTeamsWithAccess: number = tokens.length

      const hasNoLoadedTeams: boolean = numberOfTeamsWithAccess === 0
      const hasSingleTeam: boolean = numberOfTeamsWithAccess === 1
      const hasMultipleTeams: boolean = numberOfTeamsWithAccess > 1

      if (hasNoLoadedTeams) {
        logger.warn(`No teams detected for the user. This shouldn't be happening...`)
      } else if (hasSingleTeam) {
        store.dispatch(setSelectedTeam(tokens[0].teamID))
        store.dispatch(setSelectedToken(tokens[0].token))
      } else if (hasMultipleTeams) {
        navigation.navigate(PageName.TeamSelectionPage)
      } else {
        logger.warn(
          `Non-positive (${numberOfTeamsWithAccess}) teams detected. This shouldn't be happening...`
        )
      }
    }
  }

  render() {
    const { navigation, isLoading, invitationToken, renderInvitationModal } = this.props
    const { userMustBeLoggedOut, styleParameters } = this.state

    this.selectTeam()

    if (isLoading) {
      return this.renderLoadingPage()
    }

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        <TopBarMenu
          navigation={navigation}
          url={PageName.Home}
          updatePage={() => this.forceUpdate()}
          userMustBeLoggedOut={userMustBeLoggedOut}
        />

        <View style={getHomeStyles(styleParameters).page}>
          {this.renderBackgroundImage()}
          {this.renderTitleBlock()}
          {this.renderButtonsRow()}
        </View>

        {renderInvitationModal && invitationToken && (
          <UserTeamInviteModal token={invitationToken} navigation={navigation} />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.cache.loadingImages,
  user: state.auth.user,
  taktikServerVersion: state.version.taktikServerVersion,
  masterTaktikServerVersion: state.version.masterTaktikServerVersion,
  teamSelected: state.team.selectedTeam as number,
  invitationToken: state.invitation.token,
  renderInvitationModal: state.modals.invitationModalIsVisible,
  storeVersion: state.version.storeVersion,
})

export default connect(mapStateToProps, {
  playbooksLoad,
  toggleLoadingModal,
  togglePracticeModal,
  updateSelectedGroups,
  setSelectedTeam,
  setSelectedToken,
})(HomePage)
