import { StyleSheet, ScaledSize } from 'react-native'
import { StyledComponentsTheme, GlobalColors } from '../Theme'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/LoadingBarRatiosStyles'
import { StyleParameters } from '../Utilities'

export default function loadingBarStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  _rowSize: number,
  styleParameters: StyleParameters
) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(styleParameters)
  return StyleSheet.create({
    modalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: styleParameters.screenWidth,
      height: styleParameters.screenHeight,
      backgroundColor: theme.colors.popUpBackground,
    },
    modalContainer: {
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      marginTop: screenRatio.ModalContainer_marginTop,
      width: screenRatio.ModalContainer_width,
      height: screenRatio.ModalContainer_height,
      borderRadius: screenRatio.ModalContainer_borderRadius,
    },
    modalTitleText: {
      marginTop: screenRatio.ModalTitleText_marginTop,
      marginBottom: '5%',
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalTitleText_fontSize,
      marginHorizontal: '5%',
    },
    loadingProgressBar: {
      height: screenRatio.ModalLoadingProgressBar_height,
      width: '90%',
      backgroundColor: GlobalColors.paleGrey,
    },
    loadingProgressBarFill: {
      height: screenRatio.ModalLoadingProgressBar_height,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    loadingProgressBarText: {
      color: GlobalColors.white,
      fontFamily: 'roboto-light-italic',
      fontSize: screenRatio.ModalLoadingProgressBarText_fontSize,
      alignSelf: 'flex-end',
      marginRight: 5,
    },
    ModalCloseButtonIcon: {
      width: screenRatio.Icon_width,
      color: GlobalColors.paleGrey,
      alignSelf: 'flex-start',
      padding: '1%',
    },
    ModalCloseButton: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '10%',
      margin: '3%',
    },
    ModalContent: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '50%',
    },
  })
}
