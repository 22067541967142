import translator from '../../../config/Translation/languages'
import {
  storeLocalUserInfo,
  deleteLocalUserInfo,
  setLocalAuthorizationToken,
  setUserTeamsTokens,
} from '../../Utilitary/ConnectionUtils'
import Axios from 'axios'
import { store } from '../../redux/store'
import {
  toggleLogin,
  playbooksLoad,
  resetTaktikServerVersion,
  setSelectedTeam,
  setTeamInfos,
  updateUserInfo,
  setTokens,
  setSelectedToken,
} from '../../redux/actions'
import { logger } from '../../../lib/logger'
import { setTeamColor, setTheme } from '../../Styles/AppStyle'
import { DarkTheme, GlobalColors } from '../../Styles/Theme'
import { User } from '../../DataTypes/UserTypes'
import { storageWipeAllData } from '../../Utilitary/StorageUtils'
import { Dispatch } from 'react'
import { selectAndLoadTeam } from '../../components/TeamOption/thunks'
import { AuthenticationPayload, ValidAuthenticationPayload } from './types'
import { fetchTeams } from './utils'

const attemptLoginAndHandleExceptions = async (
  body: any,
  reject: any
): Promise<ValidAuthenticationPayload> => {
  const response = await Axios.post<AuthenticationPayload>('auth', body, { timeout: 10000 })
  const payload = response.data

  let errorMessage: string = ''

  try {
    // @ts-ignore
    errorMessage = payload.message // Will work only if type of `payload` is InvalidAuthenticationPayload
  } catch {}

  const isWrongLogin: boolean = errorMessage === 'Invalid credentials'

  if (isWrongLogin) {
    return reject(new Error(translator.t('error.field.wrongLogin')))
  }

  if (!payload.success) {
    return reject(new Error(translator.t('errors.userHasNoTeam')))
  }

  return payload
}

export const authenticate =
  (body: { email: string; password: string }) =>
  (dispatch: Dispatch<any>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        const payload = await attemptLoginAndHandleExceptions(body, reject)

        const { token, user, tokens } = payload

        await storageWipeAllData()

        await storeLocalUserInfo(user)
        dispatch(updateUserInfo(user))

        await setLocalAuthorizationToken(token)
        await setUserTeamsTokens(tokens)
        dispatch(setTokens(tokens))

        const teamIDs = tokens.map(({ teamID }) => teamID)
        const teamInfos = await fetchTeams(teamIDs)
        if (teamInfos) {
          dispatch(setTeamInfos(teamInfos))
        }

        if (tokens.length === 1) {
          await dispatch(selectAndLoadTeam(tokens[0].teamID))
        } else {
          dispatch(setSelectedTeam(null))
          dispatch(setSelectedToken(''))
        }

        dispatch(toggleLogin(true))

        return resolve()
      } catch {
        return reject(new Error(translator.t('error.serverConnection')))
      }
    })
  }

export function logout() {
  deleteLocalUserInfo().then()
  store.dispatch(resetTaktikServerVersion())
  store.dispatch(playbooksLoad([]))
  store.dispatch(setSelectedTeam(null))
  store.dispatch(setTeamInfos(null))
  setTheme(DarkTheme)
  setTeamColor(GlobalColors.defaultTeamColor)
  store.dispatch(toggleLogin(false))
}

export async function forgotPassword(email: string) {
  return await Axios.post('auth/forgot', { email })
    .then((response) => {
      logger.info('Success : ' + response)
      return true
    })
    .catch((error) => {
      throw new Error(error.response.data.message)
    })
}

export async function changePassword(email: string, currentPassword: string, newPassword: string) {
  return await Axios.post('auth', { email, password: currentPassword }, { timeout: 60000 })
    .then(async (response) => {
      await storeLocalUserInfo(response.data.user as User)
      await setLocalAuthorizationToken(response.data.token)
      const validResponse = await Axios.post('users/changePassword', {
        currentPassword,
        newPassword,
      })
        .then((response) => {
          return response
        })
        .catch(async (error) => {
          await deleteLocalUserInfo()
          throw error
        })
      await deleteLocalUserInfo()
      return validResponse.status
    })
    .catch((error) => {
      throw new Error(error.response.data.message)
    })
}
