import {
  storageGetAllItems,
  storageInsertItem,
  StorageItem,
  storageRemoveItem,
  StoreNames,
} from '../Utilitary/StorageUtils'
import { axiosPostStudyTime } from '../Utilitary/DataUtils'
import { generateStudyTimeKey, StudyTime } from '../DataTypes/StudyTimes'
import { logger } from '../../lib/logger'
import { UserRole } from '../DataTypes/UserTypes'
import {
  getLocalUserInfo,
  getLocalSessionID,
  connectedToInternet,
} from '../Utilitary/ConnectionUtils'

export async function synchronizeStudyTimes(viewPage: any | null = null) {
  const emptySessionStudyTimeState: any = { currentSessionStudyTime: null }
  const { currentSessionStudyTime } = viewPage ? viewPage.state : emptySessionStudyTimeState
  const userRole = (await getLocalUserInfo()).role

  if (userRole === UserRole.Coach) {
    // Do not submit study times as a coach
    return
  }

  let toUpdate: StorageItem[] = []
  const stored = await storageGetAllItems(StoreNames.StudyTimes)
  if (stored) {
    toUpdate = [...stored]
  }
  if (currentSessionStudyTime) {
    const { currentSessionStudyTime } = viewPage.state
    const studyKey = generateStudyTimeKey(currentSessionStudyTime)
    toUpdate = toUpdate.filter((item) => item.key !== studyKey)
    toUpdate.push({ key: studyKey, value: JSON.stringify(currentSessionStudyTime) })
  }
  toUpdate.forEach(async (storageItem) => {
    const studyTime = JSON.parse(storageItem.value) as StudyTime
    const entryIsCorrupted: boolean = studyTime.userID === -1 || studyTime.playbookID === 0
    if (entryIsCorrupted) {
      storageRemoveItem(generateStudyTimeKey(studyTime), StoreNames.StudyTimes)
    } else {
      if (await connectedToInternet()) {
        axiosPostStudyTime(studyTime)
          .then(async (success) => {
            if (success) {
              if (viewPage) {
                viewPage.setState({
                  currentSessionStudyTime: { ...currentSessionStudyTime, duration: 0 },
                })
              }
              storageRemoveItem(generateStudyTimeKey(studyTime), StoreNames.StudyTimes)
            } else {
              storageInsertItem(
                generateStudyTimeKey(studyTime),
                storageItem.value,
                StoreNames.StudyTimes
              )
            }
          })
          .catch((error) => {
            logger.info(error)
          })
      } else {
        storageInsertItem(generateStudyTimeKey(studyTime), storageItem.value, StoreNames.StudyTimes)
      }
    }
  })
}

export function incrementStudyDuration(viewPage: any) {
  if (viewPage.isFocused) {
    viewPage.setState((oldState: any) => {
      const result = oldState.currentSessionStudyTime
      result.duration += 1
      return { currentSessionStudyTime: result }
    })
  }
}

export function setAsyncStudyTimeState(viewPage: any) {
  getLocalSessionID()
    .then((sessionID) => {
      viewPage.setState((oldState: any) => {
        const result = oldState.currentSessionStudyTime
        result.sessionID = sessionID
        return { currentSessionStudyTime: result }
      })
    })
    .catch((error) => {
      logger.info(error)
    })

  getLocalUserInfo()
    .then((user) => {
      viewPage.setState((oldState: any) => {
        const result = oldState.currentSessionStudyTime
        result.userID = user.id
        return { currentSessionStudyTime: result }
      })
    })
    .catch((error) => {
      logger.info(error)
    })
}

export async function blurHandler(viewPage: any) {
  if (!viewPage.isFocused) {
    synchronizeStudyTimes(viewPage)
      .then()
      .catch((error) => {
        logger.info(error)
      })
  }
}
