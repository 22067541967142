import React from 'react'
import { View, Text, ImageBackground, TouchableOpacity, Dimensions } from 'react-native'

import { NavigationInterface, PageName } from './PageInfo'
import translator from '@taktik/config/Translation/languages'
import TopBarMenu from './TopBarMenu'
import BasicPage from './BasicPage'
import { getGlobalStyles, getTeamSelectionStyles, updateStyleParameters } from '../Styles/AppStyle'
import { setTeamInfos, setSelectedTeam, setSelectedToken } from '../redux/actions'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { connect } from 'react-redux'
import { MaterialIndicator } from 'react-native-indicators'
import { RootState } from '../redux/reducers'

import { User } from '../DataTypes/UserTypes'
import TeamOption from '../components/TeamOption'
import { TeamInfos } from '../redux/reducers/teams'

const imagePath = require('../../assets/team-selection-background.png')

interface TeamSelectionProps extends NavigationInterface {
  teams: TeamInfos[] | null
  user: User
}

interface TeamSelectionState {
  styleParameters: StyleParameters
  isLoading: boolean
  selectedTeamId: number | undefined
  mustShowDeleteIcon: boolean
  userID: number
  mustShowManageButton: boolean
  mustShowFinishButton: boolean
}

class TeamSelectionPage extends BasicPage<TeamSelectionProps, TeamSelectionState> {
  state = {
    styleParameters: getStyleParameters(),
    isLoading: false,
    selectedTeamId: undefined,
    mustShowDeleteIcon: false,
    userID: this.props.user.id,
    mustShowManageButton: true,
    mustShowFinishButton: false,
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this.onChange)
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.onChange)
  }

  renderButton = () => {
    const { styleParameters, mustShowManageButton, mustShowFinishButton } = this.state
    const styles = getTeamSelectionStyles(styleParameters)

    if (mustShowManageButton) {
      return (
        <View>
          <TouchableOpacity
            onPress={() =>
              this.setState({
                mustShowDeleteIcon: true,
                mustShowFinishButton: true,
                mustShowManageButton: false,
              })
            }
          >
            <Text style={styles.manageMyTeam}>
              {translator.t('topBarMenu.manageMyTeam').toUpperCase()}
            </Text>
          </TouchableOpacity>
        </View>
      )
    } else if (mustShowFinishButton) {
      return (
        <View>
          <TouchableOpacity
            onPress={() =>
              this.setState({
                mustShowDeleteIcon: false,
                mustShowFinishButton: false,
                mustShowManageButton: true,
              })
            }
          >
            <Text style={styles.manageMyTeam}>
              {translator.t('topBarMenu.finish').toUpperCase()}
            </Text>
          </TouchableOpacity>
        </View>
      )
    }
  }

  onTeamToggle = (teamId: number) => (isSelected: boolean) => {
    this.setState({
      isLoading: isSelected,
      selectedTeamId: isSelected ? teamId : undefined,
      // TODO: change for enum
      mustShowDeleteIcon: false,
      mustShowFinishButton: false,
      mustShowManageButton: false,
    })
  }

  getTeamsToRender = () => {
    const { isLoading, selectedTeamId } = this.state
    const { teams } = this.props
    return teams?.filter((team) => !isLoading || team.id === selectedTeamId) ?? []
  }

  getSelectedTeamName = (): string => {
    const { teams } = this.props
    const { selectedTeamId } = this.state
    return teams?.find((team) => team.id === selectedTeamId)?.name ?? ''
  }

  render() {
    const { styleParameters, isLoading, mustShowDeleteIcon } = this.state
    const { navigation } = this.props
    const styles = getTeamSelectionStyles(styleParameters)

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        <TopBarMenu
          navigation={navigation}
          url={PageName.TeamSelectionPage}
          updatePage={() => this.forceUpdate()}
          userMustBeLoggedOut={false}
        />
        <View style={styles.page}>
          <ImageBackground style={styles.backgroundImage} source={imagePath}>
            <View style={styles.choiceModal}>
              <Text style={styles.title}>
                {isLoading
                  ? translator.t('teamSelection.connectingTo') + ' ' + this.getSelectedTeamName()
                  : translator.t('teamSelection.title')}
              </Text>

              {isLoading && (
                <View style={styles.loaderContainer}>
                  <MaterialIndicator color="#ffa500" />
                </View>
              )}

              <View style={styles.teamsContainer}>
                {this.getTeamsToRender().map((team: TeamInfos) => (
                  <TeamOption
                    key={team.id}
                    team={team}
                    isFullPage
                    isLoading={isLoading}
                    navigation={navigation}
                    canDelete={mustShowDeleteIcon}
                    onToggle={this.onTeamToggle(team.id)}
                    styleParameters={styleParameters}
                  />
                ))}
              </View>

              {!isLoading && this.renderButton()}
            </View>
          </ImageBackground>
        </View>
      </View>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  teams: state.team.teamsInfos,
  user: state.auth.user,
})

export default connect(mapStateToProps, {
  setTeamInfos,
  setSelectedToken,
  setSelectedTeam,
})(TeamSelectionPage)
