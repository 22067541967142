import React from 'react'
import {
  Dimensions,
  Platform,
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import { DataTable } from 'react-native-paper'
import { Entypo, AntDesign } from '@expo/vector-icons'
import { LinearGradient } from 'expo-linear-gradient'
import { NavigationInterface, PageName } from './PageInfo'
import translator from '@taktik/config/Translation/languages'
import TopBarMenu from './TopBarMenu'
import { GlobalColors } from '../Styles/Theme'
import BasicPage from './BasicPage'
import {
  getPlayerStatsStyles,
  getGlobalStyles,
  graphAxisYStyle,
  graphAxisXStyle,
  graphChartStyle,
  updateStyleParameters,
} from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { graphWidth, graphHeight } from '../Styles/General/PlayerStatsStyles'
import { getScreenRatioStyle } from '../Styles/Peculiar/PlayerStatsRatiosStyles'
import {
  VictoryChart,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryLine,
} from '../components/VictoryImport'
import { axiosGetStats, axiosGetPlayerRank } from '../Utilitary/DataUtils'
import { connectedToInternet, getLocalUserInfo } from '../Utilitary/ConnectionUtils'
import { capitalizeFirstLetter, sortUniqueGroups } from '../Utilitary/Utils'
import { StatsType, storageGetItem, storageInsertItem, StoreNames } from '../Utilitary/StorageUtils'
import { logger } from '@taktik/lib/logger'
import { connect } from 'react-redux'
import { RootState } from '../redux/reducers'
import { Playbook, PlaybookFolder } from '../DataTypes/playbooks'
import EndPracticeModal from '../components/EndPracticeModal'
import { TaktikAlert } from '../Utilitary/Alert'

interface PlayerStatsPageProps extends NavigationInterface {
  url: PageName
  updatePage: () => void
  userMustBeLoggedOut: boolean
  pageTitle: string
  playbooks: Playbook[]
  folders: PlaybookFolder[]
  selectedTeamID: number
}

export enum TimeSpan {
  week = 7,
  month = 4,
  season = 12,
}

enum TableSort {
  none = 0,
  nameAscendent = 1,
  nameDescendent = 2,
  scoreAscendent = 3,
  scoreDescendent = 4,
  studyTimeAscendent = 5,
  studyTimeDescendent = 6,
}

interface GenericStats {
  name: string
  score: string
  studyTime: string
  group?: string
  image?: string
}
interface PlayerStatsPageState {
  stats: any
  timeSpanSelected: TimeSpan
  sortSelected: TableSort
  playbookGroupSelected: string
  dataMyStat: any[]
  dataTeamStat: any[]
  userGroups: string[]
  columnWidth: number
  thirdRowHeight: number
  styleParameters: StyleParameters
  NumberTimeTick: TimeSpan
  legendWidth: number
  timeSpan: number
  endPracticeModalVisible: boolean
  userID: number
  rank: number | null
}

class PlayerStatsPage extends BasicPage<PlayerStatsPageProps, PlayerStatsPageState> {
  constructor(public props: PlayerStatsPageProps) {
    super(props)

    const { isEndPractice } = this.props.route.params
    const { selectedTeamID } = this.props

    this.state = {
      stats: [],
      timeSpanSelected: TimeSpan.month,
      sortSelected: TableSort.nameAscendent,
      playbookGroupSelected: '',
      dataMyStat: [],
      dataTeamStat: [],
      userGroups: [],
      columnWidth: 100,
      thirdRowHeight: 100,
      NumberTimeTick: TimeSpan.month,
      legendWidth: 300,
      timeSpan: TimeSpan.month,
      styleParameters: getStyleParameters(),
      userID: 0,
      rank: 0,
      endPracticeModalVisible: isEndPractice,
    }

    getLocalUserInfo().then((res) => {
      const uid = res.id
      this.setState({ userID: uid })
      axiosGetPlayerRank(uid, selectedTeamID).then((rank) => {
        this.setState({ rank: rank })
      })
      const language = translator.locale.substring(0, 2)

      connectedToInternet().then((isConnected) => {
        if (isConnected) {
          logger.info('Connected to Taktik Server. Fetching stats...')
          this.getStatsFromServer(uid, selectedTeamID, language)
        } else {
          logger.info('Not connected to Taktik Server. About to display stored (offline) stats...')
          this.displayUsingOfflineStatsAlert()
          this.displayOfflineStats().then(() => {
            this.updateUserGroups()
          })
        }
      })
    })
  }

  displayPopupMessage = (messageToDisplay: string) => {
    TaktikAlert.alert(messageToDisplay, undefined, { text: 'OK' })
  }

  displayNoOfflineStatsAlert = () => {
    this.displayPopupMessage(translator.t('stats.noOfflineStats'))
  }

  displayUsingOfflineStatsAlert = () => {
    this.displayPopupMessage(translator.t('stats.usingOfflineStats'))
  }

  trimPlaybooksNames = (stats: any) => {
    const newStats = [...stats]
    for (let playbookIndex = 0; playbookIndex < stats.length; playbookIndex++) {
      if (newStats[playbookIndex].name[0] === ' ') {
        newStats[playbookIndex].name = newStats[playbookIndex].name.substring(1)
      }
    }
    return newStats
  }

  getStatsFromServer = async (uid: any, teamID: number, language: string) => {
    axiosGetStats(uid, teamID, language).then((stats) => {
      if (stats === null || stats.data === null) {
        TaktikAlert.alert(translator.t('stats.errorTitle'), translator.t('stats.errorText'), {
          text: 'OK',
          onPress: () => this.props.navigation.navigate(PageName.Home),
        })
      } else {
        stats.data.gameStats = this.trimPlaybooksNames(stats.data.gameStats)
        this.setState({
          stats: stats.data,
          dataMyStat: stats.data.userProgressionStats,
          dataTeamStat: stats.data.teamProgressionStats,
        })

        this.saveStatsToOfflineStorage(
          stats.data,
          stats.data.userProgressionStats,
          stats.data.teamProgressionStats
        )
      }

      this.updateUserGroups()
    })
  }

  updateUserGroups = () => {
    const { stats } = this.state

    if (!(stats.length === 0)) {
      let uniqueGroups: string[] = []
      stats.gameStats.map((object: any) => {
        if (uniqueGroups.indexOf(object.group) === -1) uniqueGroups.push(object.group)
      })

      uniqueGroups = sortUniqueGroups(uniqueGroups)
      let firstIndexNotUndefined = -1
      for (let groupIndex = 0; groupIndex < uniqueGroups.length; groupIndex++) {
        const isTheFirstIndexNotUndefined =
          firstIndexNotUndefined === -1 && uniqueGroups[groupIndex]
        if (isTheFirstIndexNotUndefined) {
          firstIndexNotUndefined = groupIndex
        }
      }
      const data = uniqueGroups.filter(Boolean)
      this.setState({
        userGroups: data,
        playbookGroupSelected: uniqueGroups[firstIndexNotUndefined],
      })
    }
  }

  saveStatsToOfflineStorage = (
    fullStats: any,
    userProgressionStats: any,
    teamProgressionStats: any
  ) => {
    storageInsertItem(StatsType.FullStats, JSON.stringify(fullStats), StoreNames.Stats)
      .then()
      .catch((error) => {
        logger.info(error)
      })

    storageInsertItem(StatsType.PlayerStats, JSON.stringify(userProgressionStats), StoreNames.Stats)
      .then()
      .catch((error) => {
        logger.info(error)
      })

    storageInsertItem(StatsType.TeamStats, JSON.stringify(teamProgressionStats), StoreNames.Stats)
      .then()
      .catch((error) => {
        logger.info(error)
      })
  }

  displayOfflineStats = async () => {
    const stats = JSON.parse((await storageGetItem(StatsType.FullStats, StoreNames.Stats))!) // as unknown as any[]

    if (!stats) {
      this.displayNoOfflineStatsAlert()
      return
    }

    const playerStats = JSON.parse(
      (await storageGetItem(StatsType.PlayerStats, StoreNames.Stats))!
    ) as unknown as any[]

    const teamStats = JSON.parse(
      (await storageGetItem(StatsType.TeamStats, StoreNames.Stats))!
    ) as unknown as any[]

    this.setState({
      stats: stats,
      dataMyStat: playerStats,
      dataTeamStat: teamStats,
    })
  }

  getEnglishNumberSuffix = (number: number | null) => {
    if (number === 1) {
      return translator.t('endPracticeModal.first').toUpperCase()
    } else if (number === 2) {
      return translator.t('endPracticeModal.second').toUpperCase()
    } else if (number === 3) {
      return translator.t('endPracticeModal.third').toUpperCase()
    } else {
      return translator.t('endPracticeModal.otherPosition').toUpperCase()
    }
  }

  renderEndPracticeModal = () => {
    const { goodAnswers, badAnswers, averageTimeResponse } = this.props.route.params
    const { endPracticeModalVisible } = this.state

    return (
      endPracticeModalVisible && (
        <EndPracticeModal
          averageTimeResponse={averageTimeResponse}
          numberOfWrongAnswers={badAnswers}
          numberOfGoodAnswers={goodAnswers}
        />
      )
    )
  }

  renderProfilPicture = () => {
    const { styleParameters, stats } = this.state
    return (
      <View style={getPlayerStatsStyles(styleParameters).ProfilPictureContainer}>
        <Image
          style={getPlayerStatsStyles(styleParameters).profil_picture}
          source={
            !stats.image?.includes('https')
              ? require('../../assets/profile-picture-placeholder.png')
              : {
                  uri: stats.image,
                }
          }
        />
      </View>
    )
  }

  renderNames = () => {
    const { styleParameters, stats } = this.state
    return (
      <View style={getPlayerStatsStyles(styleParameters).names}>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).name}>
          {stats.name.toUpperCase()}
        </Text>
        {/* <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).lvl}>
          {stats.previousLvl}
        </Text> */}
      </View>
    )
  }

  renderIds = () => {
    return (
      <View style={getPlayerStatsStyles(this.state.styleParameters).ids}>
        {this.renderProfilPicture()}
        {this.renderNames()}
      </View>
    )
  }

  renderProfilTitle = () => {
    const { styleParameters } = this.state
    return (
      <View style={getPlayerStatsStyles(styleParameters).ProfilContainer}>
        <Text style={getPlayerStatsStyles(styleParameters).profil}>
          {translator.t('global.profile').toUpperCase()}
        </Text>
      </View>
    )
  }

  renderIdRow = () => {
    return (
      <View style={getPlayerStatsStyles(this.state.styleParameters).row1}>
        {this.renderProfilTitle()}
        {this.renderIds()}
      </View>
    )
  }

  renderDiskIndicatorText = (isMyScore: boolean) => {
    const { styleParameters } = this.state

    return (
      <View style={getPlayerStatsStyles(styleParameters).TeamScoreText_container}>
        <Text
          adjustsFontSizeToFit
          style={getPlayerStatsStyles(styleParameters, isMyScore).ScoreText}
        >
          {isMyScore
            ? translator.t('stats.mySuccess').toUpperCase()
            : translator.t('stats.teamSuccessFirstRow').toUpperCase()}
        </Text>
        {!isMyScore && (
          <Text
            adjustsFontSizeToFit
            style={getPlayerStatsStyles(styleParameters, isMyScore).ScoreText}
          >
            {!isMyScore && translator.t('stats.teamSuccessSecondRow').toUpperCase()}
          </Text>
        )}
      </View>
    )
  }

  renderDiskIndicatorScore = (isMyScore: boolean) => {
    const { styleParameters, stats } = this.state
    let score = isMyScore ? stats.userScore : stats.teamScore
    if (score === null) {
      score = 'N/A'
    } else {
      score += '%'
    }
    return (
      <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters, isMyScore).Score100}>
        {score}
      </Text>
    )
  }

  renderScore = (isMyScore: boolean) => {
    const { styleParameters } = this.state

    return (
      <View style={getPlayerStatsStyles(styleParameters, isMyScore).ScoreContainer}>
        <View style={getPlayerStatsStyles(styleParameters).ScoreText_container}>
          {this.renderDiskIndicatorText(isMyScore)}
          {this.renderDiskIndicatorScore(isMyScore)}
        </View>
      </View>
    )
  }

  renderRatioOperatorText = (isNumerator: boolean) => {
    const { styleParameters } = this.state

    return (
      <View style={getPlayerStatsStyles(styleParameters).RatioTextContainer}>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).RatioText}>
          {isNumerator
            ? translator.t('stats.studiedGame').toUpperCase()
            : translator.t('stats.availableGame').toUpperCase()}
        </Text>
      </View>
    )
  }

  renderRatioOperatorScore = (isNumerator: boolean) => {
    const { styleParameters, stats } = this.state

    return (
      <View style={getPlayerStatsStyles(styleParameters).RatioScore_container}>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).RatioScore}>
          {isNumerator ? stats.nbGamesPlayed : stats.nbGamesAvailable}
        </Text>
      </View>
    )
  }

  renderRatioOperator = (isNumerator: boolean) => {
    return (
      <View style={getPlayerStatsStyles(this.state.styleParameters).RatioOperator}>
        {this.renderRatioOperatorScore(isNumerator)}
        {this.renderRatioOperatorText(isNumerator)}
      </View>
    )
  }

  renderRatio = () => {
    const { styleParameters } = this.state
    return (
      <View style={getPlayerStatsStyles(styleParameters).ratio}>
        {this.renderRatioOperator(true)}
        <View style={getPlayerStatsStyles(styleParameters).ratio_separator} />
        {this.renderRatioOperator(false)}
      </View>
    )
  }

  renderScores = () => {
    const { styleParameters } = this.state
    return (
      <View style={getPlayerStatsStyles(styleParameters).ScoreColumn}>
        <View style={getPlayerStatsStyles(styleParameters).scores}>
          <View style={getPlayerStatsStyles(styleParameters).circles_container}>
            {this.renderScore(true)}
            {this.renderScore(false)}
          </View>
          {this.renderRatio()}
        </View>
      </View>
    )
  }

  renderXpBarCurentXp = () => {
    const { styleParameters, stats } = this.state
    return (
      <View style={getPlayerStatsStyles(styleParameters).xpbar_top}>
        <View style={getPlayerStatsStyles(styleParameters).XpbarCurrentXp}>
          <Entypo
            name="arrow-long-down"
            style={getPlayerStatsStyles(styleParameters).XpbarCurrentXpArrow}
          />
          <View style={getPlayerStatsStyles(styleParameters).XpbarCurrentXpNumbers}>
            <Text style={getPlayerStatsStyles(styleParameters).XpbarCurrentXpScore}>
              {stats.experience + ' POINTS'}
            </Text>
            <Text style={getPlayerStatsStyles(styleParameters).XpbarCurrentXpText}>
              {translator.t('stats.only').toUpperCase() +
                (stats.nextLvlExperience - stats.experience) +
                translator.t('stats.tilNextLvl').toUpperCase()}
            </Text>
          </View>
        </View>
      </View>
    )
  }

  renderXpBar = () => {
    const { styleParameters } = this.state
    return (
      <View
        onLayout={(event: any) => {
          const { width } = event.nativeEvent.layout
          this.setState({ columnWidth: width + 60 })
        }}
        style={getPlayerStatsStyles(styleParameters).xpBar}
      >
        <LinearGradient
          colors={['#C0C0C0', '#3b3b3b']}
          style={getPlayerStatsStyles(styleParameters).xpBar_fill}
          locations={[0, 1]}
          start={[0, 0.5]}
          end={[1, 0.5]}
        />
      </View>
    )
  }

  renderXpBarLevelText = (isCurrentLvl: boolean) => {
    const { styleParameters, stats } = this.state

    return (
      <Text
        adjustsFontSizeToFit
        style={getPlayerStatsStyles(styleParameters).XpbarPreviousNextXpScore}
      >
        {isCurrentLvl
          ? stats.previousLvlExperience + ' POINTS'
          : stats.nextLvlExperience + ' POINTS'}
      </Text>
    )
  }

  renderXpBarLevelXp = (isCurrentLvl: boolean) => {
    const { styleParameters, stats } = this.state

    return (
      <Text
        adjustsFontSizeToFit
        style={getPlayerStatsStyles(styleParameters).XpbarPreviousNextXpText}
      >
        {isCurrentLvl ? stats.previousLvl : stats.nextLvl}
      </Text>
    )
  }

  renderXpBarLevel = (isCurrentLvl: boolean) => {
    const { styleParameters } = this.state

    return (
      <View style={getPlayerStatsStyles(styleParameters, isCurrentLvl).xpbar_lvl_left}>
        {this.renderXpBarLevelXp(isCurrentLvl)}
        {this.renderXpBarLevelText(isCurrentLvl)}
      </View>
    )
  }

  renderXpBarLevels = () => {
    return (
      <View style={getPlayerStatsStyles(this.state.styleParameters).xpbar_lvls}>
        {this.renderXpBarLevel(true)}
        {this.renderXpBarLevel(false)}
      </View>
    )
  }

  renderXpBarContainer = () => {
    return (
      <View style={getPlayerStatsStyles(this.state.styleParameters).XpBarContainer}>
        {this.renderXpBarCurentXp()}
        {this.renderXpBar()}
        {this.renderXpBarLevels()}
      </View>
    )
  }

  renderScoreRow = () => {
    return (
      <View style={getPlayerStatsStyles(this.state.styleParameters).row2}>
        {this.renderScores()}
        {/* {this.renderXpBarContainer()} */}
      </View>
    )
  }

  handleSortTable = (sort: number) => {
    if (sort === this.state.sortSelected) {
      this.setState({ sortSelected: TableSort.none })
    } else {
      this.setState({ sortSelected: sort })
    }
  }

  renderTabHeadButton = (sort: number) => {
    const { styleParameters, sortSelected } = this.state
    const name: any = sort % 2 === 0 ? 'caretdown' : 'caretup'
    const selected: boolean = sortSelected === sort
    return (
      <TouchableOpacity onPress={() => this.handleSortTable(sort)}>
        <AntDesign
          name={name}
          style={getPlayerStatsStyles(styleParameters, selected).TableHeaderButtonsArrow}
        />
      </TouchableOpacity>
    )
  }

  renderTabHeadButtons = (sortUp: number, sortDown: number) => {
    return (
      <View style={getPlayerStatsStyles(this.state.styleParameters).table_header_buttons_container}>
        {this.renderTabHeadButton(sortUp)}
        {this.renderTabHeadButton(sortDown)}
      </View>
    )
  }

  renderTabHeadCell = (cellName: string, sort: number[]) => {
    const { styleParameters } = this.state
    const nameCell = cellName === translator.t('stats.playbook')

    return (
      <View key={cellName} style={getPlayerStatsStyles(styleParameters, nameCell).table_head_cell}>
        {!nameCell && <View style={getPlayerStatsStyles(styleParameters).TableHeaderSeparator} />}
        <View style={getPlayerStatsStyles(styleParameters).TableHeaderText_container}>
          {this.renderTabHeadButtons(sort[0], sort[1])}
          <Text style={getPlayerStatsStyles(styleParameters).TableHeaderText}>
            {cellName.toUpperCase()}
          </Text>
        </View>
      </View>
    )
  }

  sortTabData = (rowA: any, rowB: any) => {
    const sortSelected = this.state.sortSelected
    const isName =
      sortSelected === TableSort.nameAscendent || sortSelected === TableSort.nameDescendent
    const isScore =
      sortSelected === TableSort.scoreAscendent || sortSelected === TableSort.scoreDescendent
    const isAscendent =
      sortSelected === TableSort.studyTimeAscendent ||
      sortSelected === TableSort.scoreAscendent ||
      sortSelected === TableSort.nameAscendent
    let propA: string | number
    let propB: string | number
    if (isName) {
      propA = rowA.name.toUpperCase()
      propB = rowB.name.toUpperCase()
    } else if (isScore) {
      propA = parseFloat(rowA.score.toUpperCase()) / 100
      propB = parseFloat(rowB.score.toUpperCase()) / 100
    } else {
      propA = parseFloat(rowA.studyTime.toUpperCase())
      propB = parseFloat(rowB.studyTime.toUpperCase())
    }
    if (isAscendent) {
      if (propA < propB) {
        return -1
      }
      if (propA > propB) {
        return 1
      }
      return 0
    } else {
      if (propA < propB) {
        return 1
      }
      if (propA > propB) {
        return -1
      }
      return 0
    }
  }

  renderSortTabData = (tabData: any[]) => {
    tabData.sort(this.sortTabData)
    const res = []
    for (let rowIndex = 0; rowIndex < tabData.length; rowIndex++) {
      res.push([
        tabData[rowIndex].name,
        tabData[rowIndex].score,
        tabData[rowIndex].studyTime,
        tabData[rowIndex].id,
      ])
    }
    return res
  }

  goToPlaybook = (playbookID: number) => {
    const { navigation } = this.props

    navigation.navigate(PageName.PlaybookPage, {
      playbookID: playbookID,
      playbookIDs: [playbookID],
    })
  }

  renderCell = (data: string, isName: boolean, playbookID?: number) => {
    const { styleParameters } = this.state
    const textStyle = isName
      ? getPlayerStatsStyles(styleParameters).table_name_text
      : getPlayerStatsStyles(styleParameters).table_text

    const cellStyle = isName
      ? getPlayerStatsStyles(styleParameters).table_name_cell
      : getPlayerStatsStyles(styleParameters).table_cell

    return isName ? (
      <TouchableOpacity
        onPress={() => {
          if (playbookID) this.goToPlaybook(playbookID)
        }}
      >
        <View style={cellStyle}>
          <Text adjustsFontSizeToFit style={textStyle}>
            {data.toUpperCase()}
          </Text>
        </View>
      </TouchableOpacity>
    ) : (
      <View style={cellStyle}>
        <Text adjustsFontSizeToFit style={textStyle}>
          {data.toUpperCase()}
        </Text>
      </View>
    )
  }

  renderTab = () => {
    const { styleParameters, playbookGroupSelected, stats } = this.state
    const tableHead = [
      this.renderTabHeadCell(translator.t('stats.playbook'), [
        TableSort.nameAscendent,
        TableSort.nameDescendent,
      ]),
      this.renderTabHeadCell(translator.t('global.successRate'), [
        TableSort.scoreAscendent,
        TableSort.scoreDescendent,
      ]),
      this.renderTabHeadCell(translator.t('global.studyTime'), [
        TableSort.studyTimeAscendent,
        TableSort.studyTimeDescendent,
      ]),
    ]

    const correctGroupData = stats.gameStats.filter(
      (stat: GenericStats) => stat['group'] === playbookGroupSelected
    )

    const rowData = this.renderSortTabData(correctGroupData)

    return (
      <View style={getPlayerStatsStyles(styleParameters).table_container}>
        <DataTable
          onLayout={(event: any) => {
            const { height } = event.nativeEvent.layout
            this.setState({ thirdRowHeight: height })
          }}
          style={getPlayerStatsStyles(styleParameters).table}
        >
          <View style={getPlayerStatsStyles(styleParameters).table_HeaderRow}>{tableHead}</View>
          <ScrollView
            nestedScrollEnabled
            style={getPlayerStatsStyles(styleParameters).dataWrapper}
            showsVerticalScrollIndicator={false}
          >
            {rowData.map((rowData, index) => {
              return (
                <View key={index} style={getPlayerStatsStyles(styleParameters).table_row}>
                  {this.renderCell(rowData[0], true, rowData[3])}
                  {this.renderCell(rowData[1], false)}
                  {this.renderCell(rowData[2], false)}
                </View>
              )
            })}
          </ScrollView>
        </DataTable>
      </View>
    )
  }

  handleChangingPlaybookGroup = (playbookGroup: string) => {
    this.setState({ playbookGroupSelected: playbookGroup })
  }

  renderPlaybookGroupsButton = (playbookGroup: string) => {
    const { styleParameters, playbookGroupSelected } = this.state
    const playbookSelected: boolean = playbookGroupSelected === playbookGroup
    return (
      <View
        key={playbookGroup}
        style={getPlayerStatsStyles(styleParameters, playbookSelected).PlaybookGroupButton}
      >
        <TouchableOpacity
          style={{ width: '100%', height: '100%' }}
          onPress={() => this.handleChangingPlaybookGroup(playbookGroup)}
        >
          <Text style={getPlayerStatsStyles(styleParameters, playbookSelected).PlaybookGroupText}>
            {capitalizeFirstLetter(playbookGroup)}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderPlaybookGroupsButtons = () => {
    const { styleParameters, userGroups } = this.state

    return (
      <View style={getPlayerStatsStyles(styleParameters).PlaybookGroupButtonsRow}>
        <ScrollView
          horizontal
          alwaysBounceHorizontal={false}
          showsHorizontalScrollIndicator={false}
        >
          {userGroups.map((group) => this.renderPlaybookGroupsButton(group))}
        </ScrollView>
      </View>
    )
  }

  renderTabColumn = () => {
    const { styleParameters } = this.state
    return (
      <View style={getPlayerStatsStyles(styleParameters).TableColumn}>
        <View style={getPlayerStatsStyles(styleParameters).graph_TableTitle_container}>
          <Text style={getPlayerStatsStyles(styleParameters).TableTitle}>
            {translator.t('stats.myLearning').toUpperCase()}
          </Text>
        </View>
        {this.renderPlaybookGroupsButtons()}
        {this.renderTab()}
      </View>
    )
  }

  renderGraphTopLegendRow = (isMyScore: boolean) => {
    const { styleParameters, legendWidth } = this.state

    const onLayoutFunction: any = isMyScore
      ? undefined
      : (event: any) => {
          const { width } = event.nativeEvent.layout
          this.setState({ legendWidth: width })
        }
    const text: string = isMyScore
      ? translator.t('stats.myProgress')
      : translator.t('stats.teamProgress')
    const textStyle = [
      getPlayerStatsStyles(styleParameters).GraphTopLegendText,
      isMyScore ? { width: legendWidth } : {},
    ]

    return (
      <View style={getPlayerStatsStyles(styleParameters).GraphTopLegendRow}>
        <View style={getPlayerStatsStyles(styleParameters, isMyScore).GraphTopLegendScoreBar} />
        <Text onLayout={onLayoutFunction} style={textStyle}>
          {text}
        </Text>
      </View>
    )
  }

  renderGraphTopLegend = () => {
    const { styleParameters } = this.state
    return (
      <View style={getPlayerStatsStyles(styleParameters).GraphTopLegendContainer}>
        {this.renderGraphTopLegendRow(true)}
        {this.renderGraphTopLegendRow(false)}
      </View>
    )
  }

  handleChangingTimeSpan = (span: number) => {
    this.setState({ timeSpanSelected: span })
  }

  renderGraphTopButton = (span: number) => {
    const { styleParameters, timeSpanSelected } = this.state
    const selected: boolean = timeSpanSelected === span
    let textSpan: string = 'Semaine'

    switch (span) {
      case TimeSpan.week: {
        textSpan = translator.t('global.week')
        break
      }
      case TimeSpan.month: {
        textSpan = translator.t('global.month')
        break
      }
      case TimeSpan.season: {
        textSpan = translator.t('global.season')
        break
      }
    }

    return (
      <View style={getPlayerStatsStyles(styleParameters, selected).GraphTopButton}>
        <TouchableOpacity
          style={{ width: '100%', height: '100%' }}
          onPress={() => this.handleChangingTimeSpan(span)}
        >
          <Text style={getPlayerStatsStyles(styleParameters, selected).GraphTopButton_text}>
            {textSpan}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderGraphTopBarButtons = () => {
    const { styleParameters } = this.state
    const separator = <View style={getPlayerStatsStyles(styleParameters).GraphButtonSeparator} />
    return (
      <View style={getPlayerStatsStyles(styleParameters).GraphButtonContainer}>
        {this.renderGraphTopButton(TimeSpan.week)}
        {separator}
        {this.renderGraphTopButton(TimeSpan.month)}
        {separator}
        {this.renderGraphTopButton(TimeSpan.season)}
      </View>
    )
  }

  renderGraphTopBar = (isOneRowLayout: boolean) => {
    const { styleParameters } = this.state
    if (isOneRowLayout) {
      return (
        <View style={getPlayerStatsStyles(styleParameters).GraphTopRow}>
          {this.renderGraphTopBarButtons()}
          {this.renderGraphTopLegend()}
        </View>
      )
    } else {
      return (
        <View style={getPlayerStatsStyles(styleParameters).GraphTopRow}>
          {this.renderGraphTopBarButtons()}
        </View>
      )
    }
  }

  getPreviousMonday = () => {
    var ref = new Date(new Date())
    if (ref.getDay() !== 1) {
      var today = new Date(ref.getFullYear(), ref.getMonth(), ref.getDate())
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      return new Date(today.getFullYear(), today.getMonth(), today.getDate())
    } else {
      return new Date(ref.getFullYear(), ref.getMonth(), ref.getDate())
    }
  }

  getMondays = () => {
    const ref = new Date()
    var d = new Date(ref.getFullYear(), ref.getMonth(), ref.getDate()),
      month = d.getMonth(),
      mondays = []
    d.setDate(1)
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1)
    }
    while (d.getMonth() === month) {
      mondays.push(new Date(d.getTime()))
      d.setDate(d.getDate() + 7)
    }
    return mondays
  }

  getXTicksValues = () => {
    const ticksValues = []
    const ref: any = new Date()
    switch (this.state.timeSpanSelected) {
      case TimeSpan.month: {
        const mondays: any[] = this.getMondays()
        const isaFourMondayMonth = mondays.length === 4
        const tickPadding = new Date(new Date(mondays[0]).setDate(mondays[0].getDate()))
        ticksValues.push(tickPadding)
        for (let mondayIndex = 0; mondayIndex < mondays.length; mondayIndex++) {
          ticksValues.push(mondays[mondayIndex])
        }
        if (isaFourMondayMonth) {
          ticksValues.push(new Date(new Date(mondays[3]).setDate(mondays[3].getDate() + 7)))
        }
        break
      }

      case TimeSpan.week: {
        const previousMonday: any = this.getPreviousMonday()
        const tickPadding = new Date(previousMonday)
        ticksValues.push(tickPadding)
        ticksValues.push(new Date(previousMonday))
        for (let day = 0; day < 7; day++) {
          ticksValues.push(new Date(previousMonday.setDate(previousMonday.getDate() + 1)))
        }
        break
      }

      case TimeSpan.season:
        for (let month = 0; month < ref.getMonth() + 2; month++)
          ticksValues.push(new Date(ref.getFullYear(), month, 1))

        break
    }
    return ticksValues
  }

  findDataByDate = (data: any[], firstDay: Date) => {
    for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
      const d = new Date(data[dataIndex].x)
      data[dataIndex].x = new Date(d.getFullYear(), d.getMonth(), d.getDate())
      if (data[dataIndex].x.getTime() === firstDay.getTime()) {
        return dataIndex
      }
    }
    return -1
  }

  findCloserNotNullData = (data: any, d: Date) => {
    let value: number = -1
    if (d < new Date(data[0].x)) {
      return 0
    } else {
      let nbDaysDifference: number = 0
      while (value === -1) {
        const index = this.findDataByDate(
          data,
          new Date(d.getFullYear(), d.getMonth(), d.getDate() + nbDaysDifference)
        )
        if (index !== -1) {
          value = data[index].y
        }

        nbDaysDifference -= 1
      }
      return value
    }
  }

  getGraphMonthData = (data: any) => {
    const res = []
    const mondays: any[] = this.getMondays()
    const firstDay: any = new Date(new Date(mondays[0]).setDate(mondays[0].getDate()))
    let index = this.findDataByDate(data, firstDay)
    for (let dayIndex = 0; dayIndex < 29; dayIndex++) {
      const x = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + dayIndex)
      index = this.findDataByDate(data, x)
      const isNullOrMoreRecent = data.length === 0 || x > new Date()
      if (index === -1) {
        res.push({
          x: x,
          y: isNullOrMoreRecent ? null : this.findCloserNotNullData(data, x),
        })
      } else {
        res.push({
          x: x,
          y: data[index].y,
        })
      }
    }
    return res
  }

  getGraphWeekData = (data: any) => {
    const res = []
    const previousMonday: any = this.getPreviousMonday()
    for (let dayIndex = -1; dayIndex < 7; dayIndex++) {
      const x = new Date(
        previousMonday.getFullYear(),
        previousMonday.getMonth(),
        previousMonday.getDate() + dayIndex
      )
      const index = this.findDataByDate(data, x)
      const isNullOrMoreRecent = data.length === 0 || x > new Date()
      if (index === -1) {
        res.push({
          x: new Date(x),
          y: isNullOrMoreRecent ? null : this.findCloserNotNullData(data, x),
        })
      } else {
        res.push({
          x: x,
          y: data[index].y,
        })
      }
    }
    return res
  }

  getGraphSeasonData = (data: any) => {
    const res = []
    const today = new Date()
    let ref: any = new Date(today.getFullYear(), today.getMonth() + 1, 1)
    ref = new Date(ref.getFullYear(), ref.getMonth(), ref.getDate())
    const firstDay: any = new Date(today.getFullYear(), 0, 1)

    const nbDay = Math.floor((ref - firstDay) / 86400000)
    for (let dayIndex = 0; dayIndex < nbDay + 1; dayIndex++) {
      const x = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + dayIndex)
      const index = this.findDataByDate(data, x)
      const isNullOrMoreRecent = data.length === 0 || x > new Date()
      if (index === -1) {
        res.push({
          x: x,
          y: isNullOrMoreRecent ? null : this.findCloserNotNullData(data, x),
        })
      } else {
        res.push({
          x: x,
          y: data[index].y,
        })
      }
    }
    return res
  }

  getGraphData = (isMyScore: boolean) => {
    const { dataMyStat, dataTeamStat, timeSpanSelected } = this.state
    const data = isMyScore ? dataMyStat : dataTeamStat

    switch (timeSpanSelected) {
      case TimeSpan.month: {
        return data.length === 0 ? [] : this.getGraphMonthData(data)
      }
      case TimeSpan.week: {
        return data.length === 0 ? [] : this.getGraphWeekData(data)
      }
      case TimeSpan.season: {
        return data.length === 0 ? [] : this.getGraphSeasonData(data)
      }
    }
  }

  getEnglishOrdinalTerminaison = (date: number) => {
    if (date > 3 && date < 21) return 'th'
    switch (date % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  getXAxisLegendMonthFrTwoRow = (
    monday: number,
    sunday: number,
    mondayMonth: string,
    sundayMonth: string
  ) => {
    const { styleParameters } = this.state

    const firstRowLabel =
      sunday - monday < 0
        ? monday + ' ' + mondayMonth + ' ' + translator.t('stats.to') + ' ' + sunday
        : monday + ' ' + translator.t('stats.to') + ' ' + sunday

    const secondRowLabel = sunday - monday < 0 ? sundayMonth : mondayMonth

    return (
      <View style={getPlayerStatsStyles(styleParameters).XAxisContainerLabel}>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).XAxisLabel}>
          {firstRowLabel}
        </Text>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).XAxisLabel}>
          {secondRowLabel}
        </Text>
      </View>
    )
  }

  getXAxisLegendMonthFrOneRow = (
    monday: number,
    sunday: number,
    mondayMonth: string,
    sundayMonth: string
  ) => {
    const { styleParameters } = this.state
    const label =
      sunday - monday < 0
        ? monday +
          ' ' +
          mondayMonth +
          ' ' +
          translator.t('stats.to') +
          ' ' +
          sunday +
          ' ' +
          sundayMonth
        : monday + ' ' + translator.t('stats.to') + ' ' + sunday + ' ' + mondayMonth
    return (
      <View
        key={monday + mondayMonth}
        style={getPlayerStatsStyles(styleParameters).XAxisContainerLabel}
      >
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).XAxisLabel}>
          {label}
        </Text>
      </View>
    )
  }

  getXAxisLegendMonthFr = (monday: Date, sunday: Date) => {
    const mondayMonth =
      Platform.OS === 'android'
        ? monday.toLocaleString('fr-FR', { month: 'long' })
        : monday.toLocaleString('fr-FR', { month: 'long' } as any).substring(0, 3)
    const sundayMonth =
      Platform.OS === 'android'
        ? sunday.toLocaleString('fr-FR', { month: 'long' })
        : sunday.toLocaleString('fr-FR', { month: 'long' } as any).substring(0, 3)

    const mondayDate = monday.getDate()
    const sundayDate = sunday.getDate() - 1

    if (!this.state.styleParameters.isHorizontal) {
      return this.getXAxisLegendMonthFrTwoRow(mondayDate, sundayDate, mondayMonth, sundayMonth)
    } else {
      return this.getXAxisLegendMonthFrOneRow(mondayDate, sundayDate, mondayMonth, sundayMonth)
    }
  }

  getXAxisLegendMonthEnTwoRow = (
    monday: number,
    sunday: number,
    mondayMonth: string,
    sundayMonth: string
  ) => {
    const { styleParameters } = this.state
    const monTerm = this.getEnglishOrdinalTerminaison(monday)
    const sunTerm = this.getEnglishOrdinalTerminaison(sunday)
    const firstRowLabel = mondayMonth + ' ' + monday + monTerm + ' ' + translator.t('stats.to')
    const secondRowLabel =
      sunday - monday < 0 ? sundayMonth + ' ' + sunday + sunTerm : sunday + sunTerm
    return (
      <View style={getPlayerStatsStyles(styleParameters).XAxisContainerLabel}>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).XAxisLabel}>
          {firstRowLabel}
        </Text>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).XAxisLabel}>
          {secondRowLabel}
        </Text>
      </View>
    )
  }

  getXAxisLegendMonthEnOneRow = (
    monday: number,
    sunday: number,
    mondayMonth: string,
    sundayMonth: string
  ) => {
    const { styleParameters } = this.state
    const monTerm = this.getEnglishOrdinalTerminaison(monday)
    const sunTerm = this.getEnglishOrdinalTerminaison(sunday)
    const labelDaysOfSameMonth =
      mondayMonth +
      ' ' +
      monday +
      monTerm +
      ' ' +
      translator.t('stats.to') +
      ' ' +
      sunday +
      sunTerm +
      ' '
    const labelDaysOfDifferentMonths =
      mondayMonth +
      ' ' +
      monday +
      monTerm +
      ' ' +
      translator.t('stats.to') +
      ' ' +
      sundayMonth +
      ' ' +
      sunday +
      sunTerm +
      ' '
    const label: string = sunday - monday < 0 ? labelDaysOfDifferentMonths : labelDaysOfSameMonth
    return (
      <View style={getPlayerStatsStyles(styleParameters).XAxisContainerLabel}>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).XAxisLabel}>
          {label}
        </Text>
      </View>
    )
  }

  getXAxisLegendMonthEn = (monday: Date, sunday: Date) => {
    const mondayMonth =
      Platform.OS === 'android'
        ? monday.toString().split(' ')[1]
        : monday.toLocaleString('en-US', { month: 'long' } as any).substring(0, 3)

    const sundayMonth =
      Platform.OS === 'android'
        ? sunday.toString().split(' ')[1]
        : sunday.toLocaleString('en-US', { month: 'long' } as any).substring(0, 3)

    const mondayDate = monday.getDate()
    const sundayDate = sunday.getDate() - 1
    if (!this.state.styleParameters.isHorizontal) {
      return this.getXAxisLegendMonthEnTwoRow(mondayDate, sundayDate, mondayMonth, sundayMonth)
    } else {
      return this.getXAxisLegendMonthEnOneRow(mondayDate, sundayDate, mondayMonth, sundayMonth)
    }
  }

  getXAxisLegendMonth = (monday: Date, sunday: Date) => {
    if (this.state.styleParameters.isFrench) {
      return this.getXAxisLegendMonthFr(monday, sunday)
    } else {
      return this.getXAxisLegendMonthEn(monday, sunday)
    }
  }

  getXAxisLegendWeek = (day: Date) => {
    const { styleParameters } = this.state
    const language: string = styleParameters.isFrench ? 'fr-FR' : 'en-US'

    return (
      <View key={day.getDate()} style={getPlayerStatsStyles(styleParameters).XAxisContainerLabel}>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).XAxisLabel}>
          {day.toLocaleString(language, { weekday: 'long' } as any).substring(0, 3)}
        </Text>
      </View>
    )
  }

  getXAxisLegendSeason = (month: Date) => {
    const { styleParameters } = this.state
    const language: string = styleParameters.isFrench ? 'fr-FR' : 'en-US'

    const monthStr =
      Platform.OS === 'android'
        ? month.toString().split(' ')[1]
        : month.toLocaleString(language, { month: 'long' } as any)

    return (
      <View key={monthStr} style={getPlayerStatsStyles(styleParameters).XAxisContainerLabel}>
        <Text adjustsFontSizeToFit style={getPlayerStatsStyles(styleParameters).XAxisLabel}>
          {monthStr.substring(0, 3)}
        </Text>
      </View>
    )
  }

  getXAxisLegend = (xTicksValue: Date[]) => {
    const legend = []
    const { timeSpanSelected, NumberTimeTick, styleParameters } = this.state
    for (let xTicksIndex = 1; xTicksIndex < xTicksValue.length; xTicksIndex++) {
      switch (timeSpanSelected) {
        case TimeSpan.month: {
          if (xTicksIndex !== 1) {
            const monday = xTicksValue[xTicksIndex - 1]
            const sunday = xTicksValue[xTicksIndex]
            legend.push(this.getXAxisLegendMonth(monday, sunday))
          }
          break
        }
        case TimeSpan.week: {
          if (xTicksIndex !== xTicksValue.length - 1) {
            const day = xTicksValue[xTicksIndex]
            legend.push(this.getXAxisLegendWeek(day))
          }
          break
        }
        case TimeSpan.season: {
          if (NumberTimeTick !== xTicksValue.length - 1) {
            this.setState({
              NumberTimeTick: xTicksValue.length - 1,
            })
          }
          legend.push(this.getXAxisLegendSeason(xTicksValue[xTicksIndex - 1]))
          break
        }
      }
    }
    return <View style={getPlayerStatsStyles(styleParameters).XAxisContainer}>{legend}</View>
  }

  getMaxXDomain = (myData: any[]) => {
    if (myData.length === 0) {
      return new Date()
    } else {
      switch (this.state.timeSpanSelected) {
        case TimeSpan.week: {
          return myData[6].x
        }
        case TimeSpan.month: {
          return myData[28].x
        }
        case TimeSpan.season: {
          return myData[myData.length - 1].x
        }
      }
    }
  }

  renderGraph = (graph: any, XAxis: any) => {
    const { styleParameters } = this.state
    const isOneRowLegend = styleParameters.isHorizontal && !styleParameters.isMobile
    const legend = isOneRowLegend ? (
      XAxis
    ) : (
      <View style={getPlayerStatsStyles(styleParameters).xAxisColumnContainer}>{XAxis}</View>
    )
    const graphAndLegend = (
      <View style={getPlayerStatsStyles(styleParameters).graph_and_top_row}>
        {this.renderGraphTopBar(isOneRowLegend)}
        <View pointerEvents="none" style={getPlayerStatsStyles(styleParameters).GraphContainer}>
          {graph}
        </View>
        {legend}
        {isOneRowLegend ? null : this.renderGraphTopLegend()}
      </View>
    )
    return graphAndLegend
  }

  renderGraphContainer = () => {
    const { styleParameters, thirdRowHeight, timeSpanSelected } = this.state
    const xTicksValue: any = this.getXTicksValues()
    const myData = this.getGraphData(true)
    const teamData = this.getGraphData(false)
    const maxDomainX: number = this.getMaxXDomain(myData)
    // const xTicksValueIndex: any = this.getxTicksValueIndex(myData)
    const screenRatio = getScreenRatioStyle(
      styleParameters.screenWidth,
      styleParameters.screenHeight,
      styleParameters.isMobile,
      styleParameters.isHorizontal
    )
    const graph: JSX.Element = (
      <VictoryChart
        padding={{
          left: screenRatio.Graph_paddingLeft,
          bottom: screenRatio.Graph_paddingBottom,
          top: screenRatio.Graph_paddingTop,
        }}
        width={graphWidth(styleParameters) as number}
        height={graphHeight(styleParameters, thirdRowHeight) as number}
        style={graphChartStyle()}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            responsive={false}
            style={{
              pointerEvents: 'auto',
              userSelect: 'auto',
              touchAction: 'auto',
            }}
          />
        }
      >
        <VictoryAxis
          crossAxis
          dependentAxis
          domain={[0, 100]}
          tickCount={10}
          style={graphAxisYStyle(styleParameters)}
          tickFormat={(t: any) => `${t}%`}
        />
        <VictoryAxis
          crossAxis
          domain={[myData.length === 0 ? new Date() : myData[0].x, maxDomainX]}
          tickValues={
            timeSpanSelected === TimeSpan.month ? xTicksValue.slice(2) : xTicksValue.slice(1)
          }
          style={graphAxisXStyle()}
        />
        <VictoryLine
          style={{
            data: { stroke: GlobalColors.green },
            parent: { border: '1px solid #ccc' },
          }}
          scale={{ x: 'time', y: 'linear' }}
          data={myData}
        />
        <VictoryLine
          style={{
            data: { stroke: GlobalColors.mediumGrey },
            parent: { border: '1px solid #ccc' },
          }}
          scale={{ x: 'time', y: 'linear' }}
          data={teamData}
        />
      </VictoryChart>
    )
    const XAxis: JSX.Element = this.getXAxisLegend(xTicksValue)

    return (
      <View style={getPlayerStatsStyles(styleParameters).graph_and_top_row_container}>
        {this.renderGraph(graph, XAxis)}
      </View>
    )
  }

  renderGraphColumn = () => {
    const { styleParameters } = this.state
    return (
      <View style={getPlayerStatsStyles(styleParameters).GraphColumn}>
        <View style={getPlayerStatsStyles(styleParameters).graph_TableTitle_container}>
          <Text style={getPlayerStatsStyles(styleParameters).GraphTitle}>
            {translator.t('stats.myProgress').toUpperCase()}
          </Text>
        </View>
        {this.renderGraphContainer()}
      </View>
    )
  }

  renderGraphsRow = () => {
    return (
      <View style={getPlayerStatsStyles(this.state.styleParameters).row3}>
        {this.renderTabColumn()}
        {this.renderGraphColumn()}
      </View>
    )
  }

  renderStats = () => {
    const { styleParameters } = this.state
    const page = (
      <View style={getPlayerStatsStyles(styleParameters).page}>
        {this.renderIdRow()}
        {this.renderScoreRow()}
        {this.renderGraphsRow()}
      </View>
    )
    const isNotScrollable = styleParameters.isHorizontal && !styleParameters.isMobile
    if (isNotScrollable) {
      return page
    } else {
      return (
        <ScrollView showsVerticalScrollIndicator={false} style={{ flexGrow: 1 }}>
          {page}
        </ScrollView>
      )
    }
  }

  renderLoadingPage = () => {
    const { navigation } = this.props

    return (
      <View style={getGlobalStyles(this.state.styleParameters).app}>
        <TopBarMenu
          navigation={navigation}
          url={PageName.PlaybookPage}
          updatePage={() => this.forceUpdate()}
          userMustBeLoggedOut={false}
          pageTitle={translator.t('loadingPage')}
        />

        <View style={{ flexDirection: 'column', flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={GlobalColors.defaultTeamColor} />
        </View>
      </View>
    )
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this.onChange)
    this.handleSortTable(TableSort.studyTimeDescendent)
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.onChange)
  }

  render() {
    const { styleParameters, stats } = this.state
    const { navigation } = this.props

    const statsHaveNotBeenLoadedYet: boolean = Object.keys(stats).length === 0
    if (statsHaveNotBeenLoadedYet) {
      return this.renderLoadingPage()
    }

    const pageTitle = ''

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        <TopBarMenu
          navigation={navigation}
          url={PageName.PlaybookPage}
          updatePage={() => this.forceUpdate()}
          userMustBeLoggedOut={false}
          pageTitle={pageTitle}
        />

        {this.renderStats()}

        {this.renderEndPracticeModal()}
      </View>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  playbooks: state.playbooks.playbooks,
  folders: state.playbooks.playbookFolders,
  selectedTeamID: state.team.selectedTeam as number,
})

export default connect(mapStateToProps, {})(PlayerStatsPage)
