import { Platform } from 'react-native'
import { StyleParameters, getMarginToTransformIn43, transformUXSpec } from '../Utilities'
export interface StyledScreenRatio {
  PlaybookList_marginHorizontal: any
  PlaybookList_paddingVertical: any
  PlaybookListTitle_marginLeft: any
  PlaybookListTitle_fontSize: any
  PlaybookListCaret_fontSize: any
  PlaybookListLegacy_fontSize: any
  PlaybookListGroupContainer_marginLeft: any
  PlaybookListLine_marginLeft: any
  PlaybookListGroupContent_marginLeft: any
  PlaybookGroupContainer_width: any
  PlaybookGroupBackArrow_marginLeft: any
  PlaybookGroupBackArrow_width: any
  PlaybookGroupBackTitle_fontSize: any
  FolderPerRow: any
  PlaybookGroupFolderTitle_fontSize: any
  PlaybookGroupFolderTitle_paddingLeft: any
  PlaybookGroupFoldersContainer_marginLeft: any
  PlaybookGroupFoldersContainer_width: any
  PlaybookPerRow: any
  PlaybookGroupPlaybookTitle_fontSize: any
  PlaybookGroupPlaybookTitle_marginTop: any
  PlaybookGroupPlaybooksContainer_marginLeft: any
  PlaybookGroupPlaybooksContainer_width: any
  PlaybookFolderIcon_fontSize: any
  PlaybookFolderQuantity_top: any
  PlaybookFolderQuantity_fontSize: any
  FolderPlaybookContainer_width: any
  FolderPlaybookSnapshot_width: any
  FolderPlaybookSnapshot_height: any
  ModalAlertContainer_width: any
  ModalAlertContainer_height: any
  ModalAlertContainer_marginTop: any
  ModalContainer_borderRadius: any
  ModalAlertTitle_fontSize: any
  ModalAlertQuestion_marginTop: any
  ModalAlertQuestion_fontSize: any
  ModalAlertSubtext_fontSize: any
  ModalAlertButtonYes_width: any
  ModalAlertButtonNo_width: any
  ModalAlertButton_height: any
  ModalAlertButtonText_fontSize: any
  ModalAlertTitle_marginTop: any
  ModalAlertButtonContainer_marginBottom: any
  ModalAlertButtonContainer_marginTop: any
  GroupTitleBackground_fontSize: any
  GroupTitleBackground_Bottom: any
  GroupTitleBackground_Right: any
}

export function getScreenRatioStyle(styleParameters: StyleParameters) {
  let { screenWidth, screenHeight } = styleParameters
  const marginHorizontal: number = getMarginToTransformIn43(screenWidth, screenHeight)
  const os = Platform.OS
  if (screenWidth > screenHeight) {
    if (screenHeight > 600) {
      screenWidth -= marginHorizontal
      //horizontal tablet
      return {
        PlaybookList_marginHorizontal: marginHorizontal / 2,
        PlaybookList_paddingVertical: transformUXSpec(screenWidth, screenHeight, 15),
        PlaybookListTitle_marginLeft: transformUXSpec(screenWidth, screenHeight, 140),
        PlaybookListTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 220),
        PlaybookListGroupContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookListCaret_fontSize: transformUXSpec(screenWidth, screenHeight, 60),
        PlaybookListLegacy_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        PlaybookListLine_marginLeft: transformUXSpec(screenWidth, screenHeight, 72),
        PlaybookListGroupContent_marginVertical: transformUXSpec(screenWidth, screenHeight, 80),
        PlaybookListGroupContent_marginLeft: transformUXSpec(screenWidth, screenHeight, 55),
        PlaybookGroupContainer_width: transformUXSpec(screenWidth, screenHeight, 1875),
        PlaybookGroupBackArrow_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        PlaybookGroupBackArrow_width: transformUXSpec(screenWidth, screenHeight, 90),
        PlaybookGroupBackTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 60),
        FolderPerRow: 6,
        PlaybookGroupFolderTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        PlaybookGroupFolderTitle_paddingLeft: transformUXSpec(screenWidth, screenHeight, 12),
        PlaybookGroupFoldersContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 5),
        PlaybookGroupFoldersContainer_width: transformUXSpec(screenWidth, screenHeight, 1785),
        PlaybookPerRow: 3,
        PlaybookGroupPlaybookTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookGroupPlaybookTitle_marginTop: transformUXSpec(screenWidth, screenHeight, 75),
        PlaybookGroupPlaybooksContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        PlaybookGroupPlaybooksContainer_width: transformUXSpec(screenWidth, screenHeight, 1790),
        PlaybookFolderIcon_fontSize: transformUXSpec(screenWidth, screenHeight, 250),
        PlaybookFolderQuantity_top:
          os === 'web'
            ? screenWidth <= 1024
              ? transformUXSpec(screenWidth, screenHeight, 95)
              : transformUXSpec(screenWidth, screenHeight, 120)
            : transformUXSpec(screenWidth, screenHeight, 95),
        PlaybookFolderQuantity_fontSize: transformUXSpec(screenWidth, screenHeight, 85),
        FolderPlaybookContainer_width: transformUXSpec(screenWidth, screenHeight, 615),
        FolderPlaybookSnapshot_width: transformUXSpec(screenWidth, screenHeight, 512),
        FolderPlaybookSnapshot_height: transformUXSpec(screenWidth, screenHeight, 350),
        ModalAlertContainer_width: transformUXSpec(screenWidth, screenHeight, 1050),
        ModalAlertContainer_height: transformUXSpec(screenWidth, screenHeight, 450),
        ModalAlertContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 400),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalAlertTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 180),
        ModalAlertQuestion_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        ModalAlertSubtext_fontSize: transformUXSpec(screenWidth, screenHeight, 35),
        ModalAlertQuestion_marginTop: transformUXSpec(screenWidth, screenHeight, 25),
        ModalAlertButtonYes_width: transformUXSpec(screenWidth, screenHeight, 150),
        ModalAlertButtonNo_width: transformUXSpec(screenWidth, screenHeight, 600),
        ModalAlertButton_height: transformUXSpec(screenWidth, screenHeight, 90),
        ModalAlertButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 44),
        ModalAlertTitle_marginTop: transformUXSpec(screenWidth, screenHeight, 15),
        ModalAlertButtonContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 50),
        ModalAlertButtonContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 100),
        GroupTitleBackground_fontSize: transformUXSpec(screenWidth, screenHeight, 200),
        GroupTitleBackground_Bottom: -transformUXSpec(screenWidth, screenHeight, 150),
        GroupTitleBackground_Right: -transformUXSpec(screenWidth, screenHeight, 10),
      }
    } else {
      //horizontal mobile
      return {
        PlaybookList_marginHorizontal: null,
        PlaybookList_paddingVertical: transformUXSpec(screenWidth, screenHeight, 5),
        PlaybookListTitle_marginLeft: transformUXSpec(screenWidth, screenHeight, 60),
        PlaybookListTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookListGroupContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        PlaybookListCaret_fontSize: transformUXSpec(screenWidth, screenHeight, 32),
        PlaybookListLegacy_fontSize: transformUXSpec(screenWidth, screenHeight, 35),
        PlaybookListLine_marginLeft: transformUXSpec(screenWidth, screenHeight, 48),
        PlaybookListGroupContent_marginLeft: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookGroupContainer_width: transformUXSpec(screenWidth, screenHeight, 700),
        PlaybookGroupBackArrow_marginLeft: transformUXSpec(screenWidth, screenHeight, 8),
        PlaybookGroupBackArrow_width: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookGroupBackTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        FolderPerRow: 6,
        PlaybookGroupFolderTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        PlaybookGroupFolderTitle_paddingLeft: transformUXSpec(screenWidth, screenHeight, 7),
        PlaybookGroupFoldersContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 1),
        PlaybookGroupFoldersContainer_width: transformUXSpec(screenWidth, screenHeight, 725),
        PlaybookPerRow: 3,
        PlaybookGroupPlaybookTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 16),
        PlaybookGroupPlaybookTitle_marginTop: transformUXSpec(screenWidth, screenHeight, 20),
        PlaybookGroupPlaybooksContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 8),
        PlaybookGroupPlaybooksContainer_width: transformUXSpec(screenWidth, screenHeight, 700),
        PlaybookFolderIcon_fontSize: transformUXSpec(screenWidth, screenHeight, 90),
        PlaybookFolderQuantity_top:
          os === 'web'
            ? screenWidth >= 740
              ? transformUXSpec(screenWidth, screenHeight, 37)
              : transformUXSpec(screenWidth, screenHeight, 30)
            : transformUXSpec(screenWidth, screenHeight, 30),
        PlaybookFolderQuantity_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        FolderPlaybookContainer_width: transformUXSpec(screenWidth, screenHeight, 233),
        FolderPlaybookSnapshot_width: transformUXSpec(screenWidth, screenHeight, 190),
        FolderPlaybookSnapshot_height: transformUXSpec(screenWidth, screenHeight, 120),
        ModalAlertContainer_width: transformUXSpec(screenWidth, screenHeight, 375),
        ModalAlertContainer_height: transformUXSpec(screenWidth, screenHeight, 200),
        ModalAlertContainer_marginTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 55)
            : transformUXSpec(screenWidth, screenHeight, 20),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalAlertTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 60),
        ModalAlertQuestion_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        ModalAlertSubtext_fontSize: transformUXSpec(screenWidth, screenHeight, 12),
        ModalAlertQuestion_marginTop: transformUXSpec(screenWidth, screenHeight, 6),
        ModalAlertButtonYes_width: transformUXSpec(screenWidth, screenHeight, 65),
        ModalAlertButtonNo_width: transformUXSpec(screenWidth, screenHeight, 210),
        ModalAlertButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        ModalAlertButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        ModalAlertTitle_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalAlertButtonContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        ModalAlertButtonContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 30),
        GroupTitleBackground_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        GroupTitleBackground_Bottom: -transformUXSpec(screenWidth, screenHeight, 50),
        GroupTitleBackground_Right: -transformUXSpec(screenWidth, screenHeight, 0),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        PlaybookList_marginHorizontal: null,
        PlaybookList_paddingVertical: transformUXSpec(screenWidth, screenHeight, 10),
        PlaybookListTitle_marginLeft: transformUXSpec(screenWidth, screenHeight, 25),
        PlaybookListTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookListGroupContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        PlaybookListCaret_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        PlaybookListLegacy_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        PlaybookListLine_marginLeft: transformUXSpec(screenWidth, screenHeight, 32),
        PlaybookListGroupContent_marginVertical: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookListGroupContent_marginLeft: transformUXSpec(screenWidth, screenHeight, 25),
        PlaybookGroupContainer_width: transformUXSpec(screenWidth, screenHeight, 340),
        PlaybookGroupBackArrow_marginLeft: transformUXSpec(screenWidth, screenHeight, 7),
        PlaybookGroupBackArrow_width: transformUXSpec(screenWidth, screenHeight, 30),
        PlaybookGroupBackTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        FolderPerRow: 3,
        PlaybookGroupFolderTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        PlaybookGroupFolderTitle_paddingLeft: transformUXSpec(screenWidth, screenHeight, 6),
        PlaybookGroupFoldersContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 1),
        PlaybookGroupFoldersContainer_width: transformUXSpec(screenWidth, screenHeight, 303),
        PlaybookPerRow: 2,
        PlaybookGroupPlaybookTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        PlaybookGroupPlaybookTitle_marginTop: transformUXSpec(screenWidth, screenHeight, 20),
        PlaybookGroupPlaybooksContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 7),
        PlaybookGroupPlaybooksContainer_width: transformUXSpec(screenWidth, screenHeight, 303),
        PlaybookFolderIcon_fontSize: transformUXSpec(screenWidth, screenHeight, 75),
        PlaybookFolderQuantity_top:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 35)
            : transformUXSpec(screenWidth, screenHeight, 25),
        PlaybookFolderQuantity_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        FolderPlaybookContainer_width: transformUXSpec(screenWidth, screenHeight, 155),
        FolderPlaybookSnapshot_width: transformUXSpec(screenWidth, screenHeight, 135),
        FolderPlaybookSnapshot_height: transformUXSpec(screenWidth, screenHeight, 80),
        ModalAlertContainer_width: transformUXSpec(screenWidth, screenHeight, 260),
        ModalAlertContainer_height: transformUXSpec(screenWidth, screenHeight, 200),
        ModalAlertContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 200),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalAlertTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        ModalAlertQuestion_fontSize: transformUXSpec(screenWidth, screenHeight, 12),
        ModalAlertSubtext_fontSize: transformUXSpec(screenWidth, screenHeight, 10),
        ModalAlertQuestion_marginTop: transformUXSpec(screenWidth, screenHeight, 12),
        ModalAlertButtonYes_width: transformUXSpec(screenWidth, screenHeight, 50),
        ModalAlertButtonNo_width: transformUXSpec(screenWidth, screenHeight, 150),
        ModalAlertButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        ModalAlertButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 12),
        ModalAlertTitle_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalAlertButtonContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        ModalAlertButtonContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 30),
        GroupTitleBackground_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        GroupTitleBackground_Bottom: -transformUXSpec(screenWidth, screenHeight, 50),
        GroupTitleBackground_Right: -transformUXSpec(screenWidth, screenHeight, 0),
      }
    } else {
      //vertical tablet
      return {
        PlaybookList_marginHorizontal: null,
        PlaybookList_paddingVertical: transformUXSpec(screenWidth, screenHeight, 15),
        PlaybookListTitle_marginLeft: transformUXSpec(screenWidth, screenHeight, 120),
        PlaybookListTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 220),
        PlaybookListGroupContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookListCaret_fontSize: transformUXSpec(screenWidth, screenHeight, 60),
        PlaybookListLegacy_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        PlaybookListLine_marginLeft: transformUXSpec(screenWidth, screenHeight, 70),
        PlaybookListGroupContent_marginLeft: transformUXSpec(screenWidth, screenHeight, 55),
        PlaybookGroupContainer_width: transformUXSpec(screenWidth, screenHeight, 1410),
        PlaybookGroupBackArrow_marginLeft: transformUXSpec(screenWidth, screenHeight, 15),
        PlaybookGroupBackArrow_width: transformUXSpec(screenWidth, screenHeight, 90),
        PlaybookGroupBackTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 60),
        FolderPerRow: 3,
        PlaybookGroupFolderTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        PlaybookGroupFolderTitle_paddingLeft: transformUXSpec(screenWidth, screenHeight, 15),
        PlaybookGroupFoldersContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        PlaybookGroupFoldersContainer_width: transformUXSpec(screenWidth, screenHeight, 1350),
        PlaybookPerRow: 2,
        PlaybookGroupPlaybookTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookGroupPlaybookTitle_marginTop: transformUXSpec(screenWidth, screenHeight, 75),
        PlaybookGroupPlaybooksContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 15),
        PlaybookGroupPlaybooksContainer_width: transformUXSpec(screenWidth, screenHeight, 1410),
        PlaybookFolderIcon_fontSize: transformUXSpec(screenWidth, screenHeight, 430),
        PlaybookFolderQuantity_top:
          os === 'web'
            ? screenWidth >= 1024
              ? transformUXSpec(screenWidth, screenHeight, 180)
              : transformUXSpec(screenWidth, screenHeight, 220)
            : transformUXSpec(screenWidth, screenHeight, 160),
        PlaybookFolderQuantity_fontSize: transformUXSpec(screenWidth, screenHeight, 110),
        FolderPlaybookContainer_width: transformUXSpec(screenWidth, screenHeight, 800),
        FolderPlaybookSnapshot_width: transformUXSpec(screenWidth, screenHeight, 510),
        FolderPlaybookSnapshot_height: transformUXSpec(screenWidth, screenHeight, 340),
        ModalAlertContainer_width: transformUXSpec(screenWidth, screenHeight, 825),
        ModalAlertContainer_height: transformUXSpec(screenWidth, screenHeight, 450),
        ModalAlertContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 600),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalAlertTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 156),
        ModalAlertQuestion_fontSize: transformUXSpec(screenWidth, screenHeight, 36),
        ModalAlertSubtext_fontSize: transformUXSpec(screenWidth, screenHeight, 28),
        ModalAlertQuestion_marginTop: transformUXSpec(screenWidth, screenHeight, 30),
        ModalAlertButtonYes_width: transformUXSpec(screenWidth, screenHeight, 140),
        ModalAlertButtonNo_width: transformUXSpec(screenWidth, screenHeight, 500),
        ModalAlertButton_height: transformUXSpec(screenWidth, screenHeight, 75),
        ModalAlertButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 36),
        ModalAlertTitle_marginTop: transformUXSpec(screenWidth, screenHeight, 15),
        ModalAlertButtonContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 50),
        ModalAlertButtonContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 100),
        GroupTitleBackground_fontSize: transformUXSpec(screenWidth, screenHeight, 112),
        GroupTitleBackground_Bottom: -transformUXSpec(screenWidth, screenHeight, 70),
        GroupTitleBackground_Right: -transformUXSpec(screenWidth, screenHeight, 10),
      }
    }
  }
}
