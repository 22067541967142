import axios from 'axios'
import { Platform } from 'react-native'
import * as Linking from 'expo-linking'
import { GroupNames } from '@taktik/config/Translation/utilities'
import { User, UserCreation } from '../DataTypes/UserTypes'
import { Group } from '../components/PositionPicker'
import { TaktikAlert } from './Alert'

export interface TokenInfo {
  teamId: number
  teamName: string
  coachName: string
  positions: { [key in GroupNames]: Group }
}

export interface ApprovalInsertResponse {
  success: boolean
  invalidToken?: boolean
  user?: User
}

export const onTokenFromUrl = (
  onToken: (token: string | undefined) => void
): (() => void) | undefined => {
  const onUrl = async ({ url }: { url: string | null }) => {
    const token = await getTokenFromUrl(url)
    onToken(token)
  }

  Linking.getInitialURL().then((url) => onUrl({ url }))

  if (Platform.OS !== 'web') {
    Linking.addEventListener('url', onUrl)
    return () => Linking.removeEventListener('url', onUrl)
  }

  return undefined
}

const getTokenFromUrl = async (url: string | null): Promise<string | undefined> => {
  if (url) {
    const { queryParams } = Linking.parse(url)
    const queryKeys = Object.keys(queryParams)
    const token = queryKeys.find((key) => queryParams[key]?.length === 0)
    const isValid = await isTokenValid(token)
    if (isValid) {
      return token
    }
  }
  return undefined
}

const isTokenValid = async (token: string | undefined): Promise<boolean> => {
  if (token && /[a-zA-Z0-9_-]{8}/.test(token)) {
    try {
      const response = await axios(`/invitation/fromToken/${token}`)
      const teamId = response.data?.team?.id
      if (teamId) {
        return true
      }
    } catch (err) {
      TaktikAlert.alert((err as any).message ?? 'Le token saisi est invalide.')
    }
  }
  return false
}

export const getInfoFromToken = async (token: string): Promise<TokenInfo | undefined> => {
  try {
    const response = await axios.get(`invitation/fromToken/${token}`)
    if (response.data) {
      const coachId = response.data.team.coach_id
      const coachName = await getCoachName(coachId)

      return {
        coachName,
        teamId: response.data.team.id,
        teamName: response.data.team.name,
        positions: response.data.positions,
      }
    }
  } catch {}
  return undefined
}

const getCoachName = async (coachId: number): Promise<string> => {
  try {
    const response = await axios.get(`users/${coachId}`)
    if (response.data) {
      const { firstName, lastName } = response.data?.user
      return `${firstName} ${lastName}`
    }
  } catch {}
  return ''
}

export async function submitPlayerForToken(
  token: string,
  user: UserCreation
): Promise<{ success: boolean; emailTaken?: boolean }> {
  try {
    const response = await axios.post('approval/Create', { user, token })
    if (response) {
      const { success, emailTaken } = response.data
      return { success, emailTaken }
    }
  } catch {}
  return { success: false }
}

export async function acceptInvitationForToken(
  token: string,
  teamId: number
): Promise<ApprovalInsertResponse> {
  try {
    const response = await axios.post<ApprovalInsertResponse>('approval/insert', {
      teamId,
      token,
    })
    return response.data
  } catch {
    return { success: false }
  }
}
