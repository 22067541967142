import React, { Component } from 'react'
import translator from '@taktik/config/Translation/languages'

import { View } from 'react-native'
import { getRegistrationStyles } from '../Styles/AppStyle'
import { StyleParameters } from '../Styles/Utilities'
import RegistrationFieldWrapper from './RegistrationFieldWrapper'
import GenericField from './GenericField'
import EmailField from './EmailField'
import PasswordField from './PasswordField'
import { PasswordFeedback } from './PasswordFeedback'
import { RegistrationFormData } from '../Pages/Registration'
import { InputFieldValidation } from '../DataTypes/ValidationType'
import OnlyNumberField from './OnlyNumberField'

export enum RegistrationField {
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  PHONE = 'phone',
  PLAYER_NUMBER = 'playerNumber',
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD_VALIDATION = 'passwordVerification',
}

export type RegistrationFormFieldContent = {
  data: string | undefined
  errors: InputFieldValidation[]
}

export type RegistrationFormFields = {
  [key in RegistrationField]: RegistrationFormFieldContent
}

interface RegistrationFormProps {
  showErrors: boolean
  styleParameters: StyleParameters
  onChange: (formData: RegistrationFormData, errors: InputFieldValidation[]) => void
}

interface RegistrationFormState {
  fields: RegistrationFormFields
}

export default class RegistrationForm extends Component<
  RegistrationFormProps,
  RegistrationFormState
> {
  constructor(props: RegistrationFormProps) {
    super(props)

    this.state = {
      fields: Object.values(RegistrationField).reduce((allFields, field) => {
        return { ...allFields, [field]: { data: undefined, errors: [] } }
      }, {}) as RegistrationFormFields,
    }
  }

  shouldComponentUpdate(prevProps: RegistrationFormProps, prevState: RegistrationFormState) {
    return (
      prevProps.showErrors !== this.props.showErrors ||
      this.getAllErrors(prevState.fields).length !== this.getAllErrors(this.state.fields).length
    )
  }

  componentDidUpdate() {
    this.notifyParent()
  }

  getAllData = (fields: RegistrationFormFields): RegistrationFormData => {
    return Object.values(RegistrationField).reduce(
      (allFields, fieldKey) => ({ ...allFields, [fieldKey]: fields[fieldKey].data }),
      {} as RegistrationFormData
    )
  }

  getAllErrors = (fields: RegistrationFormFields) => {
    const allErrorsWithDuplicates = Object.values(RegistrationField).reduce(
      (total, currentFieldKey) => [...total, ...fields[currentFieldKey].errors],
      [] as InputFieldValidation[]
    )
    return [...new Set(allErrorsWithDuplicates)]
  }

  notifyParent = () => {
    const { fields } = this.state
    this.props.onChange(this.getAllData(fields), this.getAllErrors(fields))
  }

  handleInput = (field: RegistrationField, input: string, errors: InputFieldValidation[]) => {
    this.setState((previousState) => ({
      fields: { ...previousState.fields, [field]: { data: input, errors } },
    }))
  }

  render() {
    const { showErrors, styleParameters } = this.props
    const styles = getRegistrationStyles(styleParameters)

    return (
      <View style={styles.formContainer}>
        <RegistrationFieldWrapper styleParameters={styleParameters}>
          <GenericField
            placeholder={translator.t('modalCreateAccount.firstName')}
            onValidationChange={(data, errors) =>
              this.handleInput(RegistrationField.FIRSTNAME, data, errors)
            }
            onComplete={() => this.notifyParent()}
            styleParameters={styleParameters}
          />
        </RegistrationFieldWrapper>
        <RegistrationFieldWrapper styleParameters={styleParameters}>
          <GenericField
            placeholder={translator.t('modalCreateAccount.lastName')}
            onValidationChange={(data, errors) =>
              this.handleInput(RegistrationField.LASTNAME, data, errors)
            }
            onComplete={() => this.notifyParent()}
            styleParameters={styleParameters}
          />
        </RegistrationFieldWrapper>
        <RegistrationFieldWrapper styleParameters={styleParameters}>
          <GenericField
            placeholder={translator.t('modalCreateAccount.phone')}
            keyboardType="phone-pad"
            onValidationChange={(data, errors) =>
              this.handleInput(RegistrationField.PHONE, data, errors)
            }
            onComplete={() => this.notifyParent()}
            styleParameters={styleParameters}
          />
        </RegistrationFieldWrapper>
        <RegistrationFieldWrapper styleParameters={styleParameters}>
          <OnlyNumberField
            placeholder={translator.t('modalCreateAccount.playerNumber')}
            keyboardType="numeric"
            maxLength={2}
            onValidationChange={(data, errors) =>
              this.handleInput(RegistrationField.PLAYER_NUMBER, data, errors)
            }
            onComplete={() => this.notifyParent()}
            styleParameters={styleParameters}
          />
        </RegistrationFieldWrapper>
        <RegistrationFieldWrapper styleParameters={styleParameters}>
          <EmailField
            onValidationChange={(data, errors) =>
              this.handleInput(RegistrationField.EMAIL, data, errors)
            }
            onComplete={() => this.notifyParent()}
            styleParameters={styleParameters}
          />
        </RegistrationFieldWrapper>
        <RegistrationFieldWrapper styleParameters={styleParameters}>
          <PasswordField
            onValidationChange={(data, errors) =>
              this.handleInput(RegistrationField.PASSWORD, data, errors)
            }
            onComplete={() => this.notifyParent()}
            styleParameters={styleParameters}
          />
        </RegistrationFieldWrapper>
        <RegistrationFieldWrapper styleParameters={styleParameters}>
          <PasswordField
            isConfirmation
            confirmationPassword={this.state.fields[RegistrationField.PASSWORD].data}
            onValidationChange={(data, errors) =>
              this.handleInput(RegistrationField.PASSWORD_VALIDATION, data, errors)
            }
            onComplete={() => this.notifyParent()}
            styleParameters={styleParameters}
          />
        </RegistrationFieldWrapper>
        <PasswordFeedback
          showErrors={showErrors}
          passwordField={this.state.fields[RegistrationField.PASSWORD]}
          styleParameters={styleParameters}
        />
      </View>
    )
  }
}
