import Constants from 'expo-constants'

import envDefault from '../../env'
import envProd from '../../env.prod'
import envStaging from '../../env.staging'
import { EnvironmentConfiguration } from './environment-configuration'

const releaseChannel = Constants.manifest?.releaseChannel
let env
if (releaseChannel === undefined) {
  env = envDefault
} else if (releaseChannel.indexOf('staging') === 0) {
  env = envStaging
} else if (releaseChannel.indexOf('prod') === 0) {
  env = envProd
} else {
  throw Error(
    `Unable to load configuration. Unrecognized releaseChannel pattern: '${releaseChannel}'`
  )
}

export const environmentConfiguration: EnvironmentConfiguration = env
