export function getCurrentDateTimestamp(): string {
  return new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
}

export class PlaybookHistory {
  redoHistory: any[] = []
  undoHistory: any[] = []
}

export class Playbook {
  id: number = -1
  title: string = ''
  orientation: string = ''
  snapshot: string = ''
  preset: string | null = ''
  fieldType: string = ''
  updatedAt: string = getCurrentDateTimestamp()
  createdAt: string = getCurrentDateTimestamp()
  userID: number | null = null
  teamID: number | null = null
  history: PlaybookHistory | null = null
  scrimmage: number | null = -1
  isTemplate: boolean | null = null
  group: string | null = ''
  folderID: number | null = null
}

export class PlaybookFolder {
  id!: number
  name: string | undefined
  group: string | undefined
  teamID: number | null = null
  parentFolderID: number | null = null
  folders: number[] | undefined
  playbooks: number[] | undefined
  isTemplate: boolean | undefined
}
