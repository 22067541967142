import { ScaledSize, StyleSheet, Dimensions, PixelRatio } from 'react-native'
import { StyleParameters } from './AppStyle'
import { StyledComponentsTheme } from './Theme'

export function normalizeSize(isHorizontal: boolean, size: number) {
  const { width: SCREEN_WIDTH } = Dimensions.get('window')

  const scalingFactor = 800
  let scale: number = SCREEN_WIDTH / scalingFactor

  if (isHorizontal) {
    const horizontalScalingFactor: number = 3
    scale = scale / horizontalScalingFactor
  }

  const newSize = size * scale

  const normalizedSize: number = Math.round(PixelRatio.roundToNearestPixel(newSize))

  return normalizedSize
}

export function normalizeSize_WithinBounds(
  isHorizontal: boolean,
  size: number,
  minSize: number,
  maxSize: number
) {
  let normalizedSize: number = normalizeSize(isHorizontal, size)

  if (normalizedSize < minSize) {
    normalizedSize = minSize
  }

  if (normalizedSize > maxSize) {
    normalizedSize = maxSize
  }

  return normalizedSize
}

export function normalizeFont(isHorizontal: boolean, isFrench: boolean, size: number) {
  let adjustedSize: number = size

  if (isFrench) {
    // adjustedSize = size / 1.5 // Old attempt
    adjustedSize = size / 1
  }

  const baseFontSize: number = 10

  return normalizeSize(isHorizontal, adjustedSize) + baseFontSize
}

export function normalizeFont_WithinBounds(
  isHorizontal: boolean,
  isFrench: boolean,
  size: number,
  minFontSize: number,
  maxFontSize: number
) {
  let normalizedFontSize: number = normalizeFont(isHorizontal, isFrench, size)

  if (normalizedFontSize < minFontSize) {
    normalizedFontSize = minFontSize
  }

  if (normalizedFontSize > maxFontSize) {
    normalizedFontSize = maxFontSize
  }

  return normalizedFontSize
}

export default function globalStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  _teamColor: string,
  styleParameters: StyleParameters
) {
  const { isTablet } = styleParameters

  return StyleSheet.create({
    app: {
      flex: 1,
      backgroundColor: theme.colors.primary,
    },
    backgroundImageContents: {
      flex: 1,
      justifyContent: 'center',
    },

    imageStyle: {
      resizeMode: 'cover',
      paddingLeft: 600,
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
    },

    logo: {
      flex: 0.1,
      resizeMode: 'contain',
      zIndex: 60,
      alignSelf: isTablet ? 'center' : 'auto', // Web does not work with 'center' alignment here for some reason. This should be investigated, as similar issues could occur in the future.
    },
    logo_Small: {
      flex: 0.06,
      resizeMode: 'contain',
      zIndex: 60,
      alignSelf: isTablet ? 'center' : 'auto', // Web does not work with 'center' alignment here for some reason. This should be investigated, as similar issues could occur in the future.
    },
    userIconPosition: {
      position: 'absolute',
      zIndex: 50,
      left: '18.50%',
    },
    userIcon: {
      color: '#ED590E',
      fontSize: 24,
    },
    passwordIconPosition: {
      position: 'absolute',
      zIndex: 50,
      left: '19.25%',
    },
    passwordIcon: {
      color: '#ED590E',
      fontSize: 18,
    },
  })
}
