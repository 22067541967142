import React, { Component } from 'react'
import translator from '@taktik/config/Translation/languages'

import { Text } from 'react-native'
import { getRegistrationStyles } from '../Styles/AppStyle'
import { StyleParameters } from '../Styles/Utilities'
import {
  EmailValidation,
  GenericValidation,
  InputFieldValidation,
  OnlyNumberValidation,
  PasswordValidation,
} from '../DataTypes/ValidationType'

interface RegistrationErrorProps {
  errors: InputFieldValidation[]
  selectedPosition: number | undefined
  styleParameters: StyleParameters
}

export default class RegistrationError extends Component<RegistrationErrorProps> {
  getErrorMessage = () => {
    const { selectedPosition, errors } = this.props

    if (errors.includes(GenericValidation.NOT_EMPTY)) {
      return translator.t('modalCreateAccount.completeAllField')
    }
    if (errors.includes(EmailValidation.NOT_EMPTY)) {
      return translator.t('modalCreateAccount.emptyEmail')
    }
    if (errors.includes(PasswordValidation.MATCHING)) {
      return translator.t('modalCreateAccount.passwordDontMatch')
    }
    if (errors.includes(OnlyNumberValidation.IS_ONLY_NUMBER)) {
      return translator.t('modalCreateAccount.onlyNumber')
    }
    if (!selectedPosition) {
      return translator.t('modalCreateAccount.pickAPosition')
    }
    if (errors.includes(EmailValidation.CORRECT_FORMAT)) {
      return translator.t('modalCreateAccount.invalidEmail')
    }
    return ''
  }

  render() {
    const { styleParameters } = this.props
    const styles = getRegistrationStyles(styleParameters)

    return <Text style={styles.errorText}>{this.getErrorMessage()}</Text>
  }
}
