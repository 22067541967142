import {
  TOGGLE_IMAGES_LOADING,
  TOGGLE_IS_ALL_PLAYBOOK_DOWNLOADED,
  PERCENT_OF_PLAYBOOK_DOWNLOADED,
} from '../actionTypes'

export interface CacheState {
  loadingImages: boolean
  percentPlaybookDownloaded: number
  isAllPlaybookDownloaded: boolean
}

const initialState: CacheState = {
  loadingImages: true,
  percentPlaybookDownloaded: 0,
  isAllPlaybookDownloaded: false,
}

export default function cache(state = initialState, action: any): CacheState {
  switch (action.type) {
    case TOGGLE_IMAGES_LOADING: {
      const { isLoading } = action.payload
      return {
        ...state,
        loadingImages: isLoading,
      }
    }
    case TOGGLE_IS_ALL_PLAYBOOK_DOWNLOADED: {
      return {
        ...state,
        isAllPlaybookDownloaded: action.payload.isAllPlaybookDownloaded,
      }
    }
    case PERCENT_OF_PLAYBOOK_DOWNLOADED: {
      return {
        ...state,
        percentPlaybookDownloaded: action.payload.percentPlaybookDownloaded,
      }
    }
    default:
      return state
  }
}
