import React, { Component } from 'react'
import { Dimensions, View, Animated } from 'react-native'
import { NavigationInterface } from '../Pages/PageInfo'
import { updateStyleParameters, getLoadingBarStyles } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { GlobalColors } from '../Styles/Theme'

import { store } from '../redux/store'

import { connect } from 'react-redux'

import { RootState } from '../redux/reducers'
import { LinearGradient } from 'expo-linear-gradient'
import { ActivityIndicator } from 'react-native-paper'
import { toggleIsAllPlaybooksDownloaded } from '../redux/actions'

interface DownloadIndicatorProps extends NavigationInterface {
  percentPlaybookDownloaded: number
  isLoading: boolean
}

interface DownloadIndicatorState {
  styleParameters: StyleParameters
  loadingProgressBarAnimation: Animated.Value
  previousPercent: number
}

class DownloadIndicator extends Component<DownloadIndicatorProps, DownloadIndicatorState> {
  public i: number
  constructor(props: DownloadIndicatorProps) {
    super(props)
    this.state = {
      styleParameters: getStyleParameters(),
      loadingProgressBarAnimation: new Animated.Value(0.01),
      previousPercent: 0,
    }
    this.i = 0
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  shouldComponentUpdate(nextProps: DownloadIndicatorProps) {
    const { percentPlaybookDownloaded } = nextProps

    const shouldUpdate = Number(percentPlaybookDownloaded) - this.state.previousPercent > 1
    if (shouldUpdate) {
      this.setState({ previousPercent: Number(percentPlaybookDownloaded) })
      return true
    } else {
      return false
    }
  }
  componentDidMount() {
    Dimensions.addEventListener('change', this.onChange)
    this.onAnimateloadingProgressBarAnimation()
  }

  onAnimateloadingProgressBarAnimation = () => {
    const { percentPlaybookDownloaded } = this.props
    const { loadingProgressBarAnimation } = this.state

    // Otherwise the iOS pop-up crashes. See TAK-1020 for details, but ultimately we should fix the
    // "Cannot update a component from inside the function body..." error

    Animated.timing(loadingProgressBarAnimation, {
      toValue: percentPlaybookDownloaded,
      duration: 10000,
      useNativeDriver: false,
      isInteraction: false,
    }).start()
  }

  render = () => {
    const { styleParameters } = this.state
    const { percentPlaybookDownloaded, isLoading } = this.props
    const percent: any = percentPlaybookDownloaded.toFixed(0)
    const allPlaybooksDownloaded = Number(percent) >= 99

    if (allPlaybooksDownloaded && isLoading) {
      store.dispatch(toggleIsAllPlaybooksDownloaded(true))
    }

    let indicator: JSX.Element
    if (allPlaybooksDownloaded) {
      indicator = <ActivityIndicator size="large" color={GlobalColors.defaultTeamColor} />
    } else {
      const percentText = (
        <Animated.Text style={getLoadingBarStyles(styleParameters).loadingProgressBarText}>
          {percent} %
        </Animated.Text>
      )

      indicator = (
        <View style={getLoadingBarStyles(styleParameters).loadingProgressBar}>
          <Animated.View
            style={{
              width: percent + '%',
              height: 10,
            }}
          >
            <LinearGradient
              colors={[GlobalColors.mediumGrey, GlobalColors.darkGrey]}
              style={getLoadingBarStyles(styleParameters).loadingProgressBarFill}
              locations={[0, 1]}
              start={[0, 0.5]}
              end={[1, 0.5]}
            >
              {percentPlaybookDownloaded > 0 ? percentText : null}
            </LinearGradient>
          </Animated.View>
        </View>
      )
    }

    return indicator
  }
}

const mapStateToProps = (state: RootState) => ({
  percentPlaybookDownloaded: state.cache.percentPlaybookDownloaded,
  isLoading: state.cache.loadingImages,
})

export default connect(mapStateToProps, {})(DownloadIndicator)
