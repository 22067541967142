import { Platform } from 'react-native'
import { StyleParameters, getMarginToTransformIn43, transformUXSpec } from '../Utilities'

export interface StyledScreenRatio {
  TopMenuBar_width: any
  TopMenuBar: any
  TopMenuBar_marginLeft: any
  HamburgerButtonContainer_width: any
  HamburgerMenuIcon_fontSize: any
  BackButtonContainer_width: any
  LogoContainer_width: any
  Logo_height: any
  RoleContainer_paddingLeft: any
  RoleContainer_alignSelf: any
  NameText_fontSize: any
  RoleText_fontSize: any
  IDs_flexDirection: any
  IDs_width: any
  IDs_justifyContent: any
  ModalContainer_width: any
  ParrallelogramLeft_borderRightWidth: any
  ModalMenuLinkContainer_marginTop: any
  ModalMenuLink_fontSize: any
  ModalMenuSettingsLogoutLink_fontSize: any
  ModalLogoContainer_width: any
  ModalLogoContainer_marginLeft: any
  ModalLogoContainer_marginBottom: any
  ModalTitle_fontSize: any
  ModalSeparator_width: any
  ModalSeparator_marginLeft: any
  ModalSeparator_height: any
  ModalSeparator_marginTop: any
  Icon_width: any
  ModalChoiceContainer_width: any
  ModalChoiceContainer_selection_maxHeight: any
  ModalChoiceContainer_selection_height: any
  ModalChoiceContainer_noselection_height: any
  ModalChoiceContainer_marginTop: any
  ModalChoiceTitle_fontSize: any
  ModalChoiceQuestion_marginTop: any
  ModalChoiceQuestion_fontSize: any
  ModalChoiceChildContainer_width: any
  ModalChoiceChildContainer_maxHeight: any
  ModalChoiceChildContainer_marginBottom: any
  ModalChoiceChildContainer_paddingVertical: any
  ModalChoiceChildText_fontSize: any
  ModalChoiceChildScrollView_height: any
  ModalChoicePractice_width: any
  ModalChoicePractice_paddingVertical: any
  ModalChoicePractice_maxHeight: any
  ModalChoiceButton_width: any
  ModalChoiceButton_height: any
  ModalChoiceButtonText_fontSize: any
  ModalContainer_borderRadius: any
  ModalChoiceGroupsColumn_marginHorizontal: any
  LanguageSettingsContainer_paddingRight: any
  LanguageSettingsText_fontSize: any
  PlaybookTitle_fontSize: any
  PlaybookTitle_paddingHorizontal: any
  ModalChoiceCloseButtonIcon_paddingTop: any
  ModalTeamLogo_size: any
  ModalTeamName_fontSize: any
  ModalTeamContainer_height: any
  ModalTitleTeamSelection_fontSize: any
  manageTeamText_fontSize: any
  finishText_fontSize: any
  trashIconContainer_width: any
  trashIconContainer_height: any
  manageTeamContainer_marginTop: any
  manageTeamContainer_marginLeft: any
  manageTeamContainer_width: any
  trashIcon_size: any
  trashIcon_marginLeft: any
  trashIcon_marginTop: any
  playbookPageButton_width: any
  playbookPageButton_height: any
  playbookPageButtonText_fontSize: any
  noPraticeAvailableText_fontSize: any
  suggestionText_fontSize: any
  suggestionSecondPartText_fontSize: any
  noPraticeAvailableText_marginTop: any
  noPraticeAvailableText_marginBottom: any
  suggestionSecondPartText_marginBottom: any
  playbookPageButton_marginLeft: any
  teamWrapper_marginLeft: any
  teamContainer_marginLeft: any
  trashIconContainer_marginLeft: any
  finishTeamContainer_width: any
  finishTeamContainer_marginTop: any
  finishTeamContainer_marginLeft: any
}

export function getScreenRatioStyle(
  styleParameters: StyleParameters,
  mustShowBackButton?: boolean,
  maxChildren?: number
) {
  const { screenHeight } = styleParameters
  let screenWidth = styleParameters.screenWidth
  const marginHorizontal: number = getMarginToTransformIn43(screenWidth, screenHeight)
  const os = Platform.OS
  if (screenWidth > screenHeight) {
    if (screenHeight > 600) {
      screenWidth -= marginHorizontal
      //horizontal tablet
      return {
        TopMenuBar_width: screenWidth,
        TopMenuBar_marginLeft: marginHorizontal,
        TopMenuBar: transformUXSpec(screenWidth, screenHeight, 140),
        HamburgerButtonContainer_width: mustShowBackButton
          ? transformUXSpec(screenWidth, screenHeight, 140)
          : transformUXSpec(screenWidth, screenHeight, 280),
        HamburgerMenuIcon_fontSize: transformUXSpec(screenWidth, screenHeight, 70),
        BackButtonContainer_width: transformUXSpec(screenWidth, screenHeight, 140),
        LogoContainer_width: transformUXSpec(screenWidth, screenHeight, 1452),
        Logo_height: transformUXSpec(screenWidth, screenHeight, 70),
        RoleContainer_paddingLeft: '5%',
        RoleContainer_alignSelf: 'center',
        NameText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        RoleText_fontSize: transformUXSpec(screenWidth, screenHeight, 37),
        IDs_flexDirection: 'row',
        IDs_width: transformUXSpec(screenWidth, screenHeight, 430),
        IDs_justifyContent: 'flex-end',
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 840),
        ParrallelogramLeft_borderRightWidth: transformUXSpec(screenWidth, screenHeight, 115),
        ModalMenuLinkContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 60),
        ModalMenuLink_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        ModalMenuSettingsLogoutLink_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        ModalLogoContainer_width: transformUXSpec(screenWidth, screenHeight, 140),
        ModalLogoContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 125),
        ModalLogoContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 25),
        ModalTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 100),
        ModalTitleTeamSelection_fontSize: transformUXSpec(screenWidth, screenHeight, 100),
        ModalSeparator_width: transformUXSpec(screenWidth, screenHeight, 600),
        ModalSeparator_marginLeft: transformUXSpec(screenWidth, screenHeight, 30),
        ModalSeparator_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalSeparator_height: transformUXSpec(screenWidth, screenHeight, 5),
        Icon_width: transformUXSpec(screenWidth, screenHeight, 80),
        ModalChoiceContainer_width: transformUXSpec(screenWidth, screenHeight, 1400),
        ModalChoiceContainer_selection_maxHeight: transformUXSpec(screenWidth, screenHeight, 1200),
        ModalChoiceContainer_selection_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 740) +
            maxChildren * transformUXSpec(screenWidth, screenHeight, 125)
          : transformUXSpec(screenWidth, screenHeight, 450),
        ModalChoiceContainer_noselection_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 565) +
            maxChildren * transformUXSpec(screenWidth, screenHeight, 125)
          : transformUXSpec(screenWidth, screenHeight, 450),
        ModalChoiceContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 380),
        ModalChoiceTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 90),
        ModalChoiceQuestion_fontSize: transformUXSpec(screenWidth, screenHeight, 60),
        ModalChoiceQuestion_marginTop: transformUXSpec(screenWidth, screenHeight, 25),
        ModalChoiceChildContainer_width: transformUXSpec(screenWidth, screenHeight, 400),
        ModalChoiceChildContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 26),
        ModalChoiceChildContainer_paddingVertical: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceChildText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        ModalChoiceChildContainer_maxHeight: transformUXSpec(screenWidth, screenHeight, 500),
        ModalChoiceChildScrollView_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 120) +
            (maxChildren - 1) * transformUXSpec(screenWidth, screenHeight, 110)
          : transformUXSpec(screenWidth, screenHeight, 120),
        ModalChoicePractice_width: transformUXSpec(screenWidth, screenHeight, 1250),
        ModalChoicePractice_paddingVertical: transformUXSpec(screenWidth, screenHeight, 80),
        ModalChoicePractice_maxHeight: transformUXSpec(screenWidth, screenHeight, 730),
        ModalChoiceButton_width: transformUXSpec(screenWidth, screenHeight, 600),
        ModalChoiceButton_height: transformUXSpec(screenWidth, screenHeight, 120),
        ModalChoiceButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 55),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceGroupsColumn_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 12),
        LanguageSettingsContainer_paddingRight: transformUXSpec(screenWidth, screenHeight, 1940),
        LanguageSettingsText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookTitle_paddingHorizontal: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceCloseButtonIcon_paddingTop: transformUXSpec(screenWidth, screenHeight, 12),
        ModalTeamLogo_size: 60,
        ModalTeamName_fontSize: 24,
        ModalTeamContainer_height: 100,
        manageTeamText_fontSize: 18,
        finishText_fontSize: 18,
        trashIconContainer_width: transformUXSpec(screenWidth, screenHeight, 80),
        trashIconContainer_height: transformUXSpec(screenWidth, screenHeight, 90),
        manageTeamContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 1500),
        manageTeamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 200),
        manageTeamContainer_width: transformUXSpec(screenWidth, screenHeight, 330),
        trashIcon_size: transformUXSpec(screenWidth, screenHeight, 60),
        trashIcon_marginLeft: transformUXSpec(screenWidth, screenHeight, 10),
        trashIcon_marginTop: transformUXSpec(screenWidth, screenHeight, 15),
        playbookPageButton_width: transformUXSpec(screenWidth, screenHeight, 400),
        playbookPageButton_height: transformUXSpec(screenWidth, screenHeight, 100),
        playbookPageButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        noPraticeAvailableText_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        suggestionText_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        suggestionSecondPartText_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        noPraticeAvailableText_marginTop: transformUXSpec(screenWidth, screenHeight, 35),
        noPraticeAvailableText_marginBottom: transformUXSpec(screenWidth, screenHeight, 35),
        suggestionSecondPartText_marginBottom: transformUXSpec(screenWidth, screenHeight, 50),
        playbookPageButton_marginLeft: transformUXSpec(screenWidth, screenHeight, 100),
        teamWrapper_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        teamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 350),
        trashIconContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 500),
        finishTeamContainer_width: transformUXSpec(screenWidth, screenHeight, 330),
        finishTeamContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 1500),
        finishTeamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 200),
      }
    } else {
      //horizontal mobile
      return {
        TopMenuBar_width: screenWidth,
        TopMenuBar_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        TopMenuBar: transformUXSpec(screenWidth, screenHeight, 50),
        HamburgerButtonContainer_width: mustShowBackButton
          ? transformUXSpec(screenWidth, screenHeight, 50)
          : transformUXSpec(screenWidth, screenHeight, 270.6),
        HamburgerMenuIcon_fontSize: transformUXSpec(screenWidth, screenHeight, 70),
        BackButtonContainer_width: transformUXSpec(screenWidth, screenHeight, 220.6),
        LogoContainer_width: transformUXSpec(screenWidth, screenHeight, 270.6),
        Logo_height: transformUXSpec(screenWidth, screenHeight, 30),
        RoleContainer_paddingLeft: '5%',
        RoleContainer_alignSelf: 'flex-end',
        NameText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        RoleText_fontSize: transformUXSpec(screenWidth, screenHeight, 12),
        IDs_flexDirection: 'column',
        IDs_width: transformUXSpec(screenWidth, screenHeight, 270.6),
        IDs_justifyContent: 'center',
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 350),
        ParrallelogramLeft_borderRightWidth: transformUXSpec(screenWidth, screenHeight, 25),
        ModalMenuLinkContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalMenuLink_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        ModalMenuSettingsLogoutLink_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        ModalLogoContainer_width: transformUXSpec(screenWidth, screenHeight, 43),
        ModalLogoContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        ModalLogoContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 23),
        ModalTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 35),
        ModalTitleTeamSelection_fontSize: transformUXSpec(screenWidth, screenHeight, 22),
        ModalSeparator_width: transformUXSpec(screenWidth, screenHeight, 250),
        ModalSeparator_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        ModalSeparator_marginTop: transformUXSpec(screenWidth, screenHeight, 3),
        ModalSeparator_height: transformUXSpec(screenWidth, screenHeight, 3),
        Icon_width: transformUXSpec(screenWidth, screenHeight, 40),
        ModalChoiceContainer_width: transformUXSpec(screenWidth, screenHeight, 525),
        ModalChoiceContainer_selection_maxHeight: transformUXSpec(screenWidth, screenHeight, 320),
        ModalChoiceContainer_selection_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 240) +
            maxChildren * transformUXSpec(screenWidth, screenHeight, 45)
          : transformUXSpec(screenWidth, screenHeight, 200),
        ModalChoiceContainer_noselection_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 200) +
            maxChildren * transformUXSpec(screenWidth, screenHeight, 45)
          : transformUXSpec(screenWidth, screenHeight, 200),
        ModalChoiceContainer_marginTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 70)
            : transformUXSpec(screenWidth, screenHeight, 35),
        ModalChoiceTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        ModalChoiceQuestion_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceQuestion_marginTop: transformUXSpec(screenWidth, screenHeight, 6),
        ModalChoiceChildContainer_width: transformUXSpec(screenWidth, screenHeight, 150),
        ModalChoiceChildContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 7),
        ModalChoiceChildContainer_paddingVertical: transformUXSpec(screenWidth, screenHeight, 7),
        ModalChoiceChildText_fontSize: transformUXSpec(screenWidth, screenHeight, 14),
        ModalChoiceChildContainer_maxHeight: transformUXSpec(screenWidth, screenHeight, 100),
        ModalChoiceChildScrollView_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 45) +
            (maxChildren - 1) * transformUXSpec(screenWidth, screenHeight, 45)
          : transformUXSpec(screenWidth, screenHeight, 45),
        ModalChoicePractice_width: transformUXSpec(screenWidth, screenHeight, 400),
        ModalChoicePractice_paddingVertical: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoicePractice_maxHeight: transformUXSpec(screenWidth, screenHeight, 180),
        ModalChoiceButton_width: transformUXSpec(screenWidth, screenHeight, 250),
        ModalChoiceButton_height: transformUXSpec(screenWidth, screenHeight, 40),
        ModalChoiceButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceGroupsColumn_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 5),
        LanguageSettingsContainer_paddingRight: transformUXSpec(screenWidth, screenHeight, 600),
        LanguageSettingsText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        PlaybookTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 10),
        PlaybookTitle_paddingHorizontal: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceCloseButtonIcon_paddingTop: transformUXSpec(screenWidth, screenHeight, 5),
        ModalTeamLogo_size: 30,
        ModalTeamName_fontSize: 15,
        ModalTeamContainer_height: 40,
        manageTeamText_fontSize: transformUXSpec(screenWidth, screenHeight, 12),
        finishText_fontSize: transformUXSpec(screenWidth, screenHeight, 12),
        trashIconContainer_width: transformUXSpec(screenWidth, screenHeight, 20),
        trashIconContainer_height: transformUXSpec(screenWidth, screenHeight, 28),
        manageTeamContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 300),
        manageTeamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 200),
        manageTeamContainer_width: transformUXSpec(screenWidth, screenHeight, 150),
        trashIcon_size: transformUXSpec(screenWidth, screenHeight, 15),
        trashIcon_marginLeft: transformUXSpec(screenWidth, screenHeight, 2),
        trashIcon_marginTop: transformUXSpec(screenWidth, screenHeight, 7),
        playbookPageButton_width: transformUXSpec(screenWidth, screenHeight, 100),
        playbookPageButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        playbookPageButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        noPraticeAvailableText_fontSize: transformUXSpec(screenWidth, screenHeight, 12),
        suggestionText_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        suggestionSecondPartText_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        noPraticeAvailableText_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        noPraticeAvailableText_marginBottom: transformUXSpec(screenWidth, screenHeight, 10),
        suggestionSecondPartText_marginBottom: transformUXSpec(screenWidth, screenHeight, 10),
        playbookPageButton_marginLeft: transformUXSpec(screenWidth, screenHeight, 80),
        teamWrapper_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        teamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 120),
        trashIconContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 200),
        finishTeamContainer_width: transformUXSpec(screenWidth, screenHeight, 150),
        finishTeamContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 300),
        finishTeamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 280),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        TopMenuBar_width: screenWidth,
        TopMenuBar: transformUXSpec(screenWidth, screenHeight, 50),
        TopMenuBar_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        HamburgerButtonContainer_width: mustShowBackButton
          ? transformUXSpec(screenWidth, screenHeight, 50)
          : transformUXSpec(screenWidth, screenHeight, 125),
        HamburgerMenuIcon_fontSize: transformUXSpec(screenWidth, screenHeight, 70),
        BackButtonContainer_width: transformUXSpec(screenWidth, screenHeight, 75),
        LogoContainer_width: transformUXSpec(screenWidth, screenHeight, 165),
        Logo_height: transformUXSpec(screenWidth, screenHeight, 25),
        RoleContainer_paddingLeft: 0,
        RoleContainer_alignSelf: 'flex-end',
        NameText_fontSize: transformUXSpec(screenWidth, screenHeight, 14),
        RoleText_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        IDs_flexDirection: 'column',
        IDs_width: transformUXSpec(screenWidth, screenHeight, 85),
        IDs_justifyContent: 'center',
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 320),
        ParrallelogramLeft_borderRightWidth: transformUXSpec(screenWidth, screenHeight, 55),
        ModalMenuLinkContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 20),
        ModalMenuLink_fontSize: transformUXSpec(screenWidth, screenHeight, 37),
        ModalMenuSettingsLogoutLink_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ModalLogoContainer_width: transformUXSpec(screenWidth, screenHeight, 61),
        ModalLogoContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 52),
        ModalLogoContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 10.4),
        ModalTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        ModalTitleTeamSelection_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        ModalSeparator_width: transformUXSpec(screenWidth, screenHeight, 280),
        ModalSeparator_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        ModalSeparator_marginTop: transformUXSpec(screenWidth, screenHeight, 6),
        ModalSeparator_height: transformUXSpec(screenWidth, screenHeight, 4),
        Icon_width: transformUXSpec(screenWidth, screenHeight, 25),
        ModalChoiceContainer_width: transformUXSpec(screenWidth, screenHeight, 350),
        ModalChoiceContainer_selection_maxHeight: transformUXSpec(screenWidth, screenHeight, 500),
        ModalChoiceContainer_selection_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 220) +
            maxChildren * transformUXSpec(screenWidth, screenHeight, 45)
          : transformUXSpec(screenWidth, screenHeight, 200),
        ModalChoiceContainer_noselection_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 200) +
            maxChildren * transformUXSpec(screenWidth, screenHeight, 45)
          : transformUXSpec(screenWidth, screenHeight, 200),
        ModalChoiceContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        ModalChoiceTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        ModalChoiceQuestion_fontSize: transformUXSpec(screenWidth, screenHeight, 16),
        ModalChoiceQuestion_marginTop: transformUXSpec(screenWidth, screenHeight, 12),
        ModalChoiceChildContainer_width: transformUXSpec(screenWidth, screenHeight, 100),
        ModalChoiceChildContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 10),
        ModalChoiceChildContainer_paddingVertical: transformUXSpec(screenWidth, screenHeight, 10),
        ModalChoiceChildText_fontSize: transformUXSpec(screenWidth, screenHeight, 12),
        ModalChoiceChildContainer_maxHeight: transformUXSpec(screenWidth, screenHeight, 245),
        ModalChoiceChildScrollView_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 45) +
            (maxChildren - 1) * transformUXSpec(screenWidth, screenHeight, 45)
          : transformUXSpec(screenWidth, screenHeight, 45),
        ModalChoicePractice_width: transformUXSpec(screenWidth, screenHeight, 300),
        ModalChoicePractice_paddingVertical: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoicePractice_maxHeight: transformUXSpec(screenWidth, screenHeight, 330),
        ModalChoiceButton_width: transformUXSpec(screenWidth, screenHeight, 200),
        ModalChoiceButton_height: transformUXSpec(screenWidth, screenHeight, 40),
        ModalChoiceButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceGroupsColumn_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 5),
        LanguageSettingsContainer_paddingRight: transformUXSpec(screenWidth, screenHeight, 260),
        LanguageSettingsText_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        PlaybookTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 5),
        PlaybookTitle_paddingHorizontal: transformUXSpec(screenWidth, screenHeight, 0),
        ModalChoiceCloseButtonIcon_paddingTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalTeamLogo_size: 60,
        ModalTeamName_fontSize: 24,
        ModalTeamContainer_height: 100,
        manageTeamText_fontSize: 17,
        finishText_fontSize: 17,
        trashIconContainer_width: transformUXSpec(screenWidth, screenHeight, 25),
        trashIconContainer_height: transformUXSpec(screenWidth, screenHeight, 30),
        manageTeamContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 700),
        manageTeamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 100),
        manageTeamContainer_width: transformUXSpec(screenWidth, screenHeight, 150),
        trashIcon_size: transformUXSpec(screenWidth, screenHeight, 18),
        trashIcon_marginLeft: transformUXSpec(screenWidth, screenHeight, 3),
        trashIcon_marginTop: transformUXSpec(screenWidth, screenHeight, 7),
        playbookPageButton_width: transformUXSpec(screenWidth, screenHeight, 100),
        playbookPageButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        playbookPageButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        noPraticeAvailableText_fontSize: transformUXSpec(screenWidth, screenHeight, 12),
        suggestionText_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        suggestionSecondPartText_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        noPraticeAvailableText_marginTop: transformUXSpec(screenWidth, screenHeight, 15),
        noPraticeAvailableText_marginBottom: transformUXSpec(screenWidth, screenHeight, 15),
        suggestionSecondPartText_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        playbookPageButton_marginLeft: transformUXSpec(screenWidth, screenHeight, 75),
        teamWrapper_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        teamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        trashIconContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 220),
        finishTeamContainer_width: transformUXSpec(screenWidth, screenHeight, 150),
        finishTeamContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 700),
        finishTeamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 100),
      }
    } else {
      //vertical tablet
      return {
        TopMenuBar_width: screenWidth,
        TopMenuBar_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        TopMenuBar: transformUXSpec(screenWidth, screenHeight, 140),
        HamburgerButtonContainer_width: mustShowBackButton
          ? transformUXSpec(screenWidth, screenHeight, 140)
          : transformUXSpec(screenWidth, screenHeight, 540),
        HamburgerMenuIcon_fontSize: transformUXSpec(screenWidth, screenHeight, 70),
        BackButtonContainer_width: transformUXSpec(screenWidth, screenHeight, 400),
        LogoContainer_width: transformUXSpec(screenWidth, screenHeight, 540),
        Logo_height: transformUXSpec(screenWidth, screenHeight, 100),
        RoleContainer_paddingLeft: 0,
        RoleContainer_alignSelf: 'flex-end',
        NameText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        RoleText_fontSize: transformUXSpec(screenWidth, screenHeight, 37),
        IDs_flexDirection: 'column',
        IDs_width: transformUXSpec(screenWidth, screenHeight, 540),
        IDs_justifyContent: 'center',
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 850),
        ParrallelogramLeft_borderRightWidth: transformUXSpec(screenWidth, screenHeight, 50),
        ModalMenuLinkContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        ModalMenuLink_fontSize: transformUXSpec(screenWidth, screenHeight, 90),
        ModalMenuSettingsLogoutLink_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        ModalLogoContainer_width: transformUXSpec(screenWidth, screenHeight, 140),
        ModalLogoContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 146),
        ModalLogoContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 29.2),
        ModalTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 120),
        ModalTitleTeamSelection_fontSize: transformUXSpec(screenWidth, screenHeight, 120),
        ModalSeparator_width: transformUXSpec(screenWidth, screenHeight, 680),
        ModalSeparator_marginLeft: transformUXSpec(screenWidth, screenHeight, 85),
        ModalSeparator_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalSeparator_height: transformUXSpec(screenWidth, screenHeight, 5),
        Icon_width: transformUXSpec(screenWidth, screenHeight, 80),
        ModalChoiceContainer_width: transformUXSpec(screenWidth, screenHeight, 1100),
        ModalChoiceContainer_selection_maxHeight: transformUXSpec(screenWidth, screenHeight, 1200),
        ModalChoiceContainer_selection_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 650) +
            maxChildren * transformUXSpec(screenWidth, screenHeight, 100)
          : transformUXSpec(screenWidth, screenHeight, 450),
        ModalChoiceContainer_noselection_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 500) +
            maxChildren * transformUXSpec(screenWidth, screenHeight, 100)
          : transformUXSpec(screenWidth, screenHeight, 450),
        ModalChoiceContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 480),
        ModalChoiceTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 78),
        ModalChoiceQuestion_fontSize: transformUXSpec(screenWidth, screenHeight, 48),
        ModalChoiceQuestion_marginTop: transformUXSpec(screenWidth, screenHeight, 30),
        ModalChoiceChildContainer_width: transformUXSpec(screenWidth, screenHeight, 300),
        ModalChoiceChildContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceChildContainer_paddingVertical: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceChildText_fontSize: transformUXSpec(screenWidth, screenHeight, 33),
        ModalChoiceChildContainer_maxHeight: transformUXSpec(screenWidth, screenHeight, 500),
        ModalChoiceChildScrollView_height: maxChildren
          ? transformUXSpec(screenWidth, screenHeight, 100) +
            (maxChildren - 1) * transformUXSpec(screenWidth, screenHeight, 100)
          : transformUXSpec(screenWidth, screenHeight, 100),
        ModalChoicePractice_width: transformUXSpec(screenWidth, screenHeight, 950),
        ModalChoicePractice_paddingVertical: transformUXSpec(screenWidth, screenHeight, 80),
        ModalChoicePractice_maxHeight: transformUXSpec(screenWidth, screenHeight, 730),
        ModalChoiceButton_width: transformUXSpec(screenWidth, screenHeight, 550),
        ModalChoiceButton_height: transformUXSpec(screenWidth, screenHeight, 100),
        ModalChoiceButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalChoiceGroupsColumn_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 10),
        LanguageSettingsContainer_paddingRight: transformUXSpec(screenWidth, screenHeight, 1250),
        LanguageSettingsText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        PlaybookTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookTitle_paddingHorizontal: transformUXSpec(screenWidth, screenHeight, 10),
        ModalChoiceCloseButtonIcon_paddingTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalTeamLogo_size: 60,
        ModalTeamName_fontSize: 24,
        ModalTeamContainer_height: 100,
        manageTeamText_fontSize: 17,
        finishText_fontSize: 17,
        trashIconContainer_width: transformUXSpec(screenWidth, screenHeight, 80),
        trashIconContainer_height: transformUXSpec(screenWidth, screenHeight, 90),
        manageTeamContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 1900),
        manageTeamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 250),
        manageTeamContainer_width: transformUXSpec(screenWidth, screenHeight, 350),
        trashIcon_size: transformUXSpec(screenWidth, screenHeight, 60),
        trashIcon_marginLeft: transformUXSpec(screenWidth, screenHeight, 10),
        trashIcon_marginTop: transformUXSpec(screenWidth, screenHeight, 15),
        playbookPageButton_width: transformUXSpec(screenWidth, screenHeight, 400),
        playbookPageButton_height: transformUXSpec(screenWidth, screenHeight, 90),
        playbookPageButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        noPraticeAvailableText_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        suggestionText_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        suggestionSecondPartText_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        noPraticeAvailableText_marginTop: transformUXSpec(screenWidth, screenHeight, 25),
        noPraticeAvailableText_marginBottom: transformUXSpec(screenWidth, screenHeight, 25),
        suggestionSecondPartText_marginBottom: transformUXSpec(screenWidth, screenHeight, 30),
        playbookPageButton_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        teamWrapper_marginLeft: transformUXSpec(screenWidth, screenHeight, 100),
        teamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 350),
        trashIconContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 600),
        finishTeamContainer_width: transformUXSpec(screenWidth, screenHeight, 350),
        finishTeamContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 1900),
        finishTeamContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 250),
      }
    }
  }
}
