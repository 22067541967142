import { Platform } from 'react-native'
import { StyleParameters, getMarginToTransformIn43, transformUXSpec } from '../Utilities'
export interface StyledScreenRatio {
  ConfirmText_marginTop: any
  ConfirmButton_height: any
  ModalContainer_width: any
  ModalContainer_height: any
  ModalContainer_marginTop: any
  ModalContainerWithImage_marginTop: any
  ModalConfirmText_fontSize: any
  ModalSubTextContainer_flexDirection: any
  ModalSubText_fontSize: any
  ModalButtonText_fontSize: any
  ModalRowButtons_direction: any
  ModalButtonContainer_width: any
  ModalButtonContainer_height: any
  ModalButtonContainer_marginTop: any
  ModalButton_width: any
  ModalButton_marginTop: any
  ModalButtonTop_marginLeft: any
  ModalCountdownFirstRow_fontSize: any
  ModalCountdownSecondRow_fontSize: any
  ModalCountdownText_fontSize: any
  ModalCountdownContainer_width: any
  ModalCountdownContainer_height: any
  ModalCountdownContainer_borderRadius: any
  ModalCountdownWidget_size: any
  ModalContainer_borderRadius: any
  ModalSubTextContainer_marginBottom: any
  ModalSubTextSuccess_marginBottom: any
  resultModalImage_width: any
  resultModalImage_heigth: any
}

export function getScreenRatioStyle(styleParameters: StyleParameters) {
  let { screenWidth, screenHeight } = styleParameters
  const marginHorizontal: number = getMarginToTransformIn43(screenWidth, screenHeight)
  const os = Platform.OS
  if (screenWidth > screenHeight) {
    if (screenHeight > 600) {
      //horizontal tablet
      screenWidth -= marginHorizontal
      return {
        ConfirmText_marginTop: 0,
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 94),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 1080),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 554),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 227),
        ModalContainerWithImage_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 375),
        ModalConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 190),
        ModalSubTextContainer_flexDirection: 'row',
        ModalSubText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        ModalButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        ModalRowButtons_direction: 'row',
        ModalButtonContainer_width: '50%',
        ModalButtonContainer_height: '40%',
        ModalButtonContainer_marginTop: '1%',
        ModalButton_width: '80%',
        ModalButton_marginTop: '5%',
        ModalButtonTop_marginLeft: 0,
        ModalCountdownFirstRow_fontSize: transformUXSpec(screenWidth, screenHeight, 90),
        ModalCountdownSecondRow_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        ModalCountdownText_fontSize: transformUXSpec(screenWidth, screenHeight, 280),
        ModalCountdownContainer_width: transformUXSpec(screenWidth, screenHeight, 300),
        ModalCountdownContainer_height: transformUXSpec(screenWidth, screenHeight, 300),
        ModalCountdownContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 150),
        ModalCountdownWidget_size: transformUXSpec(screenWidth, screenHeight, 400),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalSubTextContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 0),
        ModalSubTextSuccess_marginBottom: transformUXSpec(screenWidth, screenHeight, 50),
        resultModalImage_width: transformUXSpec(screenWidth, screenHeight, 600),
        resultModalImage_heigth: transformUXSpec(screenWidth, screenHeight, 400),
      }
    } else {
      //horizontal mobile
      return {
        ConfirmText_marginTop: 0,
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 505),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 235),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 235 / 2),
        ModalContainerWithImage_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 160),
        ModalConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 55),
        ModalSubTextContainer_flexDirection: 'row',
        ModalSubText_fontSize: transformUXSpec(screenWidth, screenHeight, 17),
        ModalButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 17),
        ModalRowButtons_direction: 'row',
        ModalButtonContainer_width: '50%',
        ModalButtonContainer_height: '0%',
        ModalButtonContainer_marginTop: '2%',
        ModalButton_width: '80%',
        ModalButton_marginTop: '5%',
        ModalButtonTop_marginLeft: 0,
        ModalCountdownFirstRow_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        ModalCountdownSecondRow_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        ModalCountdownText_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        ModalCountdownContainer_width: transformUXSpec(screenWidth, screenHeight, 90),
        ModalCountdownContainer_height: transformUXSpec(screenWidth, screenHeight, 90),
        ModalCountdownContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 45),
        ModalCountdownWidget_size: transformUXSpec(screenWidth, screenHeight, 180),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalSubTextContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 0),
        ModalSubTextSuccess_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        resultModalImage_width: transformUXSpec(screenWidth, screenHeight, 200),
        resultModalImage_heigth: transformUXSpec(screenWidth, screenHeight, 125),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        ConfirmText_marginTop: transformUXSpec(screenWidth, screenHeight, 0),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 40),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 340),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 310),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 310 / 2),
        ModalContainerWithImage_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 310),
        ModalConfirmText_fontSize:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 60)
            : transformUXSpec(screenWidth, screenHeight, 70),
        ModalSubTextContainer_flexDirection: 'column',
        ModalSubText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ModalButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ModalRowButtons_direction: 'column',
        ModalButtonContainer_width: '100%',
        ModalButtonContainer_height: transformUXSpec(screenWidth, screenHeight, 50),
        ModalButtonContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        ModalButton_width: '60%',
        ModalButton_marginTop: os === 'android' ? '2%' : '2%',
        ModalButtonTop_marginLeft:
          os === 'android' ? 0 : transformUXSpec(screenWidth, screenHeight, 15),
        ModalCountdownFirstRow_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        ModalCountdownSecondRow_fontSize: transformUXSpec(screenWidth, screenHeight, 28),
        ModalCountdownText_fontSize: transformUXSpec(screenWidth, screenHeight, 105),
        ModalCountdownContainer_width: transformUXSpec(screenWidth, screenHeight, 120),
        ModalCountdownContainer_height: transformUXSpec(screenWidth, screenHeight, 120),
        ModalCountdownContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 60),
        ModalCountdownWidget_size: transformUXSpec(screenWidth, screenHeight, 300),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalSubTextContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 0),
        ModalSubTextSuccess_marginBottom: transformUXSpec(screenWidth, screenHeight, 0),
        resultModalImage_width: transformUXSpec(screenWidth, screenHeight, 225),
        resultModalImage_heigth: transformUXSpec(screenWidth, screenHeight, 150),
      }
    } else {
      //vertical tablet
      return {
        ConfirmText_marginTop: transformUXSpec(screenWidth, screenHeight, 0),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 94),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 1080),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 750),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 750 / 2),
        ModalContainerWithImage_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 750 / 2),
        ModalConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 190),
        ModalSubTextContainer_flexDirection: 'column',
        ModalSubText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        ModalButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        ModalRowButtons_direction: 'column',
        ModalButtonContainer_width: '100%',
        ModalButtonContainer_height: transformUXSpec(screenWidth, screenHeight, 120),
        ModalButtonContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        ModalButton_width: '60%',
        ModalButton_marginTop: '5%',
        ModalButtonTop_marginLeft: transformUXSpec(screenWidth, screenHeight, 45),
        ModalCountdownFirstRow_fontSize: transformUXSpec(screenWidth, screenHeight, 90),
        ModalCountdownSecondRow_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        ModalCountdownText_fontSize: transformUXSpec(screenWidth, screenHeight, 415),
        ModalCountdownContainer_width: transformUXSpec(screenWidth, screenHeight, 425),
        ModalCountdownContainer_height: transformUXSpec(screenWidth, screenHeight, 425),
        ModalCountdownContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 212),
        ModalCountdownWidget_size: transformUXSpec(screenWidth, screenHeight, 500),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalSubTextContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        ModalSubTextSuccess_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        resultModalImage_width: transformUXSpec(screenWidth, screenHeight, 600),
        resultModalImage_heigth: transformUXSpec(screenWidth, screenHeight, 400),
      }
    }
  }
}
