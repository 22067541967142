import React, { Component } from 'react'
import { Dimensions, Platform, Text, View, TouchableOpacity } from 'react-native'
import { NavigationInterface, PageName } from '../Pages/PageInfo'
import translator from '@taktik/config/Translation/languages'
import { getLoadingBarStyles, updateStyleParameters } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'

import { store } from '../redux/store'

import { connect } from 'react-redux'
import {
  toggleLoadingModal,
  togglePracticeModal,
  toggleIsAllPlaybooksDownloaded,
} from '../redux/actions'
import { Modal } from './ModalImport'
import { RootState } from '../redux/reducers'

import CancelIcon from '../../assets/svg/CancelIcon'
import DownloadIndicator from './DownloadIndicator'

interface LoadingModalProps extends NavigationInterface {
  isLoading: boolean
  loadingModalVisible: boolean
  destinationPage: PageName
  isAllPlaybookDownloaded: boolean
}

interface LoadingModalState {
  styleParameters: StyleParameters
  nbPlaybookLoaded: any
}

class LoadingModal extends Component<LoadingModalProps, LoadingModalState> {
  constructor(props: LoadingModalProps) {
    super(props)
    this.state = {
      nbPlaybookLoaded: 0,
      styleParameters: getStyleParameters(),
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this.onChange)
  }

  renderLoadingIndicator = () => {
    const { isLoading, loadingModalVisible, navigation, destinationPage } = this.props
    const OS = Platform.OS
    const isIOS = OS === 'ios'
    const mustDisplayGamificationModal =
      destinationPage === PageName.GamificationPage && !isIOS && loadingModalVisible
    const mustNavigateToPlaybookList =
      destinationPage === PageName.PlaybookList && !isIOS && loadingModalVisible
    const mustNavigateToProfilePage =
      destinationPage === PageName.PlayerStats && !isIOS && loadingModalVisible
    const mustCancelProgressBar = !isLoading

    if (mustCancelProgressBar) {
      store.dispatch(toggleIsAllPlaybooksDownloaded(false))
      store.dispatch(toggleLoadingModal(false, destinationPage))
      if (mustDisplayGamificationModal) {
        store.dispatch(togglePracticeModal(true))
      } else if (mustNavigateToPlaybookList) {
        navigation.navigate(PageName.PlaybookList)
      } else if (mustNavigateToProfilePage) {
        navigation.navigate(PageName.PlayerStats)
      }
    }

    return <DownloadIndicator navigation={navigation} />
  }

  renderLoadingMessage = () => {
    const { styleParameters } = this.state
    const { isAllPlaybookDownloaded } = this.props
    let loadingMessage = translator.t('home.loadingPlaybook').toUpperCase()

    if (isAllPlaybookDownloaded) {
      loadingMessage = translator.t('home.updatingPlaybook').toUpperCase()
    }
    return (
      <Text adjustsFontSizeToFit style={getLoadingBarStyles(styleParameters).modalTitleText}>
        {loadingMessage}
      </Text>
    )
  }

  render() {
    const { styleParameters } = this.state
    const { loadingModalVisible, destinationPage } = this.props
    return loadingModalVisible ? (
      <View style={getLoadingBarStyles(styleParameters).modalBackground}>
        <Modal animationType="none" transparent visible={loadingModalVisible}>
          <View style={getLoadingBarStyles(styleParameters).modalContainer}>
            <TouchableOpacity
              style={[getLoadingBarStyles(styleParameters).ModalCloseButton]}
              onPress={() => {
                store.dispatch(toggleLoadingModal(false, destinationPage))
              }}
            >
              <CancelIcon
                style={getLoadingBarStyles(styleParameters).ModalCloseButtonIcon}
                isTablet={styleParameters.isTablet}
              />
            </TouchableOpacity>
            <View style={[getLoadingBarStyles(styleParameters).ModalContent]}>
              {this.renderLoadingMessage()}
              {this.renderLoadingIndicator()}
            </View>
          </View>
        </Modal>
      </View>
    ) : null
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.cache.loadingImages,
  loadingModalVisible: state.modals.loadingModalVisible,
  destinationPage: state.modals.destinationPage,
  isAllPlaybookDownloaded: state.cache.isAllPlaybookDownloaded,
})

export default connect(mapStateToProps, {})(LoadingModal)
