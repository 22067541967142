import { StyleProp, StyleSheet } from 'react-native'

export interface ColorAndWidth {
  color: string
  width: number
}

export const getColorAndWidth = (style: StyleProp<any>): ColorAndWidth => {
  const defaultColor: string = '#000'
  const defaultIconSize: number = 18

  let color: string = defaultColor
  let width: number = defaultIconSize

  if (style) {
    ;({ color, width } = StyleSheet.flatten(style))

    if (!width) {
      width = defaultIconSize
    }
    if (!color) {
      color = defaultColor
    }
  }

  const colorAndWidth: ColorAndWidth = { color: color, width: width }

  return colorAndWidth
}
