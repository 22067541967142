import { defaultUser, User } from '../../DataTypes/UserTypes'
import { TOGGLE_LOGIN, UPDATE_USER_INFO } from '../actionTypes'

export interface AuthState {
  isLoggedIn: boolean
  user: User
}

const initialState: AuthState = {
  isLoggedIn: false,
  user: defaultUser,
}

export default function auth(state = initialState, action: any): AuthState {
  switch (action.type) {
    case TOGGLE_LOGIN: {
      const { isLoggedIn } = action.payload
      return {
        ...state,
        isLoggedIn: isLoggedIn,
      }
    }
    case UPDATE_USER_INFO: {
      const { user } = action.payload
      return {
        ...state,
        user: user,
      }
    }
    default:
      return state
  }
}
