import { TaktikServerVersion } from '@taktik/config/TaktikServer/version'
import { StoreVersion } from '@taktik/config/Redux/version'

export const formatVersion = (version: StoreVersion | TaktikServerVersion | undefined | null) => {
  if (!version) {
    return `N/A`
  }

  return `v${version.major}.${version.minor}`
}

export const _onlyMinorVersionHasChanged = (
  version: TaktikServerVersion | null | undefined,
  masterVersion: TaktikServerVersion | null | undefined
): boolean => {
  const onlyMinorVersionHasChanged: boolean = !!(
    version &&
    masterVersion &&
    version.minor !== masterVersion.minor &&
    !_majorVersionHasChanged(version, masterVersion)
  )

  return onlyMinorVersionHasChanged
}

export const _majorVersionHasChanged = (
  version: TaktikServerVersion | null | undefined,
  masterVersion: TaktikServerVersion | null | undefined
): boolean => {
  const majorVersionHasChanged: boolean = !!(version && version.major !== masterVersion?.major)

  return majorVersionHasChanged
}
