import { Platform } from 'react-native'
import * as SQLite from 'expo-sqlite'
import { logger } from '../../lib/logger'
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator'
import * as FileSystem from 'expo-file-system'

let idb: IDBDatabase
let sqliteDb: SQLite.WebSQLDatabase

const DB_NAME = 'taktik-lite'

enum DatabaseType {
  IndexDB = 'idb',
  SqLiteDB = 'sqlite',
}

export enum StoreNames {
  Images = 'images',
  Playbooks = 'playbooks',
  StudyTimes = 'study_times',
  Stats = 'stats',
}

export enum StatsType {
  FullStats = 'fullStats',
  PlayerStats = 'playerStats',
  TeamStats = 'teamStats',
}

let databaseType: DatabaseType =
  Platform.OS === 'android' || Platform.OS === 'ios' ? DatabaseType.SqLiteDB : DatabaseType.IndexDB

export async function requestDatabase() {
  const platform = Platform.OS
  if (platform === 'ios' || platform === 'android') {
    databaseType = DatabaseType.SqLiteDB
  } else {
    databaseType = DatabaseType.IndexDB
  }
  switch (databaseType) {
    case DatabaseType.IndexDB:
      {
        // Increment this number when creating new stores
        const version = 3
        const request = indexedDB.open(DB_NAME, version)

        request.onupgradeneeded = function () {
          logger.info('Upgrading DB')
          // The database did not previously exist, so create object stores and indexes.
          const db = request.result
          for (const name of Object.values(StoreNames)) {
            try {
              db.deleteObjectStore(name)
            } catch (error) {
              logger.warn(
                `Failed to delete Object store with the following name: '${name}'. Error - ${error}`
              )
            }
            db.createObjectStore(name, { keyPath: 'key' })
          }
        }

        request.onsuccess = function () {
          logger.info('DB open SUCCESS')
          idb = request.result
        }

        request.onerror = function (_event) {
          logger.warn(`Error while requesting the local database`)
          logger.warn(_event)
        }
      }
      break
    case DatabaseType.SqLiteDB:
      sqliteDb = SQLite.openDatabase(DB_NAME)
      sqliteDb.transaction((tx) => {
        for (const name of Object.values(StoreNames)) {
          tx.executeSql(
            `create table if not exists ${name} (key text primary key not null, value text);`
          )
        }
      })
      break
  }
}

export async function storageGetItem(key: string, storeName: StoreNames): Promise<string | null> {
  switch (databaseType) {
    case DatabaseType.IndexDB: {
      return new Promise(function (resolve, _reject) {
        try {
          const transaction = idb.transaction(storeName, 'readonly')
          const objectStore = transaction.objectStore(storeName)
          const objectRequest = objectStore.get(key)

          objectRequest.onerror = function (_event) {
            logger.warn(`Error while reading the ${key} item from the local ${storeName} table`)
            resolve(null)
          }

          objectRequest.onsuccess = function (_event) {
            if (objectRequest.result) resolve(objectRequest.result.value)
            else resolve(null)
          }
        } catch (error) {
          logger.warn(`Error while getting the '${key}' item into the ${storeName} table: ${error}`)
        }
      })
    }
    case DatabaseType.SqLiteDB: {
      return new Promise(function (resolve, _reject) {
        sqliteDb.transaction(
          (tx) => {
            tx.executeSql(
              `select * from ${storeName} where key = ?;`,
              [key],
              (_, { rows }) => {
                if (rows.length > 0) {
                  resolve(rows.item(0).value)
                } else {
                  resolve(null)
                }
              },
              (_t, error) => {
                logger.warn(error)
                return false
              }
            )
          },
          (_) => resolve(null)
        )
      })
    }
    default:
      return null
  }
}

export async function storageRemoveItem(
  key: string,
  storeName: StoreNames
): Promise<string | null> {
  switch (databaseType) {
    case DatabaseType.IndexDB: {
      return new Promise(function (resolve, _reject) {
        try {
          const transaction = idb.transaction(storeName, 'readwrite')
          const objectStore = transaction.objectStore(storeName)
          const objectRequest: any = objectStore.delete(key)

          objectRequest.onerror = function (_event: any) {
            logger.warn(`Error while deleting the ${key} item from the local ${storeName} table`)
            resolve(null)
          }

          objectRequest.onsuccess = function (_event: any) {
            if (objectRequest.result) resolve(objectRequest.result)
            else resolve(null)
          }
        } catch (error) {
          logger.warn(
            `Error while removing the '${key}' item from the ${storeName} table: ${error}`
          )
        }
      })
    }
    case DatabaseType.SqLiteDB: {
      return new Promise(function (resolve, _reject) {
        sqliteDb.transaction(
          (tx) => {
            tx.executeSql(
              `delete from ${storeName} where key = ?;`,
              [key],
              (_, { rows }) => {
                if (rows.length > 0) {
                  resolve(rows.item(0).value)
                } else {
                  resolve(null)
                }
              },
              (_t, error) => {
                logger.warn(error)
                return false
              }
            )
          },
          (_) => resolve(null)
        )
      })
    }
    default:
      return null
  }
}

export interface StorageItem {
  key: string
  value: string
}

export async function storageGetAllItems(storeName: StoreNames): Promise<StorageItem[] | null> {
  switch (databaseType) {
    case DatabaseType.IndexDB: {
      return new Promise(function (resolve, _reject) {
        try {
          const transaction = idb.transaction(storeName, 'readonly')
          const objectStore = transaction.objectStore(storeName)
          const objectRequest = objectStore.getAll()

          objectRequest.onerror = function (_event) {
            logger.warn(`Error while removing all items from the ${storeName} table`)
            resolve(null)
          }

          objectRequest.onsuccess = function (_event) {
            if (objectRequest.result.length > 0) resolve(objectRequest.result)
            else resolve(null)
          }
        } catch (error) {
          logger.warn(`Error while getting all items from the ${storeName} table: ${error}`)
        }
      })
    }
    case DatabaseType.SqLiteDB: {
      return new Promise(function (resolve, _reject) {
        sqliteDb.transaction(
          (tx) => {
            tx.executeSql(
              `select * from ${storeName}`,
              [],
              (_, { rows }) => {
                if (rows.length > 0) {
                  const result = []
                  for (let i = 0; i < rows.length; i++) {
                    result.push(rows.item(i))
                  }
                  resolve(result)
                } else {
                  resolve(null)
                }
              },
              (_t, error) => {
                logger.warn(error)
                return false
              }
            )
          },
          (_) => resolve(null)
        )
      })
    }
    default:
      return null
  }
}

export async function storageWipeLocalData(name: StoreNames) {
  logger.info(`Clearing the local ${name} table...`)

  if (name === StoreNames.Images) {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      await storageWipeLocalImageData_Direct()
      return
    }
  }

  switch (databaseType) {
    case DatabaseType.IndexDB:
      try {
        const transaction = idb.transaction(name, 'readwrite')
        const objectStore = transaction.objectStore(name)
        objectStore.clear()
      } catch (error) {
        logger.warn(`Error while clearing local storage for the '${name}' key: ${error}`)
      }
      break
    case DatabaseType.SqLiteDB:
      sqliteDb.transaction((tx) => {
        tx.executeSql(`drop table if exists ${name};`)
        tx.executeSql(
          `create table if not exists ${name} (key text primary key not null, value text);`
        )
      })
      break
  }
}

export async function storageWipeLocalImageData_Direct() {
  await ensureImagesDirectoryExists()
  const imagesDirectory = getFileSystemImagesDirectory()

  logger.info(`About to clear the device's file system's image data directory (${imagesDirectory})`)

  if (imagesDirectory) {
    FileSystem.readDirectoryAsync(imagesDirectory).then(async (response) => {
      for (const uuid of response) {
        const fullFilePath: string = getFileSystemFileName(uuid)
        logger.info(`Deleting ${fullFilePath} from the device's file system...`)
        try {
          await FileSystem.deleteAsync(fullFilePath)
        } catch (error) {
          logger.warn(`Error while deleting ${uuid} from the file system: ${error}`)
        }
      }
    })
  }
}

export async function storageWipeLocalImageData() {
  await storageWipeLocalData(StoreNames.Images)
}

export async function storageWipeAllData() {
  for (const name of Object.values(StoreNames)) {
    if (name === StoreNames.Images) {
      await storageWipeLocalImageData()
      continue
    }
    await storageWipeLocalData(name)
  }
}

export async function storageGetImageData(url: string): Promise<string | null> {
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    const imageKey: string = getImageKey(url)
    const base64Data: string = await readFromFileSystem(imageKey)
    return base64Data
  }

  const imageData = await storageGetItem(url, StoreNames.Images)

  if (imageData) {
    return imageData
  }

  return url
}

export async function storageInsertItem(
  key: string,
  value: string,
  storeName: StoreNames
): Promise<void> {
  switch (databaseType) {
    case DatabaseType.IndexDB: {
      return new Promise(function (resolve, reject) {
        try {
          const transaction = idb.transaction(storeName, 'readwrite')
          const objectStore = transaction.objectStore(storeName)
          const objectRequest = objectStore.put({ key: key, value: value }) // Overwrite if exists

          objectRequest.onerror = function (_event) {
            logger.warn(`Error while inserting the '${key}' item into the ${storeName} table`)
            reject(Error(`Failed to insert value ${value}`))
          }

          objectRequest.onsuccess = function (_event) {
            resolve()
          }
        } catch (error) {
          logger.warn(
            `Error while inserting the '${key}' item into the ${storeName} table: ${error}`
          )
        }
      })
    }
    case DatabaseType.SqLiteDB: {
      return new Promise(function (resolve, _reject) {
        sqliteDb.transaction(
          (tx) => {
            tx.executeSql(
              `insert or replace into ${storeName} (key, value) values (?, ?)`,
              [key, value],
              (_) => {
                resolve()
              },
              (_t, error) => {
                logger.warn(error)
                return false
              }
            )
          },
          (_) => {
            resolve()
          }
        )
      })
    }
    default:
  }
}

export function getImageKey(snapshotURL: string) {
  const splitURL: string[] = snapshotURL.split('/')
  const uuidIndex: number = splitURL.length - 1

  const uuid: string = splitURL[uuidIndex]

  return uuid
}

export function getItemKey(key: string, storeName: StoreNames) {
  let trueKey: string = key

  if (storeName === StoreNames.Images) {
    trueKey = getImageKey(key)
  }

  return trueKey
}

export function isURL(urlOrBase64: string) {
  const valueIsURL: boolean = urlOrBase64.indexOf('https:') !== -1
  return valueIsURL
}

export const directoryName: string = 'images'

export async function ensureImagesDirectoryExists() {
  if (Platform.OS === 'web') {
    return
  }

  const directoryPath = getFileSystemImagesDirectory()

  const directoryInfo = await FileSystem.getInfoAsync(directoryPath)
  if (!directoryInfo.exists) {
    logger.info("Images directory doesn't exist in the local file system, creating...")
    await FileSystem.makeDirectoryAsync(directoryPath, { intermediates: true })
  } else {
    logger.info('Images directory already exists. Skipping creation...')
  }
}

export function getFileSystemImagesDirectory(): string {
  const directoryPath: string = `${FileSystem.documentDirectory}${directoryName}`
  return directoryPath
}

export function getFileSystemFileName(uuid: string): string {
  const fileName: string = `${getFileSystemImagesDirectory()}//${uuid}`
  return fileName
}

export async function readFromFileSystem(uuid: string): Promise<string> {
  const fileName: string = getFileSystemFileName(uuid)

  try {
    const partialBase64Data: string = await FileSystem.readAsStringAsync(fileName, {
      encoding: FileSystem.EncodingType.Base64,
    })
    const fullBase64Data: string = `data:image/png;base64,${partialBase64Data}`

    if (fullBase64Data) {
      logger.info(`Finished retrieving base64 for file ${uuid}: ${fullBase64Data.slice(0, 35)}`)

      return fullBase64Data
    }
  } catch (e) {
    logger.error(e)
  }

  return ''
}

export async function saveToFileSystem(url: string, callback?: () => void): Promise<string> {
  const fileName: string = getImageKey(url)

  const downloadResumable = FileSystem.createDownloadResumable(
    url,
    getFileSystemFileName(fileName),
    {},
    callback
  )

  try {
    const result = await downloadResumable.downloadAsync()

    if (result) {
      const { uri } = result
      logger.info(`Finished downloading & saving to ${uri}`)
      const uuid: string = getImageKey(uri)
      logger.info(`UUID for the file is: ${uuid}`)

      return uuid
    }
  } catch (error) {
    logger.error(error)
  }

  return ''
}

export async function getCompressedImageData(base64Data: string): Promise<string> {
  // return base64Data.slice(0, base64Data.length / 4)

  if (Platform.OS === 'web') {
    const format = SaveFormat.JPEG

    logger.info(`About to compress a file with size ${base64Data.length} units`)

    const result = await manipulateAsync(base64Data, [], {
      compress: 0.9,
      format,
      base64: true,
    })

    logger.info(
      `Compressed a file from ${base64Data.length} units to ${result.base64!.length} (${
        (result.base64!.length / base64Data.length) * 100
      }% of the original size)`
    )

    return result.base64!
  } // iOS / Android
  else {
    return base64Data
  }
}

export function snapshotIsAlreadyStored(url: string): boolean {
  const isAlreadyStored: boolean = url.indexOf('data:image/png;') !== -1

  return isAlreadyStored
}

const toDataURL = async (uri: string) => {
  if (Platform.OS !== 'web') {
    const result = await saveToFileSystem(uri)
    logger.info(`Saving result - ${result}`)

    const readBase64Data = await readFromFileSystem(result)
    logger.info(`Read image base64 data - ${readBase64Data.slice(0, 35)}`)
  } else {
    return fetch(uri)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = async () => {
              const fullImageData: string = reader.result as string
              return resolve(fullImageData)
            }
            reader.onerror = function (_event) {
              logger.warn(`Error while saving the '${uri}' image into local storage`)
              reject(Error(`Failed to insert image ${uri}`))
            }
            reader.readAsDataURL(blob)
          })
      )
      .catch((error) => {
        logger.info(error)
      })
  }
}

export async function storageInsertImage(url: string): Promise<void> {
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    const localPath = await saveToFileSystem(url).then()
    logger.info(`Saving result - ${localPath}`)
    return
  }

  const isAlreadyStored: boolean = snapshotIsAlreadyStored(url)
  if (isAlreadyStored) {
    return new Promise<void>(() => {})
  }

  return new Promise<void>(function (resolve) {
    return toDataURL(url)
      .then((dataUrl) => {
        logger.info(`About to insert the ${url} image in the local storage`)

        const imageWasAlreadyLoaded: boolean = url === dataUrl

        if (imageWasAlreadyLoaded) {
          logger.info(`Image is already present for ${url.slice(0, 25)}..., skipping...`)
        } else {
          resolve(storageInsertItem(url, dataUrl as string, StoreNames.Images).then(() => {}))
        }
      })
      .catch((error) => {
        logger.info(error)
        resolve()
      })
  })
}
