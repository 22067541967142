import React, { ReactNode, ReactNodeArray, Component } from 'react'
import {
  GestureResponderEvent,
  Linking,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { isMobile as isMobile_utils } from 'is-mobile'
import translator from '@taktik/config/Translation/languages'
import CancelIcon from '@taktik/assets/svg/CancelIcon'
import { getDownloadAppNotificationStyles } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'

interface AppDownloadNotificationProps {
  children: ReactNode | ReactNodeArray
}

interface AppDownloadNotificationState {
  isVisible: boolean
  styleParameters: StyleParameters
}

class AppDownloadNotification extends Component<
  AppDownloadNotificationProps,
  AppDownloadNotificationState
> {
  constructor(public props: AppDownloadNotificationProps) {
    super(props)
    this.state = {
      isVisible: true,
      styleParameters: getStyleParameters(),
    }
  }

  isiOS = () => {
    const agentNames = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ]
    return (
      agentNames.includes(navigator.userAgent) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  }

  getDownloadUrl = () => {
    if (this.isiOS()) {
      const appName = 'taktik-joueur'
      const appId = '1519062191'
      return `https://apps.apple.com/ca/app/${appName}/id${appId}`
    } else {
      const packageName = 'ca.kicktech.taktiklite'
      return `https://play.google.com/store/apps/details?id=${packageName}`
    }
  }

  openAppStore = () => {
    const url = this.getDownloadUrl()
    Linking.openURL(url)
  }

  close = (event: GestureResponderEvent) => {
    event.preventDefault()
    this.setState({ isVisible: false })
  }

  render() {
    const { children } = this.props
    const { isVisible, styleParameters } = this.state

    const isMobileBrowser =
      Platform.OS === 'web' && isMobile_utils({ tablet: true, featureDetect: true })

    const styles = getDownloadAppNotificationStyles(styleParameters)

    return (
      <>
        {children}
        {isMobileBrowser && isVisible && (
          <View style={styles.notificationBackground}>
            <TouchableOpacity onPress={() => this.openAppStore()}>
              <TouchableOpacity style={styles.closeButton} onPress={(event) => this.close(event)}>
                <CancelIcon isTablet={false} style={styles.cancelIcon} />
              </TouchableOpacity>
              <View style={styles.contentWrapper}>
                <Text style={styles.content}>{translator.t('downloadApp.message')}</Text>
                <Text style={[styles.content, { fontSize: 16 }]}>
                  {translator.t('downloadApp.callToAction')}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </>
    )
  }
}

export default AppDownloadNotification
