import { StyleSheet, ScaledSize } from 'react-native'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/GamificationRatiosStyles'
import { GlobalColors, StyledComponentsTheme } from '../Theme'
import { StyleParameters } from '../Utilities'

export default function GamificationStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  teamColor: string,
  _rowSize: number,
  styleParameters: StyleParameters
) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(styleParameters)
  return StyleSheet.create({
    successConfirmText: {
      marginTop: screenRatio.ConfirmText_marginTop,
      alignSelf: 'center',
      color: GlobalColors.green,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalConfirmText_fontSize,
      marginHorizontal: '5%',
    },
    failureConfirmText: {
      marginTop: screenRatio.ConfirmText_marginTop,
      alignSelf: 'center',
      color: GlobalColors.red,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalConfirmText_fontSize,
      marginHorizontal: '5%',
    },

    ModalContainer: {
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.popUpColor,
      marginTop: screenRatio.ModalContainer_marginTop,
      width: screenRatio.ModalContainer_width,
      height: screenRatio.ModalContainer_height,
      borderRadius: screenRatio.ModalContainer_borderRadius,
    },
    ModalContainerWithImage: {
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.popUpColor,
      marginTop: screenRatio.ModalContainerWithImage_marginTop,
      width: screenRatio.ModalContainer_width,
      borderRadius: screenRatio.ModalContainer_borderRadius,
      paddingBottom: '1%',
    },
    ModalImageContainer: {
      width: screenRatio.resultModalImage_width,
      height: screenRatio.resultModalImage_heigth,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '4%',
      marginBottom: '4%',
    },
    ModalImage: {
      alignSelf: 'center',
      alignItems: 'center',
      width: screenRatio.resultModalImage_width,
      height: screenRatio.resultModalImage_heigth,
    },
    ModalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: styleParameters.screenWidth,
      height: styleParameters.screenHeight,
      backgroundColor: theme.colors.popUpBackground,
    },
    ModalSubTextContainer: {
      flexDirection: screenRatio.ModalSubTextContainer_flexDirection,
      justifyContent: 'center',
      alignSelf: 'center',
      alignContent: 'center',
      alignItems: 'center',
      marginBottom: screenRatio.ModalSubTextContainer_marginBottom,
    },
    ModalSubTextSuccess: {
      alignSelf: 'center',
      color: GlobalColors.black,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.ModalSubText_fontSize,
      marginBottom: screenRatio.ModalSubTextSuccess_marginBottom,
    },
    ModalSubTextFailure: {
      alignSelf: 'center',
      color: GlobalColors.black,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.ModalSubText_fontSize,
    },
    ModalSubTextRightAnswer: {
      alignSelf: 'center',
      color: GlobalColors.black,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalSubText_fontSize,
    },
    ModalRowButtons: {
      marginTop: screenRatio.ModalButtonContainer_marginTop,
      marginBottom: screenRatio.ModalButtonContainer_marginTop,
      width: '100%',
      flexDirection: screenRatio.ModalRowButtons_direction,
      justifyContent: 'center',
      alignItems: 'center',
    },
    ModalButtonContainer: {
      width: screenRatio.ModalButtonContainer_width,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ModalButtonStop: {
      marginTop: screenRatio.ModalButton_marginTop,
      width: screenRatio.ModalButton_width,
      justifyContent: 'center',
      backgroundColor: theme.colors.secondary,
      height: screenRatio.ConfirmButton_height,
      transform: [{ skewX: '-10deg' }],
    },
    ModalButtonStopText: {
      marginHorizontal: '5%',
      alignSelf: 'center',
      color: theme.textColors.tabHeader,
      transform: [{ skewX: '10deg' }],
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.ModalButtonText_fontSize,
    },
    ModalButtonNext: {
      marginLeft: screenRatio.ModalButtonTop_marginLeft,
      marginTop: screenRatio.ModalButton_marginTop,
      width: screenRatio.ModalButton_width,
      backgroundColor: teamColor,
      justifyContent: 'center',
      height: screenRatio.ConfirmButton_height,
      transform: [{ skewX: '-10deg' }],
    },
    ModalButtonNextText: {
      transform: [{ skewX: '10deg' }],
      alignSelf: 'center',
      marginHorizontal: '5%',
      color: GlobalColors.white,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.ModalButtonText_fontSize,
    },
    ModalCountdownFirstRow: {
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalCountdownFirstRow_fontSize,
    },
    ModalCountdownSecondRow: {
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.ModalCountdownSecondRow_fontSize,
    },
    ModalCountdownCircleContainer: {
      width: screenRatio.ModalCountdownContainer_width + 10,
      height: screenRatio.ModalCountdownContainer_height,
      borderRadius: screenRatio.ModalCountdownContainer_borderRadius,
      backgroundColor: GlobalColors.paleGrey,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '3%',
    },
    ModalCountdownText: {
      alignSelf: 'center',
      color: GlobalColors.black,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.ModalCountdownText_fontSize,
      marginBottom: '5%',
      paddingHorizontal: 50,
    },
    ActivityIndicator: {
      color: teamColor,
    },
  })
}
