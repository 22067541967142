import React from 'react'
import translator from '@taktik/config/Translation/languages'
import { Text, TouchableOpacity, View } from 'react-native'
import { getPlaybookStyles } from '../Styles/AppStyle'
import { StyleParameters } from '../Styles/Utilities'
import { toggleAlertStudyTimeModal } from '../redux/actions'
import { store } from '../redux/store'
import { Modal } from '../components/ModalImport'
import { orientationIOS } from '../Pages/TopBarMenu'

export const maxStudyTimeDuration: number = 180

function onPressContinue(onPress: () => void) {
  onPress()
  store.dispatch(toggleAlertStudyTimeModal(false))
}

export function renderAlertStudyDurationModal(
  styleParameters: StyleParameters,
  navigation: any,
  alertStudyDurationVisible: boolean,
  onPress: () => void
) {
  return (
    alertStudyDurationVisible && (
      <View style={getPlaybookStyles(styleParameters).ModalBackground}>
        <Modal
          transparent
          visible={alertStudyDurationVisible}
          supportedOrientations={orientationIOS}
        >
          <View style={getPlaybookStyles(styleParameters).ModalAlertContainer}>
            <View style={getPlaybookStyles(styleParameters).ModalAlertTitleContainer}>
              <Text style={getPlaybookStyles(styleParameters).ModalAlertTitle}>PAUSE</Text>
            </View>
            <Text style={getPlaybookStyles(styleParameters).ModalAlertQuestion}>
              {translator.t('studyTimeAlert.question').toUpperCase()}
            </Text>
            <View style={getPlaybookStyles(styleParameters).ModalAlertButtonContainerLarge}>
              <View style={getPlaybookStyles(styleParameters).alertButtonNo}>
                <TouchableOpacity
                  onPress={() => {
                    navigation.goBack()
                    store.dispatch(toggleAlertStudyTimeModal(false))
                  }}
                >
                  <Text style={getPlaybookStyles(styleParameters).alertButtonTextNo}>
                    {translator.t('studyTimeAlert.buttonNo').toUpperCase()}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={getPlaybookStyles(styleParameters).alertButtonYes}>
                <TouchableOpacity onPress={() => onPressContinue(onPress)}>
                  <Text style={getPlaybookStyles(styleParameters).alertButtonTextYes}>
                    {translator.t('global.yes').toUpperCase()}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    )
  )
}
