import React, { Component, createRef } from 'react'
import translator from '@taktik/config/Translation/languages'
import { TouchableOpacity } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { getRegistrationStyles } from '../Styles/AppStyle'
import { GlobalColors } from '../Styles/Theme'
import { StyleParameters } from '../Styles/Utilities'
import {
  InputFieldValidation,
  InputFieldValidator,
  PasswordValidation,
} from '../DataTypes/ValidationType'
import GenericField from './GenericField'

enum EyeIconType {
  OPEN = 'eye-outline',
  CLOSED = 'eye-off-outline',
}

interface PasswordFieldProps {
  isConfirmation?: boolean
  confirmationPassword?: string
  styleParameters: StyleParameters
  onValidationChange: (text: string, errors: InputFieldValidation[]) => void
  onComplete: () => void
}

interface PasswordFieldState {
  showPassword: boolean
  validators: Partial<InputFieldValidator<PasswordValidation>>
}

export default class PasswordField extends Component<PasswordFieldProps, PasswordFieldState> {
  private inputFieldRef = createRef<GenericField>()

  constructor(props: PasswordFieldProps) {
    super(props)

    const validators = props.isConfirmation
      ? {
          [PasswordValidation.MATCHING]: new RegExp(''),
        }
      : {
          [PasswordValidation.ENOUGH_DIGITS]: /(.*[0-9])/i,
          [PasswordValidation.ENOUGH_CHARACTERS]: /^\S{8,}/i,
          [PasswordValidation.ENOUGH_LOWERCASE]: /(.*[a-z])/,
          [PasswordValidation.ENOUGH_UPPERCASE]: /(.*[A-Z])/,
        }

    this.state = {
      showPassword: false,
      validators,
    }
  }

  componentDidUpdate(prevProps: PasswordFieldProps) {
    const { confirmationPassword } = this.props

    if (confirmationPassword !== prevProps.confirmationPassword) {
      this.updateRegex().then(() => {
        if (this.inputFieldRef.current) {
          this.inputFieldRef.current.validate()
        }
      })
    }
  }

  updateRegex = () => {
    return new Promise<void>((resolve) => {
      const { isConfirmation, confirmationPassword } = this.props

      if (!isConfirmation) {
        return resolve()
      }

      return this.setState(
        {
          validators: { [PasswordValidation.MATCHING]: new RegExp(`^${confirmationPassword}$`) },
        },
        () => resolve()
      )
    })
  }

  toggleVisibility = () => {
    this.setState((previousState) => ({ showPassword: !previousState.showPassword }))
  }

  render() {
    const { isConfirmation, styleParameters, onValidationChange, onComplete } = this.props
    const { showPassword, validators } = this.state
    const styles = getRegistrationStyles(styleParameters)

    const placeholder = isConfirmation
      ? translator.t('changePassword.passwordConfirmation')
      : translator.t('password')

    return (
      <>
        <GenericField
          ref={this.inputFieldRef}
          extraValidators={validators}
          onValidationChange={(text, errors) => onValidationChange(text, errors)}
          onComplete={() => onComplete()}
          placeholder={placeholder.toUpperCase()}
          secureTextEntry={!showPassword}
          styleParameters={styleParameters}
        />
        <TouchableOpacity style={styles.passwordEyeIcon} onPress={() => this.toggleVisibility()}>
          <MaterialCommunityIcons
            size={16}
            color={GlobalColors.defaultHomeColor}
            name={showPassword ? EyeIconType.OPEN : EyeIconType.CLOSED}
          />
        </TouchableOpacity>
      </>
    )
  }
}
