import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'
import localforage from 'localforage'
import { Platform } from 'react-native'
import ReduxThunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage: Platform.OS === 'web' ? localforage : AsyncStorage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function initStore() {
  const middlewares = [ReduxThunk]
  const enhancers = [applyMiddleware(...middlewares)]
  /* eslint-disable no-underscore-dangle */
  const store = createStore(
    persistedReducer,
    composeEnhancers(...enhancers)

    // other store enhancers if any
  )
  /* eslint-enable */
  const persistor = persistStore(store)
  return { store, persistor }
}

export const { store, persistor } = initStore()
