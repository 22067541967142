import { StyleSheet, ScaledSize } from 'react-native'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/IdentificationNameRatiosStyles'
import { GlobalColors, StyledComponentsTheme } from '../Theme'
import { StyleParameters } from '../Utilities'

export default function identificationNameStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  teamColor: string,
  _rowSize: number,
  styleParameters: StyleParameters
) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(styleParameters)
  return StyleSheet.create({
    component: {
      width: '100%',
      height: screenRatio.Page_height,
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    gameContainer: {
      width: screenRatio.GameContainer_width,
      flexDirection: screenRatio.GameContainer_direction,
      marginTop: screenRatio.GameContainer_marginTop,
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    nameZone: {
      flexDirection: screenRatio.NameZone_direction,
      marginBottom: screenRatio.NameZone_marginBottom,
      marginTop: screenRatio.NameZone_marginTop,
      justifyContent: 'space-between',
      height: screenRatio.NameZone_height,
      alignItems: 'center',
    },
    buttonsAreaContainer: {
      alignContent: 'center',
      marginTop: screenRatio.ButtonsAreaContainer_marginTop,
      width: screenRatio.ButtonsAreaContainer_width,
      height: screenRatio.ButtonsAreaContainer_height,
      flexDirection: 'column',
    },
    namesButtonsContainer: {
      alignSelf: 'center',
      flexDirection: 'column',
      marginHorizontal: screenRatio.NamesButtonsContainer_marginHorizontal,
      width: screenRatio.NamesButtonsContainer_width,
    },
    nameButton: {
      alignSelf: 'center',
      backgroundColor: theme.colors.secondary,
      width: screenRatio.NameButton_width,
      height: screenRatio.NameButton_height,
      justifyContent: 'center',
    },
    nameButtonSelected: {
      alignSelf: 'center',
      backgroundColor: teamColor,
      width: screenRatio.NameButton_width,
      height: screenRatio.NameButton_height,
      justifyContent: 'center',
    },
    successNameButton: {
      alignSelf: 'center',
      borderColor: GlobalColors.green,
      borderWidth: 2,
      backgroundColor: GlobalColors.paleGreen,
      width: screenRatio.NameButton_width,
      height: screenRatio.NameButton_height,
      justifyContent: 'center',
    },
    failureNameButton: {
      alignSelf: 'center',
      borderColor: GlobalColors.red,
      backgroundColor: GlobalColors.paleRed,
      width: screenRatio.NameButton_width,
      height: screenRatio.NameButton_height,
      justifyContent: 'center',
      borderWidth: 2,
    },
    successFailureNameText: {
      alignSelf: 'center',
      color: GlobalColors.black,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.NameText_fontSize,
    },
    nameText: {
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.NameText_fontSize,
    },
    nameTextSelected: {
      alignSelf: 'center',
      color: GlobalColors.white,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.NameText_fontSize,
    },

    playbookImageContainer: {
      alignItems: 'center',
      paddingHorizontal: screenRatio.playbookImageContainer_RightPadding,
    },
    promptText: {
      paddingHorizontal: 7,
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.PromptText_fontSize,
    },
    confirmText: {
      alignSelf: 'center',
      color: GlobalColors.white,
      transform: [{ skewX: '10deg' }],
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.ConfirmText_fontSize,
      marginHorizontal: '5%',
    },

    promptContainer: {
      width: screenRatio.PromptContainer_width,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: screenRatio.PromptContainer_height,
      marginTop: screenRatio.PromptContainer_marginTop,
      marginBottom: screenRatio.PromptContainer_marginBottom,
    },
    title: {
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.Title_fontSize,
    },
    titleContainer: {
      flex: screenRatio.TitleContainer_flex,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: screenRatio.TitleContainer_width,
      height: screenRatio.TitleContainer_height,
    },
    prompt: {
      flex: 1,
      height: '5%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    confirmButtonContainer: {
      width: screenRatio.ConfirmButtonContainer_width,
      height: screenRatio.ConfirmButtonContainer_height,
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: screenRatio.ConfirmButtonContainer_justifyContent,
      marginBottom: screenRatio.ConfirmButtonContainer_marginBottom,
    },
    confirmButton: {
      width: screenRatio.ConfirmButton_width,
      backgroundColor: teamColor,
      justifyContent: 'center',
      height: screenRatio.ConfirmButton_height,
      transform: [{ skewX: '-10deg' }],
    },
    confirmButton_Disabled: {
      width: '40%',
      backgroundColor: teamColor,
      justifyContent: 'center',
      height: screenRatio.ConfirmButton_height,
      transform: [{ skewX: '-10deg' }],
    },
    warningText: {
      fontSize: screenRatio.WarningText_fontSize,
      color: GlobalColors.red,
      paddingBottom: screenRatio.WarningText_paddingBottom,
      fontFamily: 'barlow-semi',
    },
  })
}
