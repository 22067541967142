import { StoreVersion } from '@taktik/config/Redux/version'
import { TaktikServerVersion } from '@taktik/config/TaktikServer/version'
import { logger } from '@taktik/lib/logger'
import { formatVersion } from '@taktik/src/Utilitary/version/Common'
import {
  SET_MASTER_TAKTIK_SERVER_VERSION,
  SET_STORE_VERSION,
  SET_TAKTIK_SERVER_VERSION,
} from '../actionTypes'

export interface VersionState {
  taktikServerVersion: TaktikServerVersion | null
  masterTaktikServerVersion: TaktikServerVersion | null | undefined
  storeVersion: StoreVersion | null | undefined
}

const initialState: VersionState = {
  taktikServerVersion: null,
  masterTaktikServerVersion: null,
  storeVersion: null,
}

export default function version(state = initialState, action: any): VersionState {
  switch (action.type) {
    case SET_TAKTIK_SERVER_VERSION: {
      const taktikServerVersion = action.payload

      logger.info(
        `Setting the following soft Taktik Server version: ${formatVersion(taktikServerVersion)}`
      )

      return {
        ...state,
        taktikServerVersion: taktikServerVersion,
      }
    }
    case SET_MASTER_TAKTIK_SERVER_VERSION: {
      const masterTaktikServerVersion = action.payload

      logger.info(
        `Setting the following master Taktik Server version: ${formatVersion(
          masterTaktikServerVersion
        )}`
      )

      return {
        ...state,
        masterTaktikServerVersion: masterTaktikServerVersion,
      }
    }
    case SET_STORE_VERSION: {
      const storeVersion = action.payload

      logger.info(`Setting the following Redux version: ${formatVersion(storeVersion)}`)

      return {
        ...state,
        storeVersion: storeVersion,
      }
    }
    default:
      return state
  }
}
