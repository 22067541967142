import React from 'react'
import { View, StyleProp, StyleSheet } from 'react-native'
import { G, Svg, Path } from 'react-native-svg'

interface ZoomInIconProps {
  style?: StyleProp<any>
}

const ZoomInIcon = ({ style }: ZoomInIconProps) => {
  const defaultColor: string = '#000'
  const defaultIconSize: number = 15

  let color: string = defaultColor
  let width: number = defaultIconSize

  if (style) {
    ;({ color, width } = StyleSheet.flatten(style))
  }

  const aspectRatio: number = 52.42 / 52.43

  const calculatedHeight: number = width * aspectRatio

  return (
    <View style={style}>
      <Svg width={width} height={calculatedHeight} viewBox="0 0 52.43 52.42">
        <G data-name="Zoom">
          <G data-name="Groupe 134">
            <Path
              fill={color ?? defaultColor}
              d="M29.75 20h-3.24v6.5H20v3.24h6.47v6.47h3.24v-6.47h6.47v-3.23h-6.43z"
              transform="translate(-8.79 -8.79)"
            />
          </G>
          <Path
            fill={color ?? defaultColor}
            d="M42.92 40.57l.87-1.22a19.3 19.3 0 10-4.45 4.45l1.2-.86 18.32 18.27 2.35-2.35zm-3.24-.92a16.19 16.19 0 01-11.51 4.78 16.29 16.29 0 1111.55-4.78z"
            transform="translate(-8.79 -8.79)"
          />
        </G>
      </Svg>
    </View>
  )
}

export default ZoomInIcon
