import React from 'react'
import { View, StyleProp } from 'react-native'
import { Svg, Path, G } from 'react-native-svg'
import { getColorAndWidth } from './Utilities'

interface CancelIconProps {
  style?: StyleProp<any>
  isTablet: boolean
}

const CancelIcon = ({ style, isTablet }: CancelIconProps) => {
  const defaultColor: string = '#000'
  const defaultIconSize: number = 15.4

  const { color, width } = getColorAndWidth(style)

  let upwardsLineTransform: string = 'translate(21.791 44.511) rotate(-45)'
  let downwardsLineTransform: string = 'translate(24.502 24.49) rotate(45)'

  let viewBox: string = `0 0 ${defaultIconSize} ${defaultIconSize}`

  if (!isTablet) {
    upwardsLineTransform = 'translate(-6 40) rotate(-45) scale(2)'
    downwardsLineTransform = 'translate(0 0) rotate(45) scale(2)'

    viewBox = `-4 7 30.731 ${defaultIconSize}`
  }

  const height: number = width

  return (
    <View style={style}>
      <Svg width={width} height={height} viewBox={viewBox}>
        <G transform="translate(-21.791 -24.49)" scale={0.55}>
          <Path
            d="M1,0H28.313l-1,3.833H0Z"
            transform={upwardsLineTransform}
            fill={color || defaultColor}
          />
          <Path
            d="M1,0H28.313l-1,3.833H0Z"
            transform={downwardsLineTransform}
            fill={color || defaultColor}
          />
        </G>
      </Svg>
    </View>
  )
}

export default CancelIcon
