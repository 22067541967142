import { Platform } from 'react-native'
import { getMarginToTransformIn43, transformUXSpec } from '../Utilities'
// StyledScreenRatio property structure : PlayerStatStyles property name + '_' + CSS property name

export interface StyledScreenRatio {
  Page_height: any
  Row1_direction: any
  Row1_height: any
  Row1_marginTop: any
  Row1_marginHorizontal: any
  ProfilContainer_width: any
  ProfilContainer_flex: any
  ProfilContainer_height: any
  ProfilContainer_marginLeft: any
  Profil_fontSize: any
  IDs_paddingTop: any
  IDs_width: any
  IDs_height: any
  IDs_flex: any
  IDs_marginLeft: any
  ProfilPictureContainer_width: any
  ProfilPictureContainer_height: any
  ProfilPictureContainer_paddigLeft: any
  Names_width: any
  Names_paddingLeft: any
  Name_fontSize: any
  Lvl_fontSize: any
  Row2_direction: any
  Row2_height: any
  Row2_marginHorizontal: any
  Row2_paddingTop: any
  ScoreColumn_width: any
  ScoreColumn_marginRight: any
  ScoreColumn_marginLeft: any
  Scores_paddingTop: any
  ScoreContainer_width: any
  ScoreContainer_height: any
  ScoreContainer_borderRadius: any
  ScoreText_fontSize: any
  Score100_fontSize: any
  Separator_width: any
  Separator_height: any
  RatioText_fontSize: any
  RatioText_lineHeight: any
  RatioScore_fontSize: any
  Ratio_paddingLeft: any
  Ratio_marginRight: any
  RatioOperator_paddingRight: any
  RatioOperator_width: any
  RatioTextContainer_paddingLeft: any
  XpbarCurrentXpArrow_fontSize: any
  XpbarCurrentXpArrow_marginRight: any
  XpbarCurrentXpScore_fontSize: any
  XpbarCurrentXpText_width: any
  XpbarCurrentXpText_fontSize: any
  XpbarCurrentXpText_marginTop: any
  XpbarCurrentXp_paddingLeft: any
  XpbarCurrentXpNumbers_paddingBottom: any
  XpbarPreviousNextXpScore_fontSize: any
  XpbarPreviousNextXpText_fontSize: any
  XpBarContainer_width: any
  XpBarContainer_paddingLeft: any
  XpBarContainer_paddingTop: any
  XpBar_height: any
  XpBar_Fill_height: any
  Row3_direction: any
  Row3_height: any
  Row3_marginHorizontal: any
  Row3_paddingTop: any
  Row3_paddingBottom: any
  TableColumn_width: any
  TableColumn_marginLeft: any
  TableColumn_height: any
  GraphColumn_marginLeft: any
  GraphColumn_marginBottom: any
  GraphColumn_width: any
  GraphColumn_marginTop: any
  TableTitle_marginHorizontal: any
  TableTitle_fontSize: any
  GraphTitle_marginHorizontal: any
  GraphTitle_fontSize: any
  GraphButtonContainer_paddingLeft: any
  GraphTopButton_width: any
  GraphTopButton_height: any
  GraphTopButton_Text_paddingVertical: any
  GraphTopButton_Text_fontSize: any
  GraphTopLegendContainer_width: any
  GraphTopLegendContainer_paddingLeft: any
  GraphTopLegendContainer_paddingTop: any
  GraphTopLegendContainer_marginBottom: any
  GraphTopLegendRow_justifyContent: any
  GraphTopLegendRow_paddingTop: any
  GraphTopLegendRow_height: any
  GraphTopLegendText_fontSize: any
  GraphTopLegendText_marginLeft: any
  GraphTopLegendMyScoreBar_width: any
  GraphTopLegendTeamScoreBar_width: any
  GraphTopRow_paddingHorizontal: any
  GraphTopRow_paddingTop: any
  GraphTopRow_marginBottom: any
  GraphContainer_paddingTop: any
  GraphContainer_marginLeft: any
  GraphContainer_width: any
  GraphContainer_height: any
  Graph_paddingLeft: any
  Graph_paddingBottom: any
  Graph_paddingTop: any
  XAxisColumnContainer_marginLeft: any
  XAxisColumnContainer_marginRight: any
  XAxisColumnContainer_marginBottom: any
  XAxisContainer_paddingLeft: any
  XAxisContainer_paddingTop: any
  XAxisLabel_fontSize: any
  XAxisLabel_width: any
  XAxisContainerLabel_height: any
  PlaybookGroupButton_height: any
  PlaybookGroupButton_width: any
  PlaybookGroupText_fontSize: any
  PlaybookGroupText_paddingVertical: any
  PlaybookGroupButtonsRow_width: any
  PlaybookGroupButtonsRow_paddingBottom: any
  PlaybookGroupButtonsRow_paddingHorizontal: any
  TableHeaderButtonsArrow_fontSize: any
  TableHeaderSeparator_width: any
  TableHeaderSeparator_height: any
  TableHeaderButtonsArrow_marginVertical: any
  TableHeaderText_fontSize: any
  TableHeaderText_lineHeight: any
  TableFirstColumn_width: any
  TableNextColumn_width: any
  Table_height: any
  Table_width: any
  TextNameGame_fontSize: any
  Text_fontSize: any
  GraphAxisYSvgTicksLabel_fontSize: any
  GGraphAxisYSvgTicks_Size: any
  Row_height: any
  HeaderRow_height: any
  ModalEndPracticeContainer_width: any
  ModalEndPracticeContainer_height: any
  ModalEndPracticeContainer_marginTop: any
  ModalEndPracticeContainer_paddingTop: any
  ModalEndPracticeContainer_borderRadius: any
  ModalEndPracticeCloseButton_top: any
  ModalEndPracticeCloseButton_right: any
  Icon_width: any
  ModalEndPracticeTitle_fontSize: any
  ModalEndPracticeSubtitle_fontSize: any
  ModalEndPracticeSubtitle_marginTop: any
  ModalEndPracticeStatsContainer_marginTop: any
  ModalEndPracticeStatsText_fontSize: any
  ModalEndPracticeStatsText_marginTop: any
  ModalEndPracticeXPBar_marginTop: any
  ModalEndPracticeXpBarContainer_width: any
  ModalEndPracticeRankUpText_marginTop: any
  ModalEndPracticeStatLineMarginBottomTop: any
  ratioOfModelEndPractice: any
}

export function getScreenRatioStyle(
  currentWidth: number,
  currentHeight: number,
  isMobile: boolean,
  isHorizontal: boolean,
  thirdRowHeight: number = 605,
  timeSpan: number = 4
) {
  let screenWidth: number = currentWidth
  const screenHeight: number = currentHeight
  const marginHorizontal: number =
    getMarginToTransformIn43(currentWidth, currentHeight) +
    2 * transformUXSpec(screenWidth, screenHeight, 120)
  const os = Platform.OS

  if (isHorizontal) {
    if (!isMobile) {
      screenWidth -= marginHorizontal
      //horizontal tablet
      return {
        Page_height: '100%',
        Row1_direction: 'row',
        Row1_height: transformUXSpec(screenWidth, screenHeight, 260),
        Row1_marginHorizontal: marginHorizontal / 2,
        Row1_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        ProfilContainer_width: '50%',
        ProfilContainer_flex: 1,
        ProfilContainer_height: transformUXSpec(screenWidth, screenHeight, 280),
        ProfilContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 86),
        Profil_fontSize: transformUXSpec(screenWidth, screenHeight, 280),
        IDs_width: '50%',
        IDs_height: '100%',
        IDs_flex: 1,
        IDs_marginLeft: transformUXSpec(screenWidth, screenHeight, 205),
        IDs_paddingTop: '0%',
        ProfilPictureContainer_width: transformUXSpec(screenWidth, screenHeight, 245),
        ProfilPictureContainer_height: transformUXSpec(screenWidth, screenHeight, 245),
        ProfilPictureContainer_paddigLeft: '0%',
        Names_width: '50%',
        Names_paddingLeft: transformUXSpec(screenWidth, screenHeight, 50),
        Name_fontSize: transformUXSpec(screenWidth, screenHeight, 77),
        Lvl_fontSize: transformUXSpec(screenWidth, screenHeight, 67),
        Row2_direction: 'row',
        Row2_height: transformUXSpec(screenWidth, screenHeight, 300),
        Row2_marginHorizontal: marginHorizontal / 2,
        Row2_paddingTop: transformUXSpec(screenWidth, screenHeight, 50),
        ScoreColumn_width: transformUXSpec(screenWidth, screenHeight, 960),
        ScoreColumn_marginRight: '0%',
        ScoreColumn_marginLeft: transformUXSpec(screenWidth, screenHeight, 86),
        Scores_paddingTop: '0%',
        ScoreContainer_width: transformUXSpec(screenWidth, screenHeight, 250),
        ScoreContainer_height: transformUXSpec(screenWidth, screenHeight, 250),
        ScoreContainer_borderRadius: screenWidth / 15,
        ScoreText_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        Score100_fontSize: transformUXSpec(screenWidth, screenHeight, 95),
        Separator_width: transformUXSpec(screenWidth, screenHeight, 300),
        Separator_height: transformUXSpec(screenWidth, screenHeight, 11),
        RatioText_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        RatioText_lineHeight: transformUXSpec(screenWidth, screenHeight, 27),
        RatioScore_fontSize: transformUXSpec(screenWidth, screenHeight, 95),
        Ratio_paddingLeft: '0%',
        Ratio_marginRight: '0%',
        RatioOperator_paddingRight: '0%',
        RatioOperator_width: transformUXSpec(screenWidth, screenHeight, 350),
        RatioTextContainer_paddingLeft: '0%',
        XpbarCurrentXpArrow_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        XpbarCurrentXpArrow_marginRight: '-4%',
        XpbarCurrentXpScore_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        XpbarCurrentXpText_width: '100%',
        XpbarCurrentXpText_fontSize: transformUXSpec(screenWidth, screenHeight, 23),
        XpbarCurrentXpText_marginTop: '0%',
        XpbarCurrentXp_paddingLeft: '41%',
        XpbarCurrentXpNumbers_paddingBottom: transformUXSpec(screenWidth, screenHeight, 5),
        XpbarPreviousNextXpScore_fontSize: transformUXSpec(screenWidth, screenHeight, 35),
        XpbarPreviousNextXpText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        XpBarContainer_width: transformUXSpec(screenWidth, screenHeight, 910),
        XpBarContainer_paddingLeft: transformUXSpec(screenWidth, screenHeight, 180),
        XpBarContainer_paddingTop: 0,
        XpBar_height: transformUXSpec(screenWidth, screenHeight, 75),
        XpBar_Fill_height: transformUXSpec(screenWidth, screenHeight, 75),
        Row3_direction: 'row',
        Row3_height: screenHeight / 2,
        Row3_marginHorizontal: marginHorizontal / 2,
        Row3_paddingTop: transformUXSpec(screenWidth, screenHeight, 50),
        Row3_paddingBottom: '3%',
        TableColumn_width: transformUXSpec(screenWidth, screenHeight, 950),
        TableColumn_height: '100%',
        TableColumn_marginLeft: transformUXSpec(screenWidth, screenHeight, 86),
        GraphColumn_marginLeft: transformUXSpec(screenWidth, screenHeight, 185),
        GraphColumn_width: transformUXSpec(screenWidth, screenHeight, 910),
        GraphColumn_marginBottom: '0%',
        GraphColumn_marginTop: '0%',
        TableTitle_marginHorizontal: '0%',
        TableTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 55),
        GraphTitle_marginHorizontal: '0%',
        GraphTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 55),
        GraphTopButton_width: transformUXSpec(screenWidth, screenHeight, 182),
        GraphTopButton_height: transformUXSpec(screenWidth, screenHeight, 55),
        GraphButtonContainer_paddingLeft: '0%',
        GraphTopButton_Text_paddingVertical: '3%',
        GraphTopButton_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 33),
        GraphTopLegendContainer_width: '38%',
        GraphTopLegendContainer_paddingLeft: '0%',
        GraphTopLegendContainer_paddingTop: '0%',
        GraphTopLegendContainer_marginBottom: 0,
        GraphTopLegendRow_justifyContent: 'flex-end',
        GraphTopLegendRow_paddingTop: '0%',
        GraphTopLegendRow_height: transformUXSpec(screenWidth, screenHeight, 29),
        GraphTopLegendText_fontSize: transformUXSpec(screenWidth, screenHeight, 24),
        GraphTopLegendText_marginLeft: screenWidth / 175,
        GraphTopLegendMyScoreBar_width: transformUXSpec(screenWidth, screenHeight, 55),
        GraphTopLegendTeamScoreBar_width: transformUXSpec(screenWidth, screenHeight, 55),
        GraphTopRow_paddingHorizontal: '0%',
        GraphTopRow_paddingTop: transformUXSpec(screenWidth, screenHeight, 20),
        GraphTopRow_marginBottom: '0%',
        GraphContainer_paddingTop: '0%',
        GraphContainer_marginLeft: -transformUXSpec(screenWidth, screenHeight, 2 * 28 + 21 + 2),
        GraphContainer_width: transformUXSpec(screenWidth, screenHeight, 990),
        GraphContainer_height: thirdRowHeight,

        Graph_paddingLeft:
          2 * transformUXSpec(screenWidth, screenHeight, 28) +
          transformUXSpec(screenWidth, screenHeight, 21) +
          transformUXSpec(screenWidth, screenHeight, 5),
        Graph_paddingBottom: transformUXSpec(screenWidth, screenHeight, 2),
        Graph_paddingTop: transformUXSpec(screenWidth, screenHeight, 25),
        XAxisColumnContainer_marginLeft: '0%',
        XAxisColumnContainer_marginRight: '0%',
        XAxisColumnContainer_marginBottom: '0%',
        XAxisContainer_paddingLeft: '0%',
        XAxisContainer_paddingTop: transformUXSpec(screenWidth, screenHeight, 25),
        XAxisLabel_fontSize: transformUXSpec(screenWidth, screenHeight, 23),
        XAxisLabel_width: undefined,
        XAxisContainerLabel_height: transformUXSpec(screenWidth, screenHeight, 100),
        PlaybookGroupButton_height: transformUXSpec(screenWidth, screenHeight, 55),
        PlaybookGroupButton_width: transformUXSpec(screenWidth, screenHeight, 233),
        PlaybookGroupText_fontSize: transformUXSpec(screenWidth, screenHeight, 33),
        PlaybookGroupText_paddingVertical: '3%',
        PlaybookGroupButtonsRow_width: '75%',
        PlaybookGroupButtonsRow_paddingHorizontal: '0%',
        PlaybookGroupButtonsRow_paddingBottom: transformUXSpec(screenWidth, screenHeight, 20),
        TableHeaderButtonsArrow_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        TableHeaderSeparator_width: transformUXSpec(screenWidth, screenHeight, 1),
        TableHeaderSeparator_height: transformUXSpec(screenWidth, screenHeight, 75),
        TableHeaderText_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        TableHeaderText_lineHeight: transformUXSpec(screenWidth, screenHeight, 32),
        TableFirstColumn_width: transformUXSpec(screenWidth, screenHeight, 950) / 1.8,
        TableNextColumn_width: transformUXSpec(screenWidth, screenHeight, 960) / 4.3,
        Table_height: '100%',
        Table_width: transformUXSpec(screenWidth, screenHeight, 960),
        TextNameGame_fontSize: transformUXSpec(screenWidth, screenHeight, 38),
        Text_fontSize: transformUXSpec(screenWidth, screenHeight, 38),
        GraphAxisYSvgTicksLabel_fontSize: transformUXSpec(screenWidth, screenHeight, 24),
        GGraphAxisYSvgTicks_Size: transformUXSpec(screenWidth, screenHeight, 21),
        Row_height: transformUXSpec(screenWidth, screenHeight, 72),
        HeaderRow_height: transformUXSpec(screenWidth, screenHeight, 95),
        TableHeaderButtonsArrow_marginVertical: -transformUXSpec(screenWidth, screenHeight, 10),
        ModalEndPracticeContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 520),
        ModalEndPracticeContainer_paddingTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 100)
            : transformUXSpec(screenWidth, screenHeight, 80),
        ModalEndPracticeContainer_width: transformUXSpec(screenWidth, screenHeight, 1600),
        ModalEndPracticeContainer_height: transformUXSpec(screenWidth, screenHeight, 920),
        ModalEndPracticeContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalEndPracticeCloseButton_top:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 10)
            : transformUXSpec(screenWidth, screenHeight, 10),
        ModalEndPracticeCloseButton_right:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 5)
            : transformUXSpec(screenWidth, screenHeight, 30),
        Icon_width: transformUXSpec(screenWidth, screenHeight, 80),
        ModalEndPracticeTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 200),
        ModalEndPracticeSubtitle_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        ModalEndPracticeSubtitle_marginTop: transformUXSpec(screenWidth, screenHeight, 33),
        ModalEndPracticeStatsContainer_marginTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 40)
            : transformUXSpec(screenWidth, screenHeight, 40),
        ModalEndPracticeStatsText_fontSize:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 52)
            : transformUXSpec(screenWidth, screenHeight, 46),
        ModalEndPracticeStatsText_marginTop: transformUXSpec(screenWidth, screenHeight, 25),
        ModalEndPracticeXPBar_marginTop: transformUXSpec(screenWidth, screenHeight, 90),
        ModalEndPracticeXpBarContainer_width: transformUXSpec(screenWidth, screenHeight, 900),
        ModalEndPracticeRankUpText_marginTop: transformUXSpec(screenWidth, screenHeight, 70),
        ModalEndPracticeStatLineMarginBottomTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 5)
            : transformUXSpec(screenWidth, screenHeight, 5),
        ratioOfModelEndPractice: 0.5,
      }
    } else {
      //horizontal mobile
      return {
        Page_height: '100%',
        Row1_direction: 'row',
        Row1_height: transformUXSpec(screenWidth, screenHeight, 50),
        Row1_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 40),
        Row1_marginTop: transformUXSpec(screenWidth, screenHeight, 15),
        ProfilContainer_width: '50%',
        ProfilContainer_flex: 1,
        ProfilContainer_height: transformUXSpec(screenWidth, screenHeight, 90),
        ProfilContainer_marginLeft: '0%',
        Profil_fontSize: transformUXSpec(screenWidth, screenHeight, 70),
        IDs_width: '50%',
        IDs_height: '100%',
        IDs_flex: 1,
        IDs_marginLeft: transformUXSpec(screenWidth, screenHeight, 80),
        IDs_paddingTop: '0%',
        ProfilPictureContainer_width: transformUXSpec(screenWidth, screenHeight, 70),
        ProfilPictureContainer_height: transformUXSpec(screenWidth, screenHeight, 70),
        ProfilPictureContainer_paddigLeft: '0%',
        Names_width: '50%',
        Names_paddingLeft: '4%',
        Name_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        Lvl_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        Row2_direction: 'row',
        Row2_height: transformUXSpec(screenWidth, screenHeight, 120),
        Row2_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 40),
        Row2_paddingTop: 0,
        ScoreColumn_width: transformUXSpec(screenWidth, screenHeight, 366),
        ScoreColumn_marginRight: '0%',
        ScoreColumn_marginLeft: '0%',
        Scores_paddingTop: '0%',
        ScoreContainer_width: transformUXSpec(screenWidth, screenHeight, 90),
        ScoreContainer_height: transformUXSpec(screenWidth, screenHeight, 90),
        ScoreContainer_borderRadius: screenWidth / 2,
        ScoreText_fontSize:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 11)
            : transformUXSpec(screenWidth, screenHeight, 12),
        Score100_fontSize: transformUXSpec(screenWidth, screenHeight, 35),
        Separator_width: transformUXSpec(screenWidth, screenHeight, 124),
        Separator_height: transformUXSpec(screenWidth, screenHeight, 5),
        RatioText_fontSize:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 11)
            : transformUXSpec(screenWidth, screenHeight, 12),
        RatioText_lineHeight: transformUXSpec(screenWidth, screenHeight, 12),
        RatioScore_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        Ratio_paddingLeft: '0%',
        Ratio_marginRight: '0%',
        RatioOperator_paddingRight: 0,
        RatioOperator_width: transformUXSpec(screenWidth, screenHeight, 175),
        RatioTextContainer_paddingLeft: '0%',
        XpbarCurrentXpArrow_fontSize: transformUXSpec(screenWidth, screenHeight, 48),
        XpbarCurrentXpArrow_marginRight: '-7%',
        XpbarCurrentXpScore_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        XpbarCurrentXpText_width:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 120)
            : transformUXSpec(screenWidth, screenHeight, 112),
        XpbarCurrentXpText_fontSize: transformUXSpec(screenWidth, screenHeight, 11),
        XpbarCurrentXpText_marginTop: '-1%',
        XpbarCurrentXp_paddingLeft: '37%',
        XpbarCurrentXpNumbers_paddingBottom: transformUXSpec(screenWidth, screenHeight, 5),
        XpbarPreviousNextXpScore_fontSize: transformUXSpec(screenWidth, screenHeight, 10),
        XpbarPreviousNextXpText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        XpBarContainer_width: transformUXSpec(screenWidth, screenHeight, 335),
        XpBarContainer_paddingLeft:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 25)
            : transformUXSpec(screenWidth, screenHeight, 40),
        XpBarContainer_paddingTop: 0,
        XpBar_height: transformUXSpec(screenWidth, screenHeight, 27),
        XpBar_Fill_height: transformUXSpec(screenWidth, screenHeight, 27),
        Row3_direction: 'row',
        Row3_height: transformUXSpec(screenWidth, screenHeight, 500),
        Row3_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 40),
        Row3_paddingTop: transformUXSpec(screenWidth, screenHeight, 20),
        Row3_paddingBottom: '2%',
        TableColumn_width: '50%',
        TableColumn_height: '100%',
        TableColumn_marginLeft: '0%',
        GraphColumn_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        GraphColumn_width: '50%',
        GraphColumn_marginTop: '0%',
        GraphColumn_marginBottom:
          timeSpan === 4
            ? transformUXSpec(screenWidth, screenHeight, 18)
            : transformUXSpec(screenWidth, screenHeight, 22),
        TableTitle_marginHorizontal: '0%',
        TableTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        GraphTitle_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 20),
        GraphTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        GraphButtonContainer_paddingLeft: '0%',
        GraphTopButton_width: transformUXSpec(screenWidth, screenHeight, 111),
        GraphTopButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        GraphTopButton_Text_paddingVertical:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 2)
            : transformUXSpec(screenWidth, screenHeight, 5),
        GraphTopButton_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 17),
        GraphTopLegendContainer_width: '100%',
        GraphTopLegendContainer_paddingLeft: transformUXSpec(screenWidth, screenHeight, 62),
        GraphTopLegendContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        GraphTopLegendContainer_paddingTop:
          timeSpan === 4
            ? transformUXSpec(screenWidth, screenHeight, 0)
            : os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 10)
            : transformUXSpec(screenWidth, screenHeight, 10),
        GraphTopLegendRow_justifyContent: 'flex-start',
        GraphTopLegendRow_paddingTop: '0%',
        GraphTopLegendRow_height: transformUXSpec(screenWidth, screenHeight, 20),
        GraphTopLegendText_fontSize: transformUXSpec(screenWidth, screenHeight, 16),
        GraphTopLegendText_marginLeft: screenWidth / 25,
        GraphTopLegendMyScoreBar_width: transformUXSpec(screenWidth, screenHeight, 45),
        GraphTopLegendTeamScoreBar_width: transformUXSpec(screenWidth, screenHeight, 45),
        GraphTopRow_paddingHorizontal: '5%',
        GraphTopRow_paddingTop: transformUXSpec(screenWidth, screenHeight, 7),
        GraphTopRow_marginBottom: '0%',
        GraphContainer_paddingTop: '2%',
        GraphContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        GraphContainer_width: transformUXSpec(screenWidth, screenHeight, 294 + 28 + 10),
        GraphContainer_height:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 250)
            : transformUXSpec(screenWidth, screenHeight, 264),
        Graph_paddingLeft:
          2 * transformUXSpec(screenWidth, screenHeight, 14) +
          transformUXSpec(screenWidth, screenHeight, 10) +
          transformUXSpec(screenWidth, screenHeight, 5),
        Graph_paddingBottom: transformUXSpec(screenWidth, screenHeight, 2),
        Graph_paddingTop: transformUXSpec(screenWidth, screenHeight, 10),
        XAxisColumnContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 25),
        XAxisColumnContainer_marginRight:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 0)
            : transformUXSpec(screenWidth, screenHeight, 15),
        XAxisColumnContainer_marginBottom:
          os === 'android' ? transformUXSpec(screenWidth, screenHeight, 40) : 0,
        XAxisContainer_paddingLeft: transformUXSpec(screenWidth, screenHeight, 38),
        XAxisContainer_paddingTop:
          os === 'android' ? transformUXSpec(screenWidth, screenHeight, 15) : 0,
        XAxisLabel_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        XAxisLabel_width: undefined,
        XAxisContainerLabel_height:
          os === 'android' ? transformUXSpec(screenWidth, screenHeight, 14) : '100%',
        PlaybookGroupButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        PlaybookGroupButton_width: transformUXSpec(screenWidth, screenHeight, 111),
        PlaybookGroupText_fontSize:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 16)
            : transformUXSpec(screenWidth, screenHeight, 17),
        PlaybookGroupText_paddingVertical:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 1)
            : transformUXSpec(screenWidth, screenHeight, 5),
        PlaybookGroupButtonsRow_width: '100%',
        PlaybookGroupButtonsRow_paddingHorizontal: '0%',
        PlaybookGroupButtonsRow_paddingBottom: transformUXSpec(screenWidth, screenHeight, 7),
        TableHeaderButtonsArrow_fontSize: transformUXSpec(screenWidth, screenHeight, 26),
        TableHeaderSeparator_width: transformUXSpec(screenWidth, screenHeight, 1),
        TableHeaderSeparator_height: transformUXSpec(screenWidth, screenHeight, 46),
        TableHeaderText_fontSize:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 13)
            : transformUXSpec(screenWidth, screenHeight, 14),
        TableHeaderText_lineHeight: transformUXSpec(screenWidth, screenHeight, 14),
        TableFirstColumn_width: transformUXSpec(screenWidth, screenHeight, 366) / 2,
        TableNextColumn_width: transformUXSpec(screenWidth, screenHeight, 366) / 4,
        Table_height: transformUXSpec(screenWidth, screenHeight, 340),
        Table_width: transformUXSpec(screenWidth, screenHeight, 366),
        TextNameGame_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        Text_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        GraphAxisYSvgTicksLabel_fontSize: transformUXSpec(screenWidth, screenHeight, 14),
        GGraphAxisYSvgTicks_Size: transformUXSpec(screenWidth, screenHeight, 10),
        Row_height: transformUXSpec(screenWidth, screenHeight, 34),
        HeaderRow_height: transformUXSpec(screenWidth, screenHeight, 55),
        TableHeaderButtonsArrow_marginVertical: -transformUXSpec(screenWidth, screenHeight, 6),
        ModalEndPracticeContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        ModalEndPracticeContainer_paddingTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalEndPracticeContainer_width: transformUXSpec(screenWidth, screenHeight, 625),
        ModalEndPracticeContainer_height: transformUXSpec(screenWidth, screenHeight, 280),
        ModalEndPracticeContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalEndPracticeCloseButton_top: transformUXSpec(screenWidth, screenHeight, 6),
        ModalEndPracticeCloseButton_right: transformUXSpec(screenWidth, screenHeight, 2),
        Icon_width: transformUXSpec(screenWidth, screenHeight, 40),
        ModalEndPracticeTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 55),
        ModalEndPracticeSubtitle_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        ModalEndPracticeSubtitle_marginTop: transformUXSpec(screenWidth, screenHeight, 4),
        ModalEndPracticeStatsContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 3),
        ModalEndPracticeStatsText_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        ModalEndPracticeStatsText_marginTop: transformUXSpec(screenWidth, screenHeight, 3),
        ModalEndPracticeXPBar_marginTop: transformUXSpec(screenWidth, screenHeight, 15),
        ModalEndPracticeXpBarContainer_width: transformUXSpec(screenWidth, screenHeight, 335),
        ModalEndPracticeRankUpText_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalEndPracticeStatLineMarginBottomTop: transformUXSpec(screenWidth, screenHeight, 25),
        ratioOfModelEndPractice: 1,
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile/tablet
      return {
        Page_height: transformUXSpec(screenWidth, screenHeight, 1400),
        Row1_direction: 'column',
        Row1_height: 300,
        Row1_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 20),
        Row1_marginTop: 15,
        ProfilContainer_width: '100%',
        ProfilContainer_flex: undefined,
        ProfilContainer_height: transformUXSpec(screenWidth, screenHeight, 90),
        ProfilContainer_marginLeft: '0%',
        Profil_fontSize: transformUXSpec(screenWidth, screenHeight, 70),
        IDs_width: '100%',
        IDs_height: transformUXSpec(screenWidth, screenHeight, 85),
        IDs_flex: undefined,
        IDs_marginLeft: '0%',
        IDs_paddingTop: '0%',
        ProfilPictureContainer_width: transformUXSpec(screenWidth, screenHeight, 70),
        ProfilPictureContainer_height: transformUXSpec(screenWidth, screenHeight, 70),
        ProfilPictureContainer_paddigLeft: '0%',
        Names_width: '100%',
        Names_paddingLeft: '5%',
        Name_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        Lvl_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        Row2_direction: 'column',
        Row2_height: transformUXSpec(screenWidth, screenHeight, 100),
        Row2_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 20),
        Row2_paddingTop: 0,
        ScoreColumn_width: transformUXSpec(screenWidth, screenHeight, 375),
        ScoreColumn_marginRight: '0%',
        ScoreColumn_marginLeft: transformUXSpec(screenWidth, screenHeight, 40),
        Scores_paddingTop: '0%',
        ScoreContainer_width: transformUXSpec(screenWidth, screenHeight, 90),
        ScoreContainer_height: transformUXSpec(screenWidth, screenHeight, 90),
        ScoreContainer_borderRadius: screenWidth / 2,
        ScoreText_fontSize: transformUXSpec(screenWidth, screenHeight, 11),
        Score100_fontSize: transformUXSpec(screenWidth, screenHeight, 35),
        Separator_width: transformUXSpec(screenWidth, screenHeight, 124),
        Separator_height: transformUXSpec(screenWidth, screenHeight, 5),
        RatioText_fontSize:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 10)
            : transformUXSpec(screenWidth, screenHeight, 11),
        RatioText_lineHeight: transformUXSpec(screenWidth, screenHeight, 12),
        RatioScore_fontSize: transformUXSpec(screenWidth, screenHeight, 35),
        Ratio_paddingLeft: '0%',
        Ratio_marginRight: transformUXSpec(screenWidth, screenHeight, 40),
        RatioOperator_paddingRight: '0%',
        RatioOperator_width: transformUXSpec(screenWidth, screenHeight, 130),
        RatioTextContainer_paddingLeft: '0%',
        XpbarCurrentXpArrow_fontSize: transformUXSpec(screenWidth, screenHeight, 48),
        XpbarCurrentXpArrow_marginRight: '-7%',
        XpbarCurrentXpScore_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        XpbarCurrentXpText_width:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 110)
            : transformUXSpec(screenWidth, screenHeight, 120),
        XpbarCurrentXpText_fontSize: transformUXSpec(screenWidth, screenHeight, 11),
        XpbarCurrentXpText_marginTop: '0%',
        XpbarCurrentXp_paddingLeft: '37%',
        XpbarCurrentXpNumbers_paddingBottom: transformUXSpec(screenWidth, screenHeight, 5),
        XpbarPreviousNextXpScore_fontSize: transformUXSpec(screenWidth, screenHeight, 10),
        XpbarPreviousNextXpText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        XpBarContainer_width: transformUXSpec(screenWidth, screenHeight, 335),
        XpBarContainer_paddingLeft: '0%',
        XpBarContainer_paddingTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 20)
            : transformUXSpec(screenWidth, screenHeight, 15),
        XpBar_height: transformUXSpec(screenWidth, screenHeight, 27),
        XpBar_Fill_height: transformUXSpec(screenWidth, screenHeight, 27),
        Row3_direction: 'column',
        Row3_height: transformUXSpec(screenWidth, screenHeight, 500),
        Row3_marginHorizontal: '0%',
        Row3_paddingTop: '5%',
        Row3_paddingBottom: transformUXSpec(screenWidth, screenHeight, 10),
        TableColumn_width: '100%',
        TableColumn_marginLeft: '0%',
        TableColumn_height: transformUXSpec(screenWidth, screenHeight, 270),
        GraphColumn_marginLeft: '0%',
        GraphColumn_width: '100%',
        GraphColumn_marginTop: transformUXSpec(screenWidth, screenHeight, 210),
        GraphColumn_marginBottom: '0%',
        TableTitle_marginHorizontal: '5%',
        TableTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        GraphTitle_marginHorizontal: '5%',
        GraphTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        GraphButtonContainer_paddingLeft: '0%',
        GraphTopButton_width: transformUXSpec(screenWidth, screenHeight, 111),
        GraphTopButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        GraphTopButton_Text_paddingVertical:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 1)
            : transformUXSpec(screenWidth, screenHeight, 5),
        GraphTopButton_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 17),
        GraphTopLegendContainer_width: '100%',
        GraphTopLegendContainer_paddingLeft: transformUXSpec(screenWidth, screenHeight, 62),
        GraphTopLegendContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        GraphTopLegendContainer_paddingTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 20)
            : transformUXSpec(screenWidth, screenHeight, 330),
        GraphTopLegendRow_justifyContent: 'flex-start',
        GraphTopLegendRow_paddingTop:
          os === 'android' ? transformUXSpec(screenWidth, screenHeight, 20) : 0,
        GraphTopLegendRow_height: transformUXSpec(screenWidth, screenHeight, 20),
        GraphTopLegendText_fontSize: transformUXSpec(screenWidth, screenHeight, 16),
        GraphTopLegendText_marginLeft: screenWidth / 25,
        GraphTopLegendMyScoreBar_width: transformUXSpec(screenWidth, screenHeight, 45),
        GraphTopLegendTeamScoreBar_width: transformUXSpec(screenWidth, screenHeight, 45),
        GraphTopRow_paddingHorizontal: '5%',
        GraphTopRow_paddingTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 5)
            : os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 20)
            : transformUXSpec(screenWidth, screenHeight, 20),
        GraphTopRow_marginBottom:
          os === 'android' ? transformUXSpec(screenWidth, screenHeight, 10) : 0,
        GraphContainer_paddingTop: transformUXSpec(screenWidth, screenHeight, 20),
        GraphContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        GraphContainer_width: transformUXSpec(screenWidth, screenHeight, 294 + 28 + 10),
        GraphContainer_height: transformUXSpec(screenWidth, screenHeight, 270),
        Graph_paddingLeft:
          2 * transformUXSpec(screenWidth, screenHeight, 14) +
          transformUXSpec(screenWidth, screenHeight, 10) +
          transformUXSpec(screenWidth, screenHeight, 5),
        Graph_paddingBottom: transformUXSpec(screenWidth, screenHeight, 2),
        Graph_paddingTop: transformUXSpec(screenWidth, screenHeight, 28) / 2,
        XAxisColumnContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        XAxisColumnContainer_marginRight: transformUXSpec(screenWidth, screenHeight, 20),
        XAxisColumnContainer_marginBottom: '0%',
        XAxisContainer_paddingLeft: transformUXSpec(screenWidth, screenHeight, 38),
        XAxisContainer_paddingTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 10)
            : transformUXSpec(screenWidth, screenHeight, 284),
        XAxisLabel_fontSize: transformUXSpec(screenWidth, screenHeight, 14),
        XAxisLabel_width: '60%',
        XAxisContainerLabel_height: transformUXSpec(screenWidth, screenHeight, 15),
        PlaybookGroupButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        PlaybookGroupButton_width: transformUXSpec(screenWidth, screenHeight, 111),
        PlaybookGroupText_fontSize:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 17)
            : transformUXSpec(screenWidth, screenHeight, 16),
        PlaybookGroupText_paddingVertical:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 1)
            : transformUXSpec(screenWidth, screenHeight, 5),
        PlaybookGroupButtonsRow_width: '100%',
        PlaybookGroupButtonsRow_paddingHorizontal: '5%',
        PlaybookGroupButtonsRow_paddingBottom: '3%',
        TableHeaderButtonsArrow_fontSize: transformUXSpec(screenWidth, screenHeight, 26),
        TableHeaderSeparator_width: transformUXSpec(screenWidth, screenHeight, 1),
        TableHeaderSeparator_height: transformUXSpec(screenWidth, screenHeight, 46),
        TableHeaderText_fontSize: transformUXSpec(screenWidth, screenHeight, 14),
        TableHeaderText_lineHeight: transformUXSpec(screenWidth, screenHeight, 14),
        TableFirstColumn_width: transformUXSpec(screenWidth, screenHeight, 375) / 2,
        TableNextColumn_width: transformUXSpec(screenWidth, screenHeight, 375) / 4,
        Table_height: transformUXSpec(screenWidth, screenHeight, 340),
        Table_width: transformUXSpec(screenWidth, screenHeight, 375),
        TextNameGame_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        Text_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        GraphAxisYSvgTicksLabel_fontSize:
          os === 'android'
            ? transformUXSpec(screenWidth, screenHeight, 10)
            : transformUXSpec(screenWidth, screenHeight, 14),
        GGraphAxisYSvgTicks_Size: transformUXSpec(screenWidth, screenHeight, 10),
        Row_height: transformUXSpec(screenWidth, screenHeight, 34),
        HeaderRow_height: transformUXSpec(screenWidth, screenHeight, 55),
        TableHeaderButtonsArrow_marginVertical: -transformUXSpec(screenWidth, screenHeight, 6),
        ModalEndPracticeContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 180),
        ModalEndPracticeContainer_paddingTop: transformUXSpec(screenWidth, screenHeight, 30),
        ModalEndPracticeContainer_width: transformUXSpec(screenWidth, screenHeight, 350),
        ModalEndPracticeContainer_height: transformUXSpec(screenWidth, screenHeight, 300),
        ModalEndPracticeContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalEndPracticeCloseButton_top: transformUXSpec(screenWidth, screenHeight, 10),
        ModalEndPracticeCloseButton_right: transformUXSpec(screenWidth, screenHeight, 10),
        Icon_width: transformUXSpec(screenWidth, screenHeight, 30),
        ModalEndPracticeTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        ModalEndPracticeSubtitle_fontSize: transformUXSpec(screenWidth, screenHeight, 24),
        ModalEndPracticeSubtitle_marginTop: transformUXSpec(screenWidth, screenHeight, 12),
        ModalEndPracticeStatsContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 8),
        ModalEndPracticeStatsText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        ModalEndPracticeStatsText_marginTop: transformUXSpec(screenWidth, screenHeight, 8),
        ModalEndPracticeXPBar_marginTop: transformUXSpec(screenWidth, screenHeight, 18),
        ModalEndPracticeXpBarContainer_width: transformUXSpec(screenWidth, screenHeight, 335),
        ModalEndPracticeRankUpText_marginTop: transformUXSpec(screenWidth, screenHeight, 20),
        ModalEndPracticeStatLineMarginBottomTop: transformUXSpec(screenWidth, screenHeight, 25),
        ratioOfModelEndPractice: 0.4,
      }
    } else {
      //vertical tablet
      return {
        Page_height: transformUXSpec(screenWidth, screenHeight, 3160),
        Row1_direction: 'row',
        Row1_height: transformUXSpec(screenWidth, screenHeight, 250),
        Row1_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 20),
        Row1_marginTop: transformUXSpec(screenWidth, screenHeight, 20),
        ProfilContainer_width: '50%',
        ProfilContainer_flex: 1,
        ProfilContainer_height: transformUXSpec(screenWidth, screenHeight, 250),
        ProfilContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 110),
        Profil_fontSize: transformUXSpec(screenWidth, screenHeight, 250),
        IDs_width: '100%',
        IDs_height: transformUXSpec(screenWidth, screenHeight, 200),
        IDs_flex: 1,
        IDs_marginLeft: '3%',
        IDs_paddingTop: '3%',
        ProfilPictureContainer_width: transformUXSpec(screenWidth, screenHeight, 200),
        ProfilPictureContainer_height: transformUXSpec(screenWidth, screenHeight, 200),
        ProfilPictureContainer_paddigLeft: '0%',
        Names_width: '100%',
        Names_paddingLeft: '5%',
        Name_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        Lvl_fontSize: transformUXSpec(screenWidth, screenHeight, 60),
        Row2_direction: 'column',
        Row2_height: transformUXSpec(screenWidth, screenHeight, 300),
        Row2_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 110),
        Row2_paddingTop: transformUXSpec(screenWidth, screenHeight, 40),
        ScoreColumn_width: transformUXSpec(screenWidth, screenHeight, 1400),
        ScoreColumn_marginRight: '0%',
        ScoreColumn_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        Scores_paddingTop: '3%',
        ScoreContainer_height: transformUXSpec(screenWidth, screenHeight, 250),
        ScoreContainer_width: transformUXSpec(screenWidth, screenHeight, 250),
        ScoreContainer_borderRadius: screenWidth / 12,
        ScoreText_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        Score100_fontSize: transformUXSpec(screenWidth, screenHeight, 95),
        Separator_width: transformUXSpec(screenWidth, screenHeight, 300),
        Separator_height: transformUXSpec(screenWidth, screenHeight, 11),
        RatioText_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        RatioText_lineHeight: transformUXSpec(screenWidth, screenHeight, 27),
        RatioScore_fontSize: transformUXSpec(screenWidth, screenHeight, 95),
        Ratio_paddingLeft: '0%',
        Ratio_marginRight: '0%',
        RatioOperator_paddingRight: '0%',
        RatioOperator_width: transformUXSpec(screenWidth, screenHeight, 320),
        RatioTextContainer_paddingLeft: '0%',
        XpbarCurrentXpArrow_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        XpbarCurrentXpArrow_marginRight: '-4%',
        XpbarCurrentXpScore_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        XpbarCurrentXpText_width: '100%',
        XpbarCurrentXpText_fontSize: transformUXSpec(screenWidth, screenHeight, 23),
        XpbarCurrentXpText_marginTop: '0%',
        XpbarCurrentXp_paddingLeft: '41%',
        XpbarCurrentXpNumbers_paddingBottom: transformUXSpec(screenWidth, screenHeight, 5),
        XpbarPreviousNextXpScore_fontSize: transformUXSpec(screenWidth, screenHeight, 35),
        XpbarPreviousNextXpText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        XpBarContainer_width: transformUXSpec(screenWidth, screenHeight, 1400),
        XpBarContainer_paddingLeft: 0,
        XpBarContainer_paddingTop: 0,
        XpBar_height: transformUXSpec(screenWidth, screenHeight, 75),
        XpBar_Fill_height: transformUXSpec(screenWidth, screenHeight, 75),
        Row3_direction: 'column',
        Row3_height: transformUXSpec(screenWidth, screenHeight, 2000),
        Row3_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 20),
        Row3_paddingTop: transformUXSpec(screenWidth, screenHeight, 100),
        Row3_paddingBottom: '3%',
        TableColumn_width: transformUXSpec(screenWidth, screenHeight, 1400),
        TableColumn_height: transformUXSpec(screenWidth, screenHeight, 800),
        TableColumn_marginLeft: transformUXSpec(screenWidth, screenHeight, 110),
        GraphColumn_marginLeft: transformUXSpec(screenWidth, screenHeight, 110),
        GraphColumn_width: transformUXSpec(screenWidth, screenHeight, 1400),
        GraphColumn_marginBottom: '0%',
        GraphColumn_marginTop: '5%',
        TableTitle_marginHorizontal: '0%',
        TableTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 55),
        GraphTitle_marginHorizontal: '0%',
        GraphTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 55),
        GraphTopButton_width: transformUXSpec(screenWidth, screenHeight, 182),
        GraphTopButton_height: transformUXSpec(screenWidth, screenHeight, 55),
        GraphButtonContainer_paddingLeft: '0%',
        GraphTopButton_Text_paddingVertical: '3%',
        GraphTopButton_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 33),
        GraphTopLegendContainer_width: '38%',
        GraphTopLegendContainer_paddingLeft: '0%',
        GraphTopLegendContainer_paddingTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 20)
            : transformUXSpec(screenWidth, screenHeight, 780),
        GraphTopLegendContainer_marginBottom: 0,
        GraphTopLegendRow_justifyContent: 'flex-start',
        GraphTopLegendRow_paddingTop: 0,
        GraphTopLegendRow_height: transformUXSpec(screenWidth, screenHeight, 35),
        GraphTopLegendText_fontSize: transformUXSpec(screenWidth, screenHeight, 24),
        GraphTopLegendText_marginLeft: screenWidth / 175,
        GraphTopLegendMyScoreBar_width: transformUXSpec(screenWidth, screenHeight, 55),
        GraphTopLegendTeamScoreBar_width: transformUXSpec(screenWidth, screenHeight, 55),
        GraphTopRow_paddingHorizontal: '0%',
        GraphTopRow_paddingTop: transformUXSpec(screenWidth, screenHeight, 40),
        GraphTopRow_marginBottom: '0%',
        GraphContainer_paddingTop: '0%',
        GraphContainer_marginLeft: -transformUXSpec(screenWidth, screenHeight, 2 * 28 + 21 + 2),
        GraphContainer_width: transformUXSpec(screenWidth, screenHeight, 1480),
        GraphContainer_height: thirdRowHeight,
        Graph_paddingLeft:
          2 * transformUXSpec(screenWidth, screenHeight, 28) +
          transformUXSpec(screenWidth, screenHeight, 21) +
          transformUXSpec(screenWidth, screenHeight, 5),
        Graph_paddingBottom: transformUXSpec(screenWidth, screenHeight, 2),
        Graph_paddingTop: transformUXSpec(screenWidth, screenHeight, 50),
        XAxisColumnContainer_marginLeft: '0%',
        XAxisColumnContainer_marginRight: '0%',
        XAxisColumnContainer_marginBottom: '0%',
        XAxisContainer_paddingLeft: '0%',
        XAxisContainer_paddingTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 10)
            : transformUXSpec(screenWidth, screenHeight, 680),
        XAxisLabel_fontSize: transformUXSpec(screenWidth, screenHeight, 23),
        XAxisLabel_width: '60%',
        XAxisContainerLabel_height: transformUXSpec(screenWidth, screenHeight, 50),
        PlaybookGroupButton_height: transformUXSpec(screenWidth, screenHeight, 55),
        PlaybookGroupButton_width: transformUXSpec(screenWidth, screenHeight, 233),
        PlaybookGroupText_fontSize: transformUXSpec(screenWidth, screenHeight, 33),
        PlaybookGroupText_paddingVertical: '3%',
        PlaybookGroupButtonsRow_width: '75%',
        PlaybookGroupButtonsRow_paddingHorizontal: '0%',
        PlaybookGroupButtonsRow_paddingBottom: transformUXSpec(screenWidth, screenHeight, 20),
        TableHeaderButtonsArrow_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        TableHeaderSeparator_width: transformUXSpec(screenWidth, screenHeight, 1),
        TableHeaderSeparator_height: transformUXSpec(screenWidth, screenHeight, 75),
        TableHeaderText_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        TableHeaderText_lineHeight: transformUXSpec(screenWidth, screenHeight, 32),
        TableFirstColumn_width: transformUXSpec(screenWidth, screenHeight, 1300) / 1.8,
        TableNextColumn_width: transformUXSpec(screenWidth, screenHeight, 1400) / 4.3,
        Table_height: '100%',
        Table_width: transformUXSpec(screenWidth, screenHeight, 1400),
        TextNameGame_fontSize: transformUXSpec(screenWidth, screenHeight, 38),
        Text_fontSize: transformUXSpec(screenWidth, screenHeight, 38),
        GraphAxisYSvgTicksLabel_fontSize: transformUXSpec(screenWidth, screenHeight, 24),
        GGraphAxisYSvgTicks_Size: transformUXSpec(screenWidth, screenHeight, 21),
        Row_height: transformUXSpec(screenWidth, screenHeight, 72),
        HeaderRow_height: transformUXSpec(screenWidth, screenHeight, 95),
        TableHeaderButtonsArrow_marginVertical: -transformUXSpec(screenWidth, screenHeight, 10),
        ModalEndPracticeContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 600),
        ModalEndPracticeContainer_paddingTop: transformUXSpec(screenWidth, screenHeight, 80),
        ModalEndPracticeContainer_width: transformUXSpec(screenWidth, screenHeight, 1300),
        ModalEndPracticeContainer_height: transformUXSpec(screenWidth, screenHeight, 800),
        ModalEndPracticeContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalEndPracticeCloseButton_top: transformUXSpec(screenWidth, screenHeight, 14),
        ModalEndPracticeCloseButton_right: transformUXSpec(screenWidth, screenHeight, 10),
        Icon_width: transformUXSpec(screenWidth, screenHeight, 80),
        ModalEndPracticeTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 174),
        ModalEndPracticeSubtitle_fontSize: transformUXSpec(screenWidth, screenHeight, 70),
        ModalEndPracticeSubtitle_marginTop: transformUXSpec(screenWidth, screenHeight, 30),
        ModalEndPracticeStatsContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 46),
        ModalEndPracticeStatsText_fontSize: transformUXSpec(screenWidth, screenHeight, 46),
        ModalEndPracticeStatsText_marginTop: transformUXSpec(screenWidth, screenHeight, 25),
        ModalEndPracticeXPBar_marginTop: transformUXSpec(screenWidth, screenHeight, 70),
        ModalEndPracticeXpBarContainer_width: transformUXSpec(screenWidth, screenHeight, 900),
        ModalEndPracticeRankUpText_marginTop: transformUXSpec(screenWidth, screenHeight, 45),
        ModalEndPracticeStatLineMarginBottomTop: transformUXSpec(screenWidth, screenHeight, 25),
        ratioOfModelEndPractice: 0.4,
      }
    }
  }
}
