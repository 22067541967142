import { StyleSheet, ScaledSize, Platform } from 'react-native'
import { StyledComponentsTheme } from '../Theme'
import { StyleParameters } from '../Utilities'
export default function getUserTeamInviteStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  _teamColor: string,
  styleParameters: StyleParameters
) {
  return StyleSheet.create({
    modalContainer: {
      position: 'absolute',
      left: 0,
      top: Platform.OS === 'web' ? 0 : Platform.OS === 'android' ? 0 : 30,
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      marginLeft: styleParameters.screenWidth / 3,
      marginTop: 150,
      width: 600,
      height: 350,
      borderRadius: 20,
    },
    modalTitle: {
      fontFamily: 'barlow-extra-italic',
      fontSize: 35,
      marginTop: 20,
      color: theme.textColors.primary,
      textTransform: 'uppercase',
    },
    button: {
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    buttonCancel: {
      width: 200,
      backgroundColor: theme.textColors.secondary,
      justifyContent: 'center',
      height: 45,
      transform: [{ skewX: '-10deg' }],
    },
    buttonAcceptInvite: {
      width: 200,
      backgroundColor: _teamColor,
      justifyContent: 'center',
      height: 45,
      transform: [{ skewX: '-10deg' }],
      color: theme.textColors.primary,
    },
    cancelButtonText: {
      fontFamily: 'barlow-semi',
      color: theme.textColors.primary,
      fontSize: 20,
      textAlign: 'center',
      transform: [{ skewX: '10deg' }],
    },
    createAcceptButtonText: {
      fontFamily: 'barlow-semi',
      color: theme.textColors.primary,
      fontSize: 20,
      textAlign: 'center',
      transform: [{ skewX: '10deg' }],
    },
    buttonsRow: {
      flexDirection: 'row',
      width: '80%',
      justifyContent: 'space-between',
      position: 'relative',
      paddingBottom: 15,
      marginTop: 50,
    },
    InviteText: {
      fontFamily: 'barlow-extra',
      fontSize: 18,
      marginTop: 8,
      color: theme.textColors.primary,
      marginLeft: 5,
    },
    InviteTextContainerCoach: {
      marginLeft: 50,
    },
    InviteTextContainerTeam: {
      marginLeft: 10,
    },
    InviteTextJoin: {
      fontFamily: 'barlow-extra',
      fontWeight: '400',
      fontSize: 18,
      marginTop: 30,
      color: theme.textColors.primary,
      marginLeft: 50,
    },
  })
}
