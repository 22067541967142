import React, { Component } from 'react'
import { Dimensions, TouchableOpacity, View, Image, Text, Platform } from 'react-native'
import { getPlaybookStyles, updateStyleParameters } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { NavigationInterface, PageName } from './PageInfo'
import { isValidSnapshot } from '@taktik/src/Utilitary/Utils'
import { isURL, storageGetImageData } from '../Utilitary/StorageUtils'

interface PlaybookGroupState {
  id: number
  title: string
  snapshot: string
  styleParameters: StyleParameters
}

interface FolderPlaybookProps extends NavigationInterface {
  id: number
  title: string
  snapshot: string
  playbookIDs: number[]
}

export class FolderPlaybook extends Component<FolderPlaybookProps, PlaybookGroupState> {
  constructor(public props: FolderPlaybookProps) {
    super(props)
    this.state = {
      id: -1,
      title: '',
      snapshot: '',
      styleParameters: getStyleParameters(),
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', this.onChange)

    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      await this.updateSnapshotToBase64()
    }
  }

  updateSnapshotToBase64 = async () => {
    const { snapshot } = this.props

    if (!isURL(snapshot)) {
      return
    }

    const base64Data = await storageGetImageData(snapshot)

    if (base64Data) {
      // Should be OK to allow a `setState` here since this will only be triggered once
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ snapshot: base64Data })
    }
  }

  render() {
    const { id, navigation, playbookIDs, title, snapshot: propsSnapshot } = this.props
    const { styleParameters, snapshot: stateSnapshot } = this.state

    const isMobile: boolean = Platform.OS === 'ios' || Platform.OS === 'android'
    const snapshot = isMobile ? stateSnapshot : propsSnapshot

    return (
      <View style={getPlaybookStyles(styleParameters).snapshotContainer}>
        <Text
          key={id}
          adjustsFontSizeToFit
          style={getPlaybookStyles(styleParameters).playbookPreviewTitle}
        >
          {title.toUpperCase()}
        </Text>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate(PageName.PlaybookPage, {
              playbookID: id,
              playbookIDs: playbookIDs,
            })
          }}
        >
          {isValidSnapshot(snapshot) ? (
            <Image
              source={{ uri: snapshot === '' ? undefined : snapshot }}
              style={getPlaybookStyles(styleParameters).playbookPreviewImage}
              resizeMethod="scale"
            />
          ) : null}
        </TouchableOpacity>
      </View>
    )
  }
}
