import React, { Component, ReactNode, ReactNodeArray } from 'react'
import { AntDesign } from '@expo/vector-icons'
import { Text, TouchableOpacity, View } from 'react-native'
import { getRegistrationStyles } from '../Styles/AppStyle'
import { StyleParameters } from '../Styles/Utilities'

interface CollapsibleContainerProps {
  children: ReactNode | ReactNodeArray
  title: string
  initiallyOpen?: boolean
  styleParameters: StyleParameters
}

interface CollapsibleContainerState {
  isOpen: boolean
}

class CollapsibleContainer extends Component<CollapsibleContainerProps, CollapsibleContainerState> {
  constructor(props: CollapsibleContainerProps) {
    super(props)
    this.state = { isOpen: this.props.initiallyOpen ?? false }
  }

  toggle() {
    this.setState((previousState) => ({ isOpen: !previousState.isOpen }))
  }

  render() {
    const { title, children, styleParameters } = this.props
    const { isOpen } = this.state
    const styles = getRegistrationStyles(styleParameters)

    return (
      <View style={styles.positionPickerContainer}>
        <View style={styles.positionPickerHeaderContainer}>
          <View style={styles.positionPickerTitleContainer}>
            <TouchableOpacity onPress={() => this.toggle()} style={styles.positionPickerToggle}>
              <AntDesign name={isOpen ? 'caretdown' : 'caretright'} style={styles.groupTitleIcon} />
              <Text style={styles.positionPickerTitle}>{title}</Text>
            </TouchableOpacity>
            <View style={styles.line} />
          </View>
        </View>
        {isOpen && children}
      </View>
    )
  }
}

export default CollapsibleContainer
