import React from 'react'
import { View, Text, ImageBackground, Dimensions, TouchableOpacity } from 'react-native'
import BasicPage from './BasicPage'
import translator from '@taktik/config/Translation/languages'
import TopBarMenu from './TopBarMenu'
import { NavigationInterface, PageName } from './PageInfo'
import { getGlobalStyles, getLoginStyles, updateStyleParameters } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'

import { imgPathCut, imgPathCutLowRes } from '../AssetsManager'

interface ForgotPasswordSuccessProps extends NavigationInterface {}

interface ForgotPasswordSuccessState {
  styleParameters: StyleParameters
}

export default class ForgotPasswordSuccessPage extends BasicPage<
  ForgotPasswordSuccessProps,
  ForgotPasswordSuccessState
> {
  constructor(props: ForgotPasswordSuccessProps) {
    super(props)
    this.state = {
      styleParameters: getStyleParameters(),
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', this.onChange)
  }

  componentWillUnmount = () => {
    Dimensions.removeEventListener('change', this.onChange)
  }

  returnToLogin() {
    this.props.navigation.navigate(PageName.Login)
  }

  getSubtitleText = (message: string) => {
    switch (message) {
      case 'forgotPassword':
        return translator.t('success.forgotPassword')
      case 'changePassword':
        return translator.t('success.changePassword')
      default:
        return 'N/A'
    }
  }

  render() {
    const { navigation } = this.props
    const { styleParameters } = this.state
    const { isHorizontal } = styleParameters

    const subtitleText: string = this.getSubtitleText(this.props.route.params.message)

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        {/* The top (low-quality) ImageBackground is a placeholder while the HD image loads (useful to avoid a blank background) */}
        <ImageBackground
          source={imgPathCutLowRes}
          style={getGlobalStyles(styleParameters).backgroundImageContents}
        >
          <ImageBackground
            source={imgPathCut}
            style={getGlobalStyles(styleParameters).backgroundImageContents}
          >
            <TopBarMenu
              navigation={navigation}
              url={PageName.Login}
              updatePage={() => this.forceUpdate()}
              userMustBeLoggedOut={false}
              pageTitle={translator.t('defaultTeamName')}
            />
            <View style={getLoginStyles(styleParameters).page}>
              {isHorizontal && <View style={getLoginStyles(styleParameters).side} />}
              {isHorizontal && <View style={getLoginStyles(styleParameters).side} />}
              <View style={getLoginStyles(styleParameters).authenticationForm}>
                <Text style={getLoginStyles(styleParameters).subtitleText}>{subtitleText}</Text>
                <View style={getLoginStyles(styleParameters).authenticateForm}>
                  <View style={getLoginStyles(styleParameters).otherOptionsContainer}>
                    <TouchableOpacity
                      onPress={() => this.returnToLogin()}
                      style={getLoginStyles(styleParameters).otherOptionsButton}
                    >
                      <Text style={getLoginStyles(styleParameters).otherOptionsText}>
                        {translator.t('backToLogin')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </ImageBackground>
        </ImageBackground>
      </View>
    )
  }
}
