import React from 'react'
import { View, StyleProp, StyleSheet } from 'react-native'
import { G, Svg, Path } from 'react-native-svg'

interface ZoomOutIconProps {
  style?: StyleProp<any>
}

const ZoomOutIcon = ({ style }: ZoomOutIconProps) => {
  const defaultColor: string = '#000'
  const defaultIconSize: number = 15

  let color: string = defaultColor
  let width: number = defaultIconSize

  if (style) {
    ;({ color, width } = StyleSheet.flatten(style))
  }

  const aspectRatio: number = 52.44 / 52.43
  const height: number = width * aspectRatio

  return (
    <View style={style}>
      <Svg width={width} height={height} viewBox="0 0 52.43 52.44">
        <G data-name="Zoom -">
          <G data-name="Groupe 136">
            <Path
              fill={color ?? defaultColor}
              d="M29.75 29.75h6.47v-3.24H20v3.24z"
              transform="translate(-8.78 -8.78)"
            />
          </G>
          <Path
            fill={color ?? defaultColor}
            d="M42.92 40.57l.87-1.21a19.32 19.32 0 10-4.45 4.44l1.2-.86 18.33 18.28 2.35-2.35zm-3.23-.92a16.22 16.22 0 01-11.52 4.79 16.29 16.29 0 1111.56-4.79z"
            transform="translate(-8.78 -8.78)"
          />
        </G>
      </Svg>
    </View>
  )
}

export default ZoomOutIcon
