import { PageName } from '@taktik/src/Pages/PageInfo'
import {
  TOGGLE_PRACTICE_MODAL,
  TOGGLE_LOADING_MODAL,
  TOGGLE_ALERT_STUDY_TIME_MODAL,
  TOGGLE_INVITATION_MODAL,
  UPDATE_INVITATION_MODAL_INFORMATION,
  TOGGLE_REGISTRATION_MODAL,
} from '../actionTypes'

export interface ModalsState {
  practiceChoiceModalVisible: boolean
  loadingModalVisible: boolean
  destinationPage: PageName
  alertStudyDurationVisible: boolean
  invitationModalIsVisible: boolean
  registrationModalIsVisible: boolean
  invitationModalCoachID: number
  invitationModalTeamID: number
}

const initialState: ModalsState = {
  practiceChoiceModalVisible: false,
  loadingModalVisible: false,
  destinationPage: PageName.Home,
  alertStudyDurationVisible: false,
  invitationModalIsVisible: false,
  registrationModalIsVisible: false,
  invitationModalCoachID: -1,
  invitationModalTeamID: -1,
}

export default function toggleModal(state = initialState, action: any): ModalsState {
  switch (action.type) {
    case TOGGLE_PRACTICE_MODAL: {
      return { ...state, practiceChoiceModalVisible: action.payload.toggle }
    }
    case TOGGLE_LOADING_MODAL: {
      return {
        ...state,
        loadingModalVisible: action.payload.toggle,
        destinationPage: action.payload.destinationPage,
      }
    }
    case TOGGLE_ALERT_STUDY_TIME_MODAL: {
      return { ...state, alertStudyDurationVisible: action.payload.toggle }
    }
    case TOGGLE_INVITATION_MODAL: {
      return { ...state, invitationModalIsVisible: action.payload.toggle }
    }
    case TOGGLE_REGISTRATION_MODAL: {
      return { ...state, registrationModalIsVisible: action.payload.toggle }
    }
    case UPDATE_INVITATION_MODAL_INFORMATION: {
      return {
        ...state,
        invitationModalCoachID: action.payload.coachID,
        invitationModalTeamID: action.payload.teamID,
      }
    }
    default:
      return state
  }
}
