import React from 'react'
import { View, StyleProp } from 'react-native'
import { Svg, Path } from 'react-native-svg'
import { getColorAndWidth } from './Utilities'

interface RightArrowIconProps {
  style?: StyleProp<any>
}

const RightArrowIcon = ({ style }: RightArrowIconProps) => {
  const defaultColor: string = '#000'
  const defaultWidth: number = 47.6

  const { color, width } = getColorAndWidth(style)

  const height: number = (12.2 / defaultWidth) * width
  return (
    <View style={style}>
      <Svg width={width} height={height} viewBox="0 0 47.6 12.2">
        <Path
          fill={color ?? defaultColor}
          d="M41.5 0l-1.4 1.4 4.1 4.1H0v2h43.4l-3.3 3.2 1.4 1.5 6.1-6.1z"
        />
      </Svg>
    </View>
  )
}

export default RightArrowIcon
