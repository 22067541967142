import { StyleSheet, ScaledSize } from 'react-native'
import { StyledComponentsTheme } from '../Theme'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/TeamSelectionRatios'
import { StyleParameters } from '../Utilities'

export interface StatsPageStyle {}

export function teamSelectionStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  teamColor: string,
  _rowSize: number,
  styleParameters: StyleParameters
) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(
    styleParameters.screenWidth,
    styleParameters.screenHeight,
    styleParameters.isMobile,
    styleParameters.isHorizontal
  )
  const logoSize = screenRatio.logoSize
  return StyleSheet.create({
    page: {
      height: screenRatio.Page_height,
      width: screenRatio.Page_width,
      alignSelf: 'center',
    },
    backgroundImage: {
      opacity: 1,
      height: screenRatio.Page_height,
      width: screenRatio.Page_width,
      justifyContent: 'center',
    },
    title: {
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.title_fontSize,
      color: theme.textColors.primary,
      textTransform: 'uppercase',
      letterSpacing: 0,
      textAlign: 'center',
    },
    loaderContainer: {
      height: 50,
      width: 50,
    },
    choiceModal: {
      backgroundColor: theme.colors.primary,
      width: '80%',
      height: screenRatio.choiceModal_height,
      alignSelf: 'center',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderRadius: 15,
    },
    teamsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      width: '100%',
      height: '40%',
    },
    teamContainer: {
      position: 'relative',
      alignItems: 'center',
      width: logoSize * 1.5,
      marginTop: screenRatio.teamContainer_marginTop,
    },
    teamWrapper: {
      flexDirection: 'column-reverse',
    },
    teamLogoContainer: {
      marginTop: screenRatio.teamLogoContainer_marginTop,
      backgroundColor: theme.colors.primary,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    teamLogo: {
      width: logoSize,
      height: logoSize,
      resizeMode: 'contain',
    },
    teamNameContainer: {
      flexDirection: 'row',
      paddingTop: '10%',
      width: '80%',
      justifyContent: 'center',
    },
    teamName: {
      textAlign: 'center',
      fontFamily: 'barlow-italic',
      fontSize: screenRatio.teamName_fontSize,
      color: theme.textColors.primary,
      textTransform: 'uppercase',
      letterSpacing: 0,
    },
    loader: {
      color: teamColor,
    },
    manageMyTeam: {
      textAlign: 'center',
      fontFamily: 'barlow-italic',
      fontSize: screenRatio.manageMyTeam_fontSize,
      color: theme.textColors.primary,
      textTransform: 'uppercase',
      letterSpacing: 0,
    },
    trashIcon: {
      color: theme.textColors.primary,
      transform: [{ skewX: '13deg' }],
      marginLeft: screenRatio.trashIcon_marginLeft,
      marginTop: screenRatio.trashIcon_marginTop,
      fontSize: screenRatio.trashIcon_size,
    },
    trashIconContainer: {
      position: 'absolute',
      marginTop: screenRatio.trashIconContainer_marginTop,
      marginLeft: screenRatio.trashIconContainer_marginLeft,
      width: screenRatio.trashIconContainer_width,
      height: screenRatio.trashIconContainer_height,
      backgroundColor: theme.colors.secondary,
      transform: [{ skewX: '-13deg' }],
    },
  })
}
