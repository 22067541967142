import React, { Component } from 'react'
import { NavigationInterface } from './PageInfo'
import { Dimensions, Image, Text, View, TouchableOpacity } from 'react-native'
import { getIdentificationNameStyles, updateStyleParameters } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import translator from '@taktik/config/Translation/languages'
import { Playbook } from '../DataTypes/playbooks'
import { getScreenRatioStyle } from '../Styles/Peculiar/IdentificationNameRatiosStyles'
import { logger } from '../../lib/logger'
import { DateTime } from 'luxon'
import { axiosPostResult } from '../Utilitary/DataUtils'
import { RootState } from '../redux/reducers'
import { connect } from 'react-redux'
import { User, UserRole } from '../DataTypes/UserTypes'
import { ScrollView } from 'react-native-gesture-handler'

interface SnapshotInfo {
  snapshot: string
  width: number
  height: number
}

interface IdentificationNameProps extends NavigationInterface {
  correctAnswerIndex: number
  playbooks: Playbook[]
  snapshotInfos: SnapshotInfo[]
  exerciseID: number
  startTime: DateTime
  callback: (isExerciseSucceeded: boolean) => void
  beginSession: boolean
  user: User
}

interface IdentificationNameState {
  styleParameters: StyleParameters
  selectedPlaybookIndex: number
  answerWasSubmitted: boolean
  gotCorrectAnswer: boolean
  canConfirmSelection: boolean
  warningText: string
}

class IdentificationName extends Component<IdentificationNameProps, IdentificationNameState> {
  constructor(props: IdentificationNameProps) {
    super(props)
    this.state = {
      selectedPlaybookIndex: -1,
      answerWasSubmitted: false,
      canConfirmSelection: true,
      gotCorrectAnswer: false,
      styleParameters: getStyleParameters(),
      warningText: '',
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  renderPromptNamePick = () => {
    return (
      <Text
        adjustsFontSizeToFit
        style={getIdentificationNameStyles(this.state.styleParameters).promptText}
      >{`${translator.t('gamification.promptNamePick').toUpperCase()}`}</Text>
    )
  }

  renderPromptBar = () => {
    const styleParameters = this.state.styleParameters
    return (
      <View style={getIdentificationNameStyles(styleParameters).promptContainer}>
        <View style={getIdentificationNameStyles(styleParameters).prompt}>
          {this.renderPromptNamePick()}
        </View>
      </View>
    )
  }
  componentDidMount() {
    Dimensions.addEventListener('change', this.onChange)
  }
  getSnapshotInfo = (snapshot: string): SnapshotInfo => {
    const { snapshotInfos } = this.props

    const snapshotInfo: SnapshotInfo | undefined = snapshotInfos.find(
      (snapSize) => snapSize.snapshot === snapshot
    )

    if (!snapshotInfo) {
      logger.warn('Undefined snapshot was read')
    }
    return snapshotInfo!
  }

  renderPlaybook = (snapshot: string) => {
    if (snapshot === '') {
      return null
    }
    const styleParameters = this.state.styleParameters
    const availableWidth: number = getScreenRatioStyle(styleParameters).PlaybookImage_width
    const availableHeight: number =
      getScreenRatioStyle(styleParameters).playbookImageContainer_height

    return (
      <View
        style={{
          width: availableWidth,
          height: availableHeight,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image
          resizeMethod="scale"
          source={{
            width: availableWidth,
            height: availableHeight,
            uri: snapshot === '' ? undefined : snapshot,
          }}
          style={[
            {
              alignSelf: 'center',
              alignItems: 'center',
            },
          ]}
        />
      </View>
    )
  }

  renderContainedNameButton = (name: string, playbookIndex: number) => {
    return (
      <View
        key={name}
        style={getIdentificationNameStyles(this.state.styleParameters).namesButtonsContainer}
      >
        {this.renderNameButton(name, playbookIndex)}
      </View>
    )
  }

  alreadySendAnswerControl = (playbookIndex: number) => {
    this.setState({ selectedPlaybookIndex: playbookIndex })
  }

  getNameButtonDisplay = (name: string, index: number) => {
    const {
      styleParameters,
      answerWasSubmitted: mustShowIfCorrect,
      selectedPlaybookIndex,
    } = this.state
    const { correctAnswerIndex } = this.props
    let style: any
    const isCorrectAnswer: boolean = mustShowIfCorrect && index === correctAnswerIndex
    const isCorrectAnswerButNotPickedOne: boolean =
      mustShowIfCorrect && index === selectedPlaybookIndex && index !== correctAnswerIndex
    if (isCorrectAnswer) {
      style = getIdentificationNameStyles(styleParameters).successFailureNameText
    } else if (isCorrectAnswerButNotPickedOne) {
      style = getIdentificationNameStyles(styleParameters).successFailureNameText
    } else if (index === selectedPlaybookIndex) {
      style = getIdentificationNameStyles(styleParameters).nameTextSelected
    } else {
      style = getIdentificationNameStyles(styleParameters).nameText
    }
    return (
      <Text adjustsFontSizeToFit style={style}>
        {name}
      </Text>
    )
  }

  renderNameButton = (name: string, playbookIndex: number) => {
    const {
      styleParameters,
      answerWasSubmitted: mustShowIfCorrect,
      selectedPlaybookIndex,
    } = this.state
    const { correctAnswerIndex } = this.props
    let style: object
    const isCorrectAnswer: boolean = mustShowIfCorrect && playbookIndex === correctAnswerIndex
    const isCorrectAnswerButNotPickedOne: boolean =
      mustShowIfCorrect &&
      playbookIndex === selectedPlaybookIndex &&
      playbookIndex !== correctAnswerIndex
    if (isCorrectAnswer) {
      style = getIdentificationNameStyles(styleParameters).successNameButton
    } else if (isCorrectAnswerButNotPickedOne) {
      style = getIdentificationNameStyles(styleParameters).failureNameButton
    } else if (playbookIndex === selectedPlaybookIndex) {
      style = getIdentificationNameStyles(styleParameters).nameButtonSelected
    } else {
      style = getIdentificationNameStyles(styleParameters).nameButton
    }
    return (
      <View style={style}>
        <TouchableOpacity
          style={{ width: '100%', height: '100%', justifyContent: 'center' }}
          onPress={() => {
            this.alreadySendAnswerControl(playbookIndex)
          }}
        >
          {this.getNameButtonDisplay(name, playbookIndex)}
        </TouchableOpacity>
      </View>
    )
  }

  renderPlaybookNamesButtons = () => {
    const { styleParameters } = this.state
    const { playbooks } = this.props
    const namesButtonsRow: JSX.Element[] = []
    for (let playbookID = 0; playbookID < playbooks.length; playbookID = playbookID + 1) {
      const name: string = playbooks[playbookID].title.toUpperCase()
      namesButtonsRow.push(this.renderContainedNameButton(name, playbookID))
    }
    return (
      <View style={getIdentificationNameStyles(styleParameters).nameZone}>{namesButtonsRow}</View>
    )
  }

  handleConfirm = () => {
    const { answerWasSubmitted, selectedPlaybookIndex } = this.state
    const { correctAnswerIndex, playbooks, exerciseID, startTime, beginSession, user } = this.props
    const playbookSelected = selectedPlaybookIndex !== -1
    const canSubmit: boolean = !answerWasSubmitted && playbookSelected
    if (canSubmit) {
      const endTime: DateTime = DateTime.local()
      let completionTime: number = endTime.diff(startTime).milliseconds
      const selectedPlaybookID: number = playbooks[selectedPlaybookIndex].id
      const gotCorrectAnswer = selectedPlaybookIndex === correctAnswerIndex
      this.setState({
        gotCorrectAnswer: gotCorrectAnswer,
        answerWasSubmitted: !answerWasSubmitted,
        canConfirmSelection: false,
      })
      if (beginSession) {
        completionTime = completionTime - 3000
      }
      if (completionTime < 0) {
        completionTime = 0
      }
      if (user.role !== UserRole.Coach) {
        // Do not submit exercice results as a coach
        axiosPostResult(exerciseID, completionTime, selectedPlaybookID)
      }
      this.props.callback(gotCorrectAnswer)
    } else if (!playbookSelected) {
      this.setState({ warningText: translator.t('gamification.selectWarningNameIdentification') })
    }
  }

  renderConfirmBar = () => {
    const { styleParameters, warningText } = this.state
    const confirmButtonStyle = getIdentificationNameStyles(styleParameters).confirmButton

    return (
      <View style={getIdentificationNameStyles(styleParameters).confirmButtonContainer}>
        <Text style={getIdentificationNameStyles(styleParameters).warningText}>{warningText}</Text>
        <View style={confirmButtonStyle}>
          <TouchableOpacity
            style={{ justifyContent: 'center', width: '100%', height: '100%' }}
            onPress={() => {
              this.handleConfirm()
            }}
          >
            {this.getConfirmButtonMessage()}
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  getConfirmButtonMessage = () => {
    const styleParameters = this.state.styleParameters
    return (
      <Text
        adjustsFontSizeToFit
        style={getIdentificationNameStyles(styleParameters).confirmText}
      >{`${translator.t('global.validateAnswer').toUpperCase()}`}</Text>
    )
  }

  renderPlaybookNamesIdentification = () => {
    const { styleParameters } = this.state
    const { correctAnswerIndex, playbooks } = this.props
    return (
      <View style={getIdentificationNameStyles(styleParameters).gameContainer}>
        <View style={getIdentificationNameStyles(styleParameters).playbookImageContainer}>
          {this.renderPlaybook(playbooks[correctAnswerIndex].snapshot)}
        </View>
        <View style={getIdentificationNameStyles(styleParameters).buttonsAreaContainer}>
          {this.renderPlaybookNamesButtons()}
          {this.renderConfirmBar()}
        </View>
      </View>
    )
  }

  render() {
    const { styleParameters } = this.state

    const content: JSX.Element = (
      <>
        {this.renderPromptBar()}
        {this.renderPlaybookNamesIdentification()}
      </>
    )

    return (
      <View style={getIdentificationNameStyles(styleParameters).component}>
        {styleParameters.isMobile ? (
          <ScrollView alwaysBounceVertical={false} style={{ height: '100%' }}>
            {content}
          </ScrollView>
        ) : (
          content
        )}
      </View>
    )
  }
}
const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(IdentificationName)
