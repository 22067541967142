import { StyleSheet, ScaledSize } from 'react-native'
import { GlobalColors, StyledComponentsTheme } from '../Theme'
import { StyleParameters } from '../Utilities'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/PlaybooksRatiosStyle'

export default function playbookStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  teamColor: string,
  _rowSize: number,
  styleParameters: StyleParameters
) {
  const { isHorizontal, isMobile } = styleParameters
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(styleParameters)

  return StyleSheet.create({
    playbookList: {
      paddingVertical: screenRatio.PlaybookList_paddingVertical,
      flex: 1,
      alignItems: 'center',
      marginHorizontal: screenRatio.PlaybookList_marginHorizontal,
    },

    groupContainer: {
      width: '90%',
      marginLeft: screenRatio.PlaybookListGroupContainer_marginLeft,
    },
    groupContent: {
      width: '90%',
      flexDirection: 'column',
      marginLeft: screenRatio.PlaybookListGroupContent_marginLeft,
      alignContent: 'center',
      justifyContent: 'flex-start',
      paddingBottom: isHorizontal && isMobile ? '5%' : '10%',
      minHeight: 200,
    },
    groupBackTitle: {
      fontFamily: 'barlow-extra',
      fontSize: screenRatio.GroupTitleBackground_fontSize,
      textTransform: 'uppercase',
      position: 'absolute',
      bottom: 0,
      right: screenRatio.GroupTitleBackground_Right,
      paddingRight: 0,
      maxHeight: '100%',
      textAlign: 'right',
      color: theme.colors.secondary,
      zIndex: -1100,
    },
    horizontalLine: {
      width: isMobile && !isHorizontal ? '87%' : '90%',
      marginLeft: screenRatio.PlaybookListLine_marginLeft,
      marginTop: 5,
      borderColor: theme.textColors.tabHeader,
      borderTopWidth: isMobile ? 1 : 2,
      flexDirection: 'column',
    },

    BackArrowContainer: {
      marginLeft: screenRatio.PlaybookGroupBackArrow_marginLeft,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      marginBottom: '1%',
      marginTop: '2%',
    },
    BackArrowIcon: {
      width: screenRatio.PlaybookGroupBackArrow_width,
      color: teamColor,
      transform: [{ rotate: '180deg' }],
    },
    FolderBackTitle: {
      color: theme.textColors.primary,
      flexDirection: 'row',
      marginVertical: '1%',
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.PlaybookGroupBackTitle_fontSize,
    },

    snapshotContainer: {
      width: screenRatio.FolderPlaybookContainer_width,
    },
    PlaybookGroupContainer: {
      flexDirection: 'column',
      width: screenRatio.PlaybookGroupContainer_width,
    },
    playbookFolderImage: {
      color: theme.colors.secondary,
      fontSize: screenRatio.PlaybookFolderIcon_fontSize,
      width: screenRatio.PlaybookFolderIcon_fontSize,
    },

    playbookPreviewImage: {
      width: screenRatio.FolderPlaybookSnapshot_width,
      height: screenRatio.FolderPlaybookSnapshot_height,
    },
    playbookStrongText: {
      color: theme.textColors.primary,
      textAlign: 'left',
      fontFamily: 'barlow-extra-italic',
    },
    playbookPreviewTitle: {
      flex: 1,
      color: teamColor,
      alignSelf: 'flex-start',
      flexWrap: 'wrap',
      flexDirection: 'row',
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.PlaybookGroupPlaybookTitle_fontSize,
      width: screenRatio.PlaybookGroupContainer_width / screenRatio.PlaybookPerRow,
      marginTop: screenRatio.PlaybookGroupPlaybookTitle_marginTop,
      marginBottom: '1%',
    },
    folderPreviewTitle: {
      flex: 1,
      color: teamColor,
      alignSelf: 'flex-start',
      flexWrap: 'wrap',
      flexDirection: 'row',
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.PlaybookGroupFolderTitle_fontSize,
      paddingLeft: screenRatio.PlaybookGroupFolderTitle_paddingLeft,
    },

    FolderContainer: {
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      paddingTop: screenRatio.PlaybookGroupPlaybookTitle_marginTop,
    },
    folderQuantityTextContainer: {
      position: 'absolute',
      top: screenRatio.PlaybookFolderQuantity_top - screenRatio.PlaybookFolderQuantity_fontSize / 4,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    folderQuantityText: {
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.PlaybookFolderQuantity_fontSize,
      color: theme.textColors.tabHeader,
    },
    pageTitle: {
      fontSize: screenRatio.PlaybookListTitle_fontSize,
      marginTop: '2%',
      marginLeft:
        screenRatio.PlaybookListTitle_marginLeft + screenRatio.PlaybookList_marginHorizontal,
      color: theme.textColors.primary,
    },
    legacyTitle: {
      fontSize: screenRatio.PlaybookListLegacy_fontSize,
      marginTop: 5,
      marginLeft: isMobile ? '2%' : '0.5%',
      color: theme.textColors.primary,
    },
    groupTitleIcon: {
      fontSize: screenRatio.PlaybookListCaret_fontSize,
      marginTop: isMobile ? '30%' : '50%',
      color: teamColor,
    },
    foldersContainer: {
      marginLeft: screenRatio.PlaybookGroupFoldersContainer_marginLeft,
      flexDirection: 'row',
    },
    playbooksContainer: {
      marginTop: isMobile ? 5 : 0,
      marginLeft: screenRatio.PlaybookGroupPlaybooksContainer_marginLeft,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    playbookPageContainer: {
      paddingVertical: 30,
      backgroundColor: theme.colors.primary,
      alignSelf: 'center',
    },

    playbookText: { color: theme.textColors.primary },
    ModalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: styleParameters.screenWidth,
      height: styleParameters.screenHeight,
      backgroundColor: theme.colors.popUpBackground,
    },
    ModalAlertContainer: {
      alignSelf: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.primary,
      marginTop: screenRatio.ModalAlertContainer_marginTop,
      width: screenRatio.ModalAlertContainer_width,
      borderRadius: screenRatio.ModalContainer_borderRadius,
    },
    ModalAlertTitleContainer: {
      flexDirection: 'row',
    },
    ModalAlertTitle: {
      marginTop: screenRatio.ModalAlertTitle_marginTop,
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalAlertTitle_fontSize,
    },
    ModalAlertQuestion: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      textAlign: 'center',
      fontSize: screenRatio.ModalAlertQuestion_fontSize,
      marginTop: screenRatio.ModalAlertQuestion_marginTop,
    },
    ModalAlertSubtext: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      textAlign: 'center',
      fontSize: screenRatio.ModalAlertSubtext_fontSize,
    },
    ModalAlertButtonContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '60%',
      marginBottom: screenRatio.ModalAlertButtonContainer_marginBottom,
      marginTop: screenRatio.ModalAlertButtonContainer_marginTop,
    },
    ModalAlertButtonContainerLarge: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '80%',
      marginBottom: screenRatio.ModalAlertButtonContainer_marginBottom,
      marginTop: screenRatio.ModalAlertButtonContainer_marginTop,
    },
    alertButtonSecondary: {
      justifyContent: 'center',
      width: screenRatio.ModalAlertButtonYes_width,
      height: screenRatio.ModalAlertButton_height,
      backgroundColor: theme.colors.secondary,
      transform: [{ skewX: '-10deg' }],
    },
    alertButtonYes: {
      justifyContent: 'center',
      width: screenRatio.ModalAlertButtonYes_width,
      height: screenRatio.ModalAlertButton_height,
      backgroundColor: teamColor,
      transform: [{ skewX: '-10deg' }],
    },
    alertButtonTextYes: {
      alignSelf: 'center',
      color: GlobalColors.white,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.ModalAlertButtonText_fontSize,
      marginHorizontal: '5%',
      transform: [{ skewX: '10deg' }],
    },
    alertButtonNo: {
      justifyContent: 'center',
      width: screenRatio.ModalAlertButtonNo_width,
      height: screenRatio.ModalAlertButton_height,
      backgroundColor: theme.colors.secondary,
      transform: [{ skewX: '-10deg' }],
    },
    alertButtonTextNo: {
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.ModalAlertButtonText_fontSize,
      marginHorizontal: '5%',
      transform: [{ skewX: '10deg' }],
    },
  })
}
