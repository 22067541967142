import React, { Component } from 'react'
import { Image, TouchableOpacity, View } from 'react-native'
import { launchImageLibraryAsync, MediaTypeOptions } from 'expo-image-picker'
import { getRegistrationStyles } from '../Styles/AppStyle'
import { StyleParameters } from '../Styles/Utilities'
import { MaterialIcons } from '@expo/vector-icons'

interface ImagePickerProps {
  pickerImageUri?: string
  styleParameters: StyleParameters
  onSelectImage: (imageUri: string) => void
}

class ImagePicker extends Component<ImagePickerProps> {
  pickImage = async () => {
    const { onSelectImage } = this.props

    const imageResult = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    })
    if (!imageResult.cancelled) {
      onSelectImage(imageResult.uri)
    }
  }

  render() {
    const { pickerImageUri, styleParameters } = this.props
    const styles = getRegistrationStyles(styleParameters)

    const imageSrc = pickerImageUri
      ? { uri: pickerImageUri }
      : require('../../assets/profile-picture-placeholder.png')

    return (
      <View style={{ position: 'relative' }}>
        <TouchableOpacity style={styles.profilPictureContainer} onPress={this.pickImage}>
          <Image style={styles.profilPicture} source={imageSrc} />
          <MaterialIcons name="camera-alt" style={styles.camera} color="#b2b2b2" />
        </TouchableOpacity>
      </View>
    )
  }
}

export default ImagePicker
