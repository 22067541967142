import translator from '@taktik/config/Translation/languages'
import React, { Component } from 'react'
import { PageName, NavigationInterface } from './PageInfo'
import { View, Text, TouchableOpacity, Dimensions } from 'react-native'
import { getTopBarMenuStyles, updateStyleParameters } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { logout } from '../Services/AuthService'
import {
  axiosGetEvaluations,
  axiosGetGroups,
  axiosGetSubscriptionInfo,
  fetchUser,
} from '../Utilitary/DataUtils'
import {
  connectedToInternet,
  getLocalUserInfo,
  storeLocalUserInfo,
} from '../Utilitary/ConnectionUtils'
import { connect } from 'react-redux'
import {
  togglePracticeModal,
  toggleLoadingModal,
  updateSelectedGroups,
  updateSelectedEvaluations,
  updateUserInfo,
  setSelectedToken,
  setSelectedTeam,
  playbooksLoad,
  toggleImageLoad,
} from '../redux/actions'
import { Modal } from '../components/ModalImport'
import { logger } from '../../lib/logger'
import TaktikIcon from '../../assets/svg/TaktikIcon'
import { PlayerGroup } from '../DataTypes/GroupTypes'
import { ScrollView } from 'react-native-gesture-handler'
import { RootState } from '../redux/reducers'
import { store } from '../redux/store'
import { defaultUser, User, UserRole } from '../DataTypes/UserTypes'
import NetInfo from '@react-native-community/netinfo'
import CancelIcon from '../../assets/svg/CancelIcon'
import HamburgerIcon from '../../assets/svg/HamburgerIcon'
import RightArrowIcon from '../../assets/svg/RightArrowIcon'
import LoadingModal from '../components/LoadingModal'
import * as Localization from 'expo-localization'
import PartialTaktikIcon from '../../assets/svg/PartialTaktikIcon'
import { StoreNames, storageGetAllItems } from '../Utilitary/StorageUtils'
import { synchronizeStudyTimes } from '../Services/StudyTimeService'
import { Locale } from '@taktik/config/Translation/locale'
import { userIsCoachAlert } from '../Utilitary/Utils'
import { getUserRoleTranslation, GroupNames } from '@taktik/config/Translation/utilities'
import { AntDesign } from '@expo/vector-icons'
import { TeamInfos, TokenArray } from '../redux/reducers/teams'
import TeamOption from '../components/TeamOption'
import { TaktikAlert } from '../Utilitary/Alert'

export const orientationIOS = [
  'portrait',
  'portrait-upside-down',
  'landscape',
  'landscape-left',
  'landscape-right',
]

export interface Evaluation {
  id: number
  parentId: number
  name: string
  isAlwaysAvailable: boolean
  playbookIDs: string
  startTime: Date | null
  endTime: Date | null
  teamID: number
}

export const minimumNumberOfPlaysForGamification: number = 6

interface TopBarMenuProps extends NavigationInterface {
  url: PageName
  updatePage: () => void
  userMustBeLoggedOut: boolean
  pageTitle?: string
  practiceChoiceModalVisible: boolean
  user: User
  isLoading: boolean
  isGamificationPage?: boolean
  onGoBack?: (navigationPage: string) => void
  playbookTitle?: string
  firstPlaybookIndex?: number
  playbookIDArray?: number[]
  playbooks: any[]
  playbookFolders: any[]
  teamsInfos: TeamInfos[]
  teamID: number
  tokens: TokenArray[]
}

interface TopBarMenuState {
  hasTaktikPlaySubscription: boolean
  menuModalVisible: boolean
  styleParameters: StyleParameters
  mustShowBackButton: boolean
  parentGroups: PlayerGroup[]
  childGroups: PlayerGroup[]
  childEvaluations: Evaluation[]
  practiceGroupsIsUpdated: boolean
  isConnectedToInternet: boolean
  alertBackArrowModalVisible: boolean
  currentIndex: number
  groupMustBeDisplayed: { offense: boolean; defense: boolean; special: boolean }
  isTeamSelectionShowing: boolean
  isPracticeChoiceUpdated: boolean
  teams: TeamInfos[]
  mustShowDeleteIcon: boolean
  mustShowManageButton: boolean
  mustShowFinishButton: boolean
  evaluationPlayBooksIds: number[]
  isOkToDeleteAssociation: boolean
}

class TopBarMenu extends Component<TopBarMenuProps, TopBarMenuState> {
  public practiceGroupsSelected: number[] = []
  public practiceEvaluationsSelected: number[] = []
  constructor(props: TopBarMenuProps) {
    super(props)
    this.state = {
      evaluationPlayBooksIds: [],
      mustShowManageButton: true,
      mustShowFinishButton: false,
      hasTaktikPlaySubscription: false,
      menuModalVisible: false,
      mustShowDeleteIcon: false,
      mustShowBackButton: this.mustShowBackButton(this.props.url),
      styleParameters: getStyleParameters(),
      parentGroups: [],
      childGroups: [],
      childEvaluations: [],
      practiceGroupsIsUpdated: false,
      isConnectedToInternet: false,
      alertBackArrowModalVisible: false,
      isPracticeChoiceUpdated: false,
      currentIndex: this.props.firstPlaybookIndex ? this.props.firstPlaybookIndex : 0,
      groupMustBeDisplayed: { offense: false, defense: false, special: false },
      isTeamSelectionShowing: false,
      teams: this.props.teamsInfos,
      isOkToDeleteAssociation: false,
    }
    if (this.props.teamID) {
      this.updateTaktikPlaySubscriptionStatus()
      getLocalUserInfo().then((userInfo: User) => {
        if (this.mustDisplayUser(this.props.url) && userInfo !== null) {
          connectedToInternet().then((isConnected) => {
            if (isConnected) {
              this.updateFromUserInfo_OnlineMode(userInfo, this.props.teamID)
              this.checkIfGroupMustBeDisplayed()
            } else {
              this.updateFromUserInfo_OfflineMode(userInfo)
            }
          })
        } else {
          store.dispatch(updateUserInfo(defaultUser))
        }
      })
    }
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    // This is temporary (HomePage rerenders when its not used)
    const shouldTeamUpdate = nextProps.teamID !== this.props.teamID
    if (shouldTeamUpdate) {
      this.updateTaktikPlaySubscriptionStatus()

      getLocalUserInfo().then((userInfo: User) => {
        if (this.mustDisplayUser(this.props.url) && userInfo !== null) {
          connectedToInternet().then((isConnected) => {
            if (isConnected) {
              this.updateFromUserInfo_OnlineMode(userInfo, nextProps.teamID)
            } else {
              this.updateFromUserInfo_OfflineMode(userInfo)
            }
          })
        } else {
          store.dispatch(updateUserInfo(defaultUser))
        }
      })
      return true
    }

    // const propsHaveChanged = JSON.stringify(this.props) !== JSON.stringify(nextProps)
    const {
      practiceChoiceModalVisible,
      isLoading,
      isGamificationPage,
      playbookTitle,
      tokens,
      teamsInfos,
    } = this.props

    const interestingPropsHaveChange =
      practiceChoiceModalVisible !== nextProps.practiceChoiceModalVisible ||
      isLoading !== nextProps.isLoading ||
      isGamificationPage !== nextProps.isGamificationPage ||
      playbookTitle !== nextProps.playbookTitle ||
      tokens !== nextProps.tokens ||
      teamsInfos !== nextProps.teamInfos

    const stateHasChanged = JSON.stringify(this.state) !== JSON.stringify(nextState)
    if (stateHasChanged || interestingPropsHaveChange) {
      return true
    } else {
      return false
    }
  }

  updateFromUserInfo_OnlineMode = (userInfoRes: any, teamID: number) => {
    fetchUser(userInfoRes.id).then((userInfo) => {
      if (userInfo !== null && teamID !== null) {
        userInfo.user.teamID = teamID
        store.dispatch(updateUserInfo(userInfo.user))
        this.handleGetGroupsEvaluations(userInfoRes, teamID)
        this.setAppLanguage(userInfo.user.language)
        storeLocalUserInfo(userInfo.user)
      } else {
        store.dispatch(updateUserInfo(defaultUser))
      }
      this.checkIfGroupMustBeDisplayed()
    })
  }

  checkIfGroupMustBeDisplayed = () => {
    const { playbooks, playbookFolders } = this.props
    const { childEvaluations, childGroups, parentGroups } = this.state

    try {
      // Using these props/state values to check if any data has loaded, otherwise `checkIfGroupMustBeDisplayed()`
      // will always return false values (no playbooks).
      const infoHasNotBeenLoaded: boolean =
        (childEvaluations.length === 0 && childGroups.length === 0 && parentGroups.length === 0) ||
        playbooks.length === 0 // The user could also "truly" have access to 0 playbooks, in which
      // case this check will always run (because `isPracticeChoiceUpdated` = false) and will slow down the application somewhat. But with no playbooks,
      // what is there to do and slow down in the application anyway?

      if (infoHasNotBeenLoaded) {
        return
      }
    } catch (ex) {
      return
    }

    let offenseCount = 0
    let defenseCount = 0
    let specialCount = 0
    const mustShowGroup = { offense: false, defense: false, special: false }
    for (let playbookIndex = 0; playbookIndex < playbooks.length; playbookIndex++) {
      const playbook = playbooks[playbookIndex]
      if (playbook.group) {
        switch (playbook.group) {
          case GroupNames.Offense:
            offenseCount += 1
            break
          case GroupNames.Defense:
            defenseCount += 1
            break
          case GroupNames.Special:
            specialCount += 1
        }
      } else {
        const folder = playbookFolders.filter((f) => {
          if (f.id === playbook.folderID) {
            return true
          } else {
            return false
          }
        })[0]
        if (folder) {
          switch (folder.group) {
            case GroupNames.Offense:
              offenseCount += 1
              break
            case GroupNames.Defense:
              defenseCount += 1
              break
            case GroupNames.Special:
              specialCount += 1
          }
        }
      }
    }
    if (offenseCount >= minimumNumberOfPlaysForGamification) {
      mustShowGroup['offense'] = true
    }
    if (defenseCount >= minimumNumberOfPlaysForGamification) {
      mustShowGroup['defense'] = true
    }
    if (specialCount >= minimumNumberOfPlaysForGamification) {
      mustShowGroup['special'] = true
    }
    this.setState({ groupMustBeDisplayed: mustShowGroup, isPracticeChoiceUpdated: true })
  }
  updateFromUserInfo_OfflineMode = (userInfoRes: any) => {
    store.dispatch(updateUserInfo(userInfoRes))
    this.setAppLanguage(userInfoRes.language)
  }

  setAppLanguage(language: string) {
    translator.locale = language ?? Localization.locale

    this.props.updatePage()
  }

  componentDidMount() {
    this.checkForForcedLogout()
    this.updateTaktikPlaySubscriptionStatus()
    store.dispatch(togglePracticeModal(false))
    Dimensions.addEventListener('change', this.onChange)
    NetInfo.addEventListener((state) => {
      this.handleInternetConnection(!!state.isConnected)
    })
  }

  componentDidUpdate() {
    const { isPracticeChoiceUpdated } = this.state
    this.checkForForcedLogout()

    if (!isPracticeChoiceUpdated) {
      this.checkIfGroupMustBeDisplayed()
    }
  }

  handleInternetConnection = (isConnected: boolean) => {
    if (this.state.isConnectedToInternet !== isConnected) {
      this.setState({ isConnectedToInternet: isConnected })
    }
  }

  showForcedLogoutWarning() {
    TaktikAlert.alert(translator.t('expiredSubscription'))
  }

  updateTaktikPlaySubscriptionStatus = () => {
    axiosGetSubscriptionInfo()
      .then((response) => {
        if (response) {
          const hasTaktikPlaySubsciption: boolean = response.data.subscriptionType === 'taktik-play'

          this.setState({ hasTaktikPlaySubscription: hasTaktikPlaySubsciption })
        }
      })
      .catch((err) => {
        logger.warn(err)
      })
  }

  forceLogout() {
    this.showForcedLogoutWarning()
    logout()
  }

  checkForForcedLogout() {
    const { userMustBeLoggedOut } = this.props

    if (userMustBeLoggedOut) {
      this.forceLogout()
    }
  }

  handleGetGroupsEvaluations = (userInfoRes: any, teamID: number) => {
    const uid = userInfoRes.id
    axiosGetGroups(uid, teamID).then((res) => {
      const parents = res.filter((group: PlayerGroup) => group.parentId === null)
      const children = res.filter((group: PlayerGroup) => group.parentId !== null)
      this.setState({ parentGroups: parents, childGroups: children })
      axiosGetEvaluations(uid, teamID).then((res) => {
        const children = []

        if (res) {
          for (let evaluationIndex = 0; evaluationIndex < res.data.length; evaluationIndex++) {
            const evaluation = res.data[evaluationIndex]
            evaluation.parentId = parents.filter((group: any) => {
              return group.groupLegacy === evaluation.group
            })[0].id
            children.push(evaluation)
          }
        }
        this.setState({ childEvaluations: children })

        this.forceUpdate()
      })
    })
  }

  mustShowBackButton = (url: PageName) => {
    const mustShowBackButton: boolean =
      url !== PageName.Home && url !== PageName.Login && url !== PageName.TeamSelectionPage

    return mustShowBackButton
  }

  mustDisplayUser = (url: PageName) => {
    return (
      url !== PageName.Login &&
      url !== PageName.Success &&
      url !== PageName.ForgotPassword &&
      url !== PageName.ChangePassword &&
      url !== PageName.TeamSelectionPage
    )
  }

  mustDisplayHamburger = (url: PageName) => {
    return (
      url !== PageName.Login &&
      url !== PageName.Success &&
      url !== PageName.ForgotPassword &&
      url !== PageName.ChangePassword &&
      url !== PageName.TeamSelectionPage
    )
  }

  mustShowPlayButton = (url: PageName) => {
    const { hasTaktikPlaySubscription } = this.state
    const isOnPlaybookListPage: boolean = url === PageName.PlaybookList

    const mustShowPlayButton: boolean = hasTaktikPlaySubscription && isOnPlaybookListPage

    return mustShowPlayButton
  }

  notConnectedAlert = () => {
    TaktikAlert.alert(
      translator.t('alert.internetAlertTitle'),
      translator.t('alert.internetAlertSubtitle'),
      { text: 'OK' }
    )
  }

  renderHamburgerMenuButton = () => {
    const { styleParameters, menuModalVisible, mustShowBackButton } = this.state
    let onPressFunction: () => void
    let icon: JSX.Element
    const style: object = getTopBarMenuStyles(styleParameters).hamburgerMenuButton

    if (menuModalVisible) {
      onPressFunction = () => this.setState({ menuModalVisible: false })
      icon = (
        <CancelIcon
          style={getTopBarMenuStyles(styleParameters).cancelMenuIcon}
          isTablet={styleParameters.isTablet}
        />
      )
    } else {
      onPressFunction = () => this.setState({ menuModalVisible: true })
      icon = <HamburgerIcon style={getTopBarMenuStyles(styleParameters).hamburgerMenuIcon} />
    }
    return (
      <View
        style={getTopBarMenuStyles(styleParameters, mustShowBackButton).hamburgerButtonContainer}
      >
        <TouchableOpacity style={style} onPress={onPressFunction}>
          {icon}
        </TouchableOpacity>
      </View>
    )
  }

  renderBackButton = () => {
    const { navigation, isGamificationPage, onGoBack } = this.props
    const styleParameters = this.state.styleParameters
    return (
      <View style={getTopBarMenuStyles(styleParameters).backButtonContainer}>
        <TouchableOpacity
          style={getTopBarMenuStyles(styleParameters).backButtonTouchableOpacity}
          onPress={() => {
            if (isGamificationPage && onGoBack) {
              onGoBack('')
            } else {
              navigation.goBack()
            }
          }}
        >
          <RightArrowIcon style={getTopBarMenuStyles(styleParameters).backButtonIcon} />
        </TouchableOpacity>
      </View>
    )
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  renderLanguageOptions = () => {
    const lang = translator.locale.substring(0, 2)
    const styleParameters = this.state.styleParameters
    return (
      <View style={getTopBarMenuStyles(styleParameters).language}>
        <TouchableOpacity
          style={getTopBarMenuStyles(styleParameters).languageButtons}
          disabled={lang === Locale.FR}
          onPress={() => this.setAppLanguage(Locale.FR)}
        >
          <Text
            style={[
              getTopBarMenuStyles(styleParameters).languageText,
              lang === Locale.FR ? getTopBarMenuStyles(styleParameters).selectedLanguageText : {},
            ]}
          >
            FR
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={getTopBarMenuStyles(styleParameters).languageButtons}
          disabled={lang === Locale.EN}
          onPress={() => this.setAppLanguage(Locale.EN)}
        >
          <Text
            style={[
              getTopBarMenuStyles(styleParameters).languageText,
              lang === Locale.EN ? getTopBarMenuStyles(styleParameters).selectedLanguageText : {},
            ]}
          >
            EN
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderLogo = () => {
    return (
      <View style={getTopBarMenuStyles(this.state.styleParameters).logoContainer}>
        <TaktikIcon style={getTopBarMenuStyles(this.state.styleParameters).logo} />
      </View>
    )
  }

  navigateBetweenPlaybook = (isNext: boolean) => {
    const { currentIndex } = this.state
    const { navigation, playbookIDArray } = this.props

    if (playbookIDArray) {
      let index = 0
      if (isNext) {
        index = currentIndex + 1
        if (index >= playbookIDArray.length) {
          index = 0
        }
      } else {
        index = currentIndex - 1
        if (index < 0) {
          index = playbookIDArray.length - 1
        }
      }
      navigation.pop()

      navigation.navigate(PageName.PlaybookPage, {
        playbookID: playbookIDArray[index],
        playbookIDs: playbookIDArray,
      })
    }
  }

  renderPlaybookTitle = () => {
    const { styleParameters } = this.state
    const { playbookIDArray } = this.props
    if (playbookIDArray) {
      return playbookIDArray?.length > 1 ? (
        <View style={getTopBarMenuStyles(styleParameters).playbookTitleContainer}>
          <TouchableOpacity
            onPress={() => this.navigateBetweenPlaybook(false)}
            style={{ alignSelf: 'center' }}
          >
            <AntDesign
              name="left"
              style={getTopBarMenuStyles(styleParameters).playbookNavigationArrow}
            />
          </TouchableOpacity>
          <Text style={[getTopBarMenuStyles(styleParameters).playbookTitle]}>
            {this.props.playbookTitle?.toUpperCase()}
          </Text>
          <TouchableOpacity
            onPress={() => this.navigateBetweenPlaybook(true)}
            style={{ alignSelf: 'center' }}
          >
            <AntDesign
              name="right"
              style={getTopBarMenuStyles(styleParameters).playbookNavigationArrow}
            />
          </TouchableOpacity>
        </View>
      ) : (
        <View style={getTopBarMenuStyles(styleParameters).playbookTitleContainer}>
          <Text style={[getTopBarMenuStyles(styleParameters).playbookTitle]}>
            {this.props.playbookTitle?.toUpperCase()}
          </Text>
        </View>
      )
    }
  }

  renderTeamTitle() {
    const { pageTitle } = this.props
    const styleParameters = this.state.styleParameters
    return (
      <View style={getTopBarMenuStyles(styleParameters).titleContainer}>
        <Text
          style={getTopBarMenuStyles(styleParameters).title}
          selectable={false}
          adjustsFontSizeToFit
        >
          {pageTitle?.toUpperCase() ?? ''}
        </Text>
      </View>
    )
  }

  createAlertLogout() {
    TaktikAlert.alert(
      translator.t('alert.studyTimeLogoutAlert'),
      undefined,
      {
        text: translator.t('global.yes'),
        style: 'destructive',
        onPress: () => {
          this.setState({ menuModalVisible: false })
          logout()
        },
      },
      {
        text: translator.t('cancel'),
        style: 'cancel',
        onPress: () => this.setState({ menuModalVisible: false }),
      }
    )
  }

  AddRemoveAllSubgroup = (subgroupsID: number) => {
    const { practiceGroupsSelected } = this
    const childrenOfParent: PlayerGroup[] = this.state.childGroups.filter(
      (group) => group.parentId === -subgroupsID
    )

    if (practiceGroupsSelected.includes(subgroupsID)) {
      this.RemovePracticeGroup(subgroupsID, false)
      for (const child of childrenOfParent) {
        if (practiceGroupsSelected.includes(child.id)) {
          this.RemovePracticeGroup(child.id, false)
        }
      }
      this.RemovePracticeGroup(-subgroupsID, false)
    } else {
      this.AddPracticeGroup(subgroupsID, false)

      for (const child of childrenOfParent) {
        if (!practiceGroupsSelected.includes(child.id)) {
          this.AddPracticeGroup(child.id, false)
        }
      }

      this.AddPracticeGroup(-subgroupsID, false)
    }
  }

  RemovePracticeGroup = (childID: number, isEvaluation: boolean) => {
    const { practiceGroupsSelected, practiceEvaluationsSelected } = this
    if (isEvaluation) {
      this.practiceEvaluationsSelected = practiceEvaluationsSelected.filter(
        (id: number) => id !== childID
      )
    } else {
      this.practiceGroupsSelected = practiceGroupsSelected.filter((id: number) => id !== childID)
    }
  }

  AddPracticeGroup = (id: number, isEvaluation: boolean) => {
    if (isEvaluation) {
      this.practiceEvaluationsSelected.push(id)
    } else {
      this.practiceGroupsSelected.push(id)
    }
  }

  AddRemovePracticeGroup = (childID: number, subgroupID: number, isEvaluation: boolean) => {
    const { practiceGroupsSelected, practiceEvaluationsSelected } = this

    if (Object.is(subgroupID, -0)) {
      subgroupID = -childID
    }

    if (isEvaluation) {
      if (practiceEvaluationsSelected.includes(childID as never)) {
        this.RemovePracticeGroup(childID, isEvaluation)
      } else {
        this.AddPracticeGroup(childID, isEvaluation)
      }
    } else {
      if (practiceGroupsSelected.includes(childID as never)) {
        this.RemovePracticeGroup(childID, isEvaluation)
        if (practiceGroupsSelected.includes(subgroupID)) {
          this.RemovePracticeGroup(subgroupID, isEvaluation)
        }
      } else {
        this.AddPracticeGroup(childID, isEvaluation)

        const childrenOfParent = this.state.childGroups.filter(
          (group) => group.parentId === -subgroupID
        )

        if (
          childrenOfParent.every((child) => practiceGroupsSelected.includes(child.id)) &&
          practiceGroupsSelected.includes(-subgroupID)
        ) {
          this.AddPracticeGroup(subgroupID, isEvaluation)
        }
      }
    }
  }

  renderAllSubgroup = (parent: PlayerGroup) => {
    const { styleParameters } = this.state
    const isSelected: boolean = this.practiceGroupsSelected.includes(-parent.id)
    return (
      <View
        key={-parent.id}
        style={getTopBarMenuStyles(styleParameters, isSelected).ChildGroupContainer}
      >
        <TouchableOpacity
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
          }}
          onPress={() => {
            this.AddRemoveAllSubgroup(-parent.id)
            this.setState((prevState) => {
              return { ...prevState, practiceGroupsIsUpdated: !prevState.practiceGroupsIsUpdated }
            })
          }}
        >
          <Text style={getTopBarMenuStyles(styleParameters, isSelected).ChildGroupText}>
            {translator.t('gamification.allPlays')}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderChild = (child: Evaluation) => {
    const { styleParameters } = this.state
    const isSelected: boolean = this.practiceEvaluationsSelected.includes(child.id)

    const text: string = child.name

    return (
      <View
        key={child.id}
        style={getTopBarMenuStyles(styleParameters, isSelected).ChildGroupContainer}
      >
        <TouchableOpacity
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
          }}
          onPress={() => {
            this.AddRemovePracticeGroup(child.id, (child.parentId as number) * -1, true)
            this.setState((prevState) => {
              return { ...prevState, practiceGroupsIsUpdated: !prevState.practiceGroupsIsUpdated }
            })
          }}
        >
          <Text style={getTopBarMenuStyles(styleParameters, isSelected).ChildGroupText}>
            {text}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderParentGroup = (children: JSX.Element[], parentName: string, maxChildren: number) => {
    const { styleParameters } = this.state
    return (
      <View key={parentName} style={getTopBarMenuStyles(styleParameters).GroupsColumnContainer}>
        <View style={getTopBarMenuStyles(styleParameters).ParentGroupContainer}>
          <Text style={getTopBarMenuStyles(styleParameters).ParentGroupText}>
            {parentName.toUpperCase()}
          </Text>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          alwaysBounceVertical={false}
          style={getTopBarMenuStyles(styleParameters, false, maxChildren).ChildrenScrollView}
        >
          {children}
        </ScrollView>
      </View>
    )
  }

  handleStartPractice = () => {
    const correctGroups = this.practiceGroupsSelected.filter((group) => group > 0)
    const correctEvaluationsIDs = this.practiceEvaluationsSelected.filter(
      (evaluation) => evaluation > 0
    )
    const correctEvaluations = this.state.childEvaluations
      .filter((evaluation) => {
        return correctEvaluationsIDs.includes(evaluation.id)
      })
      .map((evaluation) => {
        return evaluation.id
      })

    if (this.state.isConnectedToInternet) {
      store.dispatch(togglePracticeModal(false))
      store.dispatch(updateSelectedGroups(correctGroups))
      store.dispatch(updateSelectedEvaluations(correctEvaluations))
      this.props.navigation.navigate(PageName.GamificationPage, {
        beginSession: true,
        selectedEvaluations: correctEvaluations,
      })
      this.practiceGroupsSelected = []
      this.practiceEvaluationsSelected = []
    } else {
      this.notConnectedAlert()
    }
  }

  renderPracticeChoiceModal = () => {
    const { styleParameters, parentGroups, childEvaluations, groupMustBeDisplayed } = this.state
    const { navigation } = this.props
    const parentGroupsContainer: JSX.Element[] = []
    let maxChildren: number = 0
    const noEvaluationAvailable: boolean =
      !groupMustBeDisplayed.offense &&
      !groupMustBeDisplayed.defense &&
      !groupMustBeDisplayed.special

    for (let idParent = 0; idParent < parentGroups.length; idParent++) {
      const childrenContainer: JSX.Element[] = []
      const evaluationChildrens: Evaluation[] = childEvaluations.filter((evaluation) => {
        const notEnoughPlaysID = evaluation.playbookIDs.length < minimumNumberOfPlaysForGamification
        if (!notEnoughPlaysID) {
          return evaluation.parentId === parentGroups[idParent].id
        }
      })

      childrenContainer.push(this.renderAllSubgroup(parentGroups[idParent]))
      for (
        let evaluationIndex = 0;
        evaluationIndex < evaluationChildrens.length;
        evaluationIndex++
      ) {
        const notEnoughPlaybookIDs =
          evaluationChildrens[evaluationIndex].playbookIDs.length <
          minimumNumberOfPlaysForGamification

        if (!notEnoughPlaybookIDs) {
          childrenContainer.push(this.renderChild(evaluationChildrens[evaluationIndex]))
        }
      }
      if (childrenContainer.length > maxChildren) {
        maxChildren = childrenContainer.length
      }
      // Pour ajouter des boutons personnalisés, seulement .push sur childrenContainer avant de push dans parentGroupsContainer
      const parentGroup: GroupNames = parentGroups[idParent].groupLegacy as GroupNames
      if (groupMustBeDisplayed[parentGroup]) {
        parentGroupsContainer.push(
          this.renderParentGroup(childrenContainer, parentGroups[idParent].text, maxChildren)
        )
      }
    }

    const hasSelection =
      !!this.practiceGroupsSelected.length || !!this.practiceEvaluationsSelected.length

    return (
      this.props.practiceChoiceModalVisible && (
        <View style={getTopBarMenuStyles(styleParameters).ModalBackground}>
          <Modal
            transparent
            visible={this.props.practiceChoiceModalVisible}
            supportedOrientations={orientationIOS}
          >
            <View
              style={
                getTopBarMenuStyles(styleParameters, hasSelection, maxChildren).ModalChoiceContainer
              }
            >
              <TouchableOpacity
                style={getTopBarMenuStyles(styleParameters).ModalChoiceCloseButton}
                onPress={() => {
                  store.dispatch(togglePracticeModal(false))
                  this.practiceGroupsSelected = []
                }}
              >
                <CancelIcon
                  style={getTopBarMenuStyles(styleParameters).ModalChoiceCloseButtonIcon}
                  isTablet={styleParameters.isTablet}
                />
              </TouchableOpacity>
              <View style={getTopBarMenuStyles(styleParameters).ModalChoiceTitleContainer}>
                <Text style={getTopBarMenuStyles(styleParameters).ModalChoiceTitle}>
                  {translator.t('gamification.practiceChoice').toUpperCase()}
                </Text>
              </View>
              <Text style={getTopBarMenuStyles(styleParameters).ModalChoiceQuestion}>
                {translator.t('gamification.choiceQuestion').toUpperCase()}
              </Text>
              {!noEvaluationAvailable && (
                <View style={getTopBarMenuStyles(styleParameters).PracticeChoiceContainer}>
                  {parentGroupsContainer}
                </View>
              )}
              {hasSelection && (
                <View style={getTopBarMenuStyles(styleParameters).startTrainingButton}>
                  <TouchableOpacity
                    onPress={() => {
                      this.handleStartPractice()
                    }}
                  >
                    <Text style={getTopBarMenuStyles(styleParameters).startTrainingButtonText}>
                      {translator.t('gamification.startPractice').toUpperCase()}
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
              {noEvaluationAvailable && (
                <View>
                  <Text style={getTopBarMenuStyles(styleParameters).noPraticeAvailableText}>
                    {translator.t('gamification.noPraticeAvailable')}
                  </Text>
                  <Text style={getTopBarMenuStyles(styleParameters).suggestionText}>
                    {translator.t('gamification.suggestion')}
                  </Text>
                  <Text style={getTopBarMenuStyles(styleParameters).suggestionSecondPartText}>
                    {translator.t('gamification.suggestionSecondPart')}
                  </Text>
                  <View style={getTopBarMenuStyles(styleParameters).playbookPageButton}>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate(PageName.PlaybookList)
                      }}
                    >
                      <Text style={getTopBarMenuStyles(styleParameters).playbookPageButtonText}>
                        {translator.t('gamification.toPlaybookButton').toUpperCase()}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </View>
          </Modal>
        </View>
      )
    )
  }

  getFormattedFullName = (user: User) => {
    const { firstName, lastName } = user

    const checkedFirstName: string = firstName ?? ''
    const checkedLastName: string = lastName ?? ''

    const fullName: string = `${checkedFirstName} ${checkedLastName}`.toUpperCase()

    return fullName
  }

  renderName = () => {
    const { styleParameters } = this.state
    const { user } = this.props

    const fullName: string = this.getFormattedFullName(user)

    return (
      <View style={getTopBarMenuStyles(styleParameters).nameContainer}>
        <Text
          adjustsFontSizeToFit
          numberOfLines={1}
          style={getTopBarMenuStyles(styleParameters).nameText}
        >
          {fullName}
        </Text>
      </View>
    )
  }

  renderRole = () => {
    const { user } = this.props

    const styleParameters = this.state.styleParameters

    const roleToShow: string = getUserRoleTranslation(user.role).toUpperCase()

    return (
      <View style={getTopBarMenuStyles(styleParameters).roleContainer}>
        <Text adjustsFontSizeToFit style={getTopBarMenuStyles(styleParameters).roleText}>
          {roleToShow}
        </Text>
      </View>
    )
  }

  renderUserInfo = () => {
    const { styleParameters } = this.state
    return (
      <View style={getTopBarMenuStyles(styleParameters).IDs}>
        {this.renderName()}
        {this.renderRole()}
      </View>
    )
  }

  renderMenuModalLink = (destinationPageName: string) => {
    let style: any
    let containerStyle: any
    let onPressNavigationFunction: any
    let text: string = ''
    const styleParameters = this.state.styleParameters
    const { hasTaktikPlaySubscription } = this.state
    const { navigation, isLoading, user, onGoBack, isGamificationPage } = this.props

    switch (destinationPageName) {
      case PageName.GamificationPage: {
        style = getTopBarMenuStyles(styleParameters).modalMenuLink
        onPressNavigationFunction = () => {
          if (!isGamificationPage) {
            this.setState({ menuModalVisible: false })
            if (isLoading) {
              store.dispatch(toggleLoadingModal(true, destinationPageName))
            } else {
              if (hasTaktikPlaySubscription) {
                store.dispatch(togglePracticeModal(true))
                store.dispatch(updateSelectedGroups([]))
              }
            }
          }
        }
        containerStyle = getTopBarMenuStyles(styleParameters).modalMenuLinkContainer
        text = translator.t('global.practice').toUpperCase()
        break
      }
      case PageName.PlayerStats: {
        style = getTopBarMenuStyles(styleParameters).modalMenuLink
        onPressNavigationFunction = () => {
          if (user.role === UserRole.Coach) {
            userIsCoachAlert()
            return
          }

          const userIsNotLoadedYet: boolean = user.password === ''
          if (userIsNotLoadedYet) {
            return
          }

          this.setState({ menuModalVisible: false })
          if (isLoading) {
            store.dispatch(toggleLoadingModal(true, destinationPageName))
          } else {
            if (onGoBack && isGamificationPage) {
              onGoBack(PageName.PlayerStats)
            } else navigation.navigate(PageName.PlayerStats)
          }
        }
        containerStyle = getTopBarMenuStyles(styleParameters).modalMenuLinkContainer
        text = translator.t('global.profile').toUpperCase()
        break
      }
      case PageName.Leaderboard: {
        style = getTopBarMenuStyles(styleParameters).modalMenuLink
        onPressNavigationFunction = () => {
          this.setState({ menuModalVisible: false })
        }
        containerStyle = getTopBarMenuStyles(styleParameters).modalMenuLinkContainer
        text = translator.t('leaderboard.title').toUpperCase()
        break
      }
      case PageName.PlaybookList: {
        style = getTopBarMenuStyles(styleParameters).modalMenuLink
        onPressNavigationFunction = () => {
          this.setState({ menuModalVisible: false })
          if (isLoading) {
            store.dispatch(toggleLoadingModal(true, destinationPageName))
          } else {
            if (onGoBack && isGamificationPage) {
              onGoBack(PageName.PlaybookList)
            } else navigation.navigate(PageName.PlaybookList)
          }
        }
        containerStyle = getTopBarMenuStyles(styleParameters).modalMenuLinkContainer
        text = translator.t('playbookList.title').toUpperCase()
        break
      }
      case PageName.Settings: {
        style = getTopBarMenuStyles(styleParameters).modalMenuSettingsLogoutLink
        onPressNavigationFunction = () => {
          this.setState({ menuModalVisible: false })
          navigation.navigate(PageName.Home)
        }
        containerStyle = getTopBarMenuStyles(styleParameters).modalMenuSettingsLogoutLinkContainer
        text = translator.t('settings.title').toUpperCase()
        break
      }
      case PageName.Logout: {
        style = getTopBarMenuStyles(styleParameters).modalMenuSettingsLogoutLink
        onPressNavigationFunction = async () => {
          synchronizeStudyTimes()
          this.setState({ menuModalVisible: false })
          const stored = await storageGetAllItems(StoreNames.StudyTimes)
          if (stored) {
            this.createAlertLogout()
          } else {
            logout()
          }
        }
        containerStyle = getTopBarMenuStyles(styleParameters).modalMenuSettingsLogoutLinkContainer
        text = translator.t('logout').toUpperCase()
        break
      }
      case PageName.TeamSelectionPage: {
        style = getTopBarMenuStyles(styleParameters).modalMenuSettingsLogoutLink
        onPressNavigationFunction = () => this.setState({ isTeamSelectionShowing: true })
        containerStyle = getTopBarMenuStyles(styleParameters).modalMenuSettingsLogoutLinkContainer
        text = translator.t('sideMenu.changeTeam').toUpperCase()
        break
      }
    }

    return (
      <View style={containerStyle}>
        <TouchableOpacity
          style={getTopBarMenuStyles(styleParameters).modalTouchableOpacity}
          onPress={onPressNavigationFunction}
        >
          <Text adjustsFontSizeToFit style={style}>
            {text}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderMenuModalLogo = () => {
    const styleParameters = this.state.styleParameters
    return (
      <View style={getTopBarMenuStyles(styleParameters).modalLogoContainer}>
        <PartialTaktikIcon style={getTopBarMenuStyles(styleParameters).modalLogo} />
      </View>
    )
  }

  mustDisplayTeamSelectionButton = (): boolean => {
    const { tokens } = this.props
    const { isConnectedToInternet } = this.state
    return tokens?.length > 1 && isConnectedToInternet
  }

  renderSideMenu = () => {
    const { styleParameters } = this.state

    return (
      <View style={getTopBarMenuStyles(styleParameters).modalContainer}>
        <View style={getTopBarMenuStyles(styleParameters).modalCloseButtonContainer}>
          {this.renderHamburgerMenuButton()}
        </View>
        {this.renderMenuModalLogo()}
        <View style={getTopBarMenuStyles(styleParameters).modalTitleContainer}>
          <Text adjustsFontSizeToFit style={getTopBarMenuStyles(styleParameters).modalTitle}>
            {translator.t('appTitle').toUpperCase()}
          </Text>
        </View>
        <View style={getTopBarMenuStyles(styleParameters).modalSeparator} />
        {this.renderMenuModalLink(PageName.GamificationPage)}
        {this.renderMenuModalLink(PageName.PlayerStats)}
        {/* {this.renderMenuModalLink(pageName.Leaderboard)} // Enable when needed */}
        {this.renderMenuModalLink(PageName.PlaybookList)}
        {/* {this.renderMenuModalLink(pageName.Settings)}  // Enable when needed */}
        {this.mustDisplayTeamSelectionButton() &&
          this.renderMenuModalLink(PageName.TeamSelectionPage)}
        {this.renderMenuModalLink(PageName.Logout)}
      </View>
    )
  }

  goToHome = () => {
    const { navigation } = this.props
    navigation.navigate(PageName.Home)
  }

  onTeamToggle = (teamId: number) => async (isSelected: boolean) => {
    if (!isSelected) {
      return
    }

    this.setState({
      menuModalVisible: false,
      mustShowDeleteIcon: false,
      isTeamSelectionShowing: false,
    })

    store.dispatch(toggleImageLoad(true))
    this.updateTaktikPlaySubscriptionStatus()

    const userInfo = await getLocalUserInfo()
    if (this.mustDisplayUser(this.props.url) && userInfo !== null) {
      const isConnected = await connectedToInternet()
      if (isConnected) {
        await this.updateFromUserInfo_OnlineMode(userInfo, teamId)
      } else {
        this.updateFromUserInfo_OfflineMode(userInfo)
      }
    } else {
      store.dispatch(updateUserInfo(defaultUser))
    }
  }

  renderButton = () => {
    const { styleParameters, mustShowManageButton, mustShowFinishButton } = this.state
    const styles = getTopBarMenuStyles(styleParameters)

    if (mustShowManageButton) {
      return (
        <View style={styles.manageTeamContainer}>
          <TouchableOpacity
            onPress={() =>
              this.setState({
                mustShowDeleteIcon: true,
                mustShowFinishButton: true,
                mustShowManageButton: false,
              })
            }
          >
            <Text style={styles.manageMyTeam}>
              {translator.t('topBarMenu.manageMyTeam').toUpperCase()}
            </Text>
          </TouchableOpacity>
        </View>
      )
    } else if (mustShowFinishButton) {
      return (
        <View style={styles.finishTeamContainer}>
          <TouchableOpacity
            onPress={() =>
              this.setState({
                mustShowDeleteIcon: false,
                mustShowFinishButton: false,
                mustShowManageButton: true,
              })
            }
          >
            <Text style={styles.finishText}>{'terminer'.toUpperCase()}</Text>
          </TouchableOpacity>
        </View>
      )
    }
  }

  manageOnPress = () => {
    this.setState({
      mustShowFinishButton: true,
      mustShowManageButton: false,
      isTeamSelectionShowing: false,
    })
  }

  renderTeamSelection = () => {
    const { navigation } = this.props
    const { styleParameters, teams, mustShowDeleteIcon } = this.state
    const styles = getTopBarMenuStyles(styleParameters)

    return (
      <View style={styles.modalContainer}>
        <TouchableOpacity style={styles.goBackButton} onPress={() => this.manageOnPress()}>
          <RightArrowIcon style={styles.backArrow} />
          <Text style={styles.goBackText}>{translator.t('sideMenu.goBackToCurrentTeam')}</Text>
        </TouchableOpacity>
        <View style={styles.modalTitleContainer}>
          <Text style={styles.modalTitleTeamSelection} adjustsFontSizeToFit numberOfLines={3}>
            {translator.t('teamSelection.title').toUpperCase()}
          </Text>
        </View>

        <View style={styles.modalSeparator} />

        {teams.map((team) => (
          <TeamOption
            key={team.id}
            team={team}
            isFullPage={false}
            isLoading={false}
            canDelete={mustShowDeleteIcon}
            styleParameters={styleParameters}
            navigation={navigation}
            onToggle={this.onTeamToggle(team.id)}
          />
        ))}
        {this.renderButton()}
      </View>
    )
  }

  renderMenuModal = () => {
    const { styleParameters, menuModalVisible, isTeamSelectionShowing } = this.state

    return (
      menuModalVisible && (
        <Modal
          animationType="none"
          transparent
          visible={menuModalVisible}
          supportedOrientations={orientationIOS}
        >
          <View style={getTopBarMenuStyles(styleParameters).modalBackground}>
            {isTeamSelectionShowing ? this.renderTeamSelection() : this.renderSideMenu()}
            <View style={getTopBarMenuStyles(styleParameters).parallelogramLeft} />
          </View>
        </Modal>
      )
    )
  }

  render() {
    const { url, practiceChoiceModalVisible, pageTitle, navigation, playbookTitle } = this.props

    const mustDiplayUser: boolean = this.mustDisplayUser(url)
    const mustDisplayHamburger: boolean = this.mustDisplayHamburger(url)
    const mustShowBackButton: boolean = this.mustShowBackButton(url)
    let showLanguageSetting = false
    if (
      pageTitle === 'LoginPage' ||
      pageTitle === 'ForgotPasswordPage' ||
      pageTitle === 'ChangePasswordPage'
    ) {
      showLanguageSetting = true
    }
    let title: JSX.Element | undefined = this.renderLogo()
    if (playbookTitle) {
      title = this.renderPlaybookTitle()
    }
    return (
      <View style={getTopBarMenuStyles(this.state.styleParameters).topBarMenu}>
        {this.renderMenuModal()}
        {mustDisplayHamburger && this.renderHamburgerMenuButton()}
        {mustDiplayUser && mustShowBackButton && this.renderBackButton()}
        {title}
        {showLanguageSetting && this.renderLanguageOptions()}
        {mustDiplayUser && this.renderUserInfo()}
        {practiceChoiceModalVisible && this.renderPracticeChoiceModal()}
        <LoadingModal navigation={navigation} />
      </View>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  practiceChoiceModalVisible: state.modals.practiceChoiceModalVisible,
  user: state.auth.user,
  isLoading: state.cache.loadingImages,
  playbooks: state.playbooks.playbooks,
  playbookFolders: state.playbooks.playbookFolders,
  teamsInfos: state.team.teamsInfos as TeamInfos[],
  teamID: state.team.selectedTeam as number,
  tokens: state.team.tokens as TokenArray[],
  selectedGroups: state.playbooks.selectedPracticeGroups,
  selectedEvaluations: state.playbooks.selectedEvaluations,
})

export default connect(mapStateToProps, {
  togglePracticeModal,
  toggleLoadingModal,
  playbooksLoad,
  updateUserInfo,
  setSelectedTeam,
  setSelectedToken,
})(TopBarMenu)
