import React from 'react'
import {
  AppState,
  Dimensions,
  Image,
  Platform,
  Text,
  View,
  TouchableOpacity,
  Animated,
  StyleSheet,
} from 'react-native'
import { logger } from '../../lib/logger'
import { NavigationInterface, PageName } from './PageInfo'
import translator from '@taktik/config/Translation/languages'
import TopBarMenu, { orientationIOS } from './TopBarMenu'
import { Playbook } from '../DataTypes/playbooks'
import BasicPage from './BasicPage'
import {
  getGamificationStyles,
  getGlobalStyles,
  getPlaybookStyles,
  updateStyleParameters,
} from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { axiosGetExercice } from '../Utilitary/DataUtils'
import { DateTime } from 'luxon'
import { hasValidSnapshot } from '@taktik/src/Utilitary/Utils'
import { exercisesTypes } from '../DataTypes/ExerciseType'
import { getScreenRatioStyle } from '../Styles/Peculiar/GamificationRatiosStyles'
import { Modal } from '../components/ModalImport'
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'
import { GlobalColors } from '../Styles/Theme'
import { updateSelectedGroups, toggleAlertStudyTimeModal } from '../redux/actions'
import { connect } from 'react-redux'
import { RootState } from '../redux/reducers'
import { StudyTime } from '../DataTypes/StudyTimes'
import IdentificationName from './IdentificationName'
import IdentificationPlaybook from './IdentificationPlaybook'
import { ActivityIndicator } from 'react-native-paper'
import {
  synchronizeStudyTimes,
  incrementStudyDuration,
  setAsyncStudyTimeState,
  blurHandler,
} from '../Services/StudyTimeService'
import { store } from '../redux/store'
import {
  maxStudyTimeDuration,
  renderAlertStudyDurationModal,
} from '../components/StudyDurationModal'
import { User, UserRole } from '../DataTypes/UserTypes'
import { TaktikAlert } from '../Utilitary/Alert'

interface GamificationProps extends NavigationInterface {
  selectedGroups: number[]
  selectedEvaluations: number[]
  playbooks: any[]
  alertStudyDurationVisible: boolean
  user: User
}

interface SnapshotInfo {
  snapshot: string
  width: number
  height: number
}

interface GamificationState {
  numberOfPlaybooksToGet: number
  styleParameters: StyleParameters
  exerciseType: string
  correctAnswerIndex: number
  playbooks: Playbook[]
  exerciseID: number
  startTime: DateTime
  allValidTeamPlaybooks: Playbook[]
  correctPlaybookIDAnswer: number
  snapshotInfos: SnapshotInfo[]
  resultModalVisible: boolean
  exerciseSucceeded: boolean
  countdownModalVisible: boolean
  timeResponse: number
  currentSessionStudyTime: StudyTime
  alertBackArrowModalVisible: boolean
  navigationTopBarMenu: string
}

class GamificationPage extends BasicPage<GamificationProps, GamificationState> {
  public isFocused: boolean = true
  public playbookTimerID: any = null
  public localTimerID: any = null
  public isNewPractice: boolean = true
  constructor(public props: GamificationProps) {
    super(props)
    this.onPress = this.onPress.bind(this)

    this.showGoBackAlert = this.showGoBackAlert.bind(this)
    const localTime = DateTime.local()
    const allReceivedTeamPlaybooks = this.props.playbooks
    this.state = {
      numberOfPlaybooksToGet: 4,
      exerciseType: '',
      playbooks: [],
      correctAnswerIndex: 0,
      exerciseID: 0,
      startTime: localTime,
      allValidTeamPlaybooks: [],
      correctPlaybookIDAnswer: 0,
      snapshotInfos: [],
      resultModalVisible: false,
      exerciseSucceeded: false,
      timeResponse: 0,
      countdownModalVisible: this.props.route.params.beginSession,
      alertBackArrowModalVisible: false,
      navigationTopBarMenu: '',
      styleParameters: getStyleParameters(),
      currentSessionStudyTime: {
        startTime: new Date(),
        duration: 0,
        playbookID: 0,
        userID: -1,
        sessionID: '',
      },
    }
    this.finishedExercise = this.finishedExercise.bind(this)
    this.getExerciseAndUpdateState(localTime, allReceivedTeamPlaybooks)
  }

  componentDidUpdate() {
    this.loadSnapshotInfos()
  }

  componentDidMount() {
    const OS = Platform.OS
    if (OS === 'ios' || OS === 'android') {
      AppState.addEventListener('change', this.handleAppStateChange)
    } else {
      addEventListener('focus', this.handleFocus)
      addEventListener('blur', this.handleBlur)
    }

    Dimensions.addEventListener('change', this.onChange)
    store.dispatch(toggleAlertStudyTimeModal(false))

    let inactivityTimer = 0
    let correctionTimer = 0

    this.playbookTimerID = setInterval(() => {
      if (this.state.resultModalVisible && this.isFocused) correctionTimer += 1
      if (correctionTimer <= 30) incrementStudyDuration(this)
      if (this.isFocused) inactivityTimer += 1
      if (inactivityTimer % maxStudyTimeDuration === 0) {
        this.isFocused = false
        store.dispatch(toggleAlertStudyTimeModal(true))
      }
    }, 1000)

    this.loadSnapshotInfos()
  }

  handleBlur = async () => {
    this.isFocused = false
    blurHandler(this)
  }

  handleFocus = () => {
    this.isFocused = true
  }

  handleAppStateChange = () => {
    this.isFocused = AppState.currentState === 'active'
    blurHandler(this)
  }

  componentWillUnmount() {
    clearInterval(this.playbookTimerID)
    clearInterval(this.localTimerID)
    synchronizeStudyTimes(this)
      .then()
      .catch((error) => {
        logger.info(error)
      })

    const OS = Platform.OS
    if (OS === 'ios' || OS === 'android') {
      AppState.removeEventListener('change', this.handleAppStateChange)
    } else {
      removeEventListener('focus', this.handleFocus)
      removeEventListener('blur', this.handleBlur)
    }

    Dimensions.removeEventListener('change', this.onChange)
  }

  snapshotInfoIsAlreadyStored = (snapshot: string) => {
    const { snapshotInfos } = this.state

    const snapshotInfo: SnapshotInfo | undefined = snapshotInfos.find(
      (snapInfo) => snapInfo.snapshot === snapshot
    )

    if (!snapshotInfo) {
      return false
    }

    return true
  }

  loadSnapshotInfo = async (snapshot: string): Promise<void> => {
    const { snapshotInfos } = this.state

    const snapshotInfoIsAlreadyStored: boolean = this.snapshotInfoIsAlreadyStored(snapshot)

    if (snapshotInfoIsAlreadyStored) {
      return
    }

    await Image.getSize(
      snapshot,
      (width, height) => {
        const newSnapshotInfo: SnapshotInfo = { snapshot, width, height }

        const snapshotInfoIsAlreadyStored: boolean = this.snapshotInfoIsAlreadyStored(snapshot)

        if (snapshotInfoIsAlreadyStored) {
          return
        }

        snapshotInfos.push(newSnapshotInfo)

        this.setState({ snapshotInfos: snapshotInfos })
      },
      (failure) => {
        logger.info('Failed to get Image Size ' + failure)
      }
    )
  }

  loadSnapshotInfos = () => {
    const { playbooks } = this.state
    playbooks.forEach(async (playbook) => await this.loadSnapshotInfo(playbook.snapshot))
  }

  getRandomPlaybooks_FromDirectList = (
    allValidTeamPlaybooks: Playbook[],
    numberOfPlaybooksToGet: number
  ) => {
    let maximumNumberOfChoices: number = allValidTeamPlaybooks.length

    if (numberOfPlaybooksToGet > maximumNumberOfChoices) {
      logger.warn(
        `Too many playbooks (${numberOfPlaybooksToGet}) have been requested for this team, which only has ${maximumNumberOfChoices} valid playbooks.`
      )
    }

    const randomPlaybooks: Playbook[] = []

    // Could be improved by eliminating already used playbooks at the source... Currently will be slow for huge values of `numberOfPlaybooksToGet`
    // IMPROVED (somewhat)
    while (randomPlaybooks.length < numberOfPlaybooksToGet) {
      const randomIndex: number = Math.floor(Math.random() * maximumNumberOfChoices)

      const randomPlaybook: Playbook = allValidTeamPlaybooks[randomIndex]

      const playbookIsAlreadyBeingUsed: boolean = randomPlaybooks.some(
        (randomPlbk) => randomPlbk.id === randomPlaybook.id
      )

      if (!playbookIsAlreadyBeingUsed) {
        allValidTeamPlaybooks = allValidTeamPlaybooks.filter(
          (playbook) => playbook.id !== randomPlaybook.id
        )
        maximumNumberOfChoices--
        randomPlaybooks.push(randomPlaybook)
      }
    }

    return randomPlaybooks
  }

  getPlaybook = (playbooks: Playbook[], playbookID: number) => {
    const playbook: Playbook = playbooks.find((plbk) => plbk.id === playbookID)!

    return playbook
  }

  playbookIsValidForGame = (playbook: Playbook) => {
    return hasValidSnapshot(playbook)
  }

  getValidChosenPlaybooks = (response: any, playbooks: Playbook[]): Playbook[] => {
    const allChosenPlaybookIDs: number[] = response.data.exerciseData.choices
    const allChosenPlaybooks: Playbook[] = allChosenPlaybookIDs.map((playbookID) =>
      this.getPlaybook(playbooks, playbookID)
    )
    const allValidTeamPlaybooks: Playbook[] = allChosenPlaybooks.filter((playbook) =>
      this.playbookIsValidForGame(playbook)
    )

    return allValidTeamPlaybooks
  }

  getExerciseAndUpdateState = (localTime: DateTime, playbooks: any[]) => {
    let { numberOfPlaybooksToGet } = this.state
    const { currentSessionStudyTime } = this.state
    const { navigation } = this.props
    axiosGetExercice(
      localTime,
      this.props.selectedGroups,
      this.props.selectedEvaluations,
      navigation
    )
      .then((response) => {
        if (response) {
          const correctPlaybookIDAnswer: number = response.data.exerciseData.answer
          const exerciseID: number = response.data.exerciseID
          const exerciseType: string = response.data.exerciseType
          const allValidTeamPlaybooks: Playbook[] = this.getValidChosenPlaybooks(
            response,
            playbooks
          )

          const numberOfValidPlaybooks: number = allValidTeamPlaybooks.length
          const notEnoughPlaybooks: boolean = numberOfValidPlaybooks < numberOfPlaybooksToGet

          if (numberOfValidPlaybooks === 0) {
            TaktikAlert.alert('There are no playbooks no load!')
            return
          }

          if (notEnoughPlaybooks) {
            numberOfPlaybooksToGet = numberOfValidPlaybooks

            logger.warn(
              'Some playbooks were not found while loading the page. Make sure that there are enough playbooks in the current team to play this game and that you are connected to the Internet.'
            )
          }

          const randomPlaybooks = this.getRandomPlaybooks_FromDirectList(
            allValidTeamPlaybooks,
            numberOfPlaybooksToGet
          )

          const correctAnswerIndex: number = randomPlaybooks.findIndex(
            (playbook) => playbook.id === correctPlaybookIDAnswer
          )
          this.setState({
            playbooks: randomPlaybooks,
            allValidTeamPlaybooks: allValidTeamPlaybooks,
            correctAnswerIndex: correctAnswerIndex,
            correctPlaybookIDAnswer: correctPlaybookIDAnswer,
            numberOfPlaybooksToGet: numberOfPlaybooksToGet,
            exerciseID: exerciseID,
            exerciseType: exerciseType,
            currentSessionStudyTime: {
              ...currentSessionStudyTime,
              playbookID: correctPlaybookIDAnswer,
            },
          })
        } else {
          TaktikAlert.alert(
            translator.t('gamification.notEnoughPlaybookTitle'),
            translator.t('gamification.notEnoughPlaybookText'),
            {
              text: 'OK',
              onPress: () => {
                navigation.navigate(PageName.Home)
              },
            }
          )
        }
      })
      .then(() => setAsyncStudyTimeState(this))
      .catch((_err) => {})
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  finishedExercise = (isExerciseSucceeded: boolean) => {
    const { startTime } = this.state
    this.setState({ resultModalVisible: true, exerciseSucceeded: isExerciseSucceeded })
    this.setState({ timeResponse: DateTime.local().diff(startTime).milliseconds - 3000 })
  }

  getResultReaction = () => {
    const { styleParameters, exerciseSucceeded } = this.state
    if (exerciseSucceeded) {
      return (
        <Text
          adjustsFontSizeToFit
          style={getGamificationStyles(styleParameters).successConfirmText}
        >{`${translator.t('gamification.confirmButtonSuccess').toUpperCase()}`}</Text>
      )
    } else {
      return (
        <Text
          adjustsFontSizeToFit
          style={getGamificationStyles(styleParameters).failureConfirmText}
        >{`${translator.t('gamification.confirmButtonFailure').toUpperCase()}`}</Text>
      )
    }
  }

  getModalSubTextRightAnswer = () => {
    const { exerciseType, correctAnswerIndex, playbooks } = this.state
    if (exerciseType !== exercisesTypes.correctName) {
      return ''
    } else {
      return playbooks[correctAnswerIndex].title
    }
  }

  getModalSubText = () => {
    const styleParameters = this.state.styleParameters
    if (this.state.exerciseSucceeded) {
      return (
        <Text style={getGamificationStyles(styleParameters).ModalSubTextSuccess}>{`${translator
          .t('gamification.rightAnswerIs')
          .toUpperCase()}`}</Text>
      )
    } else {
      const rightAnswer: any = this.getModalSubTextRightAnswer()
      return (
        <View style={getGamificationStyles(styleParameters).ModalSubTextContainer}>
          <Text style={getGamificationStyles(styleParameters).ModalSubTextFailure}>
            {`${translator.t('gamification.wrongAnswerIs').toUpperCase()}`}
          </Text>
          <Text style={getGamificationStyles(styleParameters).ModalSubTextRightAnswer}>
            {rightAnswer.toUpperCase()}
          </Text>
        </View>
      )
    }
  }

  handleEndOfPractice = () => {
    const { navigation, user } = this.props
    const { exerciseSucceeded, timeResponse } = this.state
    const { goodAnswers, badAnswers, averageTimeResponse } = this.props.route.params

    let finalNbGoodAnswers = goodAnswers
    let finalNbBadAnswers = badAnswers
    const finalTimeResponse = averageTimeResponse + timeResponse

    if (exerciseSucceeded) {
      finalNbGoodAnswers++
    } else {
      finalNbBadAnswers++
    }
    let finalAverageTimeResponse =
      Math.round((finalTimeResponse / (finalNbGoodAnswers + finalNbBadAnswers)) * 0.001 * 10) / 10
    if (finalAverageTimeResponse < 0) {
      finalAverageTimeResponse = 0
    }

    this.setState({ resultModalVisible: false })
    navigation.pop()

    const isCoach: boolean = user.role === UserRole.Coach

    if (isCoach) {
      navigation.navigate(PageName.Home, {
        isEndPractice: true,
        goodAnswers: finalNbGoodAnswers,
        badAnswers: finalNbBadAnswers,
        averageTimeResponse: finalAverageTimeResponse,
        points: 0,
      })
    } else {
      navigation.navigate(PageName.PlayerStats, {
        isEndPractice: true,
        goodAnswers: finalNbGoodAnswers,
        badAnswers: finalNbBadAnswers,
        averageTimeResponse: finalAverageTimeResponse,
        points: 0,
        isNewEndPractice: this.isNewPractice,
      })
    }
  }

  renderModalStopButton = () => {
    const styleParameters = this.state.styleParameters
    const { exerciseSucceeded, timeResponse } = this.state
    const { goodAnswers, badAnswers, averageTimeResponse } = this.props.route.params
    let finalNbGoodAnswers = goodAnswers
    let finalNbBadAnswers = badAnswers
    const finalTimeResponse = averageTimeResponse + timeResponse

    if (exerciseSucceeded) {
      finalNbGoodAnswers++
    } else {
      finalNbBadAnswers++
    }
    let finalAverageTimeResponse =
      Math.round((finalTimeResponse / (finalNbGoodAnswers + finalNbBadAnswers)) * 0.001 * 10) / 10
    if (finalAverageTimeResponse < 0) {
      finalAverageTimeResponse = 0
    }
    return (
      <View style={getGamificationStyles(styleParameters).ModalButtonStop}>
        <TouchableOpacity
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
          }}
          onPress={() => {
            this.handleEndOfPractice()
          }}
        >
          <Text style={getGamificationStyles(styleParameters).ModalButtonStopText}>{`${translator
            .t('gamification.modalButtonStop')
            .toUpperCase()}`}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  forceUpdateHandler = () => {
    const navigation = this.props.navigation
    const { exerciseSucceeded, timeResponse } = this.state
    const { goodAnswers, badAnswers, averageTimeResponse } = this.props.route.params
    const points = 0
    navigation.pop()
    this.setState({ resultModalVisible: false })
    navigation.navigate(PageName.GamificationPage, {
      beginSession: false,
      goodAnswers: exerciseSucceeded ? goodAnswers + 1 : goodAnswers,
      badAnswers: exerciseSucceeded ? badAnswers : badAnswers + 1,
      averageTimeResponse: averageTimeResponse + timeResponse,
      points: points,
    })
  }

  renderModalNextButton = () => {
    const styleParameters = this.state.styleParameters
    return (
      <View style={getGamificationStyles(styleParameters).ModalButtonNext}>
        <TouchableOpacity
          style={{ width: '100%', height: '100%', justifyContent: 'center' }}
          onPress={() => {
            this.forceUpdateHandler()
          }}
        >
          <Text style={getGamificationStyles(styleParameters).ModalButtonNextText}>{`${translator
            .t('gamification.modalButtonNext')
            .toUpperCase()}`}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderModalButtons = () => {
    const styleParameters = this.state.styleParameters
    let firstButton: JSX.Element
    let secondButton: JSX.Element
    if (styleParameters.isHorizontal) {
      firstButton = this.renderModalStopButton()
      secondButton = this.renderModalNextButton()
    } else {
      secondButton = this.renderModalStopButton()
      firstButton = this.renderModalNextButton()
    }

    return (
      <View style={getGamificationStyles(styleParameters).ModalRowButtons}>
        <View style={getGamificationStyles(styleParameters).ModalButtonContainer}>
          {firstButton}
        </View>
        <View style={getGamificationStyles(styleParameters).ModalButtonContainer}>
          {secondButton}
        </View>
      </View>
    )
  }

  renderResultModal = () => {
    const {
      styleParameters,
      resultModalVisible,
      exerciseType,
      exerciseSucceeded,
      playbooks,
      correctAnswerIndex,
    } = this.state
    if (
      !exerciseSucceeded &&
      (exerciseType === exercisesTypes.correctImage ||
        exerciseType === exercisesTypes.correctImageWithRotation)
    ) {
      return (
        resultModalVisible && (
          <View style={getGamificationStyles(styleParameters).ModalBackground}>
            <Modal
              animationType="none"
              transparent
              visible={resultModalVisible}
              supportedOrientations={orientationIOS}
            >
              <View style={getGamificationStyles(styleParameters).ModalContainerWithImage}>
                {this.getResultReaction()}
                {this.getModalSubText()}
                {this.renderPlaybookResult(playbooks[correctAnswerIndex].snapshot)}
                {this.renderModalButtons()}
              </View>
            </Modal>
          </View>
        )
      )
    } else {
      return (
        resultModalVisible && (
          <View style={getGamificationStyles(styleParameters).ModalBackground}>
            <Modal
              animationType="none"
              transparent
              visible={resultModalVisible}
              supportedOrientations={orientationIOS}
            >
              <View style={getGamificationStyles(styleParameters).ModalContainer}>
                {this.getResultReaction()}
                {this.getModalSubText()}
                {this.renderModalButtons()}
              </View>
            </Modal>
          </View>
        )
      )
    }
  }

  renderPlaybookResult = (snapshot: string) => {
    const { styleParameters } = this.state
    if (snapshot === '') return null
    return (
      <View style={getGamificationStyles(styleParameters).ModalImageContainer}>
        <Image
          resizeMethod="scale"
          source={{ uri: snapshot }}
          style={getGamificationStyles(styleParameters).ModalImage}
        />
      </View>
    )
  }

  renderGame = () => {
    const {
      exerciseType,
      correctAnswerIndex,
      playbooks,
      exerciseID,
      startTime,
      snapshotInfos,
      correctPlaybookIDAnswer,
    } = this.state
    const { navigation } = this.props
    const { beginSession } = this.props.route.params
    if (exerciseType === exercisesTypes.correctName) {
      return (
        <IdentificationName
          navigation={navigation}
          correctAnswerIndex={correctAnswerIndex}
          playbooks={playbooks}
          exerciseID={exerciseID}
          startTime={startTime}
          snapshotInfos={snapshotInfos}
          callback={this.finishedExercise}
          beginSession={beginSession}
        />
      )
    } else if (
      exerciseType === exercisesTypes.correctImage ||
      exerciseType === exercisesTypes.correctImageWithRotation
    ) {
      return (
        <IdentificationPlaybook
          navigation={navigation}
          correctAnswerIndex={correctAnswerIndex}
          playbooks={playbooks}
          exerciseID={exerciseID}
          startTime={startTime}
          snapshotInfos={snapshotInfos}
          callback={this.finishedExercise}
          beginSession={beginSession}
          correctPlaybookIDAnswer={correctPlaybookIDAnswer}
        />
      )
    }
  }

  renderLoadingPage = () => {
    const { navigation } = this.props
    const { styleParameters } = this.state

    const activityIndicatorColor: string = StyleSheet.flatten(
      getGamificationStyles(styleParameters).ActivityIndicator
    ).color

    return (
      <View style={getGlobalStyles(this.state.styleParameters).app}>
        <TopBarMenu
          navigation={navigation}
          url={PageName.PlaybookPage}
          updatePage={() => this.forceUpdate()}
          userMustBeLoggedOut={false}
          pageTitle={translator.t('loadingPage')}
        />
        <ActivityIndicator size={58} style={{ flex: 1 }} color={activityIndicatorColor} />
      </View>
    )
  }

  onPress() {
    this.isFocused = true
  }

  renderCountdownModal = () => {
    const styleParameters = this.state.styleParameters
    return (
      this.state.countdownModalVisible && (
        <View style={getGamificationStyles(styleParameters).ModalBackground}>
          <Modal
            animationType="none"
            transparent
            visible={this.state.countdownModalVisible}
            supportedOrientations={orientationIOS}
          >
            <View style={getGamificationStyles(styleParameters).ModalContainer}>
              <Text style={getGamificationStyles(styleParameters).ModalCountdownFirstRow}>
                {`${translator.t('gamification.getReady').toUpperCase()}`}
              </Text>
              <Text style={getGamificationStyles(styleParameters).ModalCountdownSecondRow}>
                {`${translator.t('gamification.yourTrainingStarts').toUpperCase()}`}
              </Text>
              <View style={getGamificationStyles(styleParameters).ModalCountdownCircleContainer}>
                <CountdownCircleTimer
                  isPlaying
                  duration={3}
                  colors={GlobalColors.paleGrey}
                  size={getScreenRatioStyle(styleParameters).ModalCountdownWidget_size}
                  strokeWidth={0}
                  onComplete={() => {
                    this.setState({ countdownModalVisible: false })
                  }}
                >
                  {({ remainingTime }) => (
                    <Animated.Text
                      style={getGamificationStyles(styleParameters).ModalCountdownText}
                    >
                      {' ' + remainingTime + ' '}
                    </Animated.Text>
                  )}
                </CountdownCircleTimer>
              </View>
            </View>
          </Modal>
        </View>
      )
    )
  }

  renderAlertBackArrowModal = () => {
    const { navigation } = this.props
    const { styleParameters, alertBackArrowModalVisible, navigationTopBarMenu } = this.state

    return (
      alertBackArrowModalVisible && (
        <View style={getPlaybookStyles(styleParameters).ModalBackground}>
          <Modal
            transparent
            visible={alertBackArrowModalVisible}
            supportedOrientations={orientationIOS}
          >
            <View style={getPlaybookStyles(styleParameters).ModalAlertContainer}>
              <View style={getPlaybookStyles(styleParameters).ModalAlertTitleContainer}>
                <Text style={getPlaybookStyles(styleParameters).ModalAlertTitle}>PAUSE</Text>
              </View>
              <Text style={getPlaybookStyles(styleParameters).ModalAlertQuestion}>
                {translator.t('backArrowAlert.question').toUpperCase()}
              </Text>
              <Text style={getPlaybookStyles(styleParameters).ModalAlertSubtext}>
                {translator.t('backArrowAlert.subtext').toUpperCase()}
              </Text>
              <View style={getPlaybookStyles(styleParameters).ModalAlertButtonContainer}>
                <View style={getPlaybookStyles(styleParameters).alertButtonSecondary}>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ alertBackArrowModalVisible: false })
                    }}
                  >
                    <Text style={getPlaybookStyles(styleParameters).alertButtonTextNo}>
                      {translator.t('no').toUpperCase()}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={getPlaybookStyles(styleParameters).alertButtonYes}>
                  <TouchableOpacity
                    onPress={() => {
                      if (navigationTopBarMenu) {
                        navigation.goBack()
                        navigation.navigate(navigationTopBarMenu)
                      } else {
                        navigation.goBack()
                      }
                    }}
                  >
                    <Text style={getPlaybookStyles(styleParameters).alertButtonTextYes}>
                      {translator.t('global.yes').toUpperCase()}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </View>
      )
    )
  }

  showGoBackAlert(navigationPage: string) {
    this.setState({ navigationTopBarMenu: navigationPage })
    this.setState({ alertBackArrowModalVisible: true })
  }

  render() {
    const { navigation, alertStudyDurationVisible } = this.props
    const { countdownModalVisible } = this.state
    const { snapshotInfos, numberOfPlaybooksToGet, styleParameters } = this.state

    const snapshotInfosHaveNotBeenLoadedYet: boolean =
      snapshotInfos.length < numberOfPlaybooksToGet || snapshotInfos.length === 0

    if (snapshotInfosHaveNotBeenLoadedYet) {
      return this.renderLoadingPage()
    }

    const pageTitle: string = ''
    return (
      <View style={getGlobalStyles(styleParameters).app}>
        <TopBarMenu
          navigation={navigation}
          url={PageName.PlaybookPage}
          updatePage={() => this.forceUpdate()}
          userMustBeLoggedOut={false}
          pageTitle={pageTitle}
          isGamificationPage
          onGoBack={this.showGoBackAlert}
        />
        {this.renderGame()}

        {countdownModalVisible ? this.renderCountdownModal() : <View />}
        {this.renderResultModal()}
        {alertStudyDurationVisible &&
          renderAlertStudyDurationModal(
            styleParameters,
            navigation,
            alertStudyDurationVisible,
            this.onPress
          )}
        {this.renderAlertBackArrowModal()}
      </View>
    )
  }
}
const mapStateToProps = (state: RootState) => ({
  selectedGroups: state.playbooks.selectedPracticeGroups,
  selectedEvaluations: state.playbooks.selectedEvaluations,
  playbooks: state.playbooks.playbooks,
  alertStudyDurationVisible: state.modals.alertStudyDurationVisible,
  user: state.auth.user,
})

export default connect(mapStateToProps, {
  updateSelectedGroups,
  toggleAlertStudyTimeModal,
})(GamificationPage)
