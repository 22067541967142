import React, { Component, ReactNode, ReactNodeArray } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { View } from 'react-native'
import { getRegistrationStyles } from '../Styles/AppStyle'
import { GlobalColors } from '../Styles/Theme'
import { StyleParameters } from '../Styles/Utilities'

interface RegistrationFieldWrapperProps {
  children: ReactNode | ReactNodeArray
  styleParameters: StyleParameters
}

export default class RegistrationFieldWrapper extends Component<RegistrationFieldWrapperProps> {
  render() {
    const { children, styleParameters } = this.props
    const styles = getRegistrationStyles(styleParameters)

    return (
      <>
        <View style={styles.textInputContainer}>
          <MaterialCommunityIcons
            name="pencil"
            size={18}
            color={GlobalColors.defaultHomeColor}
            style={{ paddingTop: 2 }}
          />
          {children}
        </View>
        <View style={styles.lineRegistration} />
      </>
    )
  }
}
