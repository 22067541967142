import { logger } from '../../../lib/logger'
import { logout } from '../../Services/AuthService'
import { store } from '../../redux/store'
import { SET_STORE_VERSION } from '../../redux/actionTypes'
import { storeVersion, StoreVersion } from '@taktik/config/Redux/version'
import { formatVersion, _onlyMinorVersionHasChanged, _majorVersionHasChanged } from './Common'

export const setReduxStoreVersion = async (reduxStoreVersion: StoreVersion) => {
  logger.info(`Setting ${formatVersion(reduxStoreVersion)} as the new Redux store version...`)
  store.dispatch({ type: SET_STORE_VERSION, payload: reduxStoreVersion })
}

export const mustLogoutFromReduxStoreVersionChange = async (
  savedStoreVersion: StoreVersion | null | undefined
): Promise<boolean> => {
  const hadNoCurrentVersion: boolean = !savedStoreVersion

  if (hadNoCurrentVersion) {
    logger.info('Logging out due to Redux store version change (no current version)')
    logout()
    return true
  }

  const onlyMinorVersionHasChanged: boolean = _onlyMinorVersionHasChanged(
    savedStoreVersion,
    storeVersion
  )
  const majorVersionHasChanged: boolean = _majorVersionHasChanged(savedStoreVersion, storeVersion)

  let mustLogout: boolean = false

  if (onlyMinorVersionHasChanged) {
    logger.info('Basic Redux store reset (NOT IMPLEMENTED)')
  } else if (majorVersionHasChanged) {
    logger.info('Logging out due to Redux store version change')
    mustLogout = true
    logout()
  } else {
    logger.info(`Redux store version up to date (${formatVersion(savedStoreVersion)})`)
  }

  return mustLogout
}
