import React, { Component } from 'react'
import translator from '@taktik/config/Translation/languages'

import { Text } from 'react-native'
import { getRegistrationStyles } from '../Styles/AppStyle'
import { StyleParameters } from '../Styles/Utilities'
import { RegistrationFormFieldContent } from './RegistrationForm'
import { PasswordValidation } from '../DataTypes/ValidationType'

interface PasswordFeedbackProps {
  showErrors: boolean
  passwordField: RegistrationFormFieldContent
  styleParameters: StyleParameters
}

export class PasswordFeedback extends Component<PasswordFeedbackProps> {
  passwordHasError = (error: PasswordValidation): boolean => {
    return this.props.passwordField.errors.includes(error)
  }

  render() {
    const { showErrors, styleParameters } = this.props
    const styles = getRegistrationStyles(styleParameters)

    const getStyleForError = (error: PasswordValidation) =>
      showErrors
        ? this.passwordHasError(error)
          ? styles.passwordSubtitleError
          : styles.passwordSubtitleCorrect
        : styles.passwordSubtitleDefault

    return (
      <Text style={styles.passwordSubtitle}>
        <Text>{translator.t('modalCreateAccount.passwordNeeds')}</Text>
        <Text style={getStyleForError(PasswordValidation.ENOUGH_CHARACTERS)}>
          {translator.t('modalCreateAccount.characters')}
        </Text>
        <Text style={getStyleForError(PasswordValidation.ENOUGH_DIGITS)}>
          {translator.t('modalCreateAccount.number')}
        </Text>
        <Text style={getStyleForError(PasswordValidation.ENOUGH_LOWERCASE)}>
          {translator.t('modalCreateAccount.lowerCase')}
        </Text>
        <Text>{translator.t('modalCreateAccount.and')}</Text>
        <Text style={getStyleForError(PasswordValidation.ENOUGH_UPPERCASE)}>
          {translator.t('modalCreateAccount.upperCase')}
        </Text>
      </Text>
    )
  }
}
