import { SET_SELECTED_TEAM, SET_SELECTED_TOKEN, SET_TEAMS_INFOS, SET_TOKENS } from '../actionTypes'

export interface TeamInfos {
  name: string
  id: number
  logo: string
}

export interface TokenArray {
  teamID: number
  token: string
}

export interface TeamState {
  selectedTeam: number | null
  selectedToken: string | null
  teamsInfos: TeamInfos[] | null
  tokens: TokenArray[] | null
}

const initialState: TeamState = {
  selectedTeam: null,
  selectedToken: null,
  teamsInfos: null,
  tokens: null,
}

export default function team(state = initialState, action: any): TeamState {
  switch (action.type) {
    case SET_SELECTED_TEAM: {
      const { selectedTeam } = action.payload
      return {
        ...state,
        selectedTeam: selectedTeam,
      }
    }
    case SET_SELECTED_TOKEN: {
      const { selectedToken } = action.payload
      return {
        ...state,
        selectedToken: selectedToken,
      }
    }
    case SET_TEAMS_INFOS: {
      const { teamsInfos } = action.payload
      return {
        ...state,
        teamsInfos: teamsInfos,
      }
    }

    case SET_TOKENS: {
      const { tokens } = action.payload
      return {
        ...state,
        tokens: tokens,
      }
    }
    default:
      return state
  }
}
