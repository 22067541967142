import React from 'react'
import translator from '@taktik/config/Translation/languages'
import { Ionicons } from '@expo/vector-icons'
import { SnackBarStyles } from './styles'
import { TouchableOpacity, View, Text } from 'react-native'
import { SnackBarType } from './snack-bar-type'

interface TSnackBarProps {
  message: string
  type: SnackBarType
  onDismiss: () => void
}

export class TSnackBar extends React.Component<TSnackBarProps> {
  render() {
    const type = this.props.type
    const styles = new SnackBarStyles(type)
    const title = getTitleI18n(type)
    const iconName = getIconName(type)
    return (
      <View style={styles.container}>
        <Ionicons
          style={styles.titleIcon}
          name={iconName}
          color={styles.customStyles.contentColor}
          size={styles.fontSize}
        />
        <View style={styles.separator} />
        <Text style={styles.titleText}>{title}:</Text>
        <View style={styles.separator} />
        <Text style={styles.message}>{this.props.message}</Text>
        <View style={styles.expandSpacer} />
        <TouchableOpacity style={styles.dismissBtn} onPress={() => this.props.onDismiss()}>
          <Ionicons
            name="md-close"
            color={styles.customStyles.contentColor}
            size={styles.fontSize}
          />
        </TouchableOpacity>
      </View>
    )
  }
}

function getIconName(type: SnackBarType) {
  switch (type) {
    case SnackBarType.ERROR:
      return 'md-alert'
    case SnackBarType.WARNING:
      return 'md-warning'
    case SnackBarType.SUCCESS:
      return 'md-checkmark'
  }
}

function getTitleI18n(type: SnackBarType) {
  switch (type) {
    case SnackBarType.ERROR:
      return translator.t('snackbar.error')
    case SnackBarType.WARNING:
      return translator.t('snackbar.warning')
    case SnackBarType.SUCCESS:
      return translator.t('snackbar.success')
  }
}
