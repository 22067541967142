import { StyleParameters, getMarginToTransformIn43, transformUXSpec } from '../Utilities'
export interface StyledScreenRatio {
  ModalContainer_width: any
  ModalContainer_height: any
  ModalContainer_borderRadius: any
  profilPictureContainer_width: any
  profilPictureContainer_heigth: any
  textInputContainer_height: any
  textInputContainer_width: any
  buttonCancel_width: any
  buttonCancel_heigth: any
  buttonCreateAccount_width: any
  buttonCreateAccount_height: any
  cancelButtonText_fontSize: any
  createAccountButtonText_fontSize: any
  positionButtonSelected_width: any
  positionButtonSelected_height: any
  positionButton_width: any
  positionButton_height: any
  groupText_fontSize: any
  formContainer_width: any
  formContainer_height: any
  positionPickerTitle_fontSize: any
  textPositionButton_fontSize: any
  textInputStyle_width: any
  lineRegistration_width: any
  passwordSubtitleBegining_fontSize: any
  passwordSubtitle_fontSize: any
  positionsRow_width: any
  contentContainer_marginLeft: any
  positionPickerContainer_marginLeft: any
  buttonsRow_marginLeft: any
  modalTitle_marginLeft: any
  positionRow_marginLeft: any
  textPositionButtonSelected_fontSize: any
  passwordSubtitle_width: any
  topSideContainer_height: any
  textInputContainer_marginLeft: any
  lineRegistration_marginLeft: any
  camera_marginLeft: any
  camera_height: any
  camera_size: any
}

export function getScreenRatioStyle(styleParameters: StyleParameters): StyledScreenRatio {
  let { screenWidth, screenHeight } = styleParameters
  const marginHorizontal: number = getMarginToTransformIn43(screenWidth, screenHeight)
  if (screenWidth > screenHeight) {
    if (screenHeight > 600) {
      screenWidth -= marginHorizontal
      //horizontal tablet
      return {
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 1180),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 1360),
        ModalContainer_borderRadius: 15,
        profilPictureContainer_width: transformUXSpec(screenWidth, screenHeight, 350),
        profilPictureContainer_heigth: transformUXSpec(screenWidth, screenHeight, 400),
        textInputContainer_height: transformUXSpec(screenWidth, screenHeight, 50),
        textInputContainer_width: transformUXSpec(screenWidth, screenHeight, 575),
        buttonCancel_width: transformUXSpec(screenWidth, screenHeight, 410),
        buttonCancel_heigth: transformUXSpec(screenWidth, screenHeight, 100),
        buttonCreateAccount_width: transformUXSpec(screenWidth, screenHeight, 450),
        buttonCreateAccount_height: transformUXSpec(screenWidth, screenHeight, 100),
        cancelButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        createAccountButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        positionButtonSelected_width: transformUXSpec(screenWidth, screenHeight, 80),
        positionButtonSelected_height: transformUXSpec(screenWidth, screenHeight, 70),
        positionButton_width: transformUXSpec(screenWidth, screenHeight, 80),
        positionButton_height: transformUXSpec(screenWidth, screenHeight, 70),
        groupText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        formContainer_width: transformUXSpec(screenWidth, screenHeight, 600),
        formContainer_height: transformUXSpec(screenWidth, screenHeight, 425),
        positionPickerTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        textPositionButton_fontSize: transformUXSpec(screenWidth, screenHeight, 38),
        textInputStyle_width: transformUXSpec(screenWidth, screenHeight, 490),
        lineRegistration_width: transformUXSpec(screenWidth, screenHeight, 600),
        passwordSubtitleBegining_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        passwordSubtitle_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        positionsRow_width: transformUXSpec(screenWidth, screenHeight, 1080),
        contentContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        positionPickerContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 45),
        buttonsRow_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        modalTitle_marginLeft: transformUXSpec(screenWidth, screenHeight, 370),
        positionRow_marginLeft: transformUXSpec(screenWidth, screenHeight, 15),
        textPositionButtonSelected_fontSize: transformUXSpec(screenWidth, screenHeight, 38),
        passwordSubtitle_width: transformUXSpec(screenWidth, screenHeight, 95),
        topSideContainer_height: transformUXSpec(screenWidth, screenHeight, 400),
        textInputContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        lineRegistration_marginLeft: transformUXSpec(screenWidth, screenHeight, -10),
        camera_marginLeft: transformUXSpec(screenWidth, screenHeight, 230),
        camera_height: transformUXSpec(screenWidth, screenHeight, 340),
        camera_size: transformUXSpec(screenWidth, screenHeight, 70),
      }
    } else {
      //horizontal mobile
      return {
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 500),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 250),
        ModalContainer_borderRadius: 15,
        profilPictureContainer_width: transformUXSpec(screenWidth, screenHeight, 100),
        profilPictureContainer_heigth: transformUXSpec(screenWidth, screenHeight, 125),
        textInputContainer_height: transformUXSpec(screenWidth, screenHeight, 20),
        textInputContainer_width: transformUXSpec(screenWidth, screenHeight, 75),
        buttonCancel_width: transformUXSpec(screenWidth, screenHeight, 182),
        buttonCancel_heigth: transformUXSpec(screenWidth, screenHeight, 60),
        buttonCreateAccount_width: transformUXSpec(screenWidth, screenHeight, 130),
        buttonCreateAccount_height: transformUXSpec(screenWidth, screenHeight, 60),
        cancelButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        createAccountButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        positionButtonSelected_width: transformUXSpec(screenWidth, screenHeight, 40),
        positionButtonSelected_height: transformUXSpec(screenWidth, screenHeight, 30),
        positionButton_width: transformUXSpec(screenWidth, screenHeight, 40),
        positionButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        groupText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        formContainer_width: transformUXSpec(screenWidth, screenHeight, 500),
        formContainer_height: transformUXSpec(screenWidth, screenHeight, 300),
        positionPickerTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        textPositionButton_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        textInputStyle_width: transformUXSpec(screenWidth, screenHeight, 235),
        lineRegistration_width: transformUXSpec(screenWidth, screenHeight, 300),
        passwordSubtitleBegining_fontSize: transformUXSpec(screenWidth, screenHeight, 7),
        passwordSubtitle_fontSize: transformUXSpec(screenWidth, screenHeight, 7),
        positionsRow_width: transformUXSpec(screenWidth, screenHeight, 250),
        contentContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        positionPickerContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        buttonsRow_marginLeft: transformUXSpec(screenWidth, screenHeight, 25),
        modalTitle_marginLeft: transformUXSpec(screenWidth, screenHeight, 130),
        positionRow_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        textPositionButtonSelected_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        passwordSubtitle_width: transformUXSpec(screenWidth, screenHeight, 38),
        topSideContainer_height: transformUXSpec(screenWidth, screenHeight, 180),
        textInputContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 15),
        lineRegistration_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        camera_marginLeft: transformUXSpec(screenWidth, screenHeight, 65),
        camera_height: transformUXSpec(screenWidth, screenHeight, 108),
        camera_size: transformUXSpec(screenWidth, screenHeight, 20),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 350),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 600),
        ModalContainer_borderRadius: 15,
        profilPictureContainer_width: transformUXSpec(screenWidth, screenHeight, 100),
        profilPictureContainer_heigth: transformUXSpec(screenWidth, screenHeight, 150),
        textInputContainer_height: transformUXSpec(screenWidth, screenHeight, 25),
        textInputContainer_width: transformUXSpec(screenWidth, screenHeight, 200),
        buttonCancel_width: transformUXSpec(screenWidth, screenHeight, 128),
        buttonCancel_heigth: transformUXSpec(screenWidth, screenHeight, 50),
        buttonCreateAccount_width: transformUXSpec(screenWidth, screenHeight, 120),
        buttonCreateAccount_height: transformUXSpec(screenWidth, screenHeight, 50),
        cancelButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        createAccountButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        positionButtonSelected_width: transformUXSpec(screenWidth, screenHeight, 23.5),
        positionButtonSelected_height: transformUXSpec(screenWidth, screenHeight, 23.5),
        positionButton_width: transformUXSpec(screenWidth, screenHeight, 23.5),
        positionButton_height: transformUXSpec(screenWidth, screenHeight, 23.5),
        groupText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        formContainer_width: transformUXSpec(screenWidth, screenHeight, 140),
        formContainer_height: transformUXSpec(screenWidth, screenHeight, 200),
        positionPickerTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        textPositionButton_fontSize: transformUXSpec(screenWidth, screenHeight, 10),
        textInputStyle_width: transformUXSpec(screenWidth, screenHeight, 155),
        lineRegistration_width: transformUXSpec(screenWidth, screenHeight, 200),
        passwordSubtitleBegining_fontSize: transformUXSpec(screenWidth, screenHeight, 4.5),
        passwordSubtitle_fontSize: transformUXSpec(screenWidth, screenHeight, 4.5),
        positionsRow_width: transformUXSpec(screenWidth, screenHeight, 300),
        contentContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 15),
        positionPickerContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        buttonsRow_marginLeft: transformUXSpec(screenWidth, screenHeight, 17),
        modalTitle_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        positionRow_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        textPositionButtonSelected_fontSize: transformUXSpec(screenWidth, screenHeight, 10),
        passwordSubtitle_width: transformUXSpec(screenWidth, screenHeight, 38),
        topSideContainer_height: transformUXSpec(screenWidth, screenHeight, 150),
        textInputContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        lineRegistration_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        camera_marginLeft: transformUXSpec(screenWidth, screenHeight, 60),
        camera_height: transformUXSpec(screenWidth, screenHeight, 130),
        camera_size: transformUXSpec(screenWidth, screenHeight, 25),
      }
    } else {
      //vertical tablet
      return {
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 1080),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 1660),
        ModalContainer_borderRadius: 15,
        profilPictureContainer_width: transformUXSpec(screenWidth, screenHeight, 300),
        profilPictureContainer_heigth: transformUXSpec(screenWidth, screenHeight, 400),
        textInputContainer_height: transformUXSpec(screenWidth, screenHeight, 50),
        textInputContainer_width: transformUXSpec(screenWidth, screenHeight, 575),
        buttonCancel_width: transformUXSpec(screenWidth, screenHeight, 410),
        buttonCancel_heigth: transformUXSpec(screenWidth, screenHeight, 100),
        buttonCreateAccount_width: transformUXSpec(screenWidth, screenHeight, 450),
        buttonCreateAccount_height: transformUXSpec(screenWidth, screenHeight, 100),
        cancelButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        createAccountButtonText_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        positionButtonSelected_width: transformUXSpec(screenWidth, screenHeight, 80),
        positionButtonSelected_height: transformUXSpec(screenWidth, screenHeight, 70),
        positionButton_width: transformUXSpec(screenWidth, screenHeight, 80),
        positionButton_height: transformUXSpec(screenWidth, screenHeight, 70),
        groupText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        formContainer_width: transformUXSpec(screenWidth, screenHeight, 600),
        formContainer_height: transformUXSpec(screenWidth, screenHeight, 425),
        positionPickerTitle_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        textPositionButton_fontSize: transformUXSpec(screenWidth, screenHeight, 38),
        textInputStyle_width: transformUXSpec(screenWidth, screenHeight, 500),
        lineRegistration_width: transformUXSpec(screenWidth, screenHeight, 600),
        passwordSubtitleBegining_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        passwordSubtitle_fontSize: transformUXSpec(screenWidth, screenHeight, 13),
        positionsRow_width: transformUXSpec(screenWidth, screenHeight, 900),
        contentContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        positionPickerContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        buttonsRow_marginLeft: transformUXSpec(screenWidth, screenHeight, 45),
        modalTitle_marginLeft: transformUXSpec(screenWidth, screenHeight, 275),
        positionRow_marginLeft: transformUXSpec(screenWidth, screenHeight, 20),
        textPositionButtonSelected_fontSize: transformUXSpec(screenWidth, screenHeight, 38),
        passwordSubtitle_width: transformUXSpec(screenWidth, screenHeight, 38),
        topSideContainer_height: transformUXSpec(screenWidth, screenHeight, 300),
        textInputContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        lineRegistration_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        camera_marginLeft: transformUXSpec(screenWidth, screenHeight, 200),
        camera_height: transformUXSpec(screenWidth, screenHeight, 350),
        camera_size: transformUXSpec(screenWidth, screenHeight, 60),
      }
    }
  }
}
