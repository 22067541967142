import React, { Dispatch } from 'react'
import { View, Dimensions, Text, TouchableOpacity } from 'react-native'
import { NavigationInterface } from './PageInfo'
import { Modal } from '../components/ModalImport'
import BasicPage from './BasicPage'
import { getRegistrationStyles, updateStyleParameters } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { connect } from 'react-redux'
import translator from '@taktik/config/Translation/languages'
import { ScrollView } from 'react-native-gesture-handler'
import { UserCreation, UserRole } from '@taktik/src/DataTypes/UserTypes'
import * as ImageManipulator from 'expo-image-manipulator'
import { Asset } from 'expo-asset'

import SnackBar from 'rn-snackbar'
import { TSnackBar } from './../components/snackbar/TSnackBar'
import { SnackBarType } from './../components/snackbar/snack-bar-type'

import { toggleRegistrationModal } from './../redux/actions'
import { getInfoFromToken, submitPlayerForToken } from '../Utilitary/TokenUtils'
import ImagePicker from '../components/ImagePicker'
import PositionPicker, { Group } from '../components/PositionPicker'
import CollapsibleContainer from '../components/CollapsibleContainer'
import { GroupNames } from '@taktik/config/Translation/utilities'
import { Locale } from '@taktik/config/Translation/locale'
import RegistrationForm, { RegistrationField } from '../components/RegistrationForm'
import RegistrationError from '../components/RegistrationError'
import { InputFieldValidation } from '../DataTypes/ValidationType'

export type RegistrationFormData = {
  [key in RegistrationField]: string
}

interface RegistrationProps extends NavigationInterface {
  token: string
  onClose: () => void
}

interface RegistrationState {
  styleParameters: StyleParameters

  formData: RegistrationFormData
  errors: InputFieldValidation[]

  photo: string | undefined
  positionSelected: number | undefined
  secondPositionSelected: number | undefined

  isLoaded: boolean
  isDisabled: boolean
  teamId: number | undefined
  positions: { [key in GroupNames]: Group } | undefined
  showErrors: boolean
}

class RegistrationPage extends BasicPage<RegistrationProps, RegistrationState> {
  constructor(public props: RegistrationProps) {
    super(props)

    this.state = {
      styleParameters: getStyleParameters(),

      formData: Object.values(RegistrationField).reduce(
        (allFields, field) => ({ ...allFields, [field]: undefined }),
        {} as RegistrationFormData
      ),
      errors: [],

      photo: undefined,
      positionSelected: undefined,
      secondPositionSelected: undefined,

      isLoaded: false,
      isDisabled: false,
      teamId: undefined,
      positions: undefined,
      showErrors: false,
    }
  }

  getDefaultPhotoBase64Data = async () => {
    const image = Asset.fromModule(require('../../assets/profile-picture-placeholder.png'))
    await image.downloadAsync()
    const manipResult = await ImageManipulator.manipulateAsync(image.localUri ?? image.uri, [], {
      base64: true,
      format: ImageManipulator.SaveFormat.PNG,
    })

    return manipResult.base64!
  }

  submitNewPlayer = async () => {
    const {
      isDisabled,
      positionSelected,
      secondPositionSelected,
      styleParameters,
      photo,
      teamId,
      formData,
      errors,
    } = this.state

    const { token, onClose } = this.props

    if (isDisabled) {
      return
    }

    if (teamId && positionSelected && errors.length === 0) {
      this.setState({ isDisabled: true })

      const photoData = photo?.toString() ?? (await this.getDefaultPhotoBase64Data())
      const userToCreate: UserCreation = {
        firstName: formData[RegistrationField.FIRSTNAME],
        lastName: formData[RegistrationField.LASTNAME],
        phone: formData[RegistrationField.PHONE],
        email: formData[RegistrationField.EMAIL],
        password: formData[RegistrationField.PASSWORD],
        number: formData[RegistrationField.PLAYER_NUMBER],
        teamID: teamId,
        photo: photoData,
        role: UserRole.Player,
        positionID: positionSelected,
        altPositionID: secondPositionSelected,
        language: styleParameters.isFrench ? Locale.FR : Locale.EN,
      }

      const { success, emailTaken } = await submitPlayerForToken(token, userToCreate)

      const feedbackMessage = success
        ? translator.t('modalCreateAccount.confirmationMessage')
        : emailTaken
        ? translator.t('modalCreateAccount.emailTakenMessage')
        : translator.t('errors.unexpected')

      SnackBar.show('', {
        position: 'top',
        renderContent: () => (
          <TSnackBar
            message={feedbackMessage}
            type={success ? SnackBarType.SUCCESS : SnackBarType.ERROR}
            onDismiss={() => SnackBar.dismiss()}
          />
        ),
      })

      onClose()
    } else {
      this.setState({ showErrors: true })
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount() {
    const { token, onClose } = this.props

    Dimensions.addEventListener('change', this.onChange)

    getInfoFromToken(token).then((tokenInfo) => {
      if (tokenInfo) {
        const { teamId, positions } = tokenInfo
        this.setState({ isLoaded: true, teamId, positions })
      } else {
        onClose()
      }
    })
  }

  renderPositionPickerContainer = () => {
    const { positions, styleParameters } = this.state
    const styles = getRegistrationStyles(styleParameters)
    if (!positions) {
      return
    }

    return (
      <View style={styles.bottomHalfContainer}>
        <CollapsibleContainer
          initiallyOpen
          title={translator.t('modalCreateAccount.position')}
          styleParameters={styleParameters}
        >
          <PositionPicker
            positions={positions}
            styleParameters={styleParameters}
            onPositionSelected={(position) => this.setState({ positionSelected: position.id })}
          />
        </CollapsibleContainer>
        <CollapsibleContainer
          title={translator.t('modalCreateAccount.alternativePosition')}
          styleParameters={styleParameters}
        >
          <PositionPicker
            positions={positions}
            styleParameters={styleParameters}
            onPositionSelected={(position) =>
              this.setState({ secondPositionSelected: position.id })
            }
          />
        </CollapsibleContainer>
        {this.renderButtonsRow()}
      </View>
    )
  }

  renderButtonsRow = () => {
    const { isDisabled, styleParameters } = this.state
    const { onClose } = this.props
    const styles = getRegistrationStyles(styleParameters)

    return (
      <View style={styles.buttonsRow}>
        <View style={styles.buttonCancel}>
          <TouchableOpacity style={styles.controlButton} onPress={() => onClose()}>
            <Text style={styles.cancelButtonText}>
              {translator.t('modalCreateAccount.alreadyHasAccount')}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.buttonCreateAccount}>
          <TouchableOpacity
            style={styles.controlButton}
            disabled={isDisabled}
            onPress={() => this.submitNewPlayer()}
          >
            <Text style={styles.createAccountButtonText}>
              {translator.t('modalCreateAccount.createAccount')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  render() {
    const { isLoaded, errors, showErrors, photo, positionSelected, styleParameters } = this.state
    const styles = getRegistrationStyles(styleParameters)

    return (
      <View style={styles.ModalBackground}>
        <Modal transparent>
          <ScrollView style={styles.modalContainer}>
            <Text style={styles.modalTitle}>
              {translator.t('modalCreateAccount.createAccount')}
            </Text>
            <View style={styles.contentContainer}>
              <View style={styles.topSideContainer}>
                <ImagePicker
                  pickerImageUri={photo}
                  styleParameters={styleParameters}
                  onSelectImage={(imageUri) => this.setState({ photo: imageUri })}
                />
                <RegistrationForm
                  showErrors={showErrors}
                  styleParameters={styleParameters}
                  onChange={(formData, errors) => this.setState({ formData, errors })}
                />
              </View>
            </View>
            {showErrors && (
              <RegistrationError
                errors={errors}
                selectedPosition={positionSelected}
                styleParameters={styleParameters}
              />
            )}
            {isLoaded && this.renderPositionPickerContainer()}
          </ScrollView>
        </Modal>
      </View>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onClose: () => dispatch(toggleRegistrationModal(false)),
})

export default connect(null, mapDispatchToProps)(RegistrationPage)
