import React from 'react'
import { AppState, Dimensions, Image, Platform, Text, View, StyleSheet } from 'react-native'
import { NavigationInterface, PageName } from './PageInfo'
import TopBarMenu from './TopBarMenu'
import { Playbook } from '../DataTypes/playbooks'
import BasicPage from './BasicPage'
import {
  getGlobalStyles,
  getPlaybookStyles,
  getTopBarMenuStyles,
  updateStyleParameters,
} from '../Styles/AppStyle'
import { StyleParameters, getMarginToTransformIn43, getStyleParameters } from '../Styles/Utilities'
import ImageZoom from 'react-native-image-pan-zoom'
import { axiosGetPlaybook } from '../Utilitary/DataUtils'
import {
  synchronizeStudyTimes,
  incrementStudyDuration,
  setAsyncStudyTimeState,
  blurHandler,
} from '../Services/StudyTimeService'
import { StudyTime } from '../DataTypes/StudyTimes'

import { getTranslatedGroupName } from './PlaybookListPage'
import { logger } from '../../lib/logger'
import { connect } from 'react-redux'
import { toggleAlertStudyTimeModal } from '../redux/actions'
import { store } from '../redux/store'
import { RootState } from '../redux/reducers'
import {
  maxStudyTimeDuration,
  renderAlertStudyDurationModal,
} from '../components/StudyDurationModal'
import { isURL, storageGetImageData } from '../Utilitary/StorageUtils'

interface PlaybookProps extends NavigationInterface {
  playbook?: Playbook
  alertStudyDurationVisible: boolean
}

interface PlaybookState {
  styleParameters: StyleParameters
  currentSessionStudyTime: StudyTime
  imageSize: { width: number; height: number }
  properties: Playbook
  comments: any[]
  playbookIDs: number[]
  currentIndex: number
}

class PlaybookViewPage extends BasicPage<PlaybookProps, PlaybookState> {
  public playbookTimerID: any = null
  public localTimerID: any = null
  public isFocused: boolean = true
  constructor(public props: PlaybookProps) {
    super(props)
    this.onPress = this.onPress.bind(this)
    const properties = props.playbook ? props.playbook : new Playbook()

    const playbookID = this.props.route.params.playbookID
    const playbookIDs = this.props.route.params.playbookIDs
    const currentIndex = playbookIDs.findIndex((id: number) => {
      return id === playbookID
    })
    this.state = {
      currentSessionStudyTime: {
        startTime: new Date(),
        duration: 0,
        playbookID,
        userID: -1,
        sessionID: '',
      },
      imageSize: { width: 1, height: 1 },
      properties,
      comments: [],
      playbookIDs: playbookIDs,
      currentIndex: currentIndex,
      styleParameters: getStyleParameters(),
    }

    setAsyncStudyTimeState(this)

    if (!props.playbook) {
      axiosGetPlaybook(playbookID).then(
        (playbook) => {
          if (playbook) {
            this.setState({ properties: playbook })
          }
        },
        (error) => {
          logger.error(error)
        }
      )
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  async componentDidMount() {
    const OS = Platform.OS
    if (OS === 'ios' || OS === 'android') {
      AppState.addEventListener('change', this.handleAppStateChange)
      await this.updateSnapshotToBase64()
    } else {
      addEventListener('focus', this.handleFocus)
      addEventListener('blur', this.handleBlur)
    }

    Dimensions.addEventListener('change', this.onChange)
    store.dispatch(toggleAlertStudyTimeModal(false))

    let inactivityTimer = 0

    this.playbookTimerID = setInterval(() => {
      incrementStudyDuration(this)
      if (this.isFocused) inactivityTimer += 1
      if (inactivityTimer % maxStudyTimeDuration === 0) {
        this.isFocused = false
        store.dispatch(toggleAlertStudyTimeModal(true))
      }
    }, 1000)
  }

  updateSnapshotToBase64 = async () => {
    const { properties } = this.state

    const snapshotURL: string = properties.snapshot

    if (!isURL(snapshotURL)) {
      return
    }

    const base64Data = await storageGetImageData(snapshotURL)

    if (base64Data) {
      // Should be OK to allow a `setState` here since this will only be triggered once
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ properties: { ...properties, snapshot: base64Data } })
    }
  }

  handleBlur = async () => {
    this.isFocused = false
    blurHandler(this)
  }

  handleFocus = () => {
    this.isFocused = true
  }

  handleAppStateChange = () => {
    this.isFocused =
      AppState.currentState === 'active' && this.props.route.name === PageName.PlaybookPage
    blurHandler(this)
  }

  componentWillUnmount() {
    clearInterval(this.playbookTimerID)
    clearInterval(this.localTimerID)
    synchronizeStudyTimes(this)
      .then()
      .catch((error) => {
        logger.info(error)
      })

    const OS = Platform.OS
    if (OS === 'ios' || OS === 'android') {
      AppState.removeEventListener('change', this.handleAppStateChange)
    } else {
      removeEventListener('focus', this.handleFocus)
      removeEventListener('blur', this.handleBlur)
    }

    Dimensions.removeEventListener('change', this.onChange)
  }

  onPress() {
    this.isFocused = true
  }

  render() {
    const { imageSize, styleParameters } = this.state
    const { title, snapshot, group } = this.state.properties
    const { navigation, alertStudyDurationVisible } = this.props
    const { isHorizontal, isMobile, screenWidth, screenHeight } = styleParameters

    const snapshotIsNotEmpty: boolean = snapshot !== ''

    if (snapshotIsNotEmpty && (imageSize.width === 1 || imageSize.height === 1))
      Image.getSize(
        snapshot,
        (width, height) => {
          this.setState({ imageSize: { width, height } })
        },
        (failure) => {
          logger.info('Failed to get Image Size ' + failure)
        }
      )
    const window = Dimensions.get('window')

    const marginHorizontal =
      isHorizontal && !isMobile ? getMarginToTransformIn43(screenWidth, screenHeight) : 0
    const windowWidth = window.width - marginHorizontal

    const imageHeight = Math.min(
      window.height - 0.3 * window.height,
      (windowWidth / imageSize.width) * imageSize.height
    )
    const imageWidth = (imageHeight * imageSize.width) / imageSize.height

    let pageTitle: string = group ?? ''

    if (group) {
      pageTitle = getTranslatedGroupName(group)
    }

    const topMenuBarWidth: number = StyleSheet.flatten(
      getTopBarMenuStyles(styleParameters).topBarMenu
    ).width

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        <TopBarMenu
          navigation={navigation}
          url={PageName.PlaybookPage}
          updatePage={() => this.forceUpdate()}
          userMustBeLoggedOut={false}
          pageTitle={pageTitle}
          playbookTitle={title.toUpperCase()}
          firstPlaybookIndex={this.state.currentIndex}
          playbookIDArray={this.state.playbookIDs}
        />
        <View style={getPlaybookStyles(styleParameters).playbookPageContainer}>
          {snapshot !== '' ? (
            <ImageZoom
              cropWidth={topMenuBarWidth}
              cropHeight={window.height - 0.3 * window.height}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
            >
              <Image
                source={{ uri: snapshot === '' ? undefined : snapshot }}
                style={[
                  {
                    width: imageWidth,
                    height: imageHeight,
                    alignSelf: 'center',
                  },
                  {},
                ]}
              />
            </ImageZoom>
          ) : null}
          {this.state.comments.map((comment) => {
            return (
              <Text key={comment.id} style={getPlaybookStyles(styleParameters).playbookText}>
                {comment.text}
              </Text>
            )
          })}
        </View>

        {alertStudyDurationVisible &&
          renderAlertStudyDurationModal(
            styleParameters,
            navigation,
            alertStudyDurationVisible,
            this.onPress
          )}
      </View>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  alertStudyDurationVisible: state.modals.alertStudyDurationVisible,
})

export default connect(mapStateToProps, {
  toggleAlertStudyTimeModal,
})(PlaybookViewPage)
