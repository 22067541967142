import translator from '@taktik/config/Translation/languages'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Dispatch } from 'react'
import { RootState } from '@taktik/src/redux/reducers'
import { TokenArray } from '@taktik/src/redux/reducers/teams'
import { setSelectedTeam, setSelectedToken } from '@taktik/src/redux/actions'
import { setLocalAuthorizationToken } from '@taktik/src/Utilitary/ConnectionUtils'
import {
  isTeamActive,
  isUserAssociatedWithTeam,
  setPlaybooks,
} from '@taktik/src/Utilitary/DataUtils'

export const selectAndLoadTeam =
  (teamId: number, onSuccess?: () => void, onError?: (error: string) => void) =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const rawTokens = await AsyncStorage.getItem('tokens')
    const tokens = (JSON.parse(rawTokens ?? '[]') ?? []) as TokenArray[]
    const selectedToken = tokens.find((token) => token.teamID === teamId)

    const state = getState()
    const userId = state.auth.user.id ?? -1

    const isValid = await isTeamValid(teamId, userId)
    if (!isValid) {
      if (onError) {
        onError(translator.t('errors.unexpected'))
      }
      return
    }

    if (selectedToken) {
      await dispatch(setSelectedTeam(teamId))
      await setLocalAuthorizationToken(selectedToken.token)
      dispatch(setSelectedToken(selectedToken.token))
      await setPlaybooks()
    }
    if (onSuccess) {
      onSuccess()
    }
  }

const isTeamValid = async (teamId: number, userId: number): Promise<boolean> => {
  const validationPromises = [isUserAssociatedWithTeam(teamId, userId), isTeamActive(teamId)]
  const validationResponses = await Promise.all(validationPromises)
  return validationResponses.every((validationPassed) => validationPassed)
}
