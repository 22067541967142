import { ScaledSize, Dimensions } from 'react-native'
import { isColor } from '../Utilitary/Utils'
import loginStyles from './General/LoginStyles'
import playbookStyles from './General/PlaybooksStyles'
import loadingStyles from './General/LoadingStyles'
import topBarMenuStyles from './General/TopBarMenuStyles'
import forgotPasswordStyles from './General/ForgotPasswordStyles'
import hamburgerIconStyles from './General/HamburgerIconStyles'
import registrationModalStyles from './General/RegistrationStyles'
import globalStyles from './GlobalStyles'
import { StyledComponentsTheme, DarkTheme } from './Theme'
import gamificationStyles from './General/GamificationStyles'
import identificationNameStyles from './General/IdentificationNameStyles'
import identificationPlaybookStyles from './General/IdentificationPlaybookStyles'
import loadingBarStyles from './General/LoadingBarStyles'
import {
  playerStatsStyles,
  graphAxisYSvg,
  graphAxisXSvg,
  graphChartSvg,
} from './General/PlayerStatsStyles'
import homeStyles from './General/HomeStyles'
import {
  getScreenHeight,
  getScreenWidth,
  isFrench,
  isMobile,
  isTablet,
  screenIsHorizontal,
  StyleParameters,
} from './Utilities'
import { teamSelectionStyles } from './General/TeamSelectionStyles'
import userTeamInviteModalStyles from './General/UserTeamInviteModalStyles'
import reconnectModalStyles from './General/ReconnectModalStyles'
import downloadAppNotificationStyles from './General/DownloadAppNotificationStyles'

type globalStyleReturnType = ReturnType<typeof globalStyles>
type playbookStyleReturnType = ReturnType<typeof playbookStyles>
type gamificationStyleReturnType = ReturnType<typeof gamificationStyles>
type identificationNameStyleReturnType = ReturnType<typeof identificationNameStyles>
type identificationPlaybookStyleReturnType = ReturnType<typeof identificationPlaybookStyles>
type playerStatsStyleReturnType = ReturnType<typeof playerStatsStyles>
type teamSelectionStyleReturnType = ReturnType<typeof teamSelectionStyles>
type loginStyleReturnType = ReturnType<typeof loginStyles>
type loadingStyleReturnType = ReturnType<typeof loadingStyles>
type topBarMenuStyleReturnType = ReturnType<typeof topBarMenuStyles>
type hamburgerIconStyleReturnType = ReturnType<typeof hamburgerIconStyles>
type forgotPasswordStyleReturnType = ReturnType<typeof forgotPasswordStyles>
type HomeReturnType = ReturnType<typeof homeStyles>
type LoadingBarType = ReturnType<typeof loadingBarStyles>
type registrationModalType = ReturnType<typeof registrationModalStyles>
type userTeamIviteModalType = ReturnType<typeof userTeamInviteModalStyles>
type reconnectModalType = ReturnType<typeof reconnectModalStyles>
type downloadAppNotificationStylesType = ReturnType<typeof downloadAppNotificationStyles>

const startColor = '#ffa500'

//CHANGES ADD color for contrast to button/team color

let appSize: ScaledSize = Dimensions.get('window')
let appTheme: StyledComponentsTheme = DarkTheme
let teamColor: string = startColor

export function setTheme(newTheme: StyledComponentsTheme) {
  appTheme = newTheme
}

export function setTeamColor(newTeamColor: string) {
  if (isColor(newTeamColor)) teamColor = newTeamColor
}

export function setAppSize(newAppSize: ScaledSize) {
  appSize = newAppSize
}

export function getRowSize() {
  let rowSize = 0
  if (appSize.width < 500) {
    rowSize += Math.floor(appSize.width / 100)
  } else {
    rowSize += 4
    if (appSize.width < 800) {
      rowSize += Math.floor((appSize.width - 500) / 150)
    } else {
      rowSize += 2
      if (appSize.width < 1200) {
        rowSize += Math.floor((appSize.width - 800) / 200)
      } else {
        rowSize += 2
        rowSize += Math.floor((appSize.width - 1200) / 250)
      }
    }
  }
  return rowSize
}

export function updateStyleParameters(styleParameters: any): any {
  const {
    isMobile: isMobilePreviousState,
    isHorizontal: isHorizontalPreviousState,
    isFrench: isFrenchPreviousState,
    screenWidth: previousWidth,
    screenHeight: previousHeight,
    isTablet: isTabletPreviousState,
  } = styleParameters
  const width = getScreenWidth()
  const height = getScreenHeight()
  const isCurrentlyFrench = isFrench()
  const isCurrentlyMobile = isMobile()
  const isHorizontal = screenIsHorizontal()
  const isCurrentlyTablet = isTablet()

  const anyChange =
    isFrenchPreviousState !== isCurrentlyFrench ||
    previousWidth !== width ||
    previousHeight !== height ||
    isCurrentlyMobile !== isMobilePreviousState ||
    isHorizontal !== isHorizontalPreviousState ||
    isCurrentlyTablet !== isTabletPreviousState

  if (anyChange) {
    return {
      isFrench: isCurrentlyFrench,
      isHorizontal: isHorizontal,
      isMobile: isCurrentlyMobile,
      screenWidth: width,
      screenHeight: height,
      isTablet: isCurrentlyTablet,
    }
  } else {
    return null
  }
}

export function getGlobalStyles(styleParameters: StyleParameters): globalStyleReturnType {
  return globalStyles(appSize, appTheme, teamColor, styleParameters)
}

export function getTopBarMenuStyles(
  styleParameters: StyleParameters,
  booleanValue?: boolean,
  maxChildren?: number
): topBarMenuStyleReturnType {
  return topBarMenuStyles(appSize, appTheme, teamColor, styleParameters, booleanValue, maxChildren)
}

export function getHamburgerIconStyles(
  styleParameters: StyleParameters
): hamburgerIconStyleReturnType {
  return hamburgerIconStyles(appSize, appTheme, teamColor, styleParameters)
}

//CHANGES seperate playbookListStyles and playbookPageStyles

export function getPlaybookStyles(styleParameters: StyleParameters): playbookStyleReturnType {
  return playbookStyles(appSize, appTheme, teamColor, getRowSize(), styleParameters)
}

export function getGamificationStyles(
  styleParameters: StyleParameters
): gamificationStyleReturnType {
  return gamificationStyles(appSize, appTheme, teamColor, getRowSize(), styleParameters)
}

export function getRegistrationStyles(styleParameters: StyleParameters): registrationModalType {
  return registrationModalStyles(appSize, appTheme, teamColor, styleParameters)
}

export function getIdentificationNameStyles(
  styleParameters: StyleParameters
): identificationNameStyleReturnType {
  return identificationNameStyles(appSize, appTheme, teamColor, getRowSize(), styleParameters)
}

export function getIdentificationPlaybookStyles(
  styleParameters: StyleParameters,
  width?: number,
  height?: number,
  boolValue?: boolean
): identificationPlaybookStyleReturnType {
  return identificationPlaybookStyles(
    appSize,
    appTheme,
    teamColor,
    getRowSize(),
    styleParameters,
    width,
    height,
    boolValue
  )
}

export function getHomeStyles(
  styleParameters: StyleParameters,
  isProfile?: boolean
): HomeReturnType {
  return homeStyles(appSize, appTheme, teamColor, getRowSize(), styleParameters, isProfile)
}

export function getLoadingBarStyles(styleParameters: StyleParameters): LoadingBarType {
  return loadingBarStyles(appSize, appTheme, getRowSize(), styleParameters)
}

export function getUserTeamInviteStyles(styleParameters: StyleParameters): userTeamIviteModalType {
  return userTeamInviteModalStyles(appSize, appTheme, teamColor, styleParameters)
}

export function getDownloadAppNotificationStyles(
  styleParameters: StyleParameters
): downloadAppNotificationStylesType {
  return downloadAppNotificationStyles(styleParameters)
}

export function getPlayerStatsStyles(
  styleParameters: StyleParameters,
  selected?: boolean,
  NumberTimeTick?: number,
  timeSpan?: number
): playerStatsStyleReturnType {
  return playerStatsStyles(
    appSize,
    appTheme,
    teamColor,
    getRowSize(),
    styleParameters,
    NumberTimeTick,
    timeSpan,
    selected
  )
}

export function graphAxisYStyle(styleParameters: any) {
  return graphAxisYSvg(appTheme, styleParameters)
}

export function graphAxisXStyle() {
  return graphAxisXSvg(appTheme)
}
export function graphChartStyle() {
  return graphChartSvg(appTheme)
}

export function getLoginStyles(styleParameters: StyleParameters): loginStyleReturnType {
  return loginStyles(appSize, appTheme, teamColor, styleParameters)
}

export function getLoadingStyles(): loadingStyleReturnType {
  return loadingStyles(appSize, appTheme, teamColor)
}

export function getForgotPasswordStyles(
  isHorizontal: boolean,
  isFrench: boolean,
  isTablet: boolean
): forgotPasswordStyleReturnType {
  return forgotPasswordStyles(appSize, appTheme, teamColor, isHorizontal, isFrench, isTablet)
}

export function getTeamSelectionStyles(
  styleParameters: StyleParameters
): teamSelectionStyleReturnType {
  return teamSelectionStyles(appSize, appTheme, teamColor, getRowSize(), styleParameters)
}

export function getReconnectModalStyles(styleParameters: StyleParameters): reconnectModalType {
  return reconnectModalStyles(appSize, appTheme, teamColor, styleParameters)
}
