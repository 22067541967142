import { LogLevel } from '../../lib/logger/types'

export class EnvironmentConfiguration {
  private apiUrl: string
  private logLevel: LogLevel

  constructor(apiUrl: string | undefined, logLevel: LogLevel) {
    this.apiUrl = assertDefined(apiUrl, 'apiUrl environment configuration value missing')
    this.logLevel = logLevel
  }

  public getApiUrl() {
    return this.apiUrl
  }

  public getLogLevel(): LogLevel {
    return this.logLevel
  }

  public static getBuilder() {
    return new EnvironmentConfigurationBuilder()
  }
}

class EnvironmentConfigurationBuilder {
  private _apiUrl?: string
  private _logLevel: LogLevel = LogLevel.info

  public apiUrl(apiUrl: string): EnvironmentConfigurationBuilder {
    this._apiUrl = apiUrl
    return this
  }

  public logLevel(logLevel: LogLevel): EnvironmentConfigurationBuilder {
    this._logLevel = logLevel
    return this
  }

  public build(): EnvironmentConfiguration {
    return new EnvironmentConfiguration(this._apiUrl, this._logLevel)
  }
}

function assertDefined<T>(value: T | undefined, message: string): T {
  if (value === undefined) {
    throw Error(message)
  }
  return value
}
