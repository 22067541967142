import { StyleSheet, ScaledSize, Platform } from 'react-native'
import { StyleParameters } from '../Utilities'
import { StyledComponentsTheme, GlobalColors } from '../Theme'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/RegistrationsRatiosStyles'
export default function getRegistrationStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  _teamColor: string,
  styleParameters: StyleParameters
) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(styleParameters)
  return StyleSheet.create({
    modalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: styleParameters.screenWidth,
      height: styleParameters.screenHeight,
    },
    modalContainer: {
      position: 'absolute',
      top: '5%',
      alignSelf: 'center',
      backgroundColor: theme.colors.primary,
      width: screenRatio.ModalContainer_width,
      height: screenRatio.ModalContainer_height,
      borderRadius: screenRatio.ModalContainer_borderRadius,
    },
    buttonCancel: {
      width: screenRatio.buttonCancel_width,
      backgroundColor: theme.colors.secondary,
      justifyContent: 'center',
      height: screenRatio.buttonCancel_heigth,
      transform: [{ skewX: '-10deg' }],
    },
    buttonCreateAccount: {
      width: screenRatio.buttonCreateAccount_width,
      backgroundColor: GlobalColors.defaultHomeColor,
      justifyContent: 'center',
      height: screenRatio.buttonCreateAccount_height,
      transform: [{ skewX: '-10deg' }],
      color: theme.textColors.primary,
    },
    cancelButtonText: {
      fontFamily: 'barlow-semi',
      color: theme.textColors.secondary,
      fontSize: screenRatio.cancelButtonText_fontSize,
      textAlign: 'center',
      transform: [{ skewX: '10deg' }],
      textTransform: 'uppercase',
    },
    createAccountButtonText: {
      fontFamily: 'barlow-semi',
      color: theme.textColors.secondary,
      fontSize: screenRatio.createAccountButtonText_fontSize,
      textAlign: 'center',
      transform: [{ skewX: '10deg' }],
      textTransform: 'uppercase',
    },
    passwordSubtitleBegining: {
      color: GlobalColors.mediumGrey,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.passwordSubtitleBegining_fontSize,
    },
    passwordSubtitle: {
      fontFamily: 'barlow-semi',
      color: GlobalColors.mediumGrey,
      fontSize: screenRatio.passwordSubtitle_fontSize,
      textTransform: 'uppercase',
    },
    passwordSubtitleDefault: {
      color: GlobalColors.mediumGrey,
    },
    passwordSubtitleError: {
      color: 'red',
    },
    passwordSubtitleCorrect: {
      color: 'green',
    },
    errorText: {
      paddingTop: 5,
      color: 'red',
      textAlign: 'center',
      marginTop: 20,
      fontSize: 15,

      fontFamily: 'barlow-extra-italic',
    },
    modalTitle: {
      fontFamily: 'barlow-extra-italic',
      fontSize: 35,
      marginTop: 20,
      color: theme.textColors.primary,
      marginLeft: screenRatio.modalTitle_marginLeft,
      textTransform: 'uppercase',
    },
    contentContainer: {
      //justifyContent: 'space-between',
      flexDirection: 'row',
      width: '90%',
      marginTop: 20,
      marginLeft: screenRatio.contentContainer_marginLeft,
      height: '18%',
    },
    topSideContainer: {
      width: '100%',
      flexDirection: 'row',
      height: '100%',
      marginBottom: 30,
    },
    formContainer: {
      justifyContent: 'flex-start',
      marginLeft: 20,
      width: screenRatio.formContainer_width,
      height: screenRatio.formContainer_height,
    },
    textInputContainer: {
      width: screenRatio.formContainer_width,
      flexDirection: 'row',
      height: screenRatio.textInputContainer_height,
      marginLeft: screenRatio.textInputContainer_marginLeft,
    },
    textInputStyle: {
      paddingTop: 2,
      marginLeft: 15,
      fontFamily: 'barlow-extra-italic',
      color: theme.textColors.primary,
      textAlign: 'left',
      width: screenRatio.textInputStyle_width,
      fontVariant: Platform.OS === 'android' ? ['small-caps'] : [],
    },
    passwordEyeIcon: {
      alignContent: 'center',
      justifyContent: 'center',
    },
    invisibleButton: {
      backgroundColor: '#0000',
      width: 50,
      height: 50,
      justifyContent: 'center',
    },
    positionButton: {
      backgroundColor: theme.colors.secondary,
      width: screenRatio.positionButton_width,
      height: screenRatio.positionButton_height,
      margin: 10,
      justifyContent: 'center',
    },
    positionButtonSelected: {
      backgroundColor: GlobalColors.defaultHomeColor,
    },
    textPositionButton: {
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.textPositionButton_fontSize,
      color: theme.textColors.primary,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    textPositionButtonSelected: {
      fontSize: screenRatio.textPositionButtonSelected_fontSize,
      color: theme.textColors.secondary,
    },
    positionsRow: {
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'wrap',
      paddingLeft: screenRatio.positionRow_marginLeft,
      paddingRight: screenRatio.positionRow_marginLeft,
    },
    positionPickingGroupHeaderContainer: {
      position: 'relative',
      width: '100%',
      marginBottom: 10,
    },
    positionContainer: {
      width: '100%',
      flexDirection: 'row',
    },
    profilPictureContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: screenRatio.profilPictureContainer_width,
      height: screenRatio.profilPictureContainer_heigth,
    },
    groupTitleIcon: {
      fontSize: 25,
      color: GlobalColors.defaultHomeColor,
      marginRight: 10,
    },
    profilPicture: {
      backgroundColor: 'red',
      borderWidth: 1,
      borderColor: theme.textColors.primary,
      width: '90%',
      height: '90%',
    },
    groupText: {
      paddingTop: 15,
      paddingLeft: 20,
      fontFamily: 'barlow-extra-italic',
      color: theme.textColors.primary,
      fontSize: screenRatio.groupText_fontSize,
      textTransform: 'uppercase',
    },
    positionPickerContainer: {
      marginTop: 30,
      width: '90%',
      flexDirection: 'column',
      marginBottom: 30,
      paddingBottom: 50,
      paddingLeft: screenRatio.positionPickerContainer_marginLeft,
    },
    positionPickerHeaderContainer: {
      width: '100%',
      flexDirection: 'row',
    },
    positionPickerTitleContainer: {
      width: '100%',
      flexDirection: 'column',
    },
    positionPickerTitle: {
      fontFamily: 'barlow-extra-italic',
      color: theme.textColors.primary,
      fontSize: screenRatio.positionPickerTitle_fontSize,
      textTransform: 'uppercase',
    },
    positionPickerToggle: {
      width: '100%',
      flexDirection: 'row',
    },
    positionWrapper: {
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    buttonsRow: {
      flexDirection: 'row',
      width: '90%',
      justifyContent: 'space-between',
      paddingTop: 100,
      paddingBottom: 15,
      marginLeft: screenRatio.buttonsRow_marginLeft,
    },
    bottomHalfContainer: {
      height: '100%',
      position: 'absolute',
      top: '100%',
      width: '100%',
    },
    controlButton: {
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    line: {
      backgroundColor: GlobalColors.mediumGrey,
      height: 1,
      marginBottom: 5,
      marginLeft: -10,
    },
    lineRegistration: {
      backgroundColor: GlobalColors.mediumGrey,
      height: 1,
      width: screenRatio.lineRegistration_width,
      marginBottom: 5,
      marginLeft: screenRatio.lineRegistration_marginLeft,
    },
    ModalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: styleParameters.screenWidth,
      height: styleParameters.screenHeight,
      backgroundColor: theme.colors.popUpBackground,
    },
    camera: {
      position: 'absolute',
      fontSize: screenRatio.camera_size,
      height: screenRatio.camera_height,
      marginLeft: screenRatio.camera_marginLeft,
    },
  })
}
