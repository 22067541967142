import React, { Component } from 'react'
import { NavigationInterface } from './PageInfo'
import { Dimensions, Image, Text, View, TouchableOpacity } from 'react-native'
import { getIdentificationPlaybookStyles, updateStyleParameters } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import translator from '@taktik/config/Translation/languages'
import { Playbook } from '../DataTypes/playbooks'
import { getScreenRatioStyle } from '../Styles/Peculiar/IdentificationPlaybookRatiosStyles'
import { DateTime } from 'luxon'
import { axiosPostResult } from '../Utilitary/DataUtils'
import { ScrollView } from 'react-native-gesture-handler'
import { logger } from '../../lib/logger'
import { RootState } from '../redux/reducers'
import { connect } from 'react-redux'
import { User, UserRole } from '../DataTypes/UserTypes'
import { Modal } from '../components/ModalImport'
import { orientationIOS } from './TopBarMenu'
import CancelIcon from '../../assets/svg/CancelIcon'
import ZoomInIcon from '../../assets/svg/ZoomInIcon'
import ZoomOutIcon from '../../assets/svg/ZoomOutIcon'
import ImageZoom from 'react-native-image-pan-zoom'

interface SnapshotInfo {
  snapshot: string
  width: number
  height: number
}

interface IdentificationPlaybookProps extends NavigationInterface {
  correctAnswerIndex: number
  playbooks: Playbook[]
  snapshotInfos: SnapshotInfo[]
  exerciseID: number
  startTime: DateTime
  callback: (isExerciseSucceeded: boolean) => void
  beginSession: boolean
  correctPlaybookIDAnswer: number
  user: User
}

interface IdentificationNameState {
  styleParameters: StyleParameters
  selectedPlaybookIndex: number
  answerWasSubmitted: boolean
  gotCorrectAnswer: boolean
  canConfirmSelection: boolean
  submittedPlaybookIndex: number
  zoomModalVisible: boolean
  zoomedSnapshot: string
  zoom: number
  imageWidth: number
  imageHeight: number
  warningText: string
}

const MAX_ZOOM = 2
const MIN_ZOOM = 1
const ZOOM_INCR = 0.25

class IdentificationPlaybook extends Component<
  IdentificationPlaybookProps,
  IdentificationNameState
> {
  public totalSnapshotsHeight = 0
  constructor(props: IdentificationPlaybookProps) {
    super(props)
    this.state = {
      selectedPlaybookIndex: -1,
      answerWasSubmitted: false,
      canConfirmSelection: true,
      gotCorrectAnswer: false,
      submittedPlaybookIndex: -1,
      styleParameters: getStyleParameters(),
      zoomModalVisible: false,
      zoomedSnapshot: '',
      zoom: MIN_ZOOM,
      imageWidth: 0,
      imageHeight: 0,
      warningText: '',
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidUpdate = () => {
    this.totalSnapshotsHeight = 0
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this.onChange)
    this.totalSnapshotsHeight = 0
  }

  componentWillUnMount() {
    Dimensions.removeEventListener('change', this.onChange)
  }

  renderPlaybooks = () => {
    const playbooks: Playbook[] = this.getPlaybooks()

    return playbooks.map((playbook) => this.renderPaddedPlaybook(playbook.snapshot))
  }

  getPlaybooks = () => {
    const { playbooks } = this.props

    return playbooks
  }

  renderPaddedPlaybook = (snapshot: string) => {
    return (
      <>
        <View
          style={getIdentificationPlaybookStyles(this.state.styleParameters).playbookImageContainer}
        >
          {this.renderPlaybook(snapshot)}
        </View>
        {this.renderPadding()}
      </>
    )
  }

  renderPadding = () => {
    const { styleParameters } = this.state

    if (styleParameters.isMobile && !styleParameters.isHorizontal) {
      this.totalSnapshotsHeight += getScreenRatioStyle(styleParameters).playbookPadding_width
    }

    return <View style={getIdentificationPlaybookStyles(styleParameters).playbookPadding} />
  }

  renderPlaybookPictureIdentification = () => {
    const { styleParameters } = this.state
    return (
      <View style={getIdentificationPlaybookStyles(styleParameters).page}>
        {this.renderPlaybookTitle()}
        <View style={getIdentificationPlaybookStyles(styleParameters).gameContainer}>
          <View style={getIdentificationPlaybookStyles(styleParameters).playbooksContainer}>
            {this.renderPlaybooks()}
          </View>
        </View>
      </View>
    )
  }

  handleConfirm = () => {
    const { answerWasSubmitted, selectedPlaybookIndex } = this.state
    const { correctAnswerIndex, playbooks, exerciseID, startTime, beginSession, user } = this.props
    const playbookSelected = selectedPlaybookIndex !== -1
    const canSubmit: boolean = !answerWasSubmitted && playbookSelected
    if (canSubmit) {
      const endTime: DateTime = DateTime.local()
      let completionTime: number = endTime.diff(startTime).milliseconds
      const selectedPlaybookID: number = playbooks[selectedPlaybookIndex].id
      const gotCorrectAnswer = selectedPlaybookIndex === correctAnswerIndex
      this.setState({
        gotCorrectAnswer: gotCorrectAnswer,
        answerWasSubmitted: !answerWasSubmitted,
        canConfirmSelection: false,
        warningText: '',
      })
      if (beginSession) {
        completionTime = completionTime - 3000
      }
      if (completionTime < 0) {
        completionTime = 0
      }
      if (user.role !== UserRole.Coach) {
        // Do not submit exercice results as a coach
        axiosPostResult(exerciseID, completionTime, selectedPlaybookID)
      }
      this.props.callback(gotCorrectAnswer)
    } else if (!playbookSelected) {
      this.setState({
        warningText: translator.t('gamification.selectWarningPlaybookIdentification'),
      })
    }
  }

  renderConfirmBar = () => {
    const { styleParameters, warningText } = this.state
    const isMobileVertical: boolean = styleParameters.isMobile && !styleParameters.isHorizontal

    const containerStyle = isMobileVertical
      ? getIdentificationPlaybookStyles(styleParameters, undefined, this.totalSnapshotsHeight)
          .confirmButtonContainer_verticalMobile
      : getIdentificationPlaybookStyles(styleParameters).confirmButtonContainer
    return (
      <View style={containerStyle}>
        <Text style={getIdentificationPlaybookStyles(styleParameters).warningText}>
          {warningText}
        </Text>
        <View style={getIdentificationPlaybookStyles(styleParameters).confirmButton}>
          <TouchableOpacity
            style={{ justifyContent: 'center', width: '100%', height: '100%' }}
            onPress={() => {
              this.handleConfirm()
            }}
          >
            {this.getConfirmButtonMessage()}
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  getConfirmButtonMessage = () => {
    const { styleParameters } = this.state

    return (
      <Text
        adjustsFontSizeToFit
        style={getIdentificationPlaybookStyles(styleParameters).confirmText}
      >{`${translator.t('global.validateAnswer').toUpperCase()}`}</Text>
    )
  }

  getPlaybookIndex = (snapshot: string): number => {
    const playbooks: Playbook[] = this.getPlaybooks()

    const playbookIndex = playbooks.findIndex((playbook) => playbook.snapshot === snapshot)

    return playbookIndex
  }

  handleSelectPlaybook = (playbookIndex: number) => {
    if (!this.state.answerWasSubmitted) {
      this.setState({ submittedPlaybookIndex: playbookIndex })
    }
    this.setState({ selectedPlaybookIndex: playbookIndex })
  }

  handleLongPress = (snapshot: string) => {
    const { imageWidth, imageHeight } = this.getDeviceSnapshotInfo(snapshot, {
      width: 'PlaybookZoomedImage_width',
      height: 'PlaybookZoomedImage_height',
    })

    this.setState({ zoomedSnapshot: snapshot, imageWidth, imageHeight })

    this.toggleZoomModal(!this.state.zoomModalVisible)
  }

  toggleZoomModal = (toggle: boolean) => {
    this.setState({ zoomModalVisible: toggle, zoom: MIN_ZOOM })
  }

  zoom = (toZoomIn: boolean) => {
    const { zoom } = this.state

    if (toZoomIn) {
      if (zoom < MAX_ZOOM) {
        this.setState({
          zoom: zoom + ZOOM_INCR,
        })
      }
    } else {
      if (zoom > MIN_ZOOM) {
        this.setState({
          zoom: zoom - ZOOM_INCR,
        })
      }
    }
  }

  renderPromptPlaybookPick = () => {
    return (
      <Text
        adjustsFontSizeToFit
        style={getIdentificationPlaybookStyles(this.state.styleParameters).promptText}
      >
        {`${translator.t('gamification.promptPlaybookPick').toUpperCase()}`}
      </Text>
    )
  }

  renderPromptBar = () => {
    const styleParameters = this.state.styleParameters
    return (
      <View style={getIdentificationPlaybookStyles(styleParameters).promptContainer}>
        <View style={getIdentificationPlaybookStyles(styleParameters).prompt}>
          {this.renderPromptPlaybookPick()}
        </View>
      </View>
    )
  }

  getSnapshotInfo = (snapshot: string): SnapshotInfo => {
    const { snapshotInfos } = this.props

    const snapshotInfo: SnapshotInfo | undefined = snapshotInfos.find(
      (snapSize) => snapSize.snapshot === snapshot
    )

    if (!snapshotInfo) {
      logger.warn('Undefined snapshot was read')
    }
    return snapshotInfo!
  }

  getDeviceSnapshotInfo = (snapshot: string, ratioToGet: { width: string; height: string }) => {
    const { styleParameters } = this.state

    const availableWidth = (getScreenRatioStyle(styleParameters) as any)[ratioToGet.width]
    const availableHeight = (getScreenRatioStyle(styleParameters) as any)[ratioToGet.height]
    const snapshotInfo: SnapshotInfo = this.getSnapshotInfo(snapshot)

    const isFlipped: boolean = snapshotInfo.width > snapshotInfo.height
    const snapshotAspectRatio: number = isFlipped
      ? snapshotInfo.height / snapshotInfo.width
      : snapshotInfo.width / snapshotInfo.height

    const imageWidth: number = isFlipped ? availableWidth : availableHeight * snapshotAspectRatio
    const imageHeight: number = isFlipped
      ? availableWidth * snapshotAspectRatio > availableHeight
        ? availableHeight
        : availableWidth * snapshotAspectRatio
      : availableHeight

    return { availableWidth, availableHeight, imageWidth, imageHeight }
  }

  renderZoomModal = () => {
    const { styleParameters, zoomModalVisible, zoomedSnapshot, zoom, imageWidth, imageHeight } =
      this.state

    return (
      zoomModalVisible && (
        <Modal
          animationType="none"
          transparent
          visible={zoomModalVisible}
          supportedOrientations={orientationIOS}
        >
          <View style={getIdentificationPlaybookStyles(styleParameters).ModalBackground}>
            <View
              style={
                getIdentificationPlaybookStyles(styleParameters, imageWidth, imageHeight)
                  .ModalContainer
              }
            >
              <View style={getIdentificationPlaybookStyles(styleParameters).ModalTopbarContainer}>
                <TouchableOpacity onPress={() => this.toggleZoomModal(false)}>
                  <CancelIcon
                    style={getIdentificationPlaybookStyles(styleParameters).ModalCloseButton}
                    isTablet={styleParameters.isTablet}
                  />
                </TouchableOpacity>
                {true && (
                  <>
                    <TouchableOpacity onPress={() => this.zoom(false)} disabled={zoom === MIN_ZOOM}>
                      <ZoomOutIcon
                        style={
                          getIdentificationPlaybookStyles(
                            styleParameters,
                            undefined,
                            undefined,
                            zoom <= MIN_ZOOM
                          ).ModalZoomButton
                        }
                      />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => this.zoom(true)} disabled={zoom === MAX_ZOOM}>
                      <ZoomInIcon
                        style={
                          getIdentificationPlaybookStyles(
                            styleParameters,
                            undefined,
                            undefined,
                            zoom >= MAX_ZOOM
                          ).ModalZoomButton
                        }
                      />
                    </TouchableOpacity>
                  </>
                )}
              </View>
              <ImageZoom
                enableDoubleClickZoom={false}
                pinchToZoom={false}
                enableCenterFocus={false}
                cropWidth={imageWidth}
                cropHeight={imageHeight}
                imageWidth={imageWidth * zoom}
                imageHeight={imageHeight * zoom}
              >
                <Image
                  source={{
                    uri: zoomedSnapshot === '' ? undefined : zoomedSnapshot,
                  }}
                  style={{
                    width: imageWidth * zoom,
                    height: imageHeight * zoom,
                  }}
                />
              </ImageZoom>
            </View>
          </View>
        </Modal>
      )
    )
  }

  renderPlaybook = (snapshot: string) => {
    if (snapshot === '') {
      return null
    }
    const { styleParameters } = this.state
    const isMobileVertical: boolean = styleParameters.isMobile && !styleParameters.isHorizontal

    const playbookIndex: number = this.getPlaybookIndex(snapshot)

    const { availableWidth, availableHeight, imageHeight } = this.getDeviceSnapshotInfo(snapshot, {
      width: 'PlaybookImage_width',
      height: 'PlaybookImage_height',
    })

    if (isMobileVertical) {
      this.totalSnapshotsHeight += imageHeight
    }

    return (
      <View
        style={{
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          width: availableWidth,
          height: availableHeight,
        }}
      >
        <TouchableOpacity
          style={{
            width: availableWidth,
            height: availableHeight,
          }}
          onPress={() => this.handleSelectPlaybook(playbookIndex)}
          onLongPress={() => this.handleLongPress(snapshot)}
        >
          <View
            style={[
              this.getPlaybookAnswerStyle(playbookIndex),
              {
                position: 'absolute',
                width: availableWidth,
                height: availableHeight,
              },
            ]}
          />
          <Image
            resizeMethod="scale"
            source={{
              uri: snapshot === '' ? undefined : snapshot,
            }}
            style={{
              width: availableWidth,
              height: availableHeight,
            }}
          />
        </TouchableOpacity>
      </View>
    )
  }

  getPlaybookAnswerStyle = (playbookIndex: number) => {
    const { selectedPlaybookIndex, submittedPlaybookIndex, styleParameters, answerWasSubmitted } =
      this.state

    const { correctAnswerIndex } = this.props

    const isGoodAnswer: boolean = playbookIndex === correctAnswerIndex && answerWasSubmitted
    const isWrongAnswer: boolean =
      submittedPlaybookIndex === playbookIndex &&
      submittedPlaybookIndex !== correctAnswerIndex &&
      answerWasSubmitted

    if (isGoodAnswer) {
      return getIdentificationPlaybookStyles(styleParameters).playbookCorrectAnswer
    } else if (isWrongAnswer) {
      return getIdentificationPlaybookStyles(styleParameters).playbookWrongAnswer
    } else if (playbookIndex === selectedPlaybookIndex) {
      return getIdentificationPlaybookStyles(styleParameters).playbookSelectedAnswer
    }

    return {}
  }

  getPageTitle = (): string => {
    const { playbooks, correctPlaybookIDAnswer } = this.props
    const correctPlaybook = playbooks.find(
      (playbook: Playbook) => playbook.id === correctPlaybookIDAnswer
    )!
    return correctPlaybook.title
  }

  renderPlaybookTitle = () => {
    const { styleParameters } = this.state
    const title: string = this.getPageTitle()
    return (
      <View style={getIdentificationPlaybookStyles(styleParameters).titleContainer}>
        <Text adjustsFontSizeToFit style={getIdentificationPlaybookStyles(styleParameters).title}>
          {title.toUpperCase()}
        </Text>
      </View>
    )
  }

  render() {
    const { styleParameters, zoomModalVisible } = this.state

    const content: JSX.Element = (
      <>
        {this.renderPromptBar()}
        {this.renderPlaybookPictureIdentification()}
        {this.renderConfirmBar()}
      </>
    )

    return (
      <View style={getIdentificationPlaybookStyles(styleParameters).component}>
        {styleParameters.isMobile ? (
          <ScrollView alwaysBounceVertical={false} style={{ flex: 1, height: '100%' }}>
            {content}
          </ScrollView>
        ) : (
          content
        )}
        {zoomModalVisible && this.renderZoomModal()}
      </View>
    )
  }
}
const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(IdentificationPlaybook)
