import React, { Component } from 'react'
import { Text, StyleSheet } from 'react-native'

interface ErrorComponentState {
  error?: Error
}

interface ErrorComponentProps {
  error?: Error
  testID?: string
}

export function getErrorToDisplay(message: string | undefined): string {
  return message ?? 'Unknown Error'
}

export default class ErrorComponent extends Component<ErrorComponentProps, ErrorComponentState> {
  render() {
    if (this.props.error !== undefined)
      return (
        <Text testID={this.props.testID} style={styles.authenticateError}>
          {getErrorToDisplay(this.props.error?.message)}
        </Text>
      )
    else {
      return null
    }
  }
}

const styles = StyleSheet.create({
  authenticateError: {
    padding: 5,
    borderColor: 'red',
    borderWidth: 2,
    color: 'red',
  },
})
