import { SET_INVITATION_TOKEN } from '../actionTypes'

export interface InvitationState {
  token: string | undefined
}

const initialState: InvitationState = {
  token: undefined,
}

export default function invitation(state = initialState, action: any): InvitationState {
  switch (action.type) {
    case SET_INVITATION_TOKEN:
      return { ...state, token: action.payload.token }
    default:
      return state
  }
}
