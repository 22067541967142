import React from 'react'
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Keyboard,
  Platform,
  TouchableWithoutFeedback,
  ImageBackground,
  Dimensions,
} from 'react-native'
import { PageName, NavigationInterface } from './PageInfo'
import { getGlobalStyles, getLoginStyles, updateStyleParameters } from '../Styles/AppStyle'
import { getMarginToTransformIn43, StyleParameters, getStyleParameters } from '../Styles/Utilities'
import translator from '../../config/Translation/languages'
import { isEmail } from '../Utilitary/Utils'
import ErrorComponent from './ErrorComponent'
import TopBarMenu from './TopBarMenu'
import BasicPage from './BasicPage'
import { MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons'

import { imgPathCutLowRes, imgPathCut } from '../AssetsManager'
import { ensureImagesDirectoryExists } from '../Utilitary/StorageUtils'

import { authenticate } from '../Services/AuthService'
import { ScrollView } from 'react-native-gesture-handler'
import {
  checkTaktikServerVersion,
  getMasterTaktikServerVersion,
} from '../Utilitary/version/TaktikServerVersionUtils'
import { connect } from 'react-redux'
import { RootAction, RootState } from '../redux/reducers'
import { TaktikServerVersion } from '@taktik/config/TaktikServer/version'
import { User } from '../DataTypes/UserTypes'
import { TeamInfos } from '../redux/reducers/teams'
import Registration from './Registration'
import { setReduxStoreVersion } from '../Utilitary/version/ReduxStoreVersionUtils'
import { storeVersion } from '@taktik/config/Redux/version'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { TaktikAlert } from '../Utilitary/Alert'

export enum loginElementTestID {
  emailInput = 'EmailInput',
  passwordInput = 'PassInput',
  loginButton = 'LoginButton',
  loginButtonText = 'LoginButtonText',
  authError = 'AuthError',
  emailError = 'EmailError',
  passwordError = 'PasswordError',
}

interface LoginPageDispatchProps {
  onAuthenticate: (body: { email: string; password: string }) => Promise<void>
}

interface LoginPageProps extends NavigationInterface, LoginPageDispatchProps {
  mustSkipLoadingResources?: boolean
  taktikServerVersion: TaktikServerVersion | null | undefined
  masterTaktikServerVersion: TaktikServerVersion | null | undefined
  selectedTeamID: number | null
  selectedToken: string | null
  lastUser: User | null
  teamsInfos: TeamInfos[] | null

  renderRegistrationModal: boolean
  invitationToken: string | undefined
}

interface LoginPageState {
  styleParameters: StyleParameters
  emailIsValid?: boolean
  passwordIsValid?: boolean
  emailValue: string
  passwordValue: string
  isAuthenticating: boolean
  invitationTeamId: number | undefined
  invitationPositions: any
  loginError?: Error
}

export class LoginPageRaw extends BasicPage<LoginPageProps, LoginPageState> {
  navigate = this.props.navigation.navigate

  constructor(props: LoginPageProps) {
    super(props)
    this.state = {
      emailValue: '',
      passwordValue: '',
      isAuthenticating: false,
      invitationTeamId: undefined,
      invitationPositions: [],
      styleParameters: getStyleParameters(),
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount = async () => {
    const { mustSkipLoadingResources } = this.props

    Dimensions.addEventListener('change', this.onChange)
    await ensureImagesDirectoryExists()

    if (!mustSkipLoadingResources) {
      await getMasterTaktikServerVersion(true)
      await setReduxStoreVersion(storeVersion)

      const { masterTaktikServerVersion, taktikServerVersion } = this.props
      checkTaktikServerVersion(false, taktikServerVersion, masterTaktikServerVersion)
    }
  }

  componentWillUnmount = () => {
    Dimensions.removeEventListener('change', this.onChange)
  }

  emailIsValid(value: string): boolean | undefined {
    if (value.length === 0) return undefined
    else return isEmail(value)
  }

  emailChangeHandler(emailValue: string) {
    this.setState({
      emailValue,
    })
  }

  emailValidationHandler() {
    this.setState((oldState) => {
      const emailIsValid = this.emailIsValid(oldState.emailValue)
      return {
        emailIsValid,
      }
    })
  }

  passwordIsValid(value: string): boolean | undefined {
    if (value.length === 0) return undefined
    else return value.length >= 1
  }

  passChangeHandler(passwordValue: string) {
    const passwordIsValid = this.passwordIsValid(passwordValue)
    this.setState({
      passwordIsValid,
      passwordValue,
    })
  }

  passValidationHandler() {
    this.setState((oldState) => {
      const passwordIsValid = this.passwordIsValid(oldState.passwordValue)
      return {
        passwordIsValid,
      }
    })
  }

  canLogin(): boolean | undefined {
    return this.state.emailIsValid && this.state.passwordIsValid
  }

  userIsCoachAlert = () => {
    TaktikAlert.alert(translator.t('alert.userIsCoachAlert'), undefined, { text: 'OK' })
  }

  async login() {
    if (this.canLogin()) {
      this.setState({
        isAuthenticating: true,
        loginError: undefined,
      })

      const { emailValue: email, passwordValue: password } = this.state
      const { onAuthenticate } = this.props

      try {
        await onAuthenticate({ email, password })
      } catch (ex) {
        TaktikAlert.alert((ex as Error).message)
      }

      this.setState({
        isAuthenticating: false,
      })
    }
  }

  forgotPassword() {
    this.navigate(PageName.ForgotPassword)
  }

  changePassword() {
    this.navigate(PageName.ChangePassword)
  }

  renderTitle = () => {
    return (
      <View>
        <Text style={getLoginStyles(this.state.styleParameters).titleText} selectable={false}>
          {translator.t('loginFormTitle').toUpperCase()}
        </Text>
      </View>
    )
  }

  renderSubtitle = () => {
    return (
      <View>
        <Text style={getLoginStyles(this.state.styleParameters).subtitleText} selectable={false}>
          {translator.t('loginFormSubtitle')}
        </Text>
      </View>
    )
  }

  renderConnectionErrorMessage = () => {
    return <ErrorComponent testID={loginElementTestID.authError} error={this.state.loginError} />
  }

  renderInvalidEmailErrorMessage = () => {
    return (
      <ErrorComponent
        testID={loginElementTestID.emailError}
        error={
          this.state.emailIsValid === false
            ? new Error(translator.t('error.field.email'))
            : undefined
        }
      />
    )
  }

  renderEmailField = () => {
    const { styleParameters } = this.state

    return (
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <View style={getGlobalStyles(styleParameters).userIconPosition}>
          <MaterialCommunityIcons
            name="account"
            style={getGlobalStyles(styleParameters).userIcon}
          />
        </View>
        <TextInput
          style={getLoginStyles(styleParameters).authenticateInputText}
          testID={loginElementTestID.emailInput}
          autoCapitalize="none"
          editable={!this.state.isAuthenticating}
          value={this.state.emailValue}
          textContentType="emailAddress"
          keyboardType="email-address"
          placeholderTextColor="#ccc"
          onChangeText={(value) => this.emailChangeHandler(value)}
          onBlur={() => this.emailValidationHandler()}
          placeholder={translator.t('email').toUpperCase()}
          returnKeyType="next"
          onSubmitEditing={() => Keyboard.dismiss()}
        />
      </View>
    )
  }

  renderPasswordFieldErrorMessage = () => {
    return (
      <ErrorComponent
        testID={loginElementTestID.passwordError}
        error={
          this.state.passwordIsValid === false
            ? new Error(translator.t('error.field.password'))
            : undefined
        }
      />
    )
  }

  renderPasswordField = () => {
    const { styleParameters } = this.state

    return (
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <View style={getGlobalStyles(styleParameters).passwordIconPosition}>
          <FontAwesome5 name="lock" style={getGlobalStyles(styleParameters).passwordIcon} />
        </View>
        <TextInput
          testID={loginElementTestID.passwordInput}
          style={getLoginStyles(styleParameters).authenticateInputText}
          editable={!this.state.isAuthenticating}
          placeholderTextColor="#ccc"
          secureTextEntry
          value={this.state.passwordValue}
          onChangeText={(value) => this.passChangeHandler(value)}
          onBlur={() => this.passValidationHandler()}
          placeholder={translator.t('password').toUpperCase()}
          returnKeyType="go"
          onSubmitEditing={() => this.login()}
        />
      </View>
    )
  }

  renderOtherButtons = () => {
    const { styleParameters } = this.state

    return (
      <View style={getLoginStyles(styleParameters).otherOptionsContainer}>
        <TouchableOpacity
          style={getLoginStyles(styleParameters).otherOptionsButton}
          onPress={() => this.forgotPassword()}
        >
          <Text style={getLoginStyles(styleParameters).otherOptionsText}>
            {translator.t('login.forgotPassword').toUpperCase()}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={getLoginStyles(styleParameters).otherOptionsButton}
          onPress={() => this.changePassword()}
        >
          <Text style={getLoginStyles(styleParameters).otherOptionsText}>
            {translator.t('login.changePassword').toUpperCase()}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderLoginButton = () => {
    const { styleParameters } = this.state

    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          transform: [{ skewX: '-10deg' }],
        }}
      >
        <TouchableOpacity
          testID={loginElementTestID.loginButton}
          style={getLoginStyles(styleParameters).authenticateButton}
          onPress={() => this.login()}
        >
          <Text
            testID={loginElementTestID.loginButtonText}
            style={getLoginStyles(styleParameters).authenticateButtonText}
          >
            {this.state.isAuthenticating
              ? translator.t('login.authenticating').toUpperCase()
              : translator.t('login.login').toUpperCase()}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderTablet() {
    return (
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        {this.renderGeneric()}
      </TouchableWithoutFeedback>
    )
  }

  renderGeneric() {
    const { navigation, invitationToken, renderRegistrationModal } = this.props

    const { styleParameters } = this.state
    const { isHorizontal, isMobile, screenWidth, screenHeight } = styleParameters

    const marginHorizontal = getMarginToTransformIn43(screenWidth, screenHeight)

    const backgroundStyle =
      !isMobile && isHorizontal ? { marginHorizontal: marginHorizontal / 2 } : {}

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        {/* The top (low-quality) ImageBackground is a placeholder while the HD image loads (useful to avoid a blank background) */}
        <ImageBackground
          source={imgPathCutLowRes}
          style={[getGlobalStyles(styleParameters).backgroundImageContents, backgroundStyle]}
        >
          <ImageBackground
            source={imgPathCut}
            style={[getGlobalStyles(styleParameters).backgroundImageContents]}
            imageStyle={[getGlobalStyles(styleParameters).imageStyle]}
          >
            <TopBarMenu
              navigation={navigation}
              url={PageName.Login}
              updatePage={() => this.forceUpdate()}
              userMustBeLoggedOut={false}
              pageTitle="LoginPage"
            />
            <View style={getLoginStyles(styleParameters).page}>
              {isHorizontal && <View style={getLoginStyles(styleParameters).side} />}
              {isHorizontal && <View style={getLoginStyles(styleParameters).side} />}
              <View style={getLoginStyles(styleParameters).authenticationForm}>
                {this.renderTitle()}
                {this.renderSubtitle()}
                <View style={getLoginStyles(styleParameters).authenticateForm}>
                  <ScrollView>
                    {this.renderConnectionErrorMessage()}
                    {this.renderInvalidEmailErrorMessage()}
                    {this.renderEmailField()}
                    {this.renderPasswordFieldErrorMessage()}
                    {this.renderPasswordField()}
                    {this.renderLoginButton()}
                    {this.renderOtherButtons()}
                  </ScrollView>
                </View>
                {renderRegistrationModal && invitationToken && (
                  <Registration navigation={navigation} token={invitationToken} />
                )}
              </View>
            </View>
          </ImageBackground>
        </ImageBackground>
      </View>
    )
  }

  render() {
    const OS = Platform.OS
    if (OS === 'ios' || OS === 'android') {
      return this.renderTablet()
    } else {
      return this.renderGeneric()
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  taktikServerVersion: state.version.taktikServerVersion,
  masterTaktikServerVersion: state.version.masterTaktikServerVersion,
  selectedTeamID: state.team.selectedTeam,
  selectedToken: state.team.selectedToken,
  lastUser: state.auth.user,
  teamsInfos: state.team.teamsInfos,
  renderRegistrationModal: state.modals.registrationModalIsVisible,
  invitationToken: state.invitation.token,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootAction, unknown, AnyAction>
): LoginPageDispatchProps => ({
  onAuthenticate: (body: { email: string; password: string }) => dispatch(authenticate(body)),
})

const LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPageRaw)

export default LoginPage
