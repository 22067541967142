import {
  PLAYBOOK_LOADING,
  UDPATE_PRACTICE_GROUPS,
  UDPATE_PRACTICE_EVALUATIONS,
} from '../actionTypes'

export interface PlaybooksState {
  playbooks: any[]
  playbookFolders: any[]
  selectedPracticeGroups: number[]
  selectedEvaluations: number[]
  playbooksIDs: number[]
}

const initialState: PlaybooksState = {
  playbooks: [],
  playbookFolders: [],
  selectedPracticeGroups: [],
  selectedEvaluations: [],
  playbooksIDs: [],
}

export default function PlaybookList(state = initialState, action: any): PlaybooksState {
  switch (action.type) {
    case PLAYBOOK_LOADING: {
      const { playbookFolders, playbooks } = action.payload.playbooks
      let playbookIDs = []
      if (playbooks) {
        playbookIDs = playbooks.map((playbook: any) => {
          return playbook.id
        })
      }

      return {
        ...state,
        playbooks: playbooks,
        playbookFolders: playbookFolders,
        playbooksIDs: playbookIDs,
      }
    }
    case UDPATE_PRACTICE_GROUPS: {
      const { groups } = action.payload
      return {
        ...state,
        selectedPracticeGroups: groups,
      }
    }

    case UDPATE_PRACTICE_EVALUATIONS: {
      const { evaluations } = action.payload
      return {
        ...state,
        selectedEvaluations: evaluations,
      }
    }
    default:
      return state
  }
}
