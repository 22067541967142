import { Platform } from 'react-native'

export interface StudyTime {
  startTime: Date
  duration: number
  playbookID: number
  userID: number
  sessionID: string
}

export class ServerStudyTime {
  constructor(
    public userID: number,
    public playbookID: number,
    public startTime: Date = new Date(),
    public endTime: Date = new Date(),
    public device: string = 'unknown',
    public sessionID: string = 'unknown'
  ) {}
}

export function generateStudyTimeKey(studyTime: StudyTime): string {
  return `${studyTime.userID}-${studyTime.playbookID}-${studyTime.startTime}`
}

export function toServerStudyTime(studyTime: StudyTime): ServerStudyTime {
  return new ServerStudyTime(
    studyTime.userID,
    studyTime.playbookID,
    studyTime.startTime,
    new Date(new Date(studyTime.startTime).getTime() + studyTime.duration * 1000),
    Platform.OS,
    studyTime.sessionID
  )
}
