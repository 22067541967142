import React from 'react'
import { View, Text, TouchableOpacity, TextInput, ImageBackground, Dimensions } from 'react-native'
import BasicPage from './BasicPage'
import ErrorComponent from './ErrorComponent'
import { isEmail } from '../Utilitary/Utils'
import translator from '@taktik/config/Translation/languages'
import { getGlobalStyles, getLoginStyles, updateStyleParameters } from '../Styles/AppStyle'
import { getMarginToTransformIn43, StyleParameters, getStyleParameters } from '../Styles/Utilities'
import TopBarMenu from './TopBarMenu'
import { PageName, NavigationInterface } from './PageInfo'
import { forgotPassword } from '../Services/AuthService'
import { imgPathCut, imgPathCutLowRes } from '../AssetsManager'
import { MaterialCommunityIcons } from '@expo/vector-icons'

interface ForgotPasswordPageProps extends NavigationInterface {}

interface ForgotPasswordPageState {
  styleParameters: StyleParameters
  emailValue: string
  emailIsValid?: boolean
  isSending: boolean
  forgotPasswordError?: Error
}

export default class ForgotPasswordPage extends BasicPage<
  ForgotPasswordPageProps,
  ForgotPasswordPageState
> {
  constructor(props: ForgotPasswordPageProps) {
    super(props)
    this.state = {
      emailValue: '',
      isSending: false,
      styleParameters: getStyleParameters(),
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', this.onChange)
  }

  componentWillUnmount = () => {
    Dimensions.removeEventListener('change', this.onChange)
  }

  emailIsValid(value: string): boolean | undefined {
    if (value.length === 0) return undefined
    else return isEmail(value)
  }

  emailChangeHandler(emailValue: string) {
    const emailIsValid = this.emailIsValid(emailValue)
    this.setState({
      emailIsValid,
      emailValue,
    })
  }

  async resetPassword() {
    try {
      await forgotPassword(this.state.emailValue)
      this.props.navigation.navigate(PageName.Success, {
        message: PageName.ForgotPassword,
      })
    } catch (exception) {
      this.setState({
        forgotPasswordError: exception,
      })
    }
  }

  render() {
    const { navigation } = this.props
    const { styleParameters } = this.state
    const { isMobile, isHorizontal, screenWidth, screenHeight } = styleParameters

    const marginHorizontal = getMarginToTransformIn43(screenWidth, screenHeight)

    const backgroundStyle =
      !isMobile && isHorizontal ? { marginHorizontal: marginHorizontal / 2 } : {}

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        {/* The top (low-quality) ImageBackground is a placeholder while the HD image loads (useful to avoid a blank background) */}
        <ImageBackground
          source={imgPathCutLowRes}
          style={[getGlobalStyles(styleParameters).backgroundImageContents, backgroundStyle]}
        >
          <ImageBackground
            source={imgPathCut}
            style={getGlobalStyles(styleParameters).backgroundImageContents}
          >
            <TopBarMenu
              navigation={navigation}
              url={PageName.Login}
              updatePage={() => this.forceUpdate()}
              userMustBeLoggedOut={false}
              pageTitle="ForgotPasswordPage"
            />
            <View style={getLoginStyles(styleParameters).page}>
              {isHorizontal && <View style={getLoginStyles(styleParameters).side} />}
              {isHorizontal && <View style={getLoginStyles(styleParameters).side} />}
              <View style={getLoginStyles(styleParameters).authenticationForm}>
                <Text style={getLoginStyles(styleParameters).titleText}>
                  {translator.t('login.forgotPassword').toUpperCase()}
                </Text>
                <Text style={getLoginStyles(styleParameters).subtitleText} selectable={false} />
                <View style={getLoginStyles(styleParameters).authenticateForm}>
                  <ErrorComponent
                    error={
                      this.state.emailIsValid === false
                        ? new Error(translator.t('error.field.email'))
                        : undefined
                    }
                  />
                  <ErrorComponent error={this.state.forgotPasswordError} />
                  <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <View style={getGlobalStyles(styleParameters).userIconPosition}>
                      <MaterialCommunityIcons
                        name="account"
                        style={getGlobalStyles(styleParameters).userIcon}
                      />
                    </View>
                    <TextInput
                      style={getLoginStyles(styleParameters).authenticateInputText}
                      autoCapitalize="none"
                      editable={!this.state.isSending}
                      value={this.state.emailValue}
                      textContentType="emailAddress"
                      keyboardType="email-address"
                      placeholderTextColor="#ccc"
                      onChangeText={(value) => this.emailChangeHandler(value)}
                      placeholder={translator.t('email').toUpperCase()}
                      returnKeyType="send"
                    />
                  </View>
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      transform: [{ skewX: '-10deg' }],
                    }}
                  >
                    <TouchableOpacity
                      style={getLoginStyles(styleParameters).authenticateButton}
                      onPress={() => this.resetPassword()}
                      disabled={!this.state.emailIsValid}
                    >
                      <Text style={getLoginStyles(styleParameters).authenticateButtonText}>
                        {translator.t('forgotPassword.resetPassword').toUpperCase()}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={getLoginStyles(styleParameters).otherOptionsContainer}>
                    <TouchableOpacity
                      style={getLoginStyles(styleParameters).otherOptionsButton}
                      onPress={() => navigation.navigate(PageName.Login)}
                    >
                      <Text style={getLoginStyles(styleParameters).otherOptionsText}>
                        {translator.t('backToLogin').toUpperCase()}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={getLoginStyles(styleParameters).side} />
              </View>
            </View>
          </ImageBackground>
        </ImageBackground>
      </View>
    )
  }
}
