import { StyleSheet } from 'react-native'
import { GlobalColors } from '../Theme'
import {
  getScreenRatioStyle,
  StyledScreenRatio,
} from '../Peculiar/DownloadAppNotificationRatioStyles'
import { StyleParameters } from '../Utilities'

export default function getAppNotificationStyles(styleParameters: StyleParameters) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(styleParameters)

  return StyleSheet.create({
    notificationBackground: {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 100,
      backgroundColor: GlobalColors.defaultTeamColor,
      width: '100%',
      paddingVertical: 3,
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: screenRatio.CancelIcon_width,
      height: '100%',
      paddingHorizontal: screenRatio.CancelIcon_width,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    cancelIcon: {
      color: GlobalColors.white,
      height: screenRatio.CancelIcon_width,
      width: screenRatio.CancelIcon_width,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    contentWrapper: {
      marginHorizontal: 2 * screenRatio.CancelIcon_width, // Should be the sum of cancelIcon.width & closeButton.paddingHorizontal to avoid the text overlapping
      alignSelf: 'center',
    },
    content: {
      fontFamily: 'barlow-semi',
      color: GlobalColors.white,
      fontSize: 18,
      textAlign: 'center',
    },
  })
}
