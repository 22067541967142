import { environmentConfiguration } from '../../config/env/environment'
import { Logger } from './logger'
import { LogFunction, LogLevel, LogTransporter } from './types'

const doNothing: LogFunction = () => {}

export class DefaultLogger implements Logger {
  private transporter: LogTransporter
  private minLevelToLog!: LogLevel
  private debugFn!: LogFunction
  private infoFn!: LogFunction
  private warnFn!: LogFunction
  private errorFn!: LogFunction

  constructor(transporter: LogTransporter) {
    this.transporter = transporter
    this.setMinLevelToLog(environmentConfiguration.getLogLevel())
  }

  setMinLevelToLog(level: LogLevel): void {
    this.minLevelToLog = level
    this.updateLoggingFunctions()
  }

  debug(message?: any, ...optionalParams: any[]) {
    this.debugFn(message, ...optionalParams)
  }

  info(message?: any, ...optionalParams: any[]) {
    this.infoFn(message, ...optionalParams)
  }

  warn(message?: any, ...optionalParams: any[]) {
    this.warnFn(message, ...optionalParams)
  }

  error(message?: any, ...optionalParams: any[]) {
    this.errorFn(message, ...optionalParams)
  }

  private updateLoggingFunctions(): void {
    this.debugFn = this.isLoggingEnabledFor(LogLevel.debug) ? this.transporter.debug : doNothing
    this.infoFn = this.isLoggingEnabledFor(LogLevel.info) ? this.transporter.info : doNothing
    this.warnFn = this.isLoggingEnabledFor(LogLevel.warn) ? this.transporter.warn : doNothing
    this.errorFn = this.isLoggingEnabledFor(LogLevel.error) ? this.transporter.error : doNothing
  }

  private isLoggingEnabledFor(level: LogLevel): boolean {
    return level >= this.minLevelToLog
  }
}
