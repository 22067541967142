import { latestTaktikServerVersion, TaktikServerVersion } from '@taktik/config/TaktikServer/version'
import translator from '@taktik/config/Translation/languages'
import { logger } from '../../../lib/logger'
import { Platform } from 'react-native'
import { logout } from '../../Services/AuthService'
import axios from 'axios'
import { store } from '../../redux/store'
import { setTaktikServerVersion } from '../../redux/actions'
import {
  SET_MASTER_TAKTIK_SERVER_VERSION,
  SET_TAKTIK_SERVER_VERSION,
} from '../../redux/actionTypes'
import { formatVersion, _majorVersionHasChanged, _onlyMinorVersionHasChanged } from './Common'
import { TaktikAlert } from '../Alert'

export const getMasterTaktikServerVersion = async (mustAlsoUpdateSoftVersion: boolean) => {
  logger.info('Requesting master version from Taktik Server...')
  await axios
    .get(`/auth/version/`, { timeout: 500 })
    .then((response) => {
      logger.info(
        `Obtained the following Taktik Server master version: ${formatVersion(
          response.data.object
        )}`
      )
      store.dispatch({ type: SET_MASTER_TAKTIK_SERVER_VERSION, payload: response.data.object })
      if (mustAlsoUpdateSoftVersion) {
        store.dispatch({ type: SET_TAKTIK_SERVER_VERSION, payload: response.data.object })
      }
    })
    .catch((error) => {
      logger.warn(
        `Attempted to retrieve the master version from Taktik Server, but failed with the following error: ${JSON.stringify(
          error
        )}`
      )
    })
}

const handlePopUp = () => {
  const getMessage = () => {
    switch (Platform.OS) {
      case 'web':
        return translator.t('login.updatePopupMessageWeb')
      case 'ios':
        return translator.t('login.updatePopupMessageIos')
      case 'android':
        return translator.t('login.updatePopupMessageAndroid')
      default:
        return undefined
    }
  }
  TaktikAlert.alert(translator.t('login.updatePopupTitle'), getMessage(), { text: 'OK' })
}

const handleTaktikServerVersionChange_Explicit = async (
  version: TaktikServerVersion | null | undefined,
  masterTaktikServerVersion: TaktikServerVersion | undefined | null,
  isSoftVersion: boolean,
  userIsLoggedIn: boolean
): Promise<boolean> => {
  if (isSoftVersion && !version) {
    logger.info(`Would be logging out due to a null or undefined Taktik Server soft version`)
    return false
  }

  const onlyMinorVersionHasChanged: boolean = _onlyMinorVersionHasChanged(
    version,
    masterTaktikServerVersion
  )
  const majorVersionHasChanged: boolean = _majorVersionHasChanged(
    version,
    masterTaktikServerVersion
  )

  const versionType: string = isSoftVersion ? 'soft' : 'hard'

  if (onlyMinorVersionHasChanged) {
    if (isSoftVersion) {
      logger.info(
        `Logging out due to Taktik Server ${versionType} minor version change (${formatVersion(
          version
        )} -> ${formatVersion(masterTaktikServerVersion)})`
      )
      return false
    }
  } else if (majorVersionHasChanged) {
    logger.info(
      `Logging out due to Taktik Server ${versionType} major version change (${formatVersion(
        version
      )} -> ${formatVersion(masterTaktikServerVersion)})`
    )

    const showPopUp: boolean = !isSoftVersion && !userIsLoggedIn

    if (showPopUp) {
      handlePopUp()
    }

    return false
  }

  logger.info(`Taktik Server ${versionType} version up to date`)
  return true
}

export const checkTaktikServerVersion = async (
  userIsLoggedIn: boolean,
  softTaktikServerVersion: TaktikServerVersion | null | undefined,
  masterTaktikServerVersion?: TaktikServerVersion | null | undefined
) => {
  const softVersion: TaktikServerVersion | undefined | null = softTaktikServerVersion
  const hardVersion: TaktikServerVersion | undefined | undefined = latestTaktikServerVersion

  const masterVersion: TaktikServerVersion | null | undefined = masterTaktikServerVersion

  if (!masterVersion) {
    return
  }

  logger.info(`Soft version - ${formatVersion(softVersion)}`)

  const softVersionIsOK: boolean = await handleTaktikServerVersionChange_Explicit(
    softVersion,
    masterVersion,
    true,
    userIsLoggedIn
  )

  logger.info(`Soft version is OK - ${softVersionIsOK}`)

  const hardVersionIsOK: boolean = await handleTaktikServerVersionChange_Explicit(
    hardVersion,
    masterVersion,
    false,
    userIsLoggedIn
  )

  logger.info(`Hard version is OK - ${hardVersionIsOK}`)

  const versionIsOutdated: boolean = !softVersionIsOK || !hardVersionIsOK
  const mustLogoutUser: boolean = versionIsOutdated && userIsLoggedIn

  logger.info(`Must log out user because of Taktik Server version - ${mustLogoutUser}`)

  if (mustLogoutUser) {
    logger.info('Logging out...')
    logout()
  }

  store.dispatch(setTaktikServerVersion(masterVersion))
}
