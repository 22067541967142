import { Platform } from 'react-native'
import { StyleParameters, getMarginToTransformIn43, transformUXSpec } from '../Utilities'
export interface StyledScreenRatio {
  GameContainer_width: any
  GameContainer_direction: any
  GameContainer_marginTop: any
  ButtonsAreaContainer_width: any
  ButtonsAreaContainer_height: any
  ButtonsAreaContainer_marginTop: any
  NameZone_direction: any
  NameZone_marginTop: any
  NameZone_marginBottom: any
  NameZone_height: any
  NamesButtonsContainer_width: any
  NamesButtonsContainer_marginHorizontal: any
  NameButton_height: any
  NameButton_width: any
  NameText_fontSize: any
  PromptText_fontSize: any
  playbookImageContainer_RightPadding: any
  ConfirmText_fontSize: any
  ConfirmText_marginTop: any
  ConfirmButton_width: any
  ConfirmButton_height: any
  ConfirmButtonContainer_justifyContent: any
  ConfirmButtonContainer_marginBottom: any
  ConfirmButtonContainer_width: any
  ConfirmButtonContainer_height: any
  PromptContainer_width: any
  PromptContainer_height: any
  PromptContainer_marginTop: any
  PromptContainer_marginBottom: any
  TitleContainer_flex: any
  TitleContainer_height: any
  TitleContainer_width: any
  Title_fontSize: any
  PlaybookImage_width: any
  playbookImageContainer_height: any
  Page_height: any
  WarningText_fontSize: any
  WarningText_paddingBottom: any
}

export function getScreenRatioStyle(styleParameters: StyleParameters) {
  let { screenWidth, screenHeight } = styleParameters
  const marginHorizontal: number = getMarginToTransformIn43(screenWidth, screenHeight)
  const os = Platform.OS
  if (screenWidth > screenHeight) {
    if (screenHeight > 600) {
      //horizontal tablet
      screenWidth -= marginHorizontal
      return {
        Page_flex: undefined,
        Page_marginHorizontal: marginHorizontal / 2,
        Page_height: '100%',
        GameContainer_direction: 'column',
        GameContainer_width: '100%',
        GameContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 30),
        ButtonsAreaContainer_width: transformUXSpec(screenWidth, screenHeight, 1567),
        ButtonsAreaContainer_height:
          screenHeight -
          transformUXSpec(screenWidth, screenHeight, 940) -
          2 * transformUXSpec(screenWidth, screenHeight, 60),
        ButtonsAreaContainer_marginTop: '0%',
        NameZone_direction: 'row',
        NameZone_marginTop: os === 'web' ? '1%' : transformUXSpec(screenWidth, screenHeight, 10),
        NameZone_marginBottom: '2%',
        NameZone_height: transformUXSpec(screenWidth, screenHeight, 76),
        NamesButtonsContainer_width: transformUXSpec(screenWidth, screenHeight, 379.5),
        NamesButtonsContainer_marginHorizontal: '0%',
        NameButton_height: transformUXSpec(screenWidth, screenHeight, 66),
        NameButton_width: transformUXSpec(screenWidth, screenHeight, 379.5),
        NameText_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        PromptText_fontSize: transformUXSpec(screenWidth, screenHeight, 70),
        playbookImageContainer_RightPadding: '0%',
        ConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 44),
        ConfirmText_marginTop: 0,
        ConfirmButton_width: transformUXSpec(screenWidth, screenHeight, 433),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 94),
        ConfirmButtonContainer_justifyContent: 'flex-start',
        ConfirmButtonContainer_width: '100%',
        ConfirmButtonContainer_height: transformUXSpec(screenWidth, screenHeight, 150),
        ConfirmButtonContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 150),
        PromptContainer_width: transformUXSpec(screenWidth, screenHeight, 1567),
        PromptContainer_height: 1 * transformUXSpec(screenWidth, screenHeight, 60),
        PromptContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 70),
        PromptContainer_marginBottom:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 40)
            : transformUXSpec(screenWidth, screenHeight, 40),
        TitleContainer_flex: os === 'web' ? 1 : undefined,
        TitleContainer_height: transformUXSpec(screenWidth, screenHeight, 80),
        TitleContainer_width: transformUXSpec(screenWidth, screenHeight, 1567),
        Title_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        PlaybookImage_width: transformUXSpec(screenWidth, screenHeight, 1567),
        playbookImageContainer_height: transformUXSpec(screenWidth, screenHeight, 940),
        WarningText_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        WarningText_paddingBottom: transformUXSpec(screenWidth, screenHeight, 30),
      }
    } else {
      //horizontal mobile
      return {
        Page_flex: undefined,
        Page_marginHorizontal: '0%',
        Page_height: '100%',
        GameContainer_width: screenWidth,
        GameContainer_direction: 'row',
        GameContainer_marginTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 0)
            : transformUXSpec(screenWidth, screenHeight, 10),
        ButtonsAreaContainer_width: '50%',
        ButtonsAreaContainer_height: '100%',
        ButtonsAreaContainer_marginTop: '0%',
        NameZone_direction: 'column',
        NameZone_marginTop: 0,
        NameZone_marginBottom: transformUXSpec(screenWidth, screenHeight, 15),
        NameZone_height: transformUXSpec(screenWidth, screenHeight, 4 * 30 + 3 * 10),
        NamesButtonsContainer_width: '100%',
        NamesButtonsContainer_marginHorizontal: '0%',
        NameButton_height: transformUXSpec(screenWidth, screenHeight, 30),
        NameButton_width: transformUXSpec(screenWidth, screenHeight, 335),
        NameText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        PromptText_fontSize:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 25)
            : transformUXSpec(screenWidth, screenHeight, 30),
        playbookImageContainer_RightPadding: transformUXSpec(screenWidth, screenHeight, 20),
        ConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ConfirmText_marginTop: 0,
        ConfirmButton_width: transformUXSpec(screenWidth, screenHeight, 216),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 40),
        ConfirmButtonContainer_justifyContent: 'flex-start',
        ConfirmButtonContainer_width: transformUXSpec(screenWidth, screenHeight, 216),
        ConfirmButtonContainer_height: transformUXSpec(screenWidth, screenHeight, 40),
        ConfirmButtonContainer_marginBottom: 0,
        PromptContainer_width: transformUXSpec(screenWidth, screenHeight, 400),
        PromptContainer_height: 1 * transformUXSpec(screenWidth, screenHeight, 30),
        PromptContainer_marginTop:
          os === 'android' ? 0 : transformUXSpec(screenWidth, screenHeight, 20),
        PromptContainer_marginBottom:
          os === 'android' ? 0 : transformUXSpec(screenWidth, screenHeight, 20),
        TitleContainer_flex: os === 'web' ? 1 : undefined,
        TitleContainer_height: transformUXSpec(screenWidth, screenHeight, 25),
        TitleContainer_width: '100%',
        Title_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        PlaybookImage_width: transformUXSpec(screenWidth, screenHeight, 375),
        playbookImageContainer_height: transformUXSpec(screenWidth, screenHeight, 228),
        WarningText_fontSize: transformUXSpec(screenWidth, screenHeight, 10),
        WarningText_paddingBottom: transformUXSpec(screenWidth, screenHeight, 5),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        Page_flex: undefined,
        Page_marginHorizontal: '0%',
        Page_height: undefined,
        GameContainer_width: '100%',
        GameContainer_direction: 'column',
        GameContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 15),
        ButtonsAreaContainer_width: '100%',
        ButtonsAreaContainer_height:
          screenHeight -
          transformUXSpec(screenWidth, screenHeight, 228) -
          2 * transformUXSpec(screenWidth, screenHeight, 60),
        ButtonsAreaContainer_marginTop: '5%',
        NameZone_direction: 'column',
        NameZone_marginTop: transformUXSpec(screenWidth, screenHeight, 18),
        NameZone_marginBottom: transformUXSpec(screenWidth, screenHeight, 36),
        NameZone_height: transformUXSpec(screenWidth, screenHeight, 4 * 36 + 3 * 16),
        NamesButtonsContainer_width: '100%',
        NamesButtonsContainer_marginHorizontal: '0%',
        NameButton_height: transformUXSpec(screenWidth, screenHeight, 36),
        NameButton_width: transformUXSpec(screenWidth, screenHeight, 335),
        NameText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        PromptText_fontSize: transformUXSpec(screenWidth, screenHeight, 22),
        playbookImageContainer_RightPadding: '0%',
        ConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ConfirmText_marginTop: transformUXSpec(screenWidth, screenHeight, 0),
        ConfirmButton_width: transformUXSpec(screenWidth, screenHeight, 216),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 40),
        ConfirmButtonContainer_justifyContent: 'flex-start',
        ConfirmButtonContainer_width: transformUXSpec(screenWidth, screenHeight, 216),
        ConfirmButtonContainer_height: transformUXSpec(screenWidth, screenHeight, 40),
        ConfirmButtonContainer_marginBottom: '0%',
        PromptContainer_width: transformUXSpec(screenWidth, screenHeight, 375),
        PromptContainer_height: 1 * transformUXSpec(screenWidth, screenHeight, 24),
        PromptContainer_marginTop: 0 * transformUXSpec(screenWidth, screenHeight, 25),
        PromptContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 0),
        TitleContainer_flex: os === 'web' ? undefined : undefined,
        TitleContainer_height: transformUXSpec(screenWidth, screenHeight, 0),
        TitleContainer_width: '100%',
        Title_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        PlaybookImage_width: transformUXSpec(screenWidth, screenHeight, 375),
        playbookImageContainer_height: transformUXSpec(screenWidth, screenHeight, 228),
        WarningText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        WarningText_paddingBottom: transformUXSpec(screenWidth, screenHeight, 5),
      }
    } else {
      //vertical tablet
      return {
        Page_flex: undefined,
        Page_marginHorizontal: '0%',
        Page_height: '100%',
        GameContainer_width: '100%',
        GameContainer_direction: 'column',
        GameContainer_marginTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 30)
            : transformUXSpec(screenWidth, screenHeight, 120),
        ButtonsAreaContainer_width: '100%',
        ButtonsAreaContainer_height:
          screenHeight -
          transformUXSpec(screenWidth, screenHeight, 228) -
          5 * transformUXSpec(screenWidth, screenHeight, 66),
        ButtonsAreaContainer_marginTop: '0%',
        NameZone_direction: 'column',
        NameZone_marginTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 10)
            : transformUXSpec(screenWidth, screenHeight, 150),
        NameZone_marginBottom: transformUXSpec(screenWidth, screenHeight, 30),
        NameZone_height: transformUXSpec(screenWidth, screenHeight, 4 * 66 + 3 * 20),
        NamesButtonsContainer_width: '95%',
        NamesButtonsContainer_marginHorizontal: transformUXSpec(screenWidth, screenHeight, 60),
        NameButton_height: transformUXSpec(screenWidth, screenHeight, 66),
        NameButton_width: transformUXSpec(screenWidth, screenHeight, 1580),
        NameText_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        PromptText_fontSize: transformUXSpec(screenWidth, screenHeight, 60),
        playbookImageContainer_RightPadding: '0%',
        ConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 44),
        ConfirmText_marginTop: transformUXSpec(screenWidth, screenHeight, 0),
        ConfirmButton_width: transformUXSpec(screenWidth, screenHeight, 434),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 94),
        ConfirmButtonContainer_justifyContent: 'flex-start',
        ConfirmButtonContainer_width: '100%',
        ConfirmButtonContainer_height: '10%',
        ConfirmButtonContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        PromptContainer_width: transformUXSpec(screenWidth, screenHeight, 1580),
        PromptContainer_height: 1 * transformUXSpec(screenWidth, screenHeight, 60),
        PromptContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 60),

        PromptContainer_marginBottom: transformUXSpec(screenWidth, screenHeight, 70),

        TitleContainer_flex: os === 'web' ? 1 : undefined,
        TitleContainer_width: '100%',
        TitleContainer_height: '10%',
        Title_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        PlaybookImage_width: transformUXSpec(screenWidth, screenHeight, 1580),
        playbookImageContainer_height: transformUXSpec(screenWidth, screenHeight, 977),
        WarningText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        WarningText_paddingBottom: transformUXSpec(screenWidth, screenHeight, 20),
      }
    }
  }
}
