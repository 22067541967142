interface BaseUser {
  email: string
  firstName: string
  language: string
  lastName: string
  password: string
  phone: string
  photo: string
  role: UserRole
  teamID: number
}

export interface User extends BaseUser {
  id: number
  accountActive: boolean
}

export interface UserCreation extends BaseUser {
  number: string
  positionID: number
  altPositionID: number | undefined
}

export enum UserRole {
  Player = 'player',
  Coach = 'coach',
}

export const defaultUser: User = {
  firstName: '',
  lastName: '',
  role: UserRole.Player,
  accountActive: true,
  email: '',
  id: 1,
  language: 'fr',
  password: '',
  phone: '',
  photo: '',
  teamID: 1,
}
