import React, { Component } from 'react'
import { TouchableOpacity, View, Text, Dimensions } from 'react-native'
import { getPlaybookStyles, updateStyleParameters } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { MaterialIcons } from '@expo/vector-icons'
import { getScreenRatioStyle } from '../Styles/Peculiar/PlaybooksRatiosStyle'
import { hasValidSnapshot } from '../Utilitary/Utils'
import { GroupNames } from '@taktik/config/Translation/utilities'

interface PlaybookFolderPreState {
  id: number
  group: GroupNames
  name: string
  parentFolderID: number
  folders: any[]
  playbooks: any[]
}

interface PlaybookFolderState extends PlaybookFolderPreState {
  styleParameters: StyleParameters
}

interface PlaybookFolderProps extends PlaybookFolderPreState {
  PlaybookList: any[]
  changeFolderFunction: (group: GroupNames, folderId: number) => void
  nbFolderInRow: number
}

export class PlaybookFolder extends Component<PlaybookFolderProps, PlaybookFolderState> {
  constructor(public props: PlaybookFolderProps) {
    super(props)
    const { id, group, name, parentFolderID, folders, playbooks } = this.props
    const playbookFolderGroup: GroupNames | undefined = Object.values(GroupNames).find(
      (groupName) => groupName === group
    )
    this.state = {
      id: id ?? -1,
      group: playbookFolderGroup ? playbookFolderGroup : GroupNames.Defense,
      name: name ?? '',
      parentFolderID: parentFolderID ? parentFolderID : -1,
      folders: folders ?? [],
      playbooks: playbooks ?? [],
      styleParameters: getStyleParameters(),
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', this.onChange)
  }

  hasValidSnapshot_fromIdOnly = (playbookID: number) => {
    const { PlaybookList } = this.props

    const fullPlaybook = PlaybookList.find((playbook) => playbook.id === playbookID)
    if (fullPlaybook) {
      return hasValidSnapshot(fullPlaybook)
    }
    return false
  }

  getDisplayablePlaybooks = (playbookIDs: number[]) => {
    const displayablePlaybookIDs: number[] = playbookIDs.filter((playbookID) =>
      this.hasValidSnapshot_fromIdOnly(playbookID)
    )

    return displayablePlaybookIDs
  }

  render() {
    const { id, group, playbooks, folders, styleParameters, name } = this.state

    const displayablePlaybookIDs: number[] = this.getDisplayablePlaybooks(playbooks)

    const numberOfPlaybooksInsideFolder = displayablePlaybookIDs.length
    const numberOfFoldersInsideFolder = folders.length

    const numberOfElementsInsideFolder = numberOfPlaybooksInsideFolder + numberOfFoldersInsideFolder
    const nbFolderPerRow = getScreenRatioStyle(styleParameters).FolderPerRow
    const folderWidth =
      this.props.nbFolderInRow === nbFolderPerRow
        ? '' + 100 / nbFolderPerRow + '%'
        : '' + 100 / this.props.nbFolderInRow + '%'

    return (
      <View style={[getPlaybookStyles(styleParameters).FolderContainer, { width: folderWidth }]}>
        <Text key={id} style={[getPlaybookStyles(styleParameters).folderPreviewTitle]}>
          {name.toUpperCase()}
        </Text>
        <TouchableOpacity
          style={{ alignItems: 'center' }}
          onPress={() => this.props.changeFolderFunction(group, id)}
        >
          <MaterialIcons
            name="folder"
            style={getPlaybookStyles(styleParameters).playbookFolderImage}
          />
          <View style={getPlaybookStyles(styleParameters).folderQuantityTextContainer}>
            <Text style={getPlaybookStyles(styleParameters).folderQuantityText}>
              {numberOfElementsInsideFolder}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}
