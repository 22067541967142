import React, { Component } from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { StyleParameters } from '../Styles/Utilities'
import { GroupNames } from '@taktik/config/Translation/utilities'
import { getRegistrationStyles } from '../Styles/AppStyle'

export interface IPosition {
  group: GroupNames
  id: number
  slug: string
  teamID: number | undefined
}

interface PositionProps {
  isSelected: boolean
  position: IPosition
  styleParameters: StyleParameters
  onSelect: (position: IPosition) => void
}

class Position extends Component<PositionProps> {
  render() {
    const { position, isSelected, styleParameters, onSelect } = this.props
    const styles = getRegistrationStyles(styleParameters)

    return (
      <TouchableOpacity
        onPress={() => onSelect(position)}
        style={[styles.positionButton, isSelected && styles.positionButtonSelected]}
      >
        <Text style={[styles.textPositionButton, isSelected && styles.textPositionButtonSelected]}>
          {position.slug}
        </Text>
      </TouchableOpacity>
    )
  }
}

export default Position
