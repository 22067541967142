import { StyleParameters, getMarginToTransformIn43, transformUXSpec } from '../Utilities'
import { Platform } from 'react-native'
export interface StyledScreenRatio {
  Icon_width: any
  BackgroundImage_width: any
  BackgroundImage_marginLeft: any
  BackgroundImage_marginTop: any
  Title_Block_paddingBottom: any
  Title_Container_height: any
  Title_Container_marginBottom: any
  Title_Container_flexDirection: any
  Title_Text_fontSize: any
  Title_Text_marginLeft: any
  Title_Text_marginBottom: any
  Title_Text_alignSelf: any
  Title_Text_width: any
  Title_Text_lineHeight: any
  Logo_Container_marginLeft: any
  Logo_Container_marginBottom: any
  Logo_width: any
  Season_Text_fontSize: any
  Season_Text_marginLeft: any
  Season_Text_marginTop: any
  Buttons_Row_splitRatio: any
  Buttons_Row_Container_bottom: any
  Buttons_Row_Container_width: any
  Buttons_Row_Container_height: any
  Profil_Text_marginTop: any
  Profil_Text_fontSize: any
  Profil_Text_paddingRight: any
  Profil_SubText_Container_flexDirection: any
  Profil_SubText_paddingRight: any
  Profil_SubText_fontSize: any
  Practice_SubText_width: any
  Practice_SubText_paddingLeft: any
  Practice_Text_marginLeft: any
  Profil_SubText_marginTop: any
  Profil_SubText_marginLeft: any
  Profil_Separator_height: any
  Profil_Separator_marginTop: any
  ModalContainer_width: any
  ModalContainer_height: any
  ModalContainer_marginTop: any
  ModalTitleText_marginTop: any
  ModalTitleText_fontSize: any
  ModalActivityIndicator_size: any
  ModalContainer_borderRadius: any
  ModalLoadingProgressBar_height: any
  ModalLoadingProgressBarText_fontSize: any
}

export function getScreenRatioStyle(styleParameters: StyleParameters) {
  const os = Platform.OS
  const { screenHeight } = styleParameters
  let screenWidth = styleParameters.screenWidth
  const marginHorizontal: number = getMarginToTransformIn43(screenWidth, screenHeight)
  if (screenWidth > screenHeight) {
    if (screenHeight > 600) {
      screenWidth -= marginHorizontal
      //horizontal tablet
      return {
        Icon_width: transformUXSpec(screenWidth, screenHeight, 80),
        BackgroundImage_width: screenWidth,
        BackgroundImage_marginLeft: transformUXSpec(
          styleParameters.screenWidth,
          screenHeight,
          marginHorizontal
        ),
        BackgroundImage_marginTop: transformUXSpec(styleParameters.screenWidth, screenHeight, 140),
        Title_Block_paddingBottom: 50,
        Title_Container_height: undefined,
        Title_Container_marginBottom: undefined,
        Title_Container_flexDirection: 'row',
        Title_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 210),
        Title_Text_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        Title_Text_marginBottom: transformUXSpec(screenWidth, screenHeight, 10),
        Title_Text_alignSelf: 'flex-end',
        Title_Text_width: '75%',
        Title_Text_lineHeight: undefined,
        Logo_Container_marginLeft: -transformUXSpec(screenWidth, screenHeight, 0),
        Logo_Container_marginBottom: 0,
        Logo_width: transformUXSpec(screenWidth, screenHeight, 420),
        Season_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 90),
        Season_Text_marginLeft: transformUXSpec(screenWidth, screenHeight, 1250),
        Season_Text_marginTop: 0,
        Buttons_Row_splitRatio: 1 / 3.1,
        Buttons_Row_Container_bottom: 0,
        Buttons_Row_Container_width: transformUXSpec(screenWidth, screenHeight, 1950),
        Buttons_Row_Container_height: transformUXSpec(screenWidth, screenHeight, 320),
        Profil_Text_marginTop: transformUXSpec(screenWidth, screenHeight, 20),
        Profil_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 150),
        Profil_Text_paddingRight: transformUXSpec(screenWidth, screenHeight, 80),
        Profil_SubText_Container_flexDirection: 'row',
        Profil_SubText_paddingRight: transformUXSpec(screenWidth, screenHeight, 20),
        Profil_SubText_fontSize: transformUXSpec(screenWidth, screenHeight, 37),
        Profil_SubText_marginTop:
          os === 'android'
            ? -transformUXSpec(screenWidth, screenHeight, 5)
            : transformUXSpec(screenWidth, screenHeight, 15),
        Practice_SubText_width: '100%',
        Practice_SubText_paddingLeft: transformUXSpec(screenWidth, screenHeight, 25),
        Practice_Text_marginLeft: transformUXSpec(screenWidth, screenHeight, 70),
        Profil_SubText_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        Profil_Separator_height: transformUXSpec(screenWidth, screenHeight, 3),
        Profil_Separator_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 1080),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 554),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 554 / 2),
        ModalTitleText_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        ModalTitleText_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        ModalActivityIndicator_size: transformUXSpec(screenWidth, screenHeight, 150),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalLoadingProgressBar_height: transformUXSpec(screenWidth, screenHeight, 70),
        ModalLoadingProgressBarText_fontSize: transformUXSpec(screenWidth, screenHeight, 37),
      }
    } else {
      //horizontal mobile
      return {
        Icon_width: transformUXSpec(screenWidth, screenHeight, 40),
        BackgroundImage_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        BackgroundImage_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        BackgroundImage_width: screenWidth,
        Title_Block_paddingBottom: 0,
        Title_Container_height: transformUXSpec(screenWidth, screenHeight, 70),
        Title_Container_marginBottom: undefined,
        Title_Container_flexDirection: 'row',
        Title_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        Title_Text_marginLeft: transformUXSpec(screenWidth, screenHeight, 12.5),
        Title_Text_marginBottom: transformUXSpec(screenWidth, screenHeight, 12.5),
        Title_Text_alignSelf: 'center',
        Title_Text_width: undefined,
        Title_Text_lineHeight: undefined,
        Logo_Container_marginLeft: -transformUXSpec(screenWidth, screenHeight, 150),
        Logo_Container_marginBottom: 0,
        Logo_width: transformUXSpec(screenWidth, screenHeight, 105),
        Season_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        Season_Text_marginLeft: transformUXSpec(screenWidth, screenHeight, 500),
        Season_Text_marginTop: os === 'android' ? -20 : 0,
        Buttons_Row_splitRatio: 1 / 3,
        Buttons_Row_Container_bottom: 0,
        Buttons_Row_Container_width: transformUXSpec(screenWidth, screenHeight, 600),
        Buttons_Row_Container_height: transformUXSpec(screenWidth, screenHeight, 100),
        Profil_Text_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        Profil_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        Profil_Text_paddingRight: transformUXSpec(screenWidth, screenHeight, 20),
        Profil_SubText_Container_flexDirection: 'row',
        Profil_SubText_paddingRight: -transformUXSpec(screenWidth, screenHeight, 0),
        Profil_SubText_fontSize: transformUXSpec(screenWidth, screenHeight, 13.5),
        Profil_SubText_marginTop:
          os === 'android'
            ? -transformUXSpec(screenWidth, screenHeight, 5)
            : transformUXSpec(screenWidth, screenHeight, 5),
        Practice_SubText_width: '100%',
        Practice_SubText_paddingLeft: transformUXSpec(screenWidth, screenHeight, 7),
        Profil_SubText_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        Practice_Text_marginLeft: transformUXSpec(screenWidth, screenHeight, 25),
        Profil_Separator_height: transformUXSpec(screenWidth, screenHeight, 2),
        Profil_Separator_marginTop: transformUXSpec(screenWidth, screenHeight, 5),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 505),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 235),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 235 / 2),
        ModalTitleText_marginTop: transformUXSpec(screenWidth, screenHeight, 0),
        ModalTitleText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ModalActivityIndicator_size: transformUXSpec(screenWidth, screenHeight, 80),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalLoadingProgressBar_height: transformUXSpec(screenWidth, screenHeight, 30),
        ModalLoadingProgressBarText_fontSize: transformUXSpec(screenWidth, screenHeight, 14),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        Icon_width: transformUXSpec(screenWidth, screenHeight, 25),
        BackgroundImage_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        BackgroundImage_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        BackgroundImage_width: screenWidth,
        Title_Block_paddingBottom: 120,
        Title_Container_height: undefined,
        Title_Container_marginBottom: transformUXSpec(screenWidth, screenHeight, 0),
        Title_Container_flexDirection: 'column',
        Title_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 70),
        Title_Text_marginLeft: transformUXSpec(screenWidth, screenHeight, 27),
        Title_Text_marginBottom: transformUXSpec(screenWidth, screenHeight, 27),
        Title_Text_alignSelf: 'flex-end',
        Title_Text_width: undefined,
        Title_Text_lineHeight: transformUXSpec(screenWidth, screenHeight, 80),
        Logo_Container_marginLeft: -transformUXSpec(screenWidth, screenHeight, 185),
        Logo_Container_marginBottom: transformUXSpec(screenWidth, screenHeight, 20),
        Logo_width: transformUXSpec(screenWidth, screenHeight, 94.5),
        Season_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        Season_Text_marginLeft: -transformUXSpec(screenWidth, screenHeight, 120),
        Season_Text_marginTop: os === 'android' ? -20 : 0,
        Buttons_Row_splitRatio: 0.4,
        Buttons_Row_Container_bottom: transformUXSpec(screenWidth, screenHeight, 22),
        Buttons_Row_Container_width: transformUXSpec(screenWidth, screenHeight, 335),
        Buttons_Row_Container_height: transformUXSpec(screenWidth, screenHeight, 105),
        Profil_Text_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        Profil_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        Profil_Text_paddingRight: transformUXSpec(screenWidth, screenHeight, 15),
        Profil_SubText_Container_flexDirection: 'column',
        Profil_SubText_paddingRight: transformUXSpec(screenWidth, screenHeight, 0),
        Profil_SubText_fontSize: transformUXSpec(screenWidth, screenHeight, 12.5),
        Profil_SubText_marginTop:
          os === 'android'
            ? -transformUXSpec(screenWidth, screenHeight, 5)
            : transformUXSpec(screenWidth, screenHeight, 5),
        Practice_SubText_width: transformUXSpec(screenWidth, screenHeight, 145),
        Practice_SubText_paddingLeft: transformUXSpec(screenWidth, screenHeight, 7),
        Practice_Text_marginLeft: transformUXSpec(screenWidth, screenHeight, 15),
        Profil_SubText_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        Profil_Separator_height: transformUXSpec(screenWidth, screenHeight, 1),
        Profil_Separator_marginTop: transformUXSpec(screenWidth, screenHeight, 7),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 340),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 310),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 310 / 2),
        ModalTitleText_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalTitleText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ModalActivityIndicator_size: transformUXSpec(screenWidth, screenHeight, 80),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalLoadingProgressBar_height: transformUXSpec(screenWidth, screenHeight, 30),
        ModalLoadingProgressBarText_fontSize: transformUXSpec(screenWidth, screenHeight, 14),
      }
    } else {
      //vertical tablet
      return {
        Icon_width: transformUXSpec(screenWidth, screenHeight, 80),
        BackgroundImage_marginLeft: transformUXSpec(screenWidth, screenHeight, 0),
        BackgroundImage_marginTop: transformUXSpec(screenWidth, screenHeight, 140),
        BackgroundImage_width: screenWidth,
        Title_Block_paddingBottom: 50,
        Title_Container_height: transformUXSpec(screenWidth, screenHeight, 1150),
        Title_Container_marginBottom: transformUXSpec(screenWidth, screenHeight, 100),
        Title_Container_flexDirection: 'column',
        Title_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 200),
        Title_Text_marginLeft: -transformUXSpec(screenWidth, screenHeight, 300),
        Title_Text_marginBottom: transformUXSpec(screenWidth, screenHeight, 0),
        Title_Text_alignSelf: 'center',
        Title_Text_width: '70%',
        Title_Text_lineHeight: transformUXSpec(screenWidth, screenHeight, 225),
        Logo_Container_marginLeft: -transformUXSpec(screenWidth, screenHeight, 1000),
        Logo_Container_marginBottom: transformUXSpec(screenWidth, screenHeight, 80),
        Logo_width: transformUXSpec(screenWidth, screenHeight, 320),
        Season_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 90),
        Season_Text_marginLeft: -transformUXSpec(screenWidth, screenHeight, 800),
        Season_Text_marginTop: 0,
        Buttons_Row_splitRatio: 0.4,
        Buttons_Row_Container_bottom: transformUXSpec(screenWidth, screenHeight, 100),
        Buttons_Row_Container_width: transformUXSpec(screenWidth, screenHeight, 1400),
        Buttons_Row_Container_height: transformUXSpec(screenWidth, screenHeight, 320),
        Profil_Text_marginTop: transformUXSpec(screenWidth, screenHeight, 20),
        Profil_Text_fontSize: transformUXSpec(screenWidth, screenHeight, 120),
        Profil_Text_paddingRight: transformUXSpec(screenWidth, screenHeight, 120),
        Profil_SubText_Container_flexDirection: 'column',
        Profil_SubText_paddingRight: transformUXSpec(screenWidth, screenHeight, 20),
        Profil_SubText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
        Profil_SubText_marginTop:
          os === 'android'
            ? -transformUXSpec(screenWidth, screenHeight, 5)
            : transformUXSpec(screenWidth, screenHeight, 10),
        Practice_SubText_width: transformUXSpec(screenWidth, screenHeight, 520),
        Practice_SubText_paddingLeft: transformUXSpec(screenWidth, screenHeight, 25),
        Practice_Text_marginLeft: transformUXSpec(screenWidth, screenHeight, 50),
        Profil_SubText_marginLeft: transformUXSpec(screenWidth, screenHeight, 175),
        Profil_Separator_height: transformUXSpec(screenWidth, screenHeight, 3),
        Profil_Separator_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 1080),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 750),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 750 / 2),
        ModalTitleText_marginTop: transformUXSpec(screenWidth, screenHeight, 0),
        ModalTitleText_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        ModalActivityIndicator_size: transformUXSpec(screenWidth, screenHeight, 150),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalLoadingProgressBar_height: transformUXSpec(screenWidth, screenHeight, 70),
        ModalLoadingProgressBarText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
      }
    }
  }
}
