import React, { Component, createRef } from 'react'
import { KeyboardTypeOptions } from 'react-native'
import ValidationInputField from '../Pages/ValidationInputField'
import { StyleParameters } from '../Styles/Utilities'
import { GlobalColors } from '../Styles/Theme'
import { getRegistrationStyles } from '../Styles/AppStyle'
import { notEmpty } from '../Utilitary/Utils'
import {
  GenericValidation,
  InputFieldValidation,
  InputFieldValidator,
} from '../DataTypes/ValidationType'

interface GenericFieldProps {
  placeholder: string
  keyboardType?: KeyboardTypeOptions
  maxLength?: number
  secureTextEntry?: boolean
  extraValidators?:
    | Partial<InputFieldValidator<InputFieldValidation>>
    | Partial<InputFieldValidator<InputFieldValidation>>[]
  styleParameters: StyleParameters
  onValidationChange: (text: string, errors: InputFieldValidation[]) => void
  onComplete: () => void
}

export default class GenericField extends Component<GenericFieldProps> {
  private inputFieldRef = createRef<ValidationInputField>()

  validate = () => this.inputFieldRef.current?.validate()

  render() {
    const {
      placeholder,
      keyboardType,
      maxLength,
      secureTextEntry,
      extraValidators,
      styleParameters,
      onValidationChange,
      onComplete,
    } = this.props
    const styles = getRegistrationStyles(styleParameters)

    const validators: Partial<InputFieldValidator<InputFieldValidation>>[] = [
      { [GenericValidation.NOT_EMPTY]: (input) => notEmpty(input) },
    ]
    if (extraValidators) {
      validators.push(...(Array.isArray(extraValidators) ? extraValidators : [extraValidators]))
    }

    return (
      <ValidationInputField
        ref={this.inputFieldRef}
        validators={validators}
        onValidate={(text, errors) => onValidationChange(text, errors)}
        onComplete={() => onComplete()}
        placeholder={placeholder.toUpperCase()}
        placeholderTextColor={GlobalColors.mediumGrey}
        style={styles.textInputStyle}
        maxLength={maxLength ?? 200}
        keyboardType={keyboardType}
        secureTextEntry={secureTextEntry}
      />
    )
  }
}
