import { StyleParameters, getMarginToTransformIn43, transformUXSpec } from '../Utilities'
export interface StyledScreenRatio {
  Icon_width: any
  ModalContainer_width: any
  ModalContainer_height: any
  ModalContainer_marginTop: any
  ModalTitleText_marginTop: any
  ModalTitleText_fontSize: any
  ModalActivityIndicator_size: any
  ModalContainer_borderRadius: any
  ModalLoadingProgressBar_height: any
  ModalLoadingProgressBarText_fontSize: any
}

export function getScreenRatioStyle(styleParameters: StyleParameters) {
  const { screenHeight } = styleParameters
  let screenWidth = styleParameters.screenWidth
  const marginHorizontal: number = getMarginToTransformIn43(screenWidth, screenHeight)
  if (screenWidth > screenHeight) {
    if (screenHeight > 600) {
      screenWidth -= marginHorizontal
      //horizontal tablet
      return {
        Icon_width: transformUXSpec(screenWidth, screenHeight, 80),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 1080),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 554),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 554 / 2),
        ModalTitleText_marginTop: transformUXSpec(screenWidth, screenHeight, 50),
        ModalTitleText_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        ModalActivityIndicator_size: transformUXSpec(screenWidth, screenHeight, 150),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalLoadingProgressBar_height: transformUXSpec(screenWidth, screenHeight, 70),
        ModalLoadingProgressBarText_fontSize: transformUXSpec(screenWidth, screenHeight, 37),
      }
    } else {
      //horizontal mobile
      return {
        Icon_width: transformUXSpec(screenWidth, screenHeight, 40),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 505),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 235),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 235 / 2),
        ModalTitleText_marginTop: transformUXSpec(screenWidth, screenHeight, 0),
        ModalTitleText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ModalActivityIndicator_size: transformUXSpec(screenWidth, screenHeight, 80),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalLoadingProgressBar_height: transformUXSpec(screenWidth, screenHeight, 30),
        ModalLoadingProgressBarText_fontSize: transformUXSpec(screenWidth, screenHeight, 14),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        Icon_width: transformUXSpec(screenWidth, screenHeight, 25),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 340),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 310),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 310 / 2),
        ModalTitleText_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        ModalTitleText_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        ModalActivityIndicator_size: transformUXSpec(screenWidth, screenHeight, 80),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalLoadingProgressBar_height: transformUXSpec(screenWidth, screenHeight, 30),
        ModalLoadingProgressBarText_fontSize: transformUXSpec(screenWidth, screenHeight, 14),
      }
    } else {
      //vertical tablet
      return {
        Icon_width: transformUXSpec(screenWidth, screenHeight, 80),
        ModalContainer_width: transformUXSpec(screenWidth, screenHeight, 1080),
        ModalContainer_height: transformUXSpec(screenWidth, screenHeight, 750),
        ModalContainer_marginTop:
          screenHeight / 2 - transformUXSpec(screenWidth, screenHeight, 750 / 2),
        ModalTitleText_marginTop: transformUXSpec(screenWidth, screenHeight, 0),
        ModalTitleText_fontSize: transformUXSpec(screenWidth, screenHeight, 50),
        ModalActivityIndicator_size: transformUXSpec(screenWidth, screenHeight, 150),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalLoadingProgressBar_height: transformUXSpec(screenWidth, screenHeight, 70),
        ModalLoadingProgressBarText_fontSize: transformUXSpec(screenWidth, screenHeight, 45),
      }
    }
  }
}
