import React, { Component } from 'react'
import translator from '@taktik/config/Translation/languages'
import { isEmail, notEmpty } from '../Utilitary/Utils'
import { EmailValidation, InputFieldValidation } from '../DataTypes/ValidationType'
import GenericField from './GenericField'
import { StyleParameters } from '../Styles/Utilities'

interface EmailFieldProps {
  styleParameters: StyleParameters
  onValidationChange: (text: string, errors: InputFieldValidation[]) => void
  onComplete: () => void
}

export default class EmailField extends Component<EmailFieldProps> {
  render() {
    const { styleParameters, onValidationChange, onComplete } = this.props

    return (
      <GenericField
        extraValidators={{
          [EmailValidation.CORRECT_FORMAT]: (input) => isEmail(input),
          [EmailValidation.NOT_EMPTY]: (input) => notEmpty(input),
        }}
        onValidationChange={(text, errors) => onValidationChange(text, errors)}
        onComplete={() => onComplete()}
        placeholder={translator.t('email').toUpperCase()}
        keyboardType="email-address"
        styleParameters={styleParameters}
      />
    )
  }
}
