import React from 'react'
import { View, StyleProp, StyleSheet } from 'react-native'
import { Svg, Path, G, Polygon, Rect } from 'react-native-svg'
import { getColorAndWidth } from './Utilities'

interface TaktikIconProps {
  style: StyleProp<any>
}

const TaktikIcon = ({ style }: TaktikIconProps) => {
  let { color, width, height, ...remainingStyle } = StyleSheet.flatten(style)
  ;({ color, width } = getColorAndWidth(style))

  const defaultHeight = 71.53

  if (!height) {
    height = defaultHeight
  }

  return (
    <View style={remainingStyle}>
      <Svg width={width} height={height} viewBox={`0 0 301.6 ${defaultHeight}`}>
        <G fill={color}>
          <Polygon points="90.47 26.5 99.86 26.5 99.86 54.35 108.95 54.35 108.95 26.5 118.23 26.5 118.23 18.44 90.47 18.44 90.47 26.5" />
          <Path d="M137.29,18.44,124.14,54.35h9.31l2.63-7.84h12.4l2.78,7.84h9.41L147.35,18.44Zm8.41,20.11h-6.92l3.4-10Z" />
          <Polygon points="189.07 18.44 179.03 30.38 179.03 18.44 169.99 18.44 169.99 54.35 179.03 54.35 179.03 42.06 188.16 54.35 199.19 54.35 185.23 35.55 200.07 18.44 189.07 18.44" />
          <Polygon points="208.14 26.5 217.53 26.5 217.53 54.35 226.62 54.35 226.62 26.5 235.9 26.5 235.9 18.44 208.14 18.44 208.14 26.5" />
          <Rect x="248.16" y="18.44" width="9.04" height="35.91" />
          <Polygon points="301.6 18.44 290.6 18.44 280.56 30.38 280.56 18.44 271.52 18.44 271.52 54.35 280.56 54.35 280.56 42.06 289.69 54.35 300.72 54.35 286.76 35.55 301.6 18.44" />
          <Polygon points="28.66 39.14 15.55 24.93 1.35 24.93 21.57 47.15 0 71.53 14.55 71.53 29.17 55.49 36.42 47.55 28.66 39.14" />
          <Polygon points="58.31 0 44.1 0 31 14.21 23.24 22.62 30.49 30.56 45.11 46.6 59.66 46.6 38.09 22.21 58.31 0" />
        </G>
      </Svg>
    </View>
  )
}

export default TaktikIcon
