import React, { Dispatch } from 'react'
import translator from '@taktik/config/Translation/languages'
import { View, Text, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'
import { getUserTeamInviteStyles } from '../Styles/AppStyle'
import { toggleInvitationModal } from '../redux/actions'

import BasicPage from './BasicPage'
import { NavigationInterface } from './PageInfo'
import { Modal } from '../components/ModalImport'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'

import SnackBar from 'rn-snackbar'
import { TSnackBar } from './../components/snackbar/TSnackBar'
import { SnackBarType } from './../components/snackbar/snack-bar-type'
import {
  acceptInvitationForToken,
  getInfoFromToken,
  ApprovalInsertResponse,
} from '../Utilitary/TokenUtils'

interface UserTeamInviteModalProps extends NavigationInterface {
  token: string | undefined
  onClose: () => void
}

interface UserTeamInviteModalState {
  isLoaded: boolean
  teamId: number | undefined
  teamName: string
  coachName: string
  positions: { [key: string]: object }
  styleParameters: StyleParameters
}

class UserTeamInviteModal extends BasicPage<UserTeamInviteModalProps, UserTeamInviteModalState> {
  constructor(public props: UserTeamInviteModalProps) {
    super(props)
    this.state = {
      styleParameters: getStyleParameters(),
      isLoaded: false,
      teamId: undefined,
      coachName: '',
      teamName: '',
      positions: {},
    }
  }

  componentDidMount() {
    const { token, onClose } = this.props

    if (!token) {
      onClose()
      return
    }

    getInfoFromToken(token).then((tokenInfo) => {
      if (tokenInfo) {
        const { coachName, teamId, teamName, positions } = tokenInfo
        this.setState({ isLoaded: true, coachName, teamId, teamName, positions })
        return
      }
      onClose()
    })
  }

  shouldComponentUpdate(prevProps: UserTeamInviteModalProps, prevState: UserTeamInviteModalState) {
    return this.props.token !== prevProps.token || this.state.isLoaded !== prevState.isLoaded
  }

  acceptInvitation = async () => {
    const { onClose, token } = this.props
    const { teamId } = this.state

    if (token && teamId) {
      // TODO: This function could only require the token, the team id being calculated from it.
      const approvalResponse = await acceptInvitationForToken(token, teamId)

      this.renderMessage(
        this.getInvitationFeedbackMessage(approvalResponse),
        approvalResponse.success ? SnackBarType.SUCCESS : SnackBarType.ERROR
      )
    }
    onClose()
  }

  getInvitationFeedbackMessage = ({
    success,
    invalidToken,
    user,
  }: ApprovalInsertResponse): string => {
    if (success) {
      return translator.t('userTeamInvite.success')
    } else if (user === undefined) {
      return translator.t('userTeamInvite.userAlreadyExists')
    } else if (invalidToken) {
      return translator.t('errors.invalidToken')
    }
    return translator.t('userTeamInvite.failure')
  }

  renderMessage = (message: string, type: SnackBarType) => {
    SnackBar.show('', {
      position: 'top',
      renderContent: () => (
        <TSnackBar message={message} type={type} onDismiss={() => SnackBar.dismiss()} />
      ),
    })
  }

  renderInviteText = () => {
    const { styleParameters, coachName, teamName } = this.state
    const styles = getUserTeamInviteStyles(styleParameters)

    return (
      <View>
        <View style={styles.InviteTextContainerCoach}>
          <Text style={styles.InviteText}>
            {translator.t('userTeamInvite.subTitleFirstPart')}
            {coachName}
          </Text>
        </View>
        <View style={styles.InviteTextContainerTeam}>
          <Text style={styles.InviteText}>
            {translator.t('userTeamInvite.team')}
            {teamName}
            {translator.t('userTeamInvite.subTitleSecondPart')}
          </Text>
        </View>
        <Text style={styles.InviteTextJoin}>{translator.t('userTeamInvite.question')}</Text>
      </View>
    )
  }

  renderButtonsRow = () => {
    const { onClose } = this.props
    const { styleParameters } = this.state
    const styles = getUserTeamInviteStyles(styleParameters)

    return (
      <View style={styles.buttonsRow}>
        <View style={styles.buttonCancel}>
          <TouchableOpacity style={styles.button} onPress={() => onClose()}>
            <Text style={styles.cancelButtonText}>
              {translator.t('userTeamInvite.decline').toUpperCase()}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.buttonAcceptInvite}>
          <TouchableOpacity style={styles.button} onPress={() => this.acceptInvitation()}>
            <Text style={styles.createAcceptButtonText}>
              {translator.t('userTeamInvite.accept').toUpperCase()}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  render() {
    const { isLoaded, styleParameters } = this.state

    return (
      <Modal transparent>
        <View style={getUserTeamInviteStyles(styleParameters).modalContainer}>
          <Text style={getUserTeamInviteStyles(styleParameters).modalTitle}>
            {translator.t('userTeamInvite.title')}
          </Text>

          {isLoaded && (
            <>
              {this.renderInviteText()}
              {this.renderButtonsRow()}
            </>
          )}
        </View>
      </Modal>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onClose: () => dispatch(toggleInvitationModal(false)),
})

export default connect(null, mapDispatchToProps)(UserTeamInviteModal)
