import { StyleSheet, ScaledSize, Platform } from 'react-native'

import { GlobalColors, StyledComponentsTheme } from '../Theme'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/PlayerStatsRatiosStyles'
import { StyleParameters, transformUXSpec } from '../Utilities'
export interface StatsPageStyle {}

export function playerStatsStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  teamColor: string,
  _rowSize: number,
  styleParameters: StyleParameters,
  NumberTimeTick?: number,
  timeSpan?: number,
  selected?: boolean
) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(
    styleParameters.screenWidth,
    styleParameters.screenHeight,
    styleParameters.isMobile,
    styleParameters.isHorizontal,
    0,
    timeSpan
  )
  if (NumberTimeTick === undefined) {
    NumberTimeTick = 1
  }
  return StyleSheet.create({
    page: {
      flex: 1,
      height: screenRatio.Page_height,
      flexDirection: 'column',
      backgroundColor: theme.colors.primary,
    },
    row1: {
      flexWrap: 'wrap',
      flexDirection: screenRatio.Row1_direction,
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: screenRatio.Row1_marginHorizontal,
      marginTop: screenRatio.Row1_marginTop,
    },
    ProfilContainer: {
      flex: screenRatio.ProfilContainer_flex,
      width: screenRatio.ProfilContainer_width,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0%',
      height: screenRatio.ProfilContainer_height,
      marginLeft: screenRatio.ProfilContainer_marginLeft,
    },
    profil: {
      flex: 1,
      flexDirection: 'column',
      alignSelf: 'flex-start',
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.Profil_fontSize,
    },
    ids: {
      flex: screenRatio.IDs_flex,
      width: screenRatio.IDs_width,
      flexDirection: 'row',
      paddingTop: screenRatio.IDs_paddingTop,
      marginLeft: screenRatio.IDs_marginLeft,
      justifyContent: 'center',
      alignItems: 'center',
      height: screenRatio.IDs_height,
    },
    ProfilPictureContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: screenRatio.ProfilPictureContainer_width,
      height: screenRatio.ProfilPictureContainer_height,
      paddingLeft: screenRatio.ProfilPictureContainer_paddigLeft,
    },
    profil_picture: {
      borderWidth: 1,
      borderColor: theme.textColors.primary,
      width: '100%',
      height: '100%',
    },
    names: {
      width: screenRatio.Names_width,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingLeft: screenRatio.Names_paddingLeft,
    },
    name: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.Name_fontSize,
    },
    lvl: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.Lvl_fontSize,
    },
    //second row
    row2: {
      flexDirection: screenRatio.Row2_direction,
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: screenRatio.Row2_height,
      marginHorizontal: screenRatio.Row2_marginHorizontal,
      paddingTop: screenRatio.Row2_paddingTop,
    },
    ScoreColumn: {
      width: screenRatio.ScoreColumn_width,
      marginLeft: screenRatio.ScoreColumn_marginLeft,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    scores: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      paddingTop: screenRatio.Scores_paddingTop,
    },
    ScoreContainer: {
      marginRight: '10%',
      width: screenRatio.ScoreContainer_width,
      height: screenRatio.ScoreContainer_height,
      borderRadius: screenRatio.ScoreContainer_borderRadius,
      backgroundColor: selected ? GlobalColors.green : theme.colors.secondary,
      flexDirection: selected ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ScoreText_container: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      flexWrap: 'wrap',
    },
    ScoreText: {
      textAlign: 'center',
      alignSelf: 'center',
      color: selected ? 'white' : theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      flexWrap: 'wrap',
      fontSize: screenRatio.ScoreText_fontSize,
      lineHeight: screenRatio.ScoreText_fontSize,
    },

    Score100: {
      alignSelf: 'center',
      color: selected ? 'white' : theme.textColors.primary,
      fontFamily: 'barlow-extra',
      fontSize: screenRatio.Score100_fontSize,
      marginBottom: selected ? '-10%' : '0%',
    },
    TeamScoreText_container: {
      width: screenRatio.ScoreContainer_width,
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
    },

    circles_container: {
      flex: 1,
      justifyContent: 'flex-start',
      flexDirection: 'row',
    },

    ratio: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      paddingLeft: screenRatio.Ratio_paddingLeft,
      alignItems: 'flex-end',
      marginRight: screenRatio.Ratio_marginRight,
    },
    RatioOperator: {
      flexDirection: 'row',
      paddingRight: screenRatio.RatioOperator_paddingRight,
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: screenRatio.RatioOperator_width,
    },
    RatioTextContainer: {
      width: '40%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      paddingLeft: screenRatio.RatioTextContainer_paddingLeft,
    },
    RatioText: {
      color: theme.textColors.primary,
      flexWrap: 'wrap',
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.RatioText_fontSize,
      paddingLeft: '3%',
      lineHeight: screenRatio.RatioText_lineHeight,
    },
    RatioScore_container: {
      width: '50%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingLeft: '10%',
      alignItems: 'flex-start',
    },
    RatioScore: {
      paddingRight: '7%',
      alignSelf: 'flex-end',
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra',
      fontSize: screenRatio.RatioScore_fontSize,
    },
    ratio_separator: {
      height: screenRatio.Separator_height,
      width: screenRatio.Separator_width,
      backgroundColor: theme.textColors.primary,
    },
    xpbar_lvl_left: {
      flexDirection: 'column',
      alignItems: selected ? 'flex-start' : 'flex-end',
    },

    xpbar_lvls: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    xpbar_top: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    XpbarCurrentXp: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingLeft: screenRatio.XpbarCurrentXp_paddingLeft,
    },
    XpbarCurrentXpArrow: {
      marginRight: screenRatio.XpbarCurrentXpArrow_marginRight,
      marginTop: '1%',
      color: theme.textColors.primary,
      fontSize: screenRatio.XpbarCurrentXpArrow_fontSize,
      transform: [{ scaleX: 0.75 }],
    },
    XpbarCurrentXpNumbers: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingBottom: screenRatio.XpbarCurrentXpNumbers_paddingBottom,
    },
    XpbarCurrentXpScore: {
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.XpbarCurrentXpScore_fontSize,
      color: theme.textColors.primary,
    },
    XpbarCurrentXpText: {
      fontFamily: 'barlow-semi-italic',
      flexWrap: 'wrap',
      width: screenRatio.XpbarCurrentXpText_width,
      fontSize: screenRatio.XpbarCurrentXpText_fontSize,
      color: theme.textColors.primary,
      marginTop: screenRatio.XpbarCurrentXpText_marginTop,
      lineHeight: screenRatio.XpbarCurrentXpText_fontSize,
    },
    XpbarPreviousNextXpScore: {
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.XpbarPreviousNextXpScore_fontSize,
      color: theme.textColors.primary,
    },
    XpbarPreviousNextXpText: {
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.XpbarPreviousNextXpText_fontSize,
      color: theme.textColors.primary,
    },
    XpBarContainer: {
      width: screenRatio.XpBarContainer_width,
      flexDirection: 'column',
      marginLeft: screenRatio.XpBarContainer_paddingLeft,
      paddingTop: screenRatio.XpBarContainer_paddingTop,
    },
    xpBar_fill: {
      height: screenRatio.XpBar_Fill_height,
      width: '45%',
    },
    xpBar: {
      height: screenRatio.XpBar_height,
      width: '100%',
      backgroundColor: GlobalColors.paleGrey,
    },

    //third row
    row3: {
      flex: 1,
      paddingTop: screenRatio.Row3_paddingTop,
      flexDirection: screenRatio.Row3_direction,
      justifyContent: 'flex-start',
      height: screenRatio.Row3_height,
      marginHorizontal: screenRatio.Row3_marginHorizontal,
      paddingBottom: screenRatio.Row3_paddingBottom,
    },

    TableColumn: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: screenRatio.TableColumn_width,
      height: screenRatio.TableColumn_height,
      marginLeft: screenRatio.TableColumn_marginLeft,
    },
    GraphColumn: {
      marginLeft: screenRatio.GraphColumn_marginLeft,
      paddingBottom: screenRatio.GraphColumn_marginBottom,
      flexDirection: 'column',
      justifyContent: 'center',
      width: screenRatio.GraphColumn_width,
      marginTop: screenRatio.GraphColumn_marginTop,
    },
    graph_TableTitle_container: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    TableTitle: {
      marginHorizontal: screenRatio.TableTitle_marginHorizontal,
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra',
      fontSize: screenRatio.TableTitle_fontSize,
    },
    GraphTitle: {
      marginHorizontal: screenRatio.GraphTitle_marginHorizontal,
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra',
      fontSize: screenRatio.GraphTitle_fontSize,
    },
    GraphButtonContainer: {
      paddingLeft: screenRatio.GraphButtonContainer_paddingLeft,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
    },
    GraphButtonSeparator: {
      backgroundColor: theme.colors.separator,
      width: 1,
      height: screenRatio.GraphTopButton_height,
    },
    GraphTopButton: {
      backgroundColor: selected ? teamColor : theme.colors.notSelectedButton,
      width: screenRatio.GraphTopButton_width,
      height: screenRatio.GraphTopButton_height,
      alignSelf: 'center',
    },
    GraphTopButton_text: {
      alignSelf: 'center',
      color: selected ? GlobalColors.white : GlobalColors.black,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.GraphTopButton_Text_fontSize,
      lineHeight: screenRatio.GraphTopButton_height,
    },
    GraphTopLegendContainer: {
      flexDirection: 'column',
      width: screenRatio.GraphTopLegendContainer_width,
      paddingLeft: screenRatio.GraphTopLegendContainer_paddingLeft,
      paddingTop: screenRatio.GraphTopLegendContainer_paddingTop,
      marginBottom: screenRatio.GraphTopLegendContainer_marginBottom,
    },
    GraphTopLegendRow: {
      height: screenRatio.GraphTopLegendRow_height,
      flexDirection: 'row',
      paddingTop: screenRatio.GraphTopLegendRow_paddingTop,
      justifyContent: screenRatio.GraphTopLegendRow_justifyContent,
      alignItems: 'center',
    },
    GraphTopLegendText: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-medium',
      fontSize: screenRatio.GraphTopLegendText_fontSize,
      marginLeft: screenRatio.GraphTopLegendText_marginLeft,
    },
    GraphTopLegendScoreBar: {
      width: selected
        ? screenRatio.GraphTopLegendMyScoreBar_width
        : screenRatio.GraphTopLegendTeamScoreBar_width,
      backgroundColor: selected ? GlobalColors.green : GlobalColors.mediumGrey,
      height: transformUXSpec(
        styleParameters.screenWidth,
        styleParameters.screenHeight,
        selected ? 2 : 2.2
      ),
    },
    GraphTopRow: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      paddingHorizontal: screenRatio.GraphTopRow_paddingHorizontal,
      paddingTop: screenRatio.GraphTopRow_paddingTop,
      marginBottom: screenRatio.GraphTopRow_marginBottom,
    },
    graph_and_top_row_container: {
      flex: 1,
      justifyContent: 'center',
      flexDirection: 'column',
    },
    graph_and_top_row: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    GraphContainer: {
      paddingTop: screenRatio.GraphContainer_paddingTop,
      width: screenRatio.GraphContainer_width,
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: screenRatio.GraphContainer_marginLeft,
      justifyContent: 'flex-start',
    },
    xAxisColumnContainer: {
      flex: 1,
      flexDirection: 'column',
      marginLeft: screenRatio.XAxisColumnContainer_marginLeft,
      marginRight: screenRatio.XAxisColumnContainer_marginRight,
      marginBottom: screenRatio.XAxisColumnContainer_marginBottom,
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    XAxisContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      paddingLeft: screenRatio.XAxisContainer_paddingLeft,
      paddingTop: screenRatio.XAxisContainer_paddingTop,
    },

    XAxisContainerLabel: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: (100 / NumberTimeTick).toString() + '%',
      height: screenRatio.XAxisContainerLabel_height,
      flexDirection: 'column',
    },
    XAxisLabel: {
      alignSelf: 'center',
      fontFamily: 'barlow-medium',
      fontSize: screenRatio.XAxisLabel_fontSize,
      flexWrap: 'wrap',
      color: theme.textColors.primary,
    },
    loadingPage: {
      flex: 1,
      backgroundColor: theme.colors.primary,
    },

    PlaybookGroupButton: {
      flexDirection: 'column',
      justifyContent: 'center',
      height: screenRatio.PlaybookGroupButton_height,
      backgroundColor: selected ? teamColor : theme.colors.notSelectedButton,
      width: screenRatio.PlaybookGroupButton_width,
      marginRight: 1,
    },
    PlaybookGroupText: {
      alignSelf: 'center',
      color: selected ? GlobalColors.white : GlobalColors.black,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.PlaybookGroupText_fontSize,
      paddingVertical: screenRatio.PlaybookGroupText_paddingVertical,
      paddingHorizontal: '2%',
    },

    PlaybookGroupButtonsRow: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: screenRatio.Table_width,
      paddingHorizontal: screenRatio.PlaybookGroupButtonsRow_paddingHorizontal,
      paddingVertical: screenRatio.PlaybookGroupButtonsRow_paddingBottom,
    },
    table_head_cell: {
      width: selected ? screenRatio.TableFirstColumn_width : screenRatio.TableNextColumn_width,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    table_header_buttons_container: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginLeft: '15%',
      paddingHorizontal: '3%',
    },
    TableHeaderButtonsArrow: {
      marginVertical: screenRatio.TableHeaderButtonsArrow_marginVertical,
      fontSize: screenRatio.TableHeaderButtonsArrow_fontSize,
      color: selected ? teamColor : GlobalColors.mediumGrey,
    },
    TableHeaderSeparator: {
      backgroundColor: GlobalColors.mediumGrey,
      width: screenRatio.TableHeaderSeparator_width,
      height: screenRatio.TableHeaderSeparator_height,
      opacity: 0.7,
    },
    table_cell: {
      width: screenRatio.TableNextColumn_width,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    table_name_cell: {
      width: screenRatio.TableFirstColumn_width,
      paddingLeft: '2%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    TableHeaderText_container: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '86%',
    },
    TableHeaderText: {
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.TableHeaderText_fontSize,
      lineHeight: screenRatio.TableHeaderText_lineHeight,
      flexWrap: 'wrap',
      paddingRight: Platform.OS === 'ios' && styleParameters.isMobile ? '10%' : '20%',
      marginLeft: '4%',
      color: theme.textColors.tabHeader,
    },
    table_container: {
      flex: 1,
      paddingTop: styleParameters.screenWidth / 500,
    },
    table: {
      height: screenRatio.Table_height,
      width: screenRatio.Table_width,
    },
    table_HeaderRow: {
      flexDirection: 'row',
      width: screenRatio.Table_width,
      height: screenRatio.HeaderRow_height,
      backgroundColor: theme.colors.secondary,
    },
    table_text: {
      width: selected ? '100%' : '75%',
      fontFamily: 'barlow-semi',
      textAlign: 'center',
      fontSize: selected ? screenRatio.TextNameGame_fontSize : screenRatio.Text_fontSize,
      color: selected ? teamColor : theme.textColors.primary,
    },
    table_name_text: {
      width: selected ? '100%' : '75%',
      fontFamily: 'barlow-semi',
      paddingLeft: '3%',
      fontSize: selected ? screenRatio.TextNameGame_fontSize : screenRatio.Text_fontSize,
      color: teamColor,
    },
    dataWrapper: { marginTop: -1, height: '90%' },
    table_row: {
      flexDirection: 'row',
      width: screenRatio.Table_width,
      paddingVertical: '1%',
      //backgroundColor: themeColor,
      borderBottomWidth: transformUXSpec(
        styleParameters.screenWidth,
        styleParameters.screenHeight,
        1
      ),
      borderBottomColor: GlobalColors.mediumGrey,
    },
    ModalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: styleParameters.screenWidth,
      height: styleParameters.screenHeight,
      backgroundColor: theme.colors.popUpBackground,
    },
    ModalEndPracticeContainer: {
      alignSelf: 'center',
      flexDirection: 'column',
      backgroundColor: theme.colors.popUpColor,
      width: screenRatio.ModalEndPracticeContainer_width,
      height: screenRatio.ModalEndPracticeContainer_height,
      borderRadius: screenRatio.ModalEndPracticeContainer_borderRadius,
      marginTop: screenRatio.ModalEndPracticeContainer_marginTop,
    },
    TopRowContainer: {
      flexDirection: 'column',
      paddingTop: screenRatio.ModalEndPracticeCloseButton_top,
      paddingRight: screenRatio.ModalEndPracticeCloseButton_right,
    },
    ModalEndPracticeCloseButtonContainer: {
      paddingTop: screenRatio.ModalEndPracticeCloseButton_top,
      paddingRight: screenRatio.ModalEndPracticeCloseButton_right,
      color: theme.textColors.primary,
      alignContent: 'flex-end',
    },

    ModalEndPracticeCloseButton: {
      alignSelf: 'flex-end',
      flexDirection: 'row',
      width: screenRatio.Icon_width,
      alignItems: 'center',
      minHeight: screenRatio.Icon_width,
    },

    ModalEndPracticeCancelIconContainer: { flex: 1, margin: 10 },

    ModalEndPracticeCloseButtonIcon: {
      minHeight: screenRatio.Icon_width,
      width: screenRatio.Icon_width,
      color: GlobalColors.paleGrey,
      alignSelf: 'center',
    },

    ModalEndPracticeTitleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },

    ModalEndPracticeTitle: {
      minHeight: screenRatio.ModalEndPracticeTitle_fontSize,
      textAlign: 'center',
      color: GlobalColors.green,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalEndPracticeTitle_fontSize,
    },

    ModalEndPracticeSubtitle: {
      color: GlobalColors.black,
      fontFamily: 'barlow-extra-italic',
      textAlign: 'center',

      fontSize: screenRatio.ModalEndPracticeSubtitle_fontSize,
      minHeight: screenRatio.ModalEndPracticeSubtitle_fontSize,
    },

    ModalEndPracticeSubtitleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },

    ModalEndPracticeStatsContainer: {
      justifyContent: 'center',
      marginTop: screenRatio.ModalEndPracticeStatsContainer_marginTop,
      width: '100%',
    },

    ModalEndPracticeStatLine: {
      marginTop: screenRatio.ModalEndPracticeStatsText_marginTop,
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: screenRatio.ModalEndPracticeStatsText_fontSize,
    },

    ModalEndPracticeStatsText: {
      flex: 1,
      textAlign: 'right',
      color: GlobalColors.black,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.ModalEndPracticeStatsText_fontSize,
      marginRight: 1,
    },

    ModalEndPracticeStatsData: {
      flex: 1,
      textAlign: 'left',
      color: GlobalColors.black,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalEndPracticeStatsText_fontSize,
    },

    ModalEndPracticeEXBar: {
      justifyContent: 'center',
      marginTop: screenRatio.ModalEndPracticeXPBar_marginTop,
      width: screenRatio.ModalEndPracticeXpBarContainer_width,
    },

    ModalEndPracticeRankUpContainer: {
      justifyContent: 'center',
      marginTop: screenRatio.ModalEndPracticeRankUpText_marginTop,
    },

    ModalEndPracticeRankUpText: {
      textAlign: 'center',
      color: '#06A800',
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalEndPracticeSubtitle_fontSize,
    },
  })
}

export function graphAxisYSvg(theme: StyledComponentsTheme, styleParameters: any): any {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(
    styleParameters.screenWidth,
    styleParameters.screenHeight,
    styleParameters.isMobile,
    styleParameters.isHorizontal
  )
  return {
    axis: { stroke: theme.colors.contrast, strokeWidth: 1 },
    ticks: {
      stroke: theme.textColors.primary,
      size: screenRatio.GGraphAxisYSvgTicks_Size as number,
      strokeWidth: 1,
    },
    grid: { stroke: theme.colors.contrast, strokeWidth: 1, opacity: 0.1 },
    tickLabels: {
      fill: theme.textColors.primary,
      fontSize: screenRatio.GraphAxisYSvgTicksLabel_fontSize,
      fontFamily: Platform.OS !== 'ios' ? 'barlow-medium' : undefined,
      padding: 2,
    },
  }
}

export function graphWidth(styleParameters: any): number {
  const screenRatio = getScreenRatioStyle(
    styleParameters.screenWidth,
    styleParameters.screenHeight,
    styleParameters.isMobile,
    styleParameters.isHorizontal
  )
  return screenRatio.GraphContainer_width as number
}
export function graphHeight(styleParameters: any, thirdRowHeight: number): any {
  const screenRatio = getScreenRatioStyle(
    styleParameters.screenWidth,
    styleParameters.screenHeight,
    styleParameters.isMobile,
    styleParameters.isHorizontal,
    thirdRowHeight
  )
  return screenRatio.GraphContainer_height as number
}

export function graphAxisXSvg(theme: StyledComponentsTheme): any {
  return {
    axis: { stroke: theme.colors.contrast, strokeWidth: 1 },
    grid: { stroke: theme.colors.primary, strokeWidth: 1, opacity: 1 },
  }
}

export function graphChartSvg(theme: StyledComponentsTheme): any {
  return {
    background: { fill: theme.colors.secondary },
  }
}
