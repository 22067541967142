import React, { Component, Dispatch } from 'react'
import AppLoading from 'expo-app-loading'
import { connect } from 'react-redux'
import { logger } from '../lib/logger'
import { RootState } from './redux/reducers'
import { loadResourcesAsync } from './AssetsManager'
import { createStackNavigator } from '@react-navigation//stack'
import { setInvitationToken, toggleInvitationModal, toggleRegistrationModal } from './redux/actions'

import { PageName } from './Pages/PageInfo'
import HomePage from './Pages/HomePage'
import LoginPage from './Pages/LoginPage'
import SuccessPage from './Pages/SuccessPage'
import PlayerStatsPage from './Pages/PlayerStatsPage'
import GamificationPage from './Pages/GamificationPage'
import PlaybookViewPage from './Pages/PlaybookViewPage'
import PlaybookListPage from './Pages/PlaybookListPage'
import TeamSelectionPage from './Pages/TeamSelectionPage'
import ForgotPasswordPage from './Pages/ForgotPasswordPage'
import ChangePasswordPage from './Pages/ChangePasswordPage'
import { onTokenFromUrl } from './Utilitary/TokenUtils'

interface NavigationModuleProps {
  mustSkipLoadingResources?: boolean
  isLoggedIn: boolean
  onInitializeModals: (initialState: boolean) => void
  onSetInvitationToken: (token: string | undefined) => void
}

interface NavigationModuleState {
  startPage: PageName
  isLoadingComplete: boolean
}
class NavigationModule extends Component<NavigationModuleProps, NavigationModuleState> {
  private unsubscribeFromUrlChanges: (() => void) | undefined = undefined

  constructor(props: NavigationModuleProps) {
    super(props)
    const isLoadingComplete = this.props.mustSkipLoadingResources ?? false

    this.state = {
      startPage: PageName.Loading,
      isLoadingComplete,
    }
  }

  componentDidMount() {
    const { onInitializeModals, onSetInvitationToken } = this.props

    onInitializeModals(false)
    loadResourcesAsync()
      .then(() => {
        this.unsubscribeFromUrlChanges = onTokenFromUrl((token) => {
          const renderModals = token !== undefined
          onSetInvitationToken(token)
          onInitializeModals(renderModals)
        })
        this.setState({ isLoadingComplete: true })
      })
      .catch((err) => {
        logger.error(err)
      })
  }

  componentWillUnmount() {
    if (this.unsubscribeFromUrlChanges) {
      this.unsubscribeFromUrlChanges()
    }
  }

  render() {
    const { isLoadingComplete } = this.state

    if (!isLoadingComplete) {
      return <AppLoading />
    }

    const Stack = createStackNavigator()
    return (
      <Stack.Navigator headerMode="none">
        {this.props.isLoggedIn ? (
          <>
            <Stack.Screen
              name={PageName.Home}
              component={HomePage}
              options={{
                animationEnabled: false,
              }}
            />
            <Stack.Screen
              name={PageName.TeamSelectionPage}
              component={TeamSelectionPage}
              options={{
                animationEnabled: false,
              }}
            />
            <Stack.Screen
              name={PageName.PlaybookList}
              component={PlaybookListPage}
              options={{
                animationEnabled: false,
              }}
            />
            <Stack.Screen
              name={PageName.PlaybookPage}
              component={PlaybookViewPage}
              options={{
                animationEnabled: false,
              }}
            />
            <Stack.Screen
              name={PageName.GamificationPage}
              component={GamificationPage}
              initialParams={{ goodAnswers: 0, badAnswers: 0, averageTimeResponse: 0, points: 0 }}
              options={{
                animationEnabled: false,
              }}
            />
            <Stack.Screen
              name={PageName.PlayerStats}
              component={PlayerStatsPage}
              initialParams={{
                isEndPractice: false,
                goodAnswers: 0,
                badAnswers: 0,
                averageTimeResponse: 0,
                points: 0,
              }}
              options={{
                animationEnabled: false,
              }}
            />
          </>
        ) : (
          <>
            <Stack.Screen
              name={PageName.Login}
              component={LoginPage}
              options={{
                animationEnabled: false,
              }}
            />
            <Stack.Screen
              name={PageName.ForgotPassword}
              component={ForgotPasswordPage}
              options={{
                animationEnabled: false,
              }}
            />
            <Stack.Screen
              name={PageName.Success}
              component={SuccessPage}
              options={{
                animationEnabled: false,
              }}
            />
            <Stack.Screen
              name={PageName.ChangePassword}
              component={ChangePasswordPage}
              options={{
                animationEnabled: false,
              }}
            />
          </>
        )}
      </Stack.Navigator>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onInitializeModals: (initialState: boolean) => {
    dispatch(toggleInvitationModal(initialState))
    dispatch(toggleRegistrationModal(initialState))
  },
  onSetInvitationToken: (token: string | undefined) => dispatch(setInvitationToken(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavigationModule)
