import i18n from 'i18n-js'
import fr from './fr.json'
import en from './en.json'
import { Locale } from './locale'
import * as Localization from 'expo-localization'

const translations: { [locale: string]: object } = {}
translations[Locale.FR] = fr
translations[Locale.EN] = en

i18n.fallbacks = true
i18n.translations = translations
i18n.locale = Locale.FR

export function setLanguageValues() {
  i18n.locale = Localization.locale
  i18n.fallbacks = true
}

export default i18n
