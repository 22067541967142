import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Network from 'expo-network'
import Axios from 'axios'
import { environmentConfiguration } from '../../config/env/environment'
import { v4 as uuidv4 } from 'uuid'
import { storageWipeAllData } from './StorageUtils'
import { store } from '../redux/store'
import { toggleLogin } from '../redux/actions'
import { logger } from '../../lib/logger'
import { User } from '../DataTypes/UserTypes'
import { TokenArray } from '../redux/reducers/teams'

export async function connectedToInternet(): Promise<boolean | undefined> {
  let connectionState: Network.NetworkState
  try {
    connectionState = await Network.getNetworkStateAsync()
  } catch (err) {
    return false
  }
  if (!connectionState.isInternetReachable) {
    return false
  }
  const internet = await Axios.get('ping', { timeout: 2500 })
    .then(
      (_response) => {
        return true
      },
      () => {
        return false
      }
    )
    .catch((_err) => {
      // logger.logError(err)
      return false
    })
  return connectionState.isConnected && connectionState.isInternetReachable && internet
}

export async function configureLocalAuthorizationToken(): Promise<boolean> {
  const token = await AsyncStorage.getItem('authToken')
  if (token) {
    await setLocalAuthorizationToken(token)
    return true
  } else {
    return false
  }
}

export async function setLocalAuthorizationToken(token: string) {
  Axios.defaults.headers.common['Authorization'] = `JWT ${token}`
  await AsyncStorage.setItem('authToken', token)
}

export async function setUserTeamsTokens(tokens: TokenArray[]) {
  await AsyncStorage.setItem('tokens', JSON.stringify(tokens))
}

async function deleteLocalAuthorizationToken() {
  delete Axios.defaults.headers.common['Authorization']
  await AsyncStorage.removeItem('authToken')
}

export async function getLocalUserInfo() {
  const userInfoJSON = await AsyncStorage.getItem('userInfo').catch((error) => {
    throw new Error(error)
  })

  const userInfo: User = userInfoJSON !== null ? JSON.parse(userInfoJSON) : null
  return userInfo
}

export async function storeLocalUserInfo(userData: User) {
  await AsyncStorage.setItem('userInfo', JSON.stringify(userData))
  await createLocalSessionID()
}

export async function getLocalTeamInfo() {
  const teamInfoJSON = await AsyncStorage.getItem('teamInfo').catch((error) => {
    throw new Error(error)
  })
  return teamInfoJSON !== null ? JSON.parse(teamInfoJSON) : null
}

export async function storeLocalTeamInfo(teamInfo: object) {
  await AsyncStorage.setItem('teamInfo', JSON.stringify(teamInfo))
}

async function createLocalSessionID() {
  let sessionID = ''

  try {
    sessionID = uuidv4()
    await AsyncStorage.setItem('sessionID', sessionID)
  } catch (error) {
    logger.info(error)
  }
  return sessionID
}

export async function getLocalSessionID() {
  return (await AsyncStorage.getItem('sessionID')) ?? (await createLocalSessionID())
}

export async function deleteLocalUserInfo() {
  await deleteLocalAuthorizationToken()
  await AsyncStorage.removeItem('userInfo')
  await storageWipeAllData()
}

export async function forceLogoutIfNotAuthenticated(hasAuthToken: boolean) {
  if (hasAuthToken) {
    const isConnectedToInternet = await connectedToInternet()
    const isAuthenticated = await verifyToken(isConnectedToInternet)
    if (!isAuthenticated) {
      await deleteLocalAuthorizationToken()
      store.dispatch(toggleLogin(false))
    }
  } else {
    store.dispatch(toggleLogin(false))
  }
}

export async function authTokenStillPersisted(): Promise<boolean> {
  const token = await AsyncStorage.getItem('authToken')
  return !!token
}

//CHANGES c'est clair qu'il y a une meilleure manière de faire cette verification
async function verifyToken(isOnline: boolean | undefined): Promise<boolean> {
  let isTokenValid = false
  const authToken = Axios.defaults.headers.common['Authorization']
  if (authToken !== undefined) {
    if (isOnline)
      await Axios.get('playbooks')
        .then((response) => {
          if (response.status === 200) isTokenValid = true
        })
        //CHANGES Add 404 response and other stuff
        .catch((error) => {
          if (error.response !== undefined) {
            isTokenValid = false
          } else {
            //logger.error(translator.t('error.serverConnection'))
          }
        })
    else isTokenValid = true
  }
  return isTokenValid
}

export function setBaseUrl() {
  const serverAddress = environmentConfiguration.getApiUrl()
  const appVersion = '/api/v1'
  Axios.defaults.baseURL = serverAddress + appVersion
  Axios.defaults.timeout = 10000
}
