import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { SnackBarType } from './snack-bar-type'

type CustomStyles = {
  backgroundColor: string
  contentColor: string
  labelColor: string
}

export class SnackBarStyles {
  public readonly customStyles: CustomStyles
  public readonly fontSize = 20
  public readonly fontFamily = 'barlow-semi'

  public readonly container: StyleProp<ViewStyle>
  public readonly titleContainer: StyleProp<ViewStyle>
  public readonly titleIcon: StyleProp<ViewStyle>
  public readonly titleText: StyleProp<TextStyle>
  public readonly message: StyleProp<TextStyle>
  public readonly expandSpacer: StyleProp<ViewStyle>
  public readonly dismissBtn: StyleProp<ViewStyle>
  public readonly separator: StyleProp<ViewStyle>

  constructor(type: SnackBarType) {
    this.customStyles = this.getCustomStyles(type)

    this.container = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
      backgroundColor: this.customStyles.backgroundColor,
    }
    this.titleIcon = {}
    this.titleText = {
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      textTransform: 'uppercase',
    }
    this.message = {
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      color: this.customStyles.contentColor,
    }
    this.expandSpacer = {
      flexGrow: 1,
    }
    this.dismissBtn = {
      padding: 10,
    }
    this.separator = {
      width: 10,
    }
  }

  private getCustomStyles(type: SnackBarType) {
    switch (type) {
      case SnackBarType.ERROR:
        return {
          backgroundColor: '#909090',
          contentColor: 'white',
          labelColor: 'black',
        }
      case SnackBarType.WARNING:
        return {
          backgroundColor: '#909090',
          contentColor: 'white',
          labelColor: 'black',
        }
      case SnackBarType.SUCCESS:
      default:
        return {
          backgroundColor: '#909090',
          contentColor: 'white',
          labelColor: 'black',
        }
    }
  }
}
