import { StyleSheet, ScaledSize, Platform } from 'react-native'
import { normalizeFont, normalizeSize } from '../GlobalStyles'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/TopBarMenuRatiosStyles'
import { StyleParameters } from '../Utilities'
import { StyledComponentsTheme, GlobalColors } from '../Theme'
const topBarHeight: number = 140

export default function getTopBarMenuStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  teamColor: string,
  styleParameters: StyleParameters,
  hasSelection?: boolean,
  maxChildren?: number
) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(
    styleParameters,
    hasSelection,
    maxChildren
  )
  return StyleSheet.create({
    topBarMenu: {
      flexDirection: 'row',
      height: screenRatio.TopMenuBar,
      alignContent: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      marginTop: Platform.OS === 'web' ? 0 : 35,
      backgroundColor: theme.colors.genericTopBar,
      width: screenRatio.TopMenuBar_width,
      shadowColor: '#000',
      shadowOpacity: 0.1,
      shadowRadius: 10,
      shadowOffset: { width: 0, height: 10 },
    },
    playbookTitleContainer: {
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      width: screenRatio.LogoContainer_width,
    },
    playbookTitle: {
      fontSize: normalizeFont(
        styleParameters.isHorizontal,
        styleParameters.isFrench,
        screenRatio.PlaybookTitle_fontSize
      ),
      flexWrap: 'wrap',
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-bold',
      paddingVertical: 8,
      paddingHorizontal: screenRatio.PlaybookTitle_paddingHorizontal,
    },
    playbookNavigationArrow: {
      fontSize: normalizeFont(
        styleParameters.isHorizontal,
        styleParameters.isFrench,
        screenRatio.PlaybookTitle_fontSize * 2
      ),
      color: teamColor,
      alignSelf: 'center',
    },
    titleContainer: {
      flex: 1,
      justifyContent: 'center',
      alignSelf: 'flex-start',
      height: normalizeSize(styleParameters.isHorizontal, topBarHeight),
    },
    title: {
      paddingHorizontal: normalizeSize(styleParameters.isHorizontal, 15),
      paddingVertical: normalizeSize(styleParameters.isHorizontal, 10),
      fontFamily: 'barlow-extra',
      fontSize: normalizeFont(styleParameters.isHorizontal, styleParameters.isFrench, 60),
      fontWeight: 'bold',
      color: theme.textColors.secondary,
      alignSelf: 'flex-start',
    },
    language: {
      position: 'absolute',
      paddingStart: screenRatio.LanguageSettingsContainer_paddingRight,
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      height: screenRatio.TopMenuBar,
    },
    languageButtons: {
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      alignSelf: 'center',
      paddingHorizontal: normalizeSize(styleParameters.isHorizontal, 20),
    },
    languageText: {
      marginHorizontal: normalizeSize(styleParameters.isHorizontal, 20),
      color: theme.textColors.secondary,
      fontWeight: 'bold',
      fontFamily: 'barlow-medium',
      fontSize: screenRatio.LanguageSettingsText_fontSize,
    },
    selectedLanguageText: {
      textDecorationLine: 'underline',
      color: teamColor,
    },

    hamburgerButtonContainer: {
      width: screenRatio.HamburgerButtonContainer_width,
      height: screenRatio.TopMenuBar,
      justifyContent: 'center',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
    },
    hamburgerMenuButton: {
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      height: screenRatio.TopMenuBar,
      width: screenRatio.TopMenuBar,
      backgroundColor: teamColor,
    },
    backButtonContainer: {
      width: screenRatio.BackButtonContainer_width,
      height: screenRatio.TopMenuBar,
      justifyContent: 'center',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
    },
    backButtonTouchableOpacity: {
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      height: screenRatio.TopMenuBar,
      width: screenRatio.TopMenuBar,
    },
    hamburgerMenuIcon: {
      alignSelf: 'center',
      fontSize: screenRatio.Icon_width,
      color: GlobalColors.white,
    },
    cancelMenuIcon: {
      alignSelf: 'center',
      width: screenRatio.Icon_width,
      color: GlobalColors.white,
    },
    backButtonIcon: {
      width: screenRatio.Icon_width,
      alignSelf: 'center',
      color: teamColor,
      transform: [{ rotate: '180deg' }],
    },
    nameContainer: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
      alignSelf: screenRatio.RoleContainer_alignSelf,
      marginRight: 0,
    },
    nameText: {
      color: theme.textColors.primary,
      fontWeight: 'bold',
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.NameText_fontSize,
      paddingRight: 2,
    },
    roleContainer: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
      alignSelf: screenRatio.RoleContainer_alignSelf,
      paddingLeft: screenRatio.RoleContainer_paddingLeft,
    },
    roleText: {
      alignSelf: 'flex-end',
      color: theme.textColors.primary,
      fontFamily: 'roboto-light-italic',
      fontSize: screenRatio.RoleText_fontSize,
    },
    IDs: {
      width: screenRatio.IDs_width,
      flexDirection: screenRatio.IDs_flexDirection,
      justifyContent: screenRatio.IDs_justifyContent,
      paddingRight: '2%',
    },
    logoContainer: {
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: screenRatio.LogoContainer_width,
      height: screenRatio.TopMenuBar,
      textAlign: 'center',
    },
    logo: {
      width: screenRatio.LogoContainer_width,
      height: screenRatio.Logo_height,
      alignSelf: 'center',
      tintColor: theme.textColors.primary,
      textAlign: 'center',
      textAlignVertical: 'center',
      justifyContent: 'center',
      color: theme.textColors.primary,
      alignContent: 'center',
      alignItems: 'center',
    },
    modalBackground: {
      alignSelf: 'center',
      width: screenRatio.TopMenuBar_width,
      height: styleParameters.screenHeight,
      backgroundColor: theme.colors.popUpBackground,
    },
    modalContainer: {
      position: 'absolute',
      left: 0,
      top: Platform.OS === 'web' ? 0 : Platform.OS === 'android' ? 0 : 30,
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: teamColor,
      width: screenRatio.ModalContainer_width,
      height: styleParameters.screenHeight,
    },
    parallelogramLeft: {
      position: 'absolute',
      left: screenRatio.ModalContainer_width,
      top: Platform.OS === 'web' ? 0 : Platform.OS === 'android' ? 0 : 30,
      borderTopWidth: styleParameters.screenHeight,
      borderRightWidth: screenRatio.ParrallelogramLeft_borderRightWidth,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderTopColor: teamColor,
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
    },
    modalMenuLinkContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginTop: screenRatio.ModalMenuLinkContainer_marginTop,
      width: screenRatio.ModalContainer_width,
      height: screenRatio.ModalMenuLink_fontSize,
    },
    modalMenuSettingsLogoutLinkContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginTop: screenRatio.ModalMenuLinkContainer_marginTop,
      width: screenRatio.ModalContainer_width,
      height: screenRatio.ModalMenuSettingsLogoutLink_fontSize,
    },
    modalMenuLink: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      color: GlobalColors.white,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.ModalMenuLink_fontSize,
      width: screenRatio.ModalContainer_width,
    },
    modalTouchableOpacity: {
      flex: 1,
      marginLeft: '15%',
    },
    modalMenuSettingsLogoutLink: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      color: GlobalColors.white,
      fontWeight: 'bold',
      fontFamily: 'roboto-light-italic',
      fontSize: screenRatio.ModalMenuSettingsLogoutLink_fontSize,
    },
    modalCloseButtonContainer: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    modalLogoContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignSelf: 'flex-start',
      marginLeft: screenRatio.ModalLogoContainer_marginLeft,
      marginBottom: screenRatio.ModalLogoContainer_marginBottom,
      width: screenRatio.ModalLogoContainer_width,
      height: screenRatio.ModalLogoContainer_width * 1.2,
    },

    modalLogo: {
      width: screenRatio.ModalLogoContainer_width,
      height: screenRatio.ModalLogoContainer_width * 1.2,
    },
    modalTitleContainer: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalTitle: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      color: GlobalColors.black,
      fontFamily: 'barlow-extra',
      fontSize: screenRatio.ModalTitle_fontSize,
      paddingLeft: '15%',
    },
    modalSeparator: {
      width: screenRatio.ModalSeparator_width,
      height: screenRatio.ModalSeparator_height,
      backgroundColor: GlobalColors.black,
      marginLeft: screenRatio.ModalSeparator_marginLeft,
      marginTop: screenRatio.ModalSeparator_marginTop,
      marginBottom: '1%',
    },
    ModalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: styleParameters.screenWidth,
      height: styleParameters.screenHeight,
      backgroundColor: theme.colors.popUpBackground,
    },
    ModalChoiceContainer: {
      alignSelf: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      marginTop: screenRatio.ModalChoiceContainer_marginTop,
      width: screenRatio.ModalChoiceContainer_width,
      height: hasSelection
        ? screenRatio.ModalChoiceContainer_selection_height >
          screenRatio.ModalChoiceContainer_selection_maxHeight
          ? screenRatio.ModalChoiceContainer_selection_maxHeight
          : screenRatio.ModalChoiceContainer_selection_height
        : screenRatio.ModalChoiceContainer_noselection_height,
      maxHeight: screenRatio.ModalChoiceContainer_selection_maxHeight,
      borderRadius: screenRatio.ModalContainer_borderRadius,
    },
    ModalChoiceTitleContainer: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    ModalChoiceTitle: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.ModalChoiceTitle_fontSize,
    },
    ModalChoiceCloseButton: {
      alignSelf: 'flex-end',
      margin: '1%',
    },
    ModalChoiceCloseButtonIcon: {
      width: screenRatio.Icon_width,
      color: GlobalColors.paleGrey,
      paddingTop: screenRatio.ModalChoiceCloseButtonIcon_paddingTop,
    },
    ModalChoiceQuestion: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      textAlign: 'center',
      fontSize: screenRatio.ModalChoiceQuestion_fontSize,
      marginTop: screenRatio.ModalChoiceQuestion_marginTop,
    },
    ChildGroupContainer: {
      flex: 1,
      flexWrap: 'wrap',
      backgroundColor: hasSelection ? teamColor : theme.colors.secondary,
      width: screenRatio.ModalChoiceChildContainer_width,
      marginBottom: screenRatio.ModalChoiceChildContainer_marginBottom,
      paddingVertical: screenRatio.ModalChoiceChildContainer_paddingVertical,
    },
    ChildGroupText: {
      marginHorizontal: '10%',
      color: hasSelection ? GlobalColors.white : theme.textColors.primary,
      textAlign: 'center',
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.ModalChoiceChildText_fontSize,
    },

    ChildrenScrollView: {
      height:
        screenRatio.ModalChoiceChildScrollView_height >
        screenRatio.ModalChoiceContainer_selection_maxHeight
          ? screenRatio.ModalChoiceContainer_selection_maxHeight
          : screenRatio.ModalChoiceChildScrollView_height,
      maxHeight: screenRatio.ModalChoiceChildContainer_maxHeight,
    },
    GroupsColumnContainer: {
      marginHorizontal: screenRatio.ModalChoiceGroupsColumn_marginHorizontal,
      maxHeight: screenRatio.ModalChoiceContainer_selection_maxHeight,
    },
    ParentGroupContainer: {
      marginBottom: screenRatio.ModalChoiceChildContainer_marginBottom,
      color: theme.textColors.primary,
      borderBottomWidth: 1,
      borderBottomColor: theme.textColors.primary,
    },
    ParentGroupText: {
      textAlign: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.ModalChoiceQuestion_fontSize,
    },
    PracticeChoiceContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: screenRatio.ModalChoicePractice_width,
      paddingVertical: screenRatio.ModalChoicePractice_paddingVertical,
      maxHeight: screenRatio.ModalChoicePractice_maxHeight,
    },
    startTrainingButton: {
      justifyContent: 'center',
      width: screenRatio.ModalChoiceButton_width,
      height: screenRatio.ModalChoiceButton_height,
      backgroundColor: teamColor,
      transform: [{ skewX: '-10deg' }],
    },
    startTrainingButtonText: {
      alignSelf: 'center',
      color: GlobalColors.white,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.ModalChoiceButtonText_fontSize,
      marginHorizontal: '5%',
      transform: [{ skewX: '10deg' }],
    },
    goBackButton: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 30,
    },
    backArrow: {
      transform: [{ rotate: '180deg' }, { scale: 2.5 }],
      opacity: 1,
      color: 'white',
    },
    goBackText: {
      fontSize: 18,
      fontFamily: 'barlow-semi-italic',
      textTransform: 'uppercase',
      textAlign: 'left',
      paddingLeft: 15,
      color: 'white',
    },
    modalTitleTeamSelection: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      color: GlobalColors.black,
      fontFamily: 'barlow-extra',
      fontSize: screenRatio.ModalTitleTeamSelection_fontSize,
      paddingLeft: '15%',
    },
    teamContainer: {
      marginLeft: screenRatio.teamContainer_marginLeft,
      flexDirection: 'row',
      alignItems: 'center',
      width: '90%',
      height: screenRatio.ModalTeamContainer_height,
    },
    teamWrapper: { flexDirection: 'row-reverse', marginLeft: screenRatio.teamWrapper_marginLeft },
    teamLogoContainer: {
      width: screenRatio.ModalTeamLogo_size,
      height: screenRatio.ModalTeamLogo_size,
      resizeMode: 'contain',
    },
    teamLogo: {
      width: screenRatio.ModalTeamLogo_size,
      height: screenRatio.ModalTeamLogo_size,
      resizeMode: 'contain',
    },
    teamNameContainer: {
      position: 'relative',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    teamName: {
      fontFamily: 'barlow-italic',
      fontSize: screenRatio.ModalTeamName_fontSize,
      color: 'white',
      textTransform: 'uppercase',
      letterSpacing: 0,
      paddingLeft: 20,
    },
    manageTeamContainer: {
      width: screenRatio.manageTeamContainer_width,
      position: 'absolute',
      bottom: '5%',
    },

    finishTeamContainer: {
      width: screenRatio.finishTeamContainer_width,
      position: 'absolute',
      bottom: '5%',
    },

    trashIcon: {
      marginLeft: screenRatio.trashIcon_marginLeft,
      marginTop: screenRatio.trashIcon_marginTop,
      color: theme.colors.secondary,
      transform: [{ skewX: '13deg' }],
      fontSize: screenRatio.trashIcon_size,
    },
    trashIconContainer: {
      position: 'absolute',
      marginLeft: screenRatio.trashIconContainer_marginLeft,
      width: screenRatio.trashIconContainer_width,
      height: screenRatio.trashIconContainer_height,
      backgroundColor: GlobalColors.paleGrey,
      transform: [{ skewX: '-13deg' }],
    },
    manageMyTeam: {
      fontFamily: 'barlow-italic',
      color: theme.textColors.primary,
      fontSize: screenRatio.manageTeamText_fontSize,
    },
    finishText: {
      fontFamily: 'barlow-italic',
      color: theme.textColors.primary,
      fontSize: screenRatio.finishText_fontSize,
    },
    playbookPageButtonText: {
      alignSelf: 'center',
      color: GlobalColors.white,
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.playbookPageButtonText_fontSize,
      marginHorizontal: '5%',
      transform: [{ skewX: '10deg' }],
    },
    playbookPageButton: {
      justifyContent: 'center',
      width: screenRatio.playbookPageButton_width,
      height: screenRatio.playbookPageButton_height,
      backgroundColor: teamColor,
      transform: [{ skewX: '-10deg' }],
      marginLeft: screenRatio.playbookPageButton_marginLeft,
    },
    noPraticeAvailableText: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      textAlign: 'center',
      fontSize: screenRatio.noPraticeAvailableText_fontSize,
      marginTop: screenRatio.noPraticeAvailableText_marginTop,
      marginBottom: screenRatio.noPraticeAvailableText_marginBottom,
    },
    suggestionText: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      textAlign: 'center',
      fontSize: screenRatio.suggestionText_fontSize,
    },
    suggestionSecondPartText: {
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      textAlign: 'center',
      fontSize: screenRatio.suggestionSecondPartText_fontSize,
      marginBottom: screenRatio.suggestionSecondPartText_marginBottom,
    },
  })
}
