import React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  ActivityIndicator,
} from 'react-native'
import { PageName, NavigationInterface } from './PageInfo'
import TopBarMenu from './TopBarMenu'
import translator from '@taktik/config/Translation/languages'
import BasicPage from './BasicPage'
import {
  getGlobalStyles,
  getLoginStyles,
  getPlaybookStyles,
  updateStyleParameters,
} from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { AntDesign } from '@expo/vector-icons'
import { RootState } from '../redux/reducers'
import { connect } from 'react-redux'
import PlaybookGroup from './PlaybookGroup'
import { hasValidSnapshot } from '../Utilitary/Utils'
import { GroupNames } from '@taktik/config/Translation/utilities'

export const getTranslatedGroupName = (groupName: GroupNames | string) => {
  switch (groupName) {
    case GroupNames.Offense:
      return translator.t('groups.offense')
    case GroupNames.Defense:
      return translator.t('groups.defense')
    case GroupNames.Special:
      return translator.t('groups.special')
    default:
      return 'N/A'
  }
}

interface PlaybookPageProps extends NavigationInterface {
  isStoringImages: boolean
  playbookFolders: any[]
  playbooks: any[]
}

// CHANGES change state so that the groupFolder elements are in an object/array and every group has an associated folder
interface PlaybookListState {
  styleParameters: StyleParameters
  offenseFolder: number
  defenseFolder: number
  specialFolder: number
  userMustBeLoggedOut: boolean
  teamName: string
  offenseFolderIsOpen: boolean
  defenseFolderIsOpen: boolean
  specialFolderIsOpen: boolean
}

class PlaybookListPage extends BasicPage<PlaybookPageProps, PlaybookListState> {
  constructor(props: PlaybookPageProps) {
    super(props)
    this.state = {
      offenseFolder: 0,
      defenseFolder: 0,
      specialFolder: 0,
      userMustBeLoggedOut: false,
      teamName: translator.t('defaultTeamName'),
      offenseFolderIsOpen: true,
      defenseFolderIsOpen: true,
      specialFolderIsOpen: true,
      styleParameters: getStyleParameters(),
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', this.onChange)
  }

  componentWillUnmount = () => {
    Dimensions.removeEventListener('change', this.onChange)
  }

  togglePlaybookGroupVisibility = (groupName: GroupNames) => {
    const { offenseFolderIsOpen, defenseFolderIsOpen, specialFolderIsOpen } = this.state

    switch (groupName) {
      case GroupNames.Defense:
        this.setState({
          defenseFolderIsOpen: !defenseFolderIsOpen,
        })
        break
      case GroupNames.Offense:
        this.setState({
          offenseFolderIsOpen: !offenseFolderIsOpen,
        })
        break
      case GroupNames.Special:
        this.setState({
          specialFolderIsOpen: !specialFolderIsOpen,
        })
        break
    }
  }

  getDisplayablePlaybooks = (playbooks: any[]) => {
    const allPlaybooksWithSnapshot = playbooks.filter((playbook) => hasValidSnapshot(playbook))

    return allPlaybooksWithSnapshot
  }

  changeGroupFolder = (group: GroupNames, folderId: number) => {
    switch (group) {
      case GroupNames.Defense:
        this.setState({
          defenseFolder: folderId,
        })
        break
      case GroupNames.Offense:
        this.setState({
          offenseFolder: folderId,
        })
        break
      case GroupNames.Special:
        this.setState({
          specialFolder: folderId,
        })
        break
    }
  }

  render() {
    const { navigation } = this.props
    const { userMustBeLoggedOut, teamName, styleParameters } = this.state

    const allGroups = Object.values(GroupNames)

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        <TopBarMenu
          navigation={navigation}
          url={PageName.PlaybookList}
          updatePage={() => this.forceUpdate()}
          userMustBeLoggedOut={userMustBeLoggedOut}
          pageTitle={teamName}
        />
        <View style={getLoginStyles(styleParameters).page}>
          <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
            <Text
              style={[
                getPlaybookStyles(styleParameters).playbookStrongText,
                getPlaybookStyles(styleParameters).pageTitle,
              ]}
            >
              {translator.t('playbookList.title').toUpperCase()}
            </Text>
            <View style={getPlaybookStyles(styleParameters).playbookList}>
              {allGroups.map((group: GroupNames) => {
                return this.renderGroup(group)
              })}
            </View>
          </ScrollView>
        </View>
      </View>
    )
  }

  renderGroup(groupName: GroupNames) {
    const { offenseFolderIsOpen, defenseFolderIsOpen, specialFolderIsOpen, styleParameters } =
      this.state
    const { isHorizontal, isMobile } = styleParameters
    let mustDisplayPlaybooks: boolean = true

    switch (groupName) {
      case GroupNames.Defense:
        if (!defenseFolderIsOpen) {
          mustDisplayPlaybooks = false
        }
        break
      case GroupNames.Offense:
        if (!offenseFolderIsOpen) {
          mustDisplayPlaybooks = false
        }
        break
      case GroupNames.Special:
        if (!specialFolderIsOpen) {
          mustDisplayPlaybooks = false
        }
        break
    }

    const translatedGroupName: string = getTranslatedGroupName(groupName)

    return (
      <View key={groupName} style={getPlaybookStyles(styleParameters).groupContainer}>
        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => this.togglePlaybookGroupVisibility(groupName)}
        >
          <View>
            {mustDisplayPlaybooks ? (
              <AntDesign
                name="caretdown"
                style={getPlaybookStyles(styleParameters).groupTitleIcon}
              />
            ) : (
              <AntDesign
                name="caretright"
                style={getPlaybookStyles(styleParameters).groupTitleIcon}
              />
            )}
          </View>
          <Text
            style={[
              getPlaybookStyles(styleParameters).playbookStrongText,
              getPlaybookStyles(styleParameters).legacyTitle,
            ]}
          >
            {translatedGroupName.toString().toUpperCase()}
          </Text>
        </TouchableOpacity>
        <View style={getPlaybookStyles(styleParameters).horizontalLine} />
        {mustDisplayPlaybooks ? (
          <View style={getPlaybookStyles(styleParameters).groupContent}>
            {this.renderGroupContent(groupName)}
            <Text style={getPlaybookStyles(styleParameters).groupBackTitle}>
              {translatedGroupName.toString().toUpperCase()}
            </Text>
          </View>
        ) : (
          <View style={{ paddingBottom: isHorizontal && isMobile ? '5%' : '10%' }} />
        )}
        {this.props.isStoringImages && <ActivityIndicator size={72} />}
      </View>
    )
  }

  renderGroupContent(groupName: GroupNames) {
    let groupFolder = 0
    switch (groupName) {
      case GroupNames.Defense:
        groupFolder = this.state.defenseFolder
        break
      case GroupNames.Offense:
        groupFolder = this.state.offenseFolder
        break
      case GroupNames.Special:
        groupFolder = this.state.specialFolder
        break
    }
    return this.renderFolderContent(groupName, groupFolder)
  }

  sortByID = (arrayToSort: any[], isPlaybook: boolean) => {
    for (let i = 0; i < arrayToSort.length; i++) {
      if (arrayToSort[i + 1] === undefined) {
        return arrayToSort
      }
      if (isPlaybook) {
        if (
          arrayToSort[i].title === arrayToSort[i + 1].title &&
          arrayToSort[i].id > arrayToSort[i + 1].id
        ) {
          const temp = arrayToSort[i]
          arrayToSort[i] = arrayToSort[i + 1]
          arrayToSort[i + 1] = temp
        }
      } else {
        if (
          arrayToSort[i].name === arrayToSort[i + 1].name &&
          arrayToSort[i].id > arrayToSort[i + 1].id
        ) {
          const temp = arrayToSort[i]
          arrayToSort[i] = arrayToSort[i + 1]
          arrayToSort[i + 1] = temp
        }
      }
    }
    return arrayToSort
  }

  renderFolderContent(groupName: GroupNames, folderId: number) {
    const { playbookFolders, playbooks } = this.props
    if (!playbookFolders) {
      return // No content to render
    }

    const currentFolder = playbookFolders.find((folder) => folder.id === folderId)
    if (!currentFolder) return null

    const folders: number[] = currentFolder.folders
    const allFolders = playbookFolders
    const playbookList = playbooks

    let foldersToRender: any[] = []

    folders.forEach((folderId) => {
      const folderToRender = allFolders.find(
        (folder) => folder.id === folderId && folder.group === groupName
      )
      if (folderToRender) foldersToRender.push(folderToRender)
    })

    const allPlaybooks: any[] = // folderId === 0 Est la racine
      folderId === 0 ? playbooks.filter((playbook) => playbook.folderID === null) : playbooks

    const folderPlaybookIDs: number[] = currentFolder.playbooks
    let playbooksToRender: any[] = []

    folderPlaybookIDs.map((playbookId) => {
      const groupPlaybook =
        currentFolder.id !== 0
          ? allPlaybooks.find((playbook) => playbook.id === playbookId)
          : allPlaybooks.find(
              (playbook) => playbook.id === playbookId && playbook.group === groupName
            )
      if (groupPlaybook) playbooksToRender.push(groupPlaybook)
    })
    playbooksToRender.sort((p1, p2) => p1.title.localeCompare(p2.title))
    playbooksToRender = this.sortByID(playbooksToRender, true)

    foldersToRender.sort((f1, f2) => f1.name.localeCompare(f2.name))
    foldersToRender = this.sortByID(foldersToRender, false)

    return (
      <PlaybookGroup
        groupName={groupName}
        folderId={currentFolder.id}
        parentFolderId={currentFolder.parentFolderID}
        foldersToRender={foldersToRender}
        playbooksToRender={playbooksToRender}
        PlaybookList={playbookList}
        navigation={this.props.navigation}
        allFolders={playbookFolders.slice(1)}
        changeGroupFolderFunction={(group: GroupNames, folderId: number) =>
          this.changeGroupFolder(group, folderId)
        }
      />
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isStoringImages: state.cache.loadingImages,
  playbookFolders: state.playbooks.playbookFolders,
  playbooks: state.playbooks.playbooks,
})

export default connect(mapStateToProps)(PlaybookListPage)
