import { StyleSheet, ScaledSize } from 'react-native'
import { StyledComponentsTheme, GlobalColors } from '../Theme'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/HomeRatiosStyles'
import { StyleParameters } from '../Utilities'

export default function homeStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  teamColor: string,
  _rowSize: number,
  styleParameters: StyleParameters,
  isProfile?: boolean
) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(styleParameters)
  return StyleSheet.create({
    page: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    backgroundImage: {
      position: 'absolute',
      bottom: 0,
      alignSelf: 'center',
      marginTop: screenRatio.BackgroundImage_marginTop,
      width: screenRatio.BackgroundImage_width,
      height: '100%',
      resizeMode: 'stretch',
    },
    titleBlock: {
      flexDirection: 'column',
      paddingBottom: screenRatio.Title_Block_paddingBottom,
    },
    titleContainer: {
      flexDirection: screenRatio.Title_Container_flexDirection,
      alignContent: 'flex-end',
      alignSelf: 'center',
      width: 0.9 * screenRatio.BackgroundImage_width,
      height: screenRatio.Title_Container_height,
      marginBottom: screenRatio.Title_Container_marginBottom,
    },
    titleText: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignSelf: screenRatio.Title_Text_alignSelf,
      color: GlobalColors.white,
      fontWeight: 'bold',
      fontFamily: 'barlow-extra',
      fontSize: screenRatio.Title_Text_fontSize,
      marginLeft: screenRatio.Title_Text_marginLeft,
      marginBottom: screenRatio.Title_Text_marginBottom,
      width: screenRatio.Title_Text_width,
      lineHeight: screenRatio.Title_Text_lineHeight,
    },
    logoContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignSelf: 'center',
      marginLeft: screenRatio.Logo_Container_marginLeft,
      marginBottom: screenRatio.Logo_Container_marginBottom,
    },

    logo: {
      width: screenRatio.Logo_width,
      height: 1.2 * screenRatio.Logo_width,
      resizeMode: 'center',
      color: teamColor,
    },
    seasonContainer: {
      width: styleParameters.screenWidth,
    },
    seasonText: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignSelf: 'center',
      color: GlobalColors.white,
      fontFamily: 'roboto-light-italic',
      fontSize: screenRatio.Season_Text_fontSize,
      marginLeft: screenRatio.Season_Text_marginLeft,
      marginBottom: screenRatio.Title_Container_marginBottom,
      marginTop: screenRatio.Season_Text_marginTop,
    },
    buttonsRowContainer: {
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      bottom: screenRatio.Buttons_Row_Container_bottom,
      flexDirection: 'row',
      width: screenRatio.Buttons_Row_Container_width,
      height: screenRatio.Buttons_Row_Container_height,
      transform: [{ skewX: '-13deg' }],
    },
    ButtonContainer: {
      height: screenRatio.Buttons_Row_Container_height,
      width:
        ((isProfile ? 0 : -1) + screenRatio.Buttons_Row_splitRatio) *
        screenRatio.Buttons_Row_Container_width,
    },
    touchableOpacityStyle: {
      width: '100%',
      height: '100%',
    },
    buttonContentContainer: {
      flexDirection: 'column',
      justifyContent: isProfile ? 'center' : 'flex-start',
      alignItems: 'center',
      backgroundColor: isProfile ? GlobalColors.paleGrey : teamColor,
      height: screenRatio.Buttons_Row_Container_height,
      width: isProfile
        ? screenRatio.Buttons_Row_splitRatio * screenRatio.Buttons_Row_Container_width
        : screenRatio.Buttons_Row_Container_width -
          screenRatio.Buttons_Row_splitRatio * screenRatio.Buttons_Row_Container_width,
    },
    TextContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignSelf: isProfile ? 'flex-end' : 'flex-start',
      alignItems: isProfile ? 'flex-end' : 'flex-start',
      alignContent: isProfile ? 'flex-end' : 'flex-start',
      height: screenRatio.Buttons_Row_Container_height,
      width:
        ((isProfile ? 0 : -1) + screenRatio.Buttons_Row_splitRatio) *
        screenRatio.Buttons_Row_Container_width,
      transform: isProfile ? [{ skewX: '13deg' }] : undefined,
      paddingRight: isProfile ? screenRatio.Profil_Text_paddingRight : 0,
      paddingLeft: isProfile ? 0 : screenRatio.Practice_Text_marginLeft,
    },
    ButtonText: {
      marginTop: screenRatio.Profil_Text_marginTop,
      color: isProfile ? GlobalColors.black : GlobalColors.white,
      fontWeight: 'bold',
      fontFamily: 'barlow-extra',
      fontSize: screenRatio.Profil_Text_fontSize,
      transform: isProfile ? undefined : [{ skewX: '13deg' }],
      height: screenRatio.Profil_Text_fontSize,
      marginBottom: screenRatio.Profil_SubText_marginTop,
    },
    ButtonSubText: {
      color: isProfile ? GlobalColors.black : GlobalColors.white,
      fontFamily: 'roboto-light-italic',
      fontSize: screenRatio.Profil_SubText_fontSize,
      textAlign: isProfile ? 'right' : 'left',
      transform: isProfile ? undefined : [{ skewX: '13deg' }],
    },

    ButtonSubTextContainer: {
      width: isProfile ? '100%' : '80%',
      flexDirection: screenRatio.Profil_SubText_Container_flexDirection,
      justifyContent: isProfile ? 'flex-end' : 'flex-start',
      paddingLeft: isProfile ? undefined : screenRatio.Practice_SubText_paddingLeft,
    },

    ButtonSeparator: {
      marginTop: screenRatio.Profil_Separator_marginTop,
      marginRight: isProfile ? -screenRatio.Profil_Text_paddingRight : 0,
      marginLeft: isProfile ? 0 : -screenRatio.Practice_Text_marginLeft,
      width: isProfile
        ? screenRatio.Buttons_Row_splitRatio * screenRatio.Buttons_Row_Container_width -
          (screenRatio.Buttons_Row_splitRatio * screenRatio.Buttons_Row_Container_width) / 8
        : (1 - screenRatio.Buttons_Row_splitRatio) * screenRatio.Buttons_Row_Container_width -
          ((1 - screenRatio.Buttons_Row_splitRatio) * screenRatio.Buttons_Row_Container_width) / 16,
      height: screenRatio.Profil_Separator_height,
      backgroundColor: isProfile ? GlobalColors.black : GlobalColors.white,
    },

    modalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: styleParameters.screenWidth,
      height: styleParameters.screenHeight,
      backgroundColor: theme.colors.popUpBackground,
    },
    modalContainer: {
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      marginTop: screenRatio.ModalContainer_marginTop,
      width: screenRatio.ModalContainer_width,
      height: screenRatio.ModalContainer_height,
      borderRadius: screenRatio.ModalContainer_borderRadius,
    },
    modalTitleText: {
      marginTop: screenRatio.ModalTitleText_marginTop,
      marginBottom: '5%',
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.ModalTitleText_fontSize,
      marginHorizontal: '5%',
    },
    loadingProgressBar: {
      height: screenRatio.ModalLoadingProgressBar_height,
      width: '90%',
      backgroundColor: GlobalColors.paleGrey,
    },
    loadingProgressBarFill: {
      height: screenRatio.ModalLoadingProgressBar_height,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    loadingProgressBarText: {
      color: GlobalColors.white,
      fontFamily: 'roboto-light-italic',
      fontSize: screenRatio.ModalLoadingProgressBarText_fontSize,
      alignSelf: 'flex-end',
      marginRight: 5,
    },
    ModalCloseButtonIcon: {
      width: screenRatio.Icon_width,
      color: GlobalColors.paleGrey,
      alignSelf: 'flex-start',
      padding: '1%',
    },
    ModalCloseButton: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '10%',
      marginVertical: '3%',
    },
    ModalContent: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '50%',
    },
  })
}
