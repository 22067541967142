import React, { Component } from 'react'
import translator from '@taktik/config/Translation/languages'

import { GroupNames } from '@taktik/config/Translation/utilities'
import { View, Text } from 'react-native'
import { getRegistrationStyles } from '../Styles/AppStyle'
import { StyleParameters } from '../Styles/Utilities'
import Position, { IPosition } from './Position'

export interface Group {
  [key: string]: IPosition
}

interface PositionPickerProps {
  positions: {
    [key in GroupNames]: Group
  }
  styleParameters: StyleParameters
  onPositionSelected: (position: IPosition) => void
}

interface PositionPickerState {
  selectedPosition: IPosition | undefined
}

class PositionPicker extends Component<PositionPickerProps, PositionPickerState> {
  constructor(props: PositionPickerProps) {
    super(props)
    this.state = { selectedPosition: undefined }
  }

  onSelect = (position: IPosition) => {
    const { onPositionSelected } = this.props
    this.setState({ selectedPosition: position })
    onPositionSelected(position)
  }

  getTitleForGroup = (group: string) => {
    switch (group.toLowerCase()) {
      case GroupNames.Offense:
        return translator.t('groups.offense')
      case GroupNames.Defense:
        return translator.t('groups.defense')
      case GroupNames.Special:
        return translator.t('groups.special')
      default:
        return 'undefined'
    }
  }

  renderGroupPositions = (group: Group) => {
    const { styleParameters } = this.props
    const styles = getRegistrationStyles(styleParameters)

    const positions = Object.values(group)
    return (
      <View style={styles.positionsRow}>
        {positions.map((position) => {
          const isSelected = this.state.selectedPosition?.id === position.id
          return (
            <Position
              key={position.slug}
              position={position}
              isSelected={isSelected}
              styleParameters={styleParameters}
              onSelect={(selectedPosition) => this.onSelect(selectedPosition)}
            />
          )
        })}
      </View>
    )
  }

  render() {
    const { positions, styleParameters } = this.props
    const styles = getRegistrationStyles(styleParameters)

    return Object.values(GroupNames).map((groupName) => (
      <View style={styles.positionPickingGroupHeaderContainer} key={groupName}>
        <Text style={styles.groupText}>{this.getTitleForGroup(groupName)}</Text>
        <View style={styles.line} />
        <View style={styles.positionContainer}>
          {this.renderGroupPositions(positions[groupName])}
        </View>
      </View>
    ))
  }
}

export default PositionPicker
