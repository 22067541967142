import { StyleParameters, getMarginToTransformIn43, transformUXSpec } from '../Utilities'

export interface StyledScreenRatio {
  authenticateInputText_PaddingLeft: any
}

export function getScreenRatioStyle(styleParameters: StyleParameters) {
  let { screenWidth, screenHeight } = styleParameters
  const marginHorizontal: number = getMarginToTransformIn43(screenWidth, screenHeight)
  if (screenWidth > screenHeight) {
    if (screenHeight > 600) {
      //horizontal tablet
      screenWidth -= marginHorizontal
      return {
        authenticateInputText_PaddingLeft: transformUXSpec(screenWidth, screenHeight, 80),
      }
    } else {
      //horizontal mobile
      return {
        authenticateInputText_PaddingLeft: transformUXSpec(screenWidth, screenHeight, 45),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        authenticateInputText_PaddingLeft: transformUXSpec(screenWidth, screenHeight, 50),
      }
    } else {
      //vertical tablet
      return {
        authenticateInputText_PaddingLeft: transformUXSpec(screenWidth, screenHeight, 80),
      }
    }
  }
}
