export enum GlobalColors {
  white = '#fff',
  paleGrey = '#EAEAEA',
  mediumGrey = '#C0C0C0',
  darkGrey = '#3B3B3B',
  veryDarkGrey = '#1F1F1F',
  black = '#000',
  green = '#06A800',
  paleGreen = '#E9FFE8',
  red = '#E00303',
  paleRed = '#FFCECE',
  defaultTeamColor = '#ED590E',
  defaultHomeColor = '#d9a414',
}

export enum ThemeName {
  Light = 'light',
  Dark = 'dark',
}
export interface StyledComponentsTheme {
  name: string
  colors: {
    [iterables: string]: string // TODO: check if removable for stricter typing checks
    primary: string // Mostly main page buttons
    secondary: string // Secondary buttons (ex. Cancel / Return / etc.)
    genericTopBar: string
    contrast: string
    notSelectedButton: string
    separator: string
    popUpColor: string
    popUpBackground: string
  }
  textColors: {
    [iterables: string]: string // TODO: check if removable for stricter typing checks
    primary: string
    secondary: string
    tabHeader: string
  }
}

export const LightTheme: StyledComponentsTheme = {
  name: 'light',
  colors: {
    primary: GlobalColors.white,
    secondary: GlobalColors.paleGrey,
    genericTopBar: GlobalColors.white,
    contrast: GlobalColors.darkGrey,
    notSelectedButton: GlobalColors.paleGrey,
    separator: GlobalColors.mediumGrey,
    popUpColor: GlobalColors.white,
    popUpBackground: '#000000AA',
  },
  textColors: {
    primary: GlobalColors.black,
    secondary: GlobalColors.white,
    tabHeader: GlobalColors.black,
  },
}

export const DarkTheme: StyledComponentsTheme = {
  name: 'dark',
  colors: {
    primary: GlobalColors.veryDarkGrey,
    secondary: GlobalColors.darkGrey,
    genericTopBar: GlobalColors.darkGrey,
    contrast: GlobalColors.mediumGrey,
    notSelectedButton: GlobalColors.mediumGrey,
    separator: GlobalColors.paleGrey,
    popUpColor: GlobalColors.mediumGrey,
    popUpBackground: '#0000006E',
  },
  textColors: {
    primary: GlobalColors.white,
    secondary: GlobalColors.mediumGrey,
    tabHeader: GlobalColors.mediumGrey,
  },
}
