import { Dimensions, Platform } from 'react-native'
import translator from '@taktik/config/Translation/languages'
import { Locale } from '@taktik/config/Translation/locale'

//CHANGES ADD color for contrast to button/team color

export function getMarginToTransformIn43(screenWidth: number, screenHeight: number) {
  return screenWidth - (4 / 3) * screenHeight
}

export function transformUXSpec(screenWidth: number, screenHeight: number, spec: number) {
  if (screenWidth >= screenHeight) {
    if (screenHeight > 600) {
      const UXWidth = 2160
      const ratioWidth = screenWidth / UXWidth
      return spec * ratioWidth
    } else {
      const UXWidth = 812
      const ratioWidth = screenWidth / UXWidth
      return spec * ratioWidth
    }
  } else {
    if (screenWidth < 600) {
      const UXWidth = 375
      const ratioWidth = screenWidth / UXWidth
      return spec * ratioWidth
    } else {
      const UXWidth = 1620
      const ratioWidth = screenWidth / UXWidth
      return spec * ratioWidth
    }
  }
}

export function isFrench(): boolean {
  const lang = translator.locale.substring(0, 2)
  const isFrench: boolean = lang === Locale.FR

  return isFrench
}

export function isMobile(): boolean {
  const currentHeight: number = Dimensions.get('window').height
  const currentWidth: number = Dimensions.get('window').width
  if (currentHeight < 600 || currentWidth < 600) {
    return true
  } else {
    return false
  }
}

export function isTablet(): boolean {
  const OS = Platform.OS
  const isTablet = OS === 'ios' || OS === 'android'

  return isTablet
}

export function screenIsHorizontal(): boolean {
  const currentHeight: number = Dimensions.get('window').height
  const currentWidth: number = Dimensions.get('window').width
  const isHorizontal = currentWidth >= currentHeight

  return isHorizontal
}

export function getScreenWidth(): number {
  return !isBeingRunAsAnApp() ? Dimensions.get('window').width : Dimensions.get('screen').width
}

export function getScreenHeight(): number {
  return !isBeingRunAsAnApp() ? Dimensions.get('window').height : Dimensions.get('screen').height
}

export function isBeingRunAsAnApp(): boolean {
  return Platform.OS !== 'web'
}

export function getStyleParameters(): StyleParameters {
  const styleParameters: StyleParameters = {
    isHorizontal: screenIsHorizontal(),
    isFrench: isFrench(),
    screenWidth: getScreenWidth(),
    screenHeight: getScreenHeight(),
    isMobile: isMobile(),
    isTablet: isTablet(),
  }

  return styleParameters
}

export interface StyleParameters {
  isHorizontal: boolean
  isFrench: boolean
  isMobile: boolean
  screenWidth: number
  screenHeight: number
  isTablet: boolean
}
