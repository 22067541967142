export type LogFunction = (message?: any, ...optionalParams: any[]) => void

export enum LogLevel {
  debug = 1,
  info = 2,
  warn = 3,
  error = 4,
}

export interface LogTransporter {
  debug: LogFunction
  info: LogFunction
  error: LogFunction
  warn: LogFunction
}
