import axios from 'axios'
import { Playbook, PlaybookFolder } from '../DataTypes/playbooks'
import {
  storageGetItem,
  storageInsertItem,
  storageInsertImage,
  StoreNames,
  storageGetAllItems,
  StorageItem,
  storageGetImageData,
} from './StorageUtils'
import { StudyTime, toServerStudyTime } from '../DataTypes/StudyTimes'
import { DateTime } from 'luxon'
import { percentOfPlaybooksDownloaded, playbooksLoad, toggleImageLoad } from '../redux/actions'
import { store } from '../redux/store'
import { logger } from '../../lib/logger'
import { Platform } from 'react-native'
import { isValidSnapshot } from '@taktik/src/Utilitary/Utils'
import translator from '@taktik/config/Translation/languages'
import { TaktikAlert } from './Alert'

interface AxiosPlaybookResponse {
  playbooks: Playbook[]
  folders: PlaybookFolder[]
}

export function filterOutTemplatesAndEmptySnapshots(
  playbookResponse: AxiosPlaybookResponse
): AxiosPlaybookResponse {
  const foldersWithoutTemplates: PlaybookFolder[] = playbookResponse.folders.filter(
    (folder: PlaybookFolder) => {
      const isRoot: boolean = folder.id === 0

      const folderShouldBeVisible: boolean = isRoot || !folder.isTemplate
      return folderShouldBeVisible
    }
  )

  const validPlaybooks: Playbook[] = playbookResponse.playbooks.filter(
    (playbook: Playbook) => !playbook.isTemplate && isValidSnapshot(playbook.snapshot)
  )

  const responseWithoutTemplates: AxiosPlaybookResponse = {
    playbooks: validPlaybooks,
    folders: foldersWithoutTemplates,
  }

  return responseWithoutTemplates
}

export const displayCannotQueryServerAndNoLocalPlaybooksAlert = () => {
  TaktikAlert.alert(
    translator.t('error.noPlaybooksToLoadTitle'),
    translator.t('error.noPlaybooksToLoadMessage'),
    { text: 'OK' }
  )
}

export function isThereNewPlaybooks(storedPlaybooks: any[], responsePlaybooks: any[]) {
  const newPlaybooks = []
  const playbookIDs = responsePlaybooks.map((playbook) => {
    return playbook.id
  })
  const storedPlaybookIDs = storedPlaybooks.map((playbook: any) => {
    return playbook.id
  })
  const arePlaybookNew = playbookIDs.filter((playbook: any) => {
    if (storedPlaybookIDs.indexOf(playbook.id) === -1) {
      return true
    } else {
      return false
    }
  })

  for (let newPlaybookIndex = 0; newPlaybookIndex < arePlaybookNew.length; newPlaybookIndex++) {
    if (arePlaybookNew[newPlaybookIndex]) {
      newPlaybooks.push(responsePlaybooks[newPlaybookIndex])
    }
  }

  return newPlaybooks.length > 0
}

export async function axiosGetPlaybooks(): Promise<AxiosPlaybookResponse> {
  // Retrieve the JSON of all playbooks to see if it changed
  const storedPlaybooks = await storageGetItem('all', StoreNames.Playbooks)
  let requestSucceeded = true
  const response = await axios
    .get('playbooks?noCache=' + new Date().getTime().toString(), {
      headers: { 'Cache-Control': 'no-store' },
      onDownloadProgress: (progressEvent) => {
        store.dispatch(
          percentOfPlaybooksDownloaded(
            Platform.OS !== 'web'
              ? (progressEvent.target._response.length / progressEvent.total) * 100
              : (progressEvent.loaded / progressEvent.total) * 100
          )
        )
      },
    })
    .catch((_) => {
      requestSucceeded = false
    })
  // Get all images from storage if we can't query the server

  if (storedPlaybooks && !requestSucceeded) {
    const playbookResult: AxiosPlaybookResponse = JSON.parse(storedPlaybooks)
    if (Platform.OS === 'web') {
      for (const playbook of playbookResult.playbooks) {
        const snapshot = await storageGetImageData(playbook.snapshot)
        if (snapshot) {
          playbook.snapshot = snapshot
        }
      }
    }
    return playbookResult
  } else {
    if (!response) {
      displayCannotQueryServerAndNoLocalPlaybooksAlert()
      return Promise.reject(new Error('No stored values, failed to contact server'))
    }

    const playbookFolders: PlaybookFolder[] = []
    const playbooks: Playbook[] = [...response.data.playbooks[1]]
    for (const property in response.data.playbooks[0]) {
      if (response.data.playbooks[0].hasOwnProperty(property)) {
        playbookFolders.push(response.data.playbooks[0][property])
      }
    }
    const playbookResponse: AxiosPlaybookResponse = {
      playbooks,
      folders: playbookFolders,
    }
    const jsonStringifyResponse = JSON.stringify(playbookResponse)
    const loadAllImagesIntoDB = !storedPlaybooks
    let isThereNewPlaybook = false
    if (storedPlaybooks) {
      const alreadyStoredPlaybooks = JSON.parse(storedPlaybooks).playbooks
      const responsePlaybooks = [...response.data.playbooks[1]]
      isThereNewPlaybook = isThereNewPlaybooks(alreadyStoredPlaybooks, responsePlaybooks)
    }
    if (loadAllImagesIntoDB || isThereNewPlaybook) {
      const promises = []
      // Load all images into the database since none have been stored
      for (const playbook of playbookResponse.playbooks) {
        promises.push(storageInsertImage(playbook.snapshot))
      }
      await Promise.all(promises)
    }

    logger.info(`Number of playbooks - ${playbookResponse.playbooks.length}`)

    // All the images already exist in the database, just load all of them
    playbookResponse.playbooks = await Promise.all(
      playbookResponse.playbooks.map(async (playbook) => {
        logger.info(`Old playbook snapshot size - ${playbook.snapshot?.length}`)

        let snapshot = await storageGetImageData(playbook.snapshot)

        logger.info(`New playbook snapshot size - ${snapshot?.length}`)
        if (Platform.OS === 'web') {
          if (snapshot) {
            playbook.snapshot = snapshot
          } else {
            // Backup image storage, in case the user refreshed a page before it finished storing images
            await storageInsertImage(playbook.snapshot)
            snapshot = await storageGetImageData(playbook.snapshot)
            if (snapshot) {
              playbook.snapshot = snapshot
            }
          }
        }
        return playbook
      })
    )

    // await storageWipeLocalImageData()

    const playbooksHaveChanged: boolean = storedPlaybooks !== jsonStringifyResponse

    logger.info(`Playbooks have changed? -> ${playbooksHaveChanged}`)

    if (playbooksHaveChanged) {
      storageInsertItem('all', jsonStringifyResponse, StoreNames.Playbooks)
        .then(async () => {
          const allPlaybooks: StorageItem[] = (await storageGetAllItems(StoreNames.Playbooks))!

          logger.info(`Number of playbooks in 'Playbooks' table: ${allPlaybooks.values}`)
          logger.info(`'Playbooks' table: ${JSON.stringify(allPlaybooks)}`)
        })
        .catch((error) => {
          logger.info(error)
        })
    }

    const playbookResponseWithoutTemplates: AxiosPlaybookResponse =
      filterOutTemplatesAndEmptySnapshots(playbookResponse)

    return playbookResponseWithoutTemplates
  }
}

export async function axiosGetPlaybook(id: number): Promise<any> {
  const result = await storageGetItem('all', StoreNames.Playbooks)

  let playbook = null
  if (result) {
    const playbookResult: AxiosPlaybookResponse = JSON.parse(result)
    playbook = playbookResult.playbooks.find((playbook) => playbook.id === id)
  }

  if (!playbook) {
    return Promise.reject(new Error('Could not fetch playbook from local storage'))
  }

  const snapshot = await storageGetImageData(playbook.snapshot)
  if (snapshot) {
    playbook.snapshot = snapshot
  }

  return playbook
}

export function fetchUser(userID: number): Promise<any> {
  return axios
    .get(`users/${userID}`)
    .then((response) => {
      return response.data
    })
    .catch((_err) => {
      return null
    })
}

export function fetchTeam(teamID: number | null): Promise<any> {
  return axios
    .get(`teams/${teamID}`)
    .then((response) => {
      return response.data
    })
    .catch((_err) => {
      return null
    })
}

export function axiosPostStudyTime(studyTime: StudyTime): Promise<boolean> {
  return axios
    .post('studyTimes/', toServerStudyTime(studyTime))
    .then((response) => {
      return !!response.data.success
    })
    .catch((_err) => {
      return false
    })
}

export async function axiosGetStudyTimeDuration(playbookID: number): Promise<number | null> {
  return axios
    .get(`studyTimes/${playbookID}/duration/`, { headers: { 'Cache-Control': 'no-store' } })
    .then((response) => {
      if (response.data.success) {
        return Math.round(response.data.duration)
      }
      return null
    })
    .catch((_err) => {
      return null
    })
}

export async function axiosGetPlayerRank(userID: number, teamID: number): Promise<number | null> {
  return axios
    .get(`gamification/rank/${userID}/${teamID}`, { headers: { 'Cache-Control': 'no-store' } })
    .then((response) => {
      if (response.data.success) {
        return response.data.data
      }
      return null
    })
    .catch((_err) => {
      return null
    })
}

export async function axiosGetExercice(
  localTimestamp: DateTime,
  selectedGroups: number[],
  selectedEvaluations: number[],
  _navigation: any
): Promise<any> {
  return await axios
    .get(`gamification/exercise/${localTimestamp.toString()}/`, {
      params: { groups: selectedGroups, selectedEvaluations: selectedEvaluations },
      headers: { 'Cache-Control': 'no-store' },
    })
    .then((response) => {
      if (response.data.success) {
        return response.data
      }
      // logger.error(response.data.message)
    })
    .catch((_err) => {
      return null
    })
}

export async function axiosPostResult(
  exerciseID: number,
  completionTime: number,
  answer: number
): Promise<any> {
  return axios
    .post(`gamification/exercise/${exerciseID}/submit`, { exerciseID, completionTime, answer })
    .then((response) => {
      return response.data
    })
    .catch((_err) => {
      return false
    })
}

export async function axiosGetSubscriptionInfo(): Promise<any> {
  return axios
    .get(`gamification/subscription/`, {
      headers: { 'Cache-Control': 'no-store' },
    })
    .then((response) => {
      if (response.data.success) {
        return response.data
      }
      return null
    })
    .catch((_err) => {
      return null
    })
}

export async function axiosGetStats(uid: number, teamID: number, language: string): Promise<any> {
  return axios
    .get(`gamification/stats/user/${uid}/${teamID}`, {
      headers: { 'Cache-Control': 'no-store' },
      timeout: 5000,
      params: { language },
    })
    .then((response) => {
      if (response.data.success) {
        return response.data
      }
      logger.error(response.data.message)
      return null
    })
    .catch((_err) => {
      return null
    })
}

export async function axiosGetEvaluations(uid: number, teamID: number): Promise<any> {
  return axios
    .get(`evaluations/user/${uid}/${teamID}`, {
      headers: { 'Cache-Control': 'no-store' },
      timeout: 5000,
    })
    .then((response) => {
      if (response.data.success) {
        return response.data
      }
      logger.error(response.data.message)
      return null
    })
    .catch((_err) => {
      return null
    })
}

export async function axiosGetGroups(uid: number, teamID: number): Promise<any> {
  return axios
    .get(`groups`, {
      headers: { 'Cache-Control': 'no-store' },
      params: { uid, teamID },
    })
    .then((response) => {
      if (response.data.success) {
        return response.data.payload
      }

      return null
    })
    .catch((_err) => {
      return null
    })
}

export async function axiosGetTeamGroups(uid: number, teamID: number): Promise<any> {
  return axios
    .get(`positions/groups`, {
      headers: { 'Cache-Control': 'no-store' },
      params: { uid, teamID },
    })
    .then((response) => {
      if (response.data.success) {
        return response.data.positions
      }

      return null
    })
    .catch((_err) => {
      return null
    })
}

export function dispatchAllTeamPlaybooksToRedux(playbooks: object) {
  store.dispatch(playbooksLoad(playbooks))
}

export async function setPlaybooks() {
  store.dispatch(percentOfPlaybooksDownloaded(0))
  store.dispatch(toggleImageLoad(true))
  await axiosGetPlaybooks()
    .then(async (axiosPlaybookResponse) => {
      // const displayablePlaybooks = this.getDisplayablePlaybooks(axiosPlaybookResponse.playbooks)
      const payload = {
        playbookFolders: axiosPlaybookResponse.folders,
        playbooks: axiosPlaybookResponse.playbooks,
      }
      await dispatchAllTeamPlaybooksToRedux(payload)
      store.dispatch(toggleImageLoad(false))
      store.dispatch(percentOfPlaybooksDownloaded(100))
    })
    .catch((error) => {
      logger.info(error)
    })
}
export const getCoachName = (coachID: number) => {
  return axios

    .get(`users/${coachID}`)

    .then((response) => {
      const { success, user } = response.data

      if (success) {
        return user.firstName + ' ' + user.lastName
      } else {
        return null
      }
    })

    .catch((_err) => {
      return null
    })
}
export const getTeamName = (teamID: number) => {
  return axios

    .get(`teams/${teamID}`)

    .then((data) => {
      const { success, team } = data.data

      if (success) {
        return team.name
      }
    })

    .catch(() => {
      return null
    })
}
export async function axiosDeleteToUserTeams(teamID: number, userID: number): Promise<any> {
  return axios
    .delete(`userTeams/`, { params: { teamID: teamID, userID: userID } })
    .then((response) => {
      return response.data
    })
    .catch((_err) => {
      return false
    })
}

const fetchTeamsForUserById = async (userID: number) => {
  try {
    const response = await axios.get(`/userTeams/${userID}`)
    return response.data as {
      success: boolean
      user: { id: number; teamID: number; userID: number }[]
    }
  } catch {
    return { success: false, user: [] }
  }
}

export const isUserAssociatedWithTeam = async (
  teamId: number,
  userId: number
): Promise<boolean> => {
  const userTeamData = await fetchTeamsForUserById(userId)
  return userTeamData.user.find((userTeam) => userTeam.teamID === teamId) !== undefined
}

export const isTeamActive = async (teamId: number): Promise<boolean> => {
  try {
    const response = await axios.get(`/teams/isActive/${teamId}`)
    return response.data.isActive ?? false
  } catch {
    return false
  }
}
