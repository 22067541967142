import { StyleSheet, ScaledSize } from 'react-native'
import { StyleParameters } from '../Utilities'
import { normalizeFont, normalizeSize } from '../GlobalStyles'
import { StyledComponentsTheme } from '../Theme'
import { getScreenRatioStyle, StyledScreenRatio } from '../Peculiar/LoginRatiosStyles'

export default function loginStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  _teamColor: string,
  styleParameters: StyleParameters
) {
  const { isHorizontal, isFrench } = styleParameters
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(styleParameters)
  return StyleSheet.create({
    page: {
      flex: 1,
      flexDirection: 'row',
    },
    side: {
      flex: 1,
    },
    authenticationForm: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'stretch',
      minWidth: 200,
    },
    titleText: {
      color: theme.textColors.primary,
      fontSize: normalizeFont(isHorizontal, isFrench, 100),
      fontWeight: 'bold',
      textAlign: 'center',
      fontFamily: 'barlow-bold-italic',
      marginTop: normalizeSize(isHorizontal, 60),
    },
    subtitleText: {
      color: theme.textColors.primary,
      fontSize: normalizeFont(isHorizontal, isFrench, 30),
      textAlign: 'center',
      marginTop: normalizeSize(isHorizontal, 2),
      marginBottom: normalizeSize(isHorizontal, 40),
      fontFamily: 'barlow-semi',
      marginRight: normalizeSize(isHorizontal, 5),
    },
    authenticateForm: {
      flex: 800,
      paddingHorizontal: normalizeSize(isHorizontal, 10),
      justifyContent: 'flex-start',
    },
    authenticateInputText: {
      margin: normalizeSize(isHorizontal, 20),
      padding: normalizeSize(isHorizontal, 20),
      color: theme.colors.primary,
      backgroundColor: theme.textColors.primary,
      paddingLeft: screenRatio.authenticateInputText_PaddingLeft,
      fontFamily: 'barlow-semi',
      fontSize: normalizeFont(isHorizontal, isFrench, 15),
      width: '65%',
    },
    otherOptionsButton: {
      marginVertical: normalizeFont(isHorizontal, false, 4),
      marginHorizontal: '5%',
      width: '45%',
      // backgroundColor: _teamColor ?? '#ED590E',
      borderRadius: 5,
    },
    otherOptionsText: {
      color: theme.textColors.primary,
      fontWeight: '500',
      fontSize: normalizeFont(isHorizontal, isFrench, 8),
      textAlign: 'center',
      fontFamily: 'roboto-italic',
    },
    otherOptionsContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    authenticateButton: {
      marginVertical: normalizeSize(isHorizontal, 10),
      padding: normalizeSize(isHorizontal, 28),
      width: '35%',
      marginTop: normalizeSize(isHorizontal, 15),
      backgroundColor: '#ED590E',
    },
    authenticateButton_Large: {
      marginVertical: normalizeSize(isHorizontal, 10),
      backgroundColor: '#ED590E',
      padding: normalizeSize(isHorizontal, 28),
      width: '55%',
      marginTop: normalizeSize(isHorizontal, 15),
    },
    authenticateButtonText: {
      color: theme.textColors.primary,
      fontWeight: 'bold',
      fontSize: normalizeFont(isHorizontal, isFrench, 14),
      textAlign: 'center',
      fontFamily: 'barlow-semi',
      transform: [{ skewX: '10deg' }], // To compensate for the button's angle
    },
    changePasswordTextInputs: {
      margin: normalizeSize(isHorizontal, 13),
      padding: normalizeSize(isHorizontal, 13),
      color: theme.colors.primary,
      backgroundColor: theme.textColors.primary,
      paddingLeft: normalizeSize(isHorizontal, 80),
      fontFamily: 'barlow-semi',
      fontSize: normalizeFont(isHorizontal, isFrench, 15),
      width: '65%',
    },
  })
}
