import { StyleSheet, ScaledSize } from 'react-native'
import {
  getScreenRatioStyle,
  StyledScreenRatio,
} from '../Peculiar/IdentificationPlaybookRatiosStyles'
import { GlobalColors, StyledComponentsTheme } from '../Theme'
import { StyleParameters } from '../Utilities'

export default function identificationPlaybookStyles(
  _appSize: ScaledSize,
  theme: StyledComponentsTheme,
  teamColor: string,
  _rowSize: number,
  styleParameters: StyleParameters,
  width?: number,
  height?: number,
  boolValue?: boolean
) {
  const screenRatio: StyledScreenRatio = getScreenRatioStyle(styleParameters)
  return StyleSheet.create({
    component: {
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    page: {
      width: screenRatio.PromptContainer_width,
      flex: screenRatio.Page_flex,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: screenRatio.Page_height,
      marginHorizontal: screenRatio.Page_marginHorizontal,
      marginBottom: screenRatio.Page_marginBottom,
    },
    gameContainer: {
      width: screenRatio.GameContainer_width,
      flexDirection: screenRatio.GameContainer_direction,
      marginTop: screenRatio.GameContainer_marginTop,
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    playbooksContainer: {
      width: screenRatio.PlaybooksContainer_width,
      height: screenRatio.PlaybooksContainer_height,
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    playbookImageContainer: {
      alignItems: 'center',
      paddingHorizontal: screenRatio.playbookImageContainer_RightPadding,
    },
    playbookCorrectAnswer: {
      borderWidth: 3,
      borderColor: GlobalColors.green,
      backgroundColor: GlobalColors.green + '35',
      zIndex: 1000,
    },
    playbookWrongAnswer: {
      borderWidth: 3,
      borderColor: GlobalColors.red,
      backgroundColor: GlobalColors.red + '35',
      zIndex: 1000,
    },
    playbookSelectedAnswer: {
      borderWidth: 3,
      borderColor: teamColor,
      backgroundColor: teamColor + '35',
      zIndex: 1000,
    },
    promptText: {
      paddingHorizontal: 7,
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-semi-italic',
      fontSize: screenRatio.PromptText_fontSize,
    },
    confirmText: {
      alignSelf: 'center',
      color: GlobalColors.white,
      transform: [{ skewX: '10deg' }],
      fontFamily: 'barlow-semi',
      fontSize: screenRatio.ConfirmText_fontSize,
      marginHorizontal: '5%',
    },

    promptContainer: {
      width: screenRatio.PromptContainer_width,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: screenRatio.PromptContainer_marginTop,
    },
    title: {
      alignSelf: 'center',
      color: theme.textColors.primary,
      fontFamily: 'barlow-extra-italic',
      fontSize: screenRatio.Title_fontSize,
    },
    titleContainer: {
      flex: screenRatio.TitleContainer_flex,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      marginBottom: '1%',
    },
    prompt: {
      alignItems: 'flex-end',
    },
    confirmButtonContainer: {
      width: screenRatio.ConfirmButtonContainer_width,
      height: screenRatio.ConfirmButtonContainer_height,
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: screenRatio.ConfirmButtonContainer_justifyContent,
    },
    confirmButtonContainer_verticalMobile: {
      position: 'absolute',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      bottom: screenRatio.ConfirmButtonContainer_bottom,
    },
    confirmButton: {
      width: screenRatio.ConfirmButton_width,
      backgroundColor: teamColor,
      justifyContent: 'center',
      height: screenRatio.ConfirmButton_height,
      transform: [{ skewX: '-10deg' }],
    },
    playbookPadding: {
      width: screenRatio.playbookPadding_width,
      height: screenRatio.playbookPadding_width,
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    ModalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: styleParameters.screenWidth,
      height: styleParameters.screenHeight,
      backgroundColor: theme.colors.popUpBackground,
    },
    ModalContainer: {
      alignSelf: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      marginTop:
        (styleParameters.screenHeight -
          (height + screenRatio.PlaybookZoomedImage_additionalHeight)) /
        2,
      width: width + screenRatio.PlaybookZoomedImage_additionalWidth,
      height: height + screenRatio.PlaybookZoomedImage_additionalHeight,
      borderRadius: screenRatio.ModalContainer_borderRadius,
    },
    ModalZoomButton: {
      alignSelf: 'center',
      width: screenRatio.ModalButton_fontSize,
      color: boolValue ? GlobalColors.mediumGrey : teamColor,
    },
    ModalCloseButton: {
      width: screenRatio.ModalButton_fontSize,
      marginRight: screenRatio.ModalButton_paddingHorizontal,
      color: GlobalColors.paleGrey,
    },
    ModalTopbarContainer: {
      width: screenRatio.ModalTopbarContainer_width,
      paddingVertical: screenRatio.ModalTopbarContainer_paddingVertical,
      flexDirection: 'row-reverse',
      alignSelf: 'flex-end',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      zIndex: 1000,
      backgroundColor: theme.colors.primary,
      borderRadius: screenRatio.ModalContainer_borderRadius,
    },
    SnapshotContainer: {
      width: '100%',
      height: '100%',
    },
    warningText: {
      fontSize: screenRatio.WarningText_fontSize,
      color: GlobalColors.red,
      paddingBottom: screenRatio.WarningText_paddingBottom,
      fontFamily: 'barlow-semi',
    },
  })
}
