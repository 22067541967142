import { Asset } from 'expo-asset'
import * as Font from 'expo-font'

export const imgPathCut = require('../assets/loginBackground-Cut.png')
export const imgPathCutLowRes = require('../assets/loginBackground-Cut-LowRes.png')
export const errorIconPath = require('../assets/errorIcon.png')

export const loadResourcesAsync = async (): Promise<void> => {
  await Promise.all([
    Asset.loadAsync([require('../assets/loginBackground-Cut.png')]),
    Asset.loadAsync([require('../assets/loginBackground-Cut-LowRes.png')]),
    Font.loadAsync({
      'barlow-bold': require('../assets/fonts/BarlowCondensed-Bold.ttf'),
      'barlow-extra': require('../assets/fonts/BarlowCondensed-ExtraBold.ttf'),
      'barlow-semi': require('../assets/fonts/BarlowCondensed-SemiBold.ttf'),
      'barlow-extra-italic': require('../assets/fonts/BarlowCondensed-ExtraBoldItalic.ttf'),
      'barlow-semi-italic': require('../assets/fonts/BarlowCondensed-SemiBoldItalic.ttf'),
      'barlow-bold-italic': require('../assets/fonts/BarlowCondensed-BoldItalic.ttf'),
      'barlow-medium': require('../assets/fonts/BarlowCondensed-Medium.ttf'),
      'barlow-italic': require('../assets/fonts/Barlow-Italic.ttf'),
      roboto: require('../assets/fonts/Roboto-Regular.ttf'),
      'roboto-italic': require('../assets/fonts/Roboto-Italic.ttf'),
      'roboto-bold': require('../assets/fonts/Roboto-Bold.ttf'),
      'roboto-light-italic': require('../assets/fonts/Roboto-LightItalic.ttf'),
      'roboto-medium-italic': require('../assets/fonts/Roboto-MediumItalic.ttf'),
    }),
  ])
}
