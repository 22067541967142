import { Platform } from 'react-native'
import { StyleParameters, getMarginToTransformIn43, transformUXSpec } from '../Utilities'
export interface StyledScreenRatio {
  Page_marginHorizontal: any
  Page_flex: any
  Page_height: any
  Page_marginBottom: any
  GameContainer_width: any
  GameContainer_direction: any
  GameContainer_marginTop: any
  PromptText_fontSize: any
  PlaybookImage_width: any
  PlaybookZoomedImage_width: any
  PlaybookZoomedImage_additionalWidth: any
  playbookImageContainer_RightPadding: any
  PlaybookImage_height: any
  PlaybookZoomedImage_height: any
  PlaybookZoomedImage_additionalHeight: any
  ConfirmText_fontSize: any
  ConfirmButton_width: any
  ConfirmButton_height: any
  ConfirmButtonContainer_justifyContent: any
  ConfirmButtonContainer_bottom: any
  ConfirmButtonContainer_width: any
  ConfirmButtonContainer_height: any
  playbookPadding_width: any
  PromptContainer_width: any
  PromptContainer_marginTop: any
  PromptContainer_marginBottom: any
  TitleContainer_flex: any
  TitleContainer_height: any
  Title_fontSize: any
  PlaybooksContainer_width: any
  PlaybooksContainer_height: any
  ModalContainer_borderRadius: any
  ModalContainer_marginTop: any
  ModalTopbarContainer_width: any
  ModalTopbarContainer_paddingVertical: any
  ModalButton_paddingHorizontal: any
  ModalButton_fontSize: any
  WarningText_fontSize: any
  WarningText_paddingBottom: any
}

export function getScreenRatioStyle(styleParameters: StyleParameters) {
  let { screenWidth, screenHeight } = styleParameters
  const marginHorizontal: number = getMarginToTransformIn43(screenWidth, screenHeight)
  const os = Platform.OS
  if (screenWidth > screenHeight) {
    if (screenHeight > 600) {
      //horizontal tablet
      screenWidth -= marginHorizontal
      return {
        Page_flex: undefined,
        Page_marginHorizontal: marginHorizontal / 2,
        Page_height: undefined,
        Page_marginBottom: 0,
        GameContainer_direction: 'column',
        GameContainer_width: '100%',
        GameContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 20),
        PromptText_fontSize: transformUXSpec(screenWidth, screenHeight, 55),
        PlaybookImage_width: transformUXSpec(screenWidth, screenHeight, 700),
        PlaybookZoomedImage_width: transformUXSpec(screenWidth, screenHeight, 1700),
        PlaybookZoomedImage_additionalWidth: transformUXSpec(screenWidth, screenHeight, 190),
        PlaybookImage_height: transformUXSpec(screenWidth, screenHeight, 450),
        PlaybookZoomedImage_height: transformUXSpec(screenWidth, screenHeight, 1200),
        PlaybookZoomedImage_additionalHeight: transformUXSpec(screenWidth, screenHeight, 210),
        playbookImageContainer_RightPadding: '1%',
        ConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 44),
        ConfirmButton_width: transformUXSpec(screenWidth, screenHeight, 433),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 94),
        ConfirmButtonContainer_justifyContent: 'flex-start',
        ConfirmButtonContainer_width: '100%',
        ConfirmButtonContainer_height: transformUXSpec(screenWidth, screenHeight, 200),
        ConfirmButtonContainer_bottom: transformUXSpec(screenWidth, screenHeight, 180),
        playbookPadding_width: transformUXSpec(screenWidth, screenHeight, 30),
        PromptContainer_width: transformUXSpec(screenWidth, screenHeight, 1567),
        PromptContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 60),
        PromptContainer_marginBottom:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 10)
            : transformUXSpec(screenWidth, screenHeight, 10),
        TitleContainer_flex: os === 'web' ? 1 : undefined,
        TitleContainer_height: transformUXSpec(screenWidth, screenHeight, 80),
        Title_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        PlaybooksContainer_width: transformUXSpec(screenWidth, screenHeight, 1450),
        PlaybooksContainer_height: transformUXSpec(screenWidth, screenHeight, 984),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 25),
        ModalContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 160),
        ModalTopbarContainer_width: transformUXSpec(screenWidth, screenHeight, 400),
        ModalTopbarContainer_paddingVertical: transformUXSpec(screenWidth, screenHeight, 20),
        ModalButton_paddingHorizontal: transformUXSpec(screenWidth, screenHeight, 40),
        ModalButton_fontSize: transformUXSpec(screenWidth, screenHeight, 65),
        WarningText_fontSize: transformUXSpec(screenWidth, screenHeight, 30),
        WarningText_paddingBottom: transformUXSpec(screenWidth, screenHeight, 30),
      }
    } else {
      //horizontal mobile
      return {
        Page_flex: undefined,
        Page_marginHorizontal: '0%',
        Page_height: 400,
        Page_marginBottom: transformUXSpec(screenWidth, screenHeight, 0),
        GameContainer_width: screenWidth,
        GameContainer_direction: 'row',
        GameContainer_marginTop: 0,
        PromptText_fontSize:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 24)
            : transformUXSpec(screenWidth, screenHeight, 28),
        PlaybookImage_width: transformUXSpec(screenWidth, screenHeight, 240),
        PlaybookZoomedImage_width: transformUXSpec(screenWidth, screenHeight, 450),
        PlaybookZoomedImage_additionalWidth: transformUXSpec(screenWidth, screenHeight, 60),
        PlaybookImage_height: transformUXSpec(screenWidth, screenHeight, 150),
        PlaybookZoomedImage_height: transformUXSpec(screenWidth, screenHeight, 300),
        PlaybookZoomedImage_additionalHeight: transformUXSpec(screenWidth, screenHeight, 80),
        playbookImageContainer_RightPadding: '2%',
        ConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ConfirmButton_width: transformUXSpec(screenWidth, screenHeight, 216),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 40),
        ConfirmButtonContainer_justifyContent: 'flex-start',
        ConfirmButtonContainer_width: transformUXSpec(screenWidth, screenHeight, 250),
        ConfirmButtonContainer_height: transformUXSpec(screenWidth, screenHeight, 125),
        playbookPadding_width: transformUXSpec(screenWidth, screenHeight, 12),
        ConfirmButtonContainer_bottom:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 5)
            : transformUXSpec(screenWidth, screenHeight, 30),
        PromptContainer_width: transformUXSpec(screenWidth, screenHeight, 800),
        PromptContainer_marginTop: 0,
        PromptContainer_marginBottom: 0,
        TitleContainer_flex: undefined,
        TitleContainer_height: transformUXSpec(screenWidth, screenHeight, 25),
        Title_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        PlaybooksContainer_width: transformUXSpec(screenWidth, screenHeight, 500),
        PlaybooksContainer_height: transformUXSpec(screenWidth, screenHeight, 350),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 15),
        ModalContainer_marginTop:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 10)
            : transformUXSpec(screenWidth, screenHeight, 70),
        ModalTopbarContainer_width: transformUXSpec(screenWidth, screenHeight, 120),
        ModalTopbarContainer_paddingVertical: transformUXSpec(screenWidth, screenHeight, 10),
        ModalButton_paddingHorizontal: transformUXSpec(screenWidth, screenHeight, 12),
        ModalButton_fontSize: transformUXSpec(screenWidth, screenHeight, 28),
        WarningText_fontSize: transformUXSpec(screenWidth, screenHeight, 10),
        WarningText_paddingBottom: transformUXSpec(screenWidth, screenHeight, 5),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        Page_flex: undefined,
        Page_marginHorizontal: '0%',
        Page_height: undefined,
        Page_marginBottom: 0,
        GameContainer_width: '100%',
        GameContainer_direction: 'column',
        GameContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 5),
        ButtonsAreaContainer_width: '100%',
        ButtonsAreaContainer_marginTop: '0%',
        PromptText_fontSize: transformUXSpec(screenWidth, screenHeight, 18),
        PlaybookImage_width: transformUXSpec(screenWidth, screenHeight, 250),
        PlaybookZoomedImage_width: transformUXSpec(screenWidth, screenHeight, 330),
        PlaybookZoomedImage_additionalWidth: transformUXSpec(screenWidth, screenHeight, 40),
        PlaybookImage_height: transformUXSpec(screenWidth, screenHeight, 140),
        PlaybookZoomedImage_height: transformUXSpec(screenWidth, screenHeight, 300),
        PlaybookZoomedImage_additionalHeight: transformUXSpec(screenWidth, screenHeight, 70),
        playbookImageContainer_RightPadding: '0%',
        ConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
        ConfirmButton_width: transformUXSpec(screenWidth, screenHeight, 216),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 40),
        ConfirmButtonContainer_justifyContent: 'flex-start',
        ConfirmButtonContainer_width: transformUXSpec(screenWidth, screenHeight, 216),
        ConfirmButtonContainer_height: transformUXSpec(screenWidth, screenHeight, 40),
        playbookPadding_width: transformUXSpec(screenWidth, screenHeight, 15),
        ConfirmButtonContainer_bottom: transformUXSpec(screenWidth, screenHeight, 200),
        PromptContainer_width: transformUXSpec(screenWidth, screenHeight, 375),
        PromptContainer_marginTop: 0 * transformUXSpec(screenWidth, screenHeight, 25),
        PromptContainer_marginBottom: 0 * transformUXSpec(screenWidth, screenHeight, 25),
        TitleContainer_flex: os === 'web' ? undefined : undefined,
        TitleContainer_height: transformUXSpec(screenWidth, screenHeight, 35),
        Title_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        PlaybooksContainer_width: transformUXSpec(screenWidth, screenHeight, 250),
        PlaybooksContainer_height: transformUXSpec(screenWidth, screenHeight, 900),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 10),
        ModalContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 150),
        ModalTopbarContainer_width: transformUXSpec(screenWidth, screenHeight, 140),
        ModalTopbarContainer_paddingVertical: transformUXSpec(screenWidth, screenHeight, 10),
        ModalButton_paddingHorizontal: transformUXSpec(screenWidth, screenHeight, 10),
        ModalButton_fontSize: transformUXSpec(screenWidth, screenHeight, 25),
        WarningText_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
        WarningText_paddingBottom: transformUXSpec(screenWidth, screenHeight, 5),
      }
    } else {
      //vertical tablet
      return {
        Page_flex: undefined,
        Page_marginHorizontal: '0%',
        Page_height: undefined,
        Page_marginBottom: 0,
        GameContainer_width: '100%',
        GameContainer_direction: 'column',
        GameContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 30),
        ButtonsAreaContainer_width: '100%',
        ButtonsAreaContainer_marginTop: '0%',
        PromptText_fontSize: transformUXSpec(screenWidth, screenHeight, 55),
        PlaybookImage_width: transformUXSpec(screenWidth, screenHeight, 700),
        PlaybookZoomedImage_width: transformUXSpec(screenWidth, screenHeight, 1300),
        PlaybookZoomedImage_additionalWidth: transformUXSpec(screenWidth, screenHeight, 190),
        PlaybookImage_height: transformUXSpec(screenWidth, screenHeight, 460),
        PlaybookZoomedImage_height: transformUXSpec(screenWidth, screenHeight, 1000),
        PlaybookZoomedImage_additionalHeight: transformUXSpec(screenWidth, screenHeight, 210),
        playbookImageContainer_RightPadding: '1%',
        ConfirmText_fontSize: transformUXSpec(screenWidth, screenHeight, 44),
        ConfirmButton_width: transformUXSpec(screenWidth, screenHeight, 434),
        ConfirmButton_height: transformUXSpec(screenWidth, screenHeight, 94),
        ConfirmButtonContainer_justifyContent: 'flex-start',
        ConfirmButtonContainer_width: '100%',
        ConfirmButtonContainer_height: '10%',
        playbookPadding_width: transformUXSpec(screenWidth, screenHeight, 30),
        ConfirmButtonContainer_bottom: transformUXSpec(screenWidth, screenHeight, 200),
        PromptContainer_width: transformUXSpec(screenWidth, screenHeight, 1580),
        PromptContainer_marginTop:
          os === 'web'
            ? 0 * transformUXSpec(screenWidth, screenHeight, 120)
            : transformUXSpec(screenWidth, screenHeight, 60),
        PromptContainer_marginBottom:
          os === 'web'
            ? transformUXSpec(screenWidth, screenHeight, 6)
            : transformUXSpec(screenWidth, screenHeight, 7),
        TitleContainer_flex: os === 'web' ? 1 : undefined,
        TitleContainer_height: '10%',
        Title_fontSize: transformUXSpec(screenWidth, screenHeight, 80),
        PlaybooksContainer_width: transformUXSpec(screenWidth, screenHeight, 1450),
        PlaybooksContainer_height: transformUXSpec(screenWidth, screenHeight, 1000),
        ModalContainer_borderRadius: transformUXSpec(screenWidth, screenHeight, 20),
        ModalContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 300),
        ModalTopbarContainer_width: transformUXSpec(screenWidth, screenHeight, 350),
        ModalTopbarContainer_paddingVertical: transformUXSpec(screenWidth, screenHeight, 20),
        ModalButton_paddingHorizontal: transformUXSpec(screenWidth, screenHeight, 40),
        ModalButton_fontSize: transformUXSpec(screenWidth, screenHeight, 75),
        WarningText_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
        WarningText_paddingBottom: transformUXSpec(screenWidth, screenHeight, 20),
      }
    }
  }
}
