import React, { Component } from 'react'
import { Dimensions, ScaledSize } from 'react-native'
import { setAppSize } from '../Styles/AppStyle'

interface BasicPage<PageProps = object, PageState = object> {}

class BasicPage<PageProps, PageState> extends Component<PageProps, PageState> {
  dimensionChangeHandler(window: ScaledSize) {
    setAppSize(window)
    this.forceUpdate()
  }

  componentDidMount() {
    Dimensions.addEventListener('change', ({ window }) => this.dimensionChangeHandler(window))
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', ({ window }) => this.dimensionChangeHandler(window))
  }
}
export default BasicPage

export function updateScreenSize<P extends object>(WrappedComponent: React.ComponentType<P>) {
  return class extends Component<P> {
    dimensionChangeHandler(window: ScaledSize) {
      setAppSize(window)
      this.forceUpdate()
    }

    componentDidMount() {
      Dimensions.addEventListener('change', ({ window }) => this.dimensionChangeHandler(window))
    }

    componentWillUnmount() {
      Dimensions.removeEventListener('change', ({ window }) => this.dimensionChangeHandler(window))
    }
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
