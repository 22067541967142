import { TeamInfos } from '@taktik/src/redux/reducers/teams'
import axios from 'axios'

export const fetchTeamInfos = (teamID: number): Promise<TeamInfos | undefined> => {
  return axios.get<{ success: boolean; team: TeamInfos }>(`teams/${teamID}`).then((data) => {
    const { success, team } = data.data
    if (success) {
      return { name: team.name, logo: team.logo, id: team.id }
    }
  })
}

export const fetchTeams = async (teamIDs: number[]) => {
  const promises = teamIDs.map((teamId) => fetchTeamInfos(teamId))
  const allTeamInfos = await Promise.all(promises)
  return allTeamInfos.filter((teamInfos) => teamInfos !== undefined) as TeamInfos[]
}
