import { TaktikServerVersion } from '@taktik/config/TaktikServer/version'
import { logger } from '@taktik/lib/logger'
import { User } from '../DataTypes/UserTypes'
import { PageName } from '../Pages/PageInfo'
import { formatVersion } from '../Utilitary/version/Common'
import * as Actions from './actionTypes'
import { TeamInfos, TokenArray } from './reducers/teams'

export const toggleLogin = (isLoggedIn: boolean): any => ({
  type: Actions.TOGGLE_LOGIN,
  payload: {
    isLoggedIn,
  },
})

export const percentOfPlaybooksDownloaded = (percentPlaybookDownloaded: number): any => ({
  type: Actions.PERCENT_OF_PLAYBOOK_DOWNLOADED,
  payload: {
    percentPlaybookDownloaded,
  },
})
export const toggleImageLoad = (isLoading: boolean): any => ({
  type: Actions.TOGGLE_IMAGES_LOADING,
  payload: {
    isLoading,
  },
})

export const setSelectedTeam = (selectedTeam: number | null): any => ({
  type: Actions.SET_SELECTED_TEAM,
  payload: {
    selectedTeam,
  },
})

export const setSelectedToken = (selectedToken: string): any => ({
  type: Actions.SET_SELECTED_TOKEN,
  payload: {
    selectedToken,
  },
})

export const setTeamInfos = (teamsInfos: TeamInfos[] | null): any => ({
  type: Actions.SET_TEAMS_INFOS,
  payload: {
    teamsInfos,
  },
})

export const setTokens = (tokens: TokenArray[] | null): any => ({
  type: Actions.SET_TOKENS,
  payload: {
    tokens,
  },
})
export const playbooksLoad = (playbooks: object): any => ({
  type: Actions.PLAYBOOK_LOADING,
  payload: {
    playbooks,
  },
})

export const togglePracticeModal = (toggle: boolean): any => ({
  type: Actions.TOGGLE_PRACTICE_MODAL,
  payload: {
    toggle,
  },
})

export const toggleLoadingModal = (toggle: boolean, destinationPage: PageName): any => ({
  type: Actions.TOGGLE_LOADING_MODAL,
  payload: {
    toggle,
    destinationPage,
  },
})
export const toggleAlertStudyTimeModal = (toggle: boolean): any => ({
  type: Actions.TOGGLE_ALERT_STUDY_TIME_MODAL,
  payload: {
    toggle,
  },
})

export const updateSelectedGroups = (groups: number[]): any => ({
  type: Actions.UDPATE_PRACTICE_GROUPS,
  payload: {
    groups,
  },
})

export const updateSelectedEvaluations = (evaluations: number[]): any => ({
  type: Actions.UDPATE_PRACTICE_EVALUATIONS,
  payload: {
    evaluations,
  },
})

export const updateUserInfo = (user: User): any => ({
  type: Actions.UPDATE_USER_INFO,
  payload: {
    user,
  },
})

export const toggleIsAllPlaybooksDownloaded = (isAllPlaybookDownloaded: boolean): any => ({
  type: Actions.TOGGLE_IS_ALL_PLAYBOOK_DOWNLOADED,
  payload: {
    isAllPlaybookDownloaded,
  },
})

export const setTaktikServerVersion = (
  newMasterTaktikServerVersion: TaktikServerVersion | null
): any => {
  logger.info(
    `Setting the following soft Taktik Server version: ${formatVersion(
      newMasterTaktikServerVersion
    )}`
  )

  return {
    type: Actions.SET_TAKTIK_SERVER_VERSION,
    payload: newMasterTaktikServerVersion,
  }
}

export const resetTaktikServerVersion = (): any => {
  return setTaktikServerVersion(null)
}

export const setMasterTaktikServerVersion = (
  newMasterTaktikServerVersion: TaktikServerVersion
): any => {
  logger.info(
    `Setting the following master Taktik Server version: ${formatVersion(
      newMasterTaktikServerVersion
    )}`
  )

  return {
    type: Actions.SET_MASTER_TAKTIK_SERVER_VERSION,
    payload: newMasterTaktikServerVersion,
  }
}

export const toggleInvitationModal = (toggle: boolean): any => ({
  type: Actions.TOGGLE_INVITATION_MODAL,
  payload: {
    toggle,
  },
})

export const toggleRegistrationModal = (toggle: boolean): any => ({
  type: Actions.TOGGLE_REGISTRATION_MODAL,
  payload: {
    toggle,
  },
})

export const updateInvitationModalInformation = (coachID: number, teamID: number): any => ({
  type: Actions.UPDATE_INVITATION_MODAL_INFORMATION,
  payload: {
    coachID: coachID,
    teamID: teamID,
  },
})

export const setInvitationToken = (token: string | undefined) => ({
  type: Actions.SET_INVITATION_TOKEN,
  payload: { token },
})
