import { getMarginToTransformIn43, transformUXSpec } from '../Utilities'

export interface StyledScreenRatio {
  Page_height: any
  Page_width: any
  logoSize: any
  teamName_fontSize: any
  choiceModal_height: any
  title_fontSize: any
  teamContainer_marginTop: any
  trashIconContainer_width: any
  trashIconContainer_height: any
  trashIcon_marginLeft: any
  trashIcon_marginTop: any
  trashIconContainer_marginLeft: any
  trashIcon_size: any
  trashIconContainer_marginTop: any
  teamLogoContainer_marginTop: any
  manageMyTeam_fontSize: any
}

export function getScreenRatioStyle(
  currentWidth: number,
  currentHeight: number,
  isMobile: boolean,
  isHorizontal: boolean
) {
  let screenWidth: number = currentWidth
  const screenHeight: number = currentHeight
  const marginHorizontal: number = getMarginToTransformIn43(currentWidth, currentHeight)

  if (isHorizontal) {
    if (!isMobile) {
      screenWidth -= marginHorizontal
      //horizontal tablet
      return {
        Page_height: screenHeight - transformUXSpec(screenWidth, screenHeight, 140),
        Page_width: screenWidth,
        logoSize: 100,
        teamName_fontSize: transformUXSpec(screenWidth, screenHeight, 27),
        choiceModal_height: '60%',
        title_fontSize: 40,
        teamContainer_marginTop: 0,
        trashIconContainer_width: transformUXSpec(screenWidth, screenHeight, 50),
        trashIconContainer_height: transformUXSpec(screenWidth, screenHeight, 60),
        trashIcon_marginLeft: transformUXSpec(screenWidth, screenHeight, 7),
        trashIcon_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        trashIconContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 160),
        trashIcon_size: transformUXSpec(screenWidth, screenHeight, 40),
        trashIconContainer_marginTop: transformUXSpec(screenWidth, screenHeight, -40),
        teamLogoContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 40),
        manageMyTeam_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
      }
    } else {
      //horizontal mobile
      return {
        Page_height: screenHeight - transformUXSpec(screenWidth, screenHeight, 50),
        Page_width: screenWidth,
        logoSize: 50,
        teamName_fontSize: 8,
        choiceModal_height: '65%',
        title_fontSize: 30,
        teamContainer_marginTop: 0,
        trashIconContainer_width: transformUXSpec(screenWidth, screenHeight, 15),
        trashIconContainer_height: transformUXSpec(screenWidth, screenHeight, 20),
        trashIcon_marginLeft: transformUXSpec(screenWidth, screenHeight, 2),
        trashIcon_marginTop: transformUXSpec(screenWidth, screenHeight, 4),
        trashIconContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 40),
        trashIcon_size: transformUXSpec(screenWidth, screenHeight, 10),
        trashIconContainer_marginTop: transformUXSpec(screenWidth, screenHeight, -20),
        teamLogoContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        manageMyTeam_fontSize: transformUXSpec(screenWidth, screenHeight, 15),
      }
    }
  } else {
    if (screenWidth < 600) {
      //vertical mobile
      return {
        Page_height: screenHeight - transformUXSpec(screenWidth, screenHeight, 50),
        Page_width: screenWidth,
        logoSize: 50,
        teamName_fontSize: 6.5,
        choiceModal_height: '50%',
        title_fontSize: 25,
        teamContainer_marginTop: 20,
        trashIconContainer_width: transformUXSpec(screenWidth, screenHeight, 15),
        trashIconContainer_height: transformUXSpec(screenWidth, screenHeight, 20),
        trashIcon_marginLeft: transformUXSpec(screenWidth, screenHeight, 2),
        trashIcon_marginTop: transformUXSpec(screenWidth, screenHeight, 4),
        trashIconContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 40),
        trashIcon_size: transformUXSpec(screenWidth, screenHeight, 10),
        trashIconContainer_marginTop: transformUXSpec(screenWidth, screenHeight, -15),
        teamLogoContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        manageMyTeam_fontSize: transformUXSpec(screenWidth, screenHeight, 20),
      }
    } else {
      //vertical tablet
      return {
        Page_height: screenHeight - transformUXSpec(screenWidth, screenHeight, 140),
        Page_width: screenWidth,
        logoSize: 80,
        teamName_fontSize: 10,
        choiceModal_height: '40%',
        title_fontSize: 35,
        teamContainer_marginTop: 0,
        trashIconContainer_width: transformUXSpec(screenWidth, screenHeight, 60),
        trashIconContainer_height: transformUXSpec(screenWidth, screenHeight, 70),
        trashIcon_marginLeft: transformUXSpec(screenWidth, screenHeight, 10),
        trashIcon_marginTop: transformUXSpec(screenWidth, screenHeight, 15),
        trashIconContainer_marginLeft: transformUXSpec(screenWidth, screenHeight, 130),
        trashIcon_size: transformUXSpec(screenWidth, screenHeight, 40),
        trashIconContainer_marginTop: transformUXSpec(screenWidth, screenHeight, -50),
        teamLogoContainer_marginTop: transformUXSpec(screenWidth, screenHeight, 10),
        manageMyTeam_fontSize: transformUXSpec(screenWidth, screenHeight, 40),
      }
    }
  }
}
