import React from 'react'
import BasicPage from './BasicPage'
import { Text, View, TextInput, TouchableOpacity, ImageBackground, Dimensions } from 'react-native'
import { NavigationInterface, PageName } from './PageInfo'
import { getGlobalStyles, getLoginStyles, updateStyleParameters } from '../Styles/AppStyle'
import { getMarginToTransformIn43, StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { changePassword } from '../Services/AuthService'
import TopBarMenu from './TopBarMenu'
import translator from '@taktik/config/Translation/languages'
import ErrorComponent from './ErrorComponent'
import { isEmail } from '../Utilitary/Utils'
import { imgPathCut, imgPathCutLowRes } from '../AssetsManager'
import { MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons'
import { ScrollView } from 'react-native-gesture-handler'

interface ChangePasswordPageProps extends NavigationInterface {}
interface ChangePasswordPageState {
  styleParameters: StyleParameters
  emailIsValid?: boolean
  currentPasswordIsValid?: boolean
  newPasswordIsValid?: boolean
  passwordConfirmationIsValid?: boolean
  emailValue: string
  currentPasswordValue: string
  newPasswordValue: string
  passwordConfirmationValue: string
  changePasswordError?: Error
  isSending: boolean
  newPasswordNotEnoughNumber: boolean
  newPasswordNotEnoughCharacter: boolean
  newPasswordNotEnoughLowerCaseLetter: boolean
  newPasswordNotEnoughUpperCaseLetter: boolean
  atLeastOneError: boolean
}

export default class ChangePasswordPage extends BasicPage<
  ChangePasswordPageProps,
  ChangePasswordPageState
> {
  constructor(props: ChangePasswordPageProps) {
    super(props)
    this.state = {
      emailValue: '',
      currentPasswordValue: '',
      newPasswordValue: '',
      passwordConfirmationValue: '',
      isSending: false,
      styleParameters: getStyleParameters(),
      newPasswordNotEnoughNumber: false,
      newPasswordNotEnoughCharacter: false,
      newPasswordNotEnoughLowerCaseLetter: false,
      newPasswordNotEnoughUpperCaseLetter: false,
      atLeastOneError: false,
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', this.onChange)
  }

  componentWillUnmount = () => {
    Dimensions.removeEventListener('change', this.onChange)
  }

  emailIsValid(value: string): boolean | undefined {
    if (value.length === 0) return undefined
    else return isEmail(value)
  }

  emailChangeHandler(emailValue: string) {
    this.setState({
      emailIsValid: this.emailIsValid(emailValue),
      emailValue,
    })
  }

  passwordIsValid(value: string): boolean | undefined {
    if (value.length === 0) return undefined
    else return value.length >= 1
  }

  newPasswordIsValid(value: string): boolean | undefined {
    const newPasswordNotEnoughNumber = !/(.*[0-9])/i.test(value)
    const newPasswordNotEnoughCharacter = !/^\S{8,}/i.test(value)
    const newPasswordNotEnoughLowerCaseLetter = !/(.*[a-z])/.test(value)
    const newPasswordNotEnoughUpperCaseLetter = !/(.*[A-Z])/.test(value)
    const atLeastOneError =
      newPasswordNotEnoughNumber ||
      newPasswordNotEnoughLowerCaseLetter ||
      newPasswordNotEnoughCharacter ||
      newPasswordNotEnoughUpperCaseLetter
    this.setState({
      newPasswordNotEnoughCharacter: newPasswordNotEnoughCharacter,
      newPasswordNotEnoughNumber: newPasswordNotEnoughNumber,
      newPasswordNotEnoughLowerCaseLetter: newPasswordNotEnoughLowerCaseLetter,
      newPasswordNotEnoughUpperCaseLetter: newPasswordNotEnoughUpperCaseLetter,
    })
    if (value.length === 0) {
      return undefined
    }
    if (atLeastOneError) {
      return false
    } else return true
  }

  passChangeHandler(currentPasswordValue: string) {
    this.setState({
      currentPasswordIsValid: this.passwordIsValid(currentPasswordValue),
      currentPasswordValue,
    })
  }

  newPassChangeHandler(newPasswordValue: string) {
    this.setState(
      {
        newPasswordIsValid: this.newPasswordIsValid(newPasswordValue),
        newPasswordValue,
      },
      () => this.passConfirmationChangeHandler()
    )
  }

  passwordConfirmationIsValid(value: string): boolean | undefined {
    if (value.length === 0) return undefined
    else return value === this.state.newPasswordValue
  }

  passConfirmationChangeHandler(
    passwordConfirmationValue: string = this.state.passwordConfirmationValue
  ) {
    this.setState({
      passwordConfirmationIsValid: this.passwordConfirmationIsValid(passwordConfirmationValue),
      passwordConfirmationValue,
    })
  }

  async changePassword() {
    const { emailValue, currentPasswordValue: passwordValue, newPasswordValue } = this.state
    try {
      await changePassword(emailValue, passwordValue, newPasswordValue)
      this.props.navigation.navigate(PageName.Success, {
        message: PageName.ChangePassword,
      })
    } catch (error) {
      this.setState({
        changePasswordError: error,
      })
    }
  }

  render() {
    const { navigation } = this.props
    const {
      emailIsValid,
      currentPasswordIsValid: passwordIsValid,
      newPasswordIsValid,
      passwordConfirmationIsValid,
      styleParameters,
    } = this.state

    const { isMobile, isHorizontal, screenWidth, screenHeight } = styleParameters

    const marginHorizontal = getMarginToTransformIn43(screenWidth, screenHeight)

    const backgroundStyle =
      !isMobile && isHorizontal ? { marginHorizontal: marginHorizontal / 2 } : {}

    return (
      <View style={getGlobalStyles(styleParameters).app}>
        {/* The top (low-quality) ImageBackground is a placeholder while the HD image loads (useful to avoid a blank background) */}
        <ImageBackground
          source={imgPathCutLowRes}
          style={[getGlobalStyles(styleParameters).backgroundImageContents, backgroundStyle]}
        >
          <ImageBackground
            source={imgPathCut}
            style={getGlobalStyles(styleParameters).backgroundImageContents}
          >
            <TopBarMenu
              navigation={navigation}
              url={PageName.Login}
              updatePage={() => this.forceUpdate()}
              userMustBeLoggedOut={false}
              pageTitle="ChangePasswordPage"
            />
            <View style={getLoginStyles(styleParameters).page}>
              {isHorizontal && <View style={getLoginStyles(styleParameters).side} />}
              {isHorizontal && <View style={getLoginStyles(styleParameters).side} />}
              <View style={getLoginStyles(styleParameters).authenticationForm}>
                <Text style={getLoginStyles(styleParameters).titleText}>
                  {translator.t('changePassword.title').toUpperCase()}
                </Text>

                <ScrollView>
                  <View style={getLoginStyles(styleParameters).authenticateForm}>
                    <ErrorComponent error={this.state.changePasswordError} />
                    <ErrorComponent
                      error={
                        this.state.emailIsValid === false
                          ? new Error(translator.t('error.field.email'))
                          : undefined
                      }
                    />
                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                      <View style={getGlobalStyles(styleParameters).userIconPosition}>
                        <MaterialCommunityIcons
                          name="account"
                          style={getGlobalStyles(styleParameters).userIcon}
                        />
                      </View>
                      <TextInput
                        style={getLoginStyles(styleParameters).changePasswordTextInputs}
                        autoCapitalize="none"
                        editable={!this.state.isSending}
                        value={this.state.emailValue}
                        textContentType="emailAddress"
                        keyboardType="email-address"
                        placeholderTextColor="#ccc"
                        onChangeText={(value) => this.emailChangeHandler(value)}
                        placeholder={translator.t('email').toUpperCase()}
                        returnKeyType="next"
                      />
                    </View>
                    <ErrorComponent
                      error={
                        this.state.currentPasswordIsValid === false
                          ? new Error(translator.t('error.field.password'))
                          : undefined
                      }
                    />
                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                      <View style={getGlobalStyles(styleParameters).passwordIconPosition}>
                        <FontAwesome5
                          name="lock"
                          style={getGlobalStyles(styleParameters).passwordIcon}
                        />
                      </View>
                      <TextInput
                        style={getLoginStyles(styleParameters).changePasswordTextInputs}
                        editable={!this.state.isSending}
                        placeholderTextColor="#ccc"
                        secureTextEntry
                        textContentType="password"
                        value={this.state.currentPasswordValue}
                        onChangeText={(value) => this.passChangeHandler(value)}
                        placeholder={translator.t('password').toUpperCase()}
                        returnKeyType="next"
                        onSubmitEditing={() => this.changePassword()}
                      />
                    </View>
                    <ErrorComponent
                      error={
                        this.state.newPasswordIsValid === false
                          ? new Error(translator.t('error.field.newPassword'))
                          : undefined
                      }
                    />
                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                      <TextInput
                        style={getLoginStyles(styleParameters).changePasswordTextInputs}
                        editable={!this.state.isSending}
                        placeholderTextColor="#ccc"
                        secureTextEntry
                        textContentType="newPassword"
                        value={this.state.newPasswordValue}
                        onChangeText={(value) => this.newPassChangeHandler(value)}
                        placeholder={translator.t('changePassword.newPassword').toUpperCase()}
                        returnKeyType="next"
                        onSubmitEditing={() => this.changePassword()}
                      />
                    </View>
                    <ErrorComponent
                      error={
                        this.state.passwordConfirmationIsValid === false
                          ? new Error(translator.t('error.field.passwordConfirmation'))
                          : undefined
                      }
                    />
                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                      <TextInput
                        style={getLoginStyles(styleParameters).changePasswordTextInputs}
                        editable={!this.state.isSending}
                        placeholderTextColor="#ccc"
                        secureTextEntry
                        textContentType="newPassword"
                        value={this.state.passwordConfirmationValue}
                        onChangeText={(value) => this.passConfirmationChangeHandler(value)}
                        placeholder={translator
                          .t('changePassword.passwordConfirmation')
                          .toUpperCase()}
                        returnKeyType="go"
                        onSubmitEditing={() => this.changePassword()}
                      />
                    </View>
                    <View
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        transform: [{ skewX: '-10deg' }],
                      }}
                    >
                      <TouchableOpacity
                        style={getLoginStyles(styleParameters).authenticateButton_Large}
                        onPress={() => this.changePassword()}
                        disabled={
                          !emailIsValid ||
                          !passwordIsValid ||
                          !newPasswordIsValid ||
                          !passwordConfirmationIsValid
                        }
                      >
                        <Text style={getLoginStyles(styleParameters).authenticateButtonText}>
                          {translator.t('changePassword.changePassword').toUpperCase()}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={getLoginStyles(styleParameters).otherOptionsContainer}>
                      <TouchableOpacity
                        style={getLoginStyles(styleParameters).otherOptionsButton}
                        onPress={() => navigation.navigate(PageName.Login)}
                      >
                        <Text style={getLoginStyles(styleParameters).otherOptionsText}>
                          {translator.t('backToLogin').toUpperCase()}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={getLoginStyles(styleParameters).side} />
                </ScrollView>
              </View>
            </View>
          </ImageBackground>
        </ImageBackground>
      </View>
    )
  }
}
