import React from 'react'
import { Dimensions, Text, View, TouchableOpacity } from 'react-native'
import translator from '@taktik/config/Translation/languages'
import BasicPage from '../Pages/BasicPage'
import { getPlayerStatsStyles, updateStyleParameters } from '../Styles/AppStyle'
import { getStyleParameters, StyleParameters } from '../Styles/Utilities'
import { Modal } from '../components/ModalImport'
import CancelIcon from '../../assets/svg/CancelIcon'
import { connect } from 'react-redux'
import { RootState } from '../redux/reducers'
import { orientationIOS } from '../Pages/TopBarMenu'

interface EndPracticeModalProps {
  numberOfGoodAnswers: number
  numberOfWrongAnswers: number
  averageTimeResponse: number
}

interface EndPracticeModalState {
  endPracticeModalVisible: boolean
  styleParameters: StyleParameters
}

class EndPracticeModal extends BasicPage<EndPracticeModalProps, EndPracticeModalState> {
  constructor(public props: EndPracticeModalProps) {
    super(props)

    this.state = {
      endPracticeModalVisible: true,
      styleParameters: getStyleParameters(),
    }
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this.onChange)
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.onChange)
  }

  renderEndPracticeModal = () => {
    const { styleParameters, endPracticeModalVisible } = this.state
    const {
      numberOfGoodAnswers: goodAnswers,
      numberOfWrongAnswers: badAnswers,
      averageTimeResponse,
    } = this.props

    return (
      endPracticeModalVisible && (
        <Modal transparent supportedOrientations={orientationIOS} visible={endPracticeModalVisible}>
          <View style={getPlayerStatsStyles(styleParameters).ModalBackground}>
            <View style={getPlayerStatsStyles(styleParameters).ModalEndPracticeContainer}>
              <View
                style={getPlayerStatsStyles(styleParameters).ModalEndPracticeCloseButtonContainer}
              >
                <TouchableOpacity
                  style={getPlayerStatsStyles(styleParameters).ModalEndPracticeCloseButton}
                  onPress={() => {
                    this.setState({ endPracticeModalVisible: false })
                  }}
                >
                  <View
                    style={
                      getPlayerStatsStyles(styleParameters).ModalEndPracticeCancelIconContainer
                    }
                  >
                    <CancelIcon
                      style={getPlayerStatsStyles(styleParameters).ModalEndPracticeCloseButtonIcon}
                      isTablet={styleParameters.isTablet}
                    />
                  </View>
                </TouchableOpacity>
              </View>

              <Text style={getPlayerStatsStyles(styleParameters).ModalEndPracticeTitle}>
                {translator.t('endPracticeModal.title').toUpperCase()}
              </Text>
              <View style={getPlayerStatsStyles(styleParameters).ModalEndPracticeSubtitleContainer}>
                <Text style={getPlayerStatsStyles(styleParameters).ModalEndPracticeSubtitle}>
                  {translator.t('endPracticeModal.subtitle').toUpperCase()}
                </Text>
              </View>
              <View style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatsContainer}>
                <View style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatLine}>
                  <Text style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatsText}>
                    {translator.t('endPracticeModal.rightAns').toUpperCase()}
                  </Text>
                  <Text style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatsData}>
                    {goodAnswers}
                  </Text>
                </View>
                <View style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatLine}>
                  <Text style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatsText}>
                    {translator.t('endPracticeModal.wrongAns').toUpperCase()}
                  </Text>
                  <Text style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatsData}>
                    {badAnswers}
                  </Text>
                </View>
                <View style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatLine}>
                  <Text style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatsText}>
                    {translator.t('endPracticeModal.averageAns').toUpperCase()}
                  </Text>
                  <Text style={getPlayerStatsStyles(styleParameters).ModalEndPracticeStatsData}>
                    {averageTimeResponse + translator.t('endPracticeModal.seconds').toUpperCase()}
                  </Text>
                </View>
              </View>
              <View
                style={getPlayerStatsStyles(styleParameters).ModalEndPracticeCloseButtonContainer}
              />
            </View>
          </View>
        </Modal>
      )
    )
  }

  render() {
    return this.renderEndPracticeModal()
  }
}

const mapStateToProps = (state: RootState) => ({
  playbooks: state.playbooks.playbooks,
  folders: state.playbooks.playbookFolders,
})

export default connect(mapStateToProps, {})(EndPracticeModal)
