export const TOGGLE_LOGIN = 'LOGIN'
export const TOGGLE_IMAGES_LOADING = 'TOGGLE_IMAGES_LOADING'
export const PLAYBOOK_LOADING = 'PLAYBOOKS LOADING'
export const TOGGLE_PRACTICE_MODAL = 'TOGGLE_PRACTICE_MODAL'
export const TOGGLE_LOADING_MODAL = 'TOGGLE_LOADING_MODAL'
export const UDPATE_PRACTICE_GROUPS = 'UDPATE_PRACTICE_GROUPS'
export const UDPATE_PRACTICE_EVALUATIONS = 'UDPATE_PRACTICE_EVALUATIONS'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const TOGGLE_IS_ALL_PLAYBOOK_DOWNLOADED = 'TOGGLE_IS_ALL_PLAYBOOK_DOWNLOADED'
export const PERCENT_OF_PLAYBOOK_DOWNLOADED = 'PERCENT_OF_PLAYBOOK_DOWNLOADED'
export const TOGGLE_ALERT_STUDY_TIME_MODAL = 'TOGGLE_ALERT_STUDY_TIME_MODAL'
export const SET_STORE_VERSION = '[GLOBAL] SET STORE VERSION'
export const SET_TAKTIK_SERVER_VERSION = '[GLOBAL] SET TAKTIK SERVER VERSION NUMBER'
export const SET_MASTER_TAKTIK_SERVER_VERSION = '[GLOBAL] SET MASTER TAKTIK SERVER VERSION NUMBER'
export const SET_SELECTED_TEAM = '[USERTEAMS] SET SELECTED TEAM'
export const SET_SELECTED_TOKEN = '[USERTEAMS] SET SELECTED TOKEN'
export const SET_TEAMS_INFOS = '[USERTEAMS] SET TEAMS INFOS'
export const SET_TOKENS = '[USERTEAMS] SET TOKENS'
export const TOGGLE_INVITATION_MODAL = 'TOGGLE_INVITATION_MODAL'
export const TOGGLE_REGISTRATION_MODAL = 'TOGGLE_REGISTRATION_MODAL'
export const UPDATE_INVITATION_MODAL_INFORMATION = 'UPDATE_INVITATION_MODAL_INFORMATION'
export const SET_INVITATION_TOKEN = '[INVITATION] SET INVITATION TOKEN'
