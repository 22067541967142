import React, { Component } from 'react'
import { TextInput, TextInputProps, TextInputState } from 'react-native'
import {
  InputFieldCheck,
  InputFieldValidation,
  InputFieldValidator,
  ParsedInputFieldValidator,
  parseValidators,
} from '../DataTypes/ValidationType'

interface ValidationInputFieldProps extends TextInputProps {
  validators: Partial<InputFieldValidator> | Partial<InputFieldValidator>[]
  onValidate: (text: string, errors: InputFieldValidation[]) => void
  onComplete: () => void
}

interface ValidationInputFieldState extends TextInputState {
  value: string
  parsedValidators: Partial<ParsedInputFieldValidator>[]
}

export default class ValidationInputField extends Component<
  ValidationInputFieldProps,
  ValidationInputFieldState
> {
  constructor(props: ValidationInputFieldProps) {
    super(props)
    const parsedValidators = parseValidators(props.validators)
    this.state = { ...this.state, value: '', parsedValidators }
  }

  componentDidMount() {
    this.validate()
  }

  private onInputChange = (input: string) => {
    this.setState({ value: input }, () => {
      this.validate()
    })
  }

  applyCheck = (input: string, check: InputFieldCheck): boolean => {
    if (check instanceof RegExp) {
      return check.test(input)
    } else {
      return check?.(input) ?? false
    }
  }

  applyValidator = <T extends InputFieldValidation>(
    value: string,
    validator: Partial<ParsedInputFieldValidator<T>>
  ): InputFieldValidation[] => {
    const keys = Object.keys(validator) as (keyof typeof validator)[]
    return keys.reduce((allInvalidValidators, key) => {
      const checks = validator[key]
      const checkIsValid = checks?.every((check) => this.applyCheck(value, check)) ?? true
      return checkIsValid ? [...allInvalidValidators] : [...allInvalidValidators, key]
    }, [] as InputFieldValidation[])
  }

  validate = () => {
    const { onValidate } = this.props
    const { value } = this.state

    const parsedValidators = parseValidators(this.props.validators)
    const errors = parsedValidators.map((validator) => this.applyValidator(value, validator)).flat()
    onValidate(value, errors)
  }

  render() {
    const { onComplete } = this.props

    return (
      <TextInput
        {...this.props}
        onChangeText={(text) => this.onInputChange(text)}
        onBlur={() => onComplete()}
      />
    )
  }
}
