import auth from './auth'
import cache from './cache'
import playbooks from './playbooks'
import modals from './modals'
import { combineReducers } from 'redux'
import team from './teams'
import version from './version'
import invitation from './invitation'
import { Dispatch } from 'react'
import { ActionType } from 'typesafe-actions'
import * as Actions from './../actions'

const reducers = combineReducers({
  auth: auth,
  playbooks: playbooks,
  cache: cache,
  modals: modals,
  version: version,
  team: team,
  invitation: invitation,
})

export type RootState = ReturnType<typeof reducers>
export default reducers

type ThunkedAction = (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  ...rest: any
) => Promise<void>

export type RootAction = ActionType<typeof Actions> | ThunkedAction
