import translator from '@taktik/config/Translation/languages'
import React, { Component } from 'react'
import { View, TouchableOpacity, Text, Image, ImageSourcePropType } from 'react-native'
import { connect } from 'react-redux'
import { AntDesign } from '@expo/vector-icons'
import { TeamInfos } from './../../redux/reducers/teams'
import { StyleParameters } from './../../Styles/Utilities'
import { getTeamSelectionStyles, getTopBarMenuStyles } from './../../Styles/AppStyle'
import { RootAction, RootState } from './../../redux/reducers'
import { selectAndLoadTeam } from './thunks'
import { AnyAction } from 'redux'
import { PageName } from '@taktik/src/Pages/PageInfo'
import * as NetInfo from '@react-native-community/netinfo'
import { axiosDeleteToUserTeams } from '@taktik/src/Utilitary/DataUtils'
import { User } from '@taktik/src/DataTypes/UserTypes'
import { ThunkDispatch } from 'redux-thunk'
import { TaktikAlert } from '@taktik/src/Utilitary/Alert'

const placeholderLogo = require('../../../assets/icon.png')

interface TeamOptionDispatchProps {
  onSelectAndLoadTeam: (
    teamId: number,
    onSuccess: () => void,
    onError: (error: string) => void
  ) => Promise<void>
}

interface TeamOptionProps extends TeamOptionDispatchProps {
  user: User
  team: TeamInfos
  isFullPage: boolean
  canDelete: boolean
  isLoading: boolean
  navigation: any
  styleParameters: StyleParameters
  onToggle: (isSelected: boolean) => void
}
interface TeamOptionState {
  isOkToDeleteAssociation: boolean
}
class TeamOption extends Component<TeamOptionProps, TeamOptionState> {
  state = {
    isOkToDeleteAssociation: false,
  }

  getStyles = () => {
    const { isFullPage, styleParameters } = this.props
    return isFullPage
      ? getTeamSelectionStyles(styleParameters)
      : getTopBarMenuStyles(styleParameters)
  }

  goToHome = () => {
    const { navigation } = this.props
    navigation.navigate(PageName.Home)
  }

  handleSelection = async () => {
    const { team, onToggle, onSelectAndLoadTeam } = this.props

    onToggle(true)
    const { isConnected, isInternetReachable } = await NetInfo.fetch()
    if (isConnected && isInternetReachable) {
      await onSelectAndLoadTeam(team.id, this.goToHome, alert)
    } else {
      TaktikAlert.alert(translator.t('team.noSwitchWithoutInternet'))
    }
    onToggle(false)
  }

  handleAlertMessage = (teamName: string) => {
    const { isOkToDeleteAssociation } = this.state

    if (!isOkToDeleteAssociation) {
      TaktikAlert.alert(
        translator.t('deleteTeamAlert.team') + teamName + translator.t('deleteTeamAlert.secondPart')
      )
    }
  }

  handleConfirmMessage = () => {
    const { team, user } = this.props

    TaktikAlert.alert(
      translator.t('deleteTeamModal.Title'),
      translator.t('deleteTeamModal.firstPart') + team.name + translator.t('deleteTeamModal.team'),
      {
        text: 'OK',
        onPress: () => {
          axiosDeleteToUserTeams(team.id, user.id)
          this.handleAlertMessage(team.name)
          this.setState({ isOkToDeleteAssociation: true })
        },
      },
      { text: 'Cancel' },
      true
    )
  }

  render() {
    const { team, canDelete, isLoading } = this.props

    const styles = this.getStyles()
    const imgSource: ImageSourcePropType = team.logo ? { uri: team.logo } : placeholderLogo

    return (
      <View style={styles.teamContainer}>
        <TouchableOpacity onPress={this.handleSelection} disabled={isLoading}>
          <View style={styles.teamWrapper}>
            {!isLoading && (
              <View style={styles.teamNameContainer}>
                <Text style={styles.teamName} numberOfLines={2} ellipsizeMode="tail">
                  {team.name}
                </Text>
              </View>
            )}

            <View style={styles.teamLogoContainer}>
              {canDelete && !isLoading && (
                <View style={styles.trashIconContainer}>
                  <AntDesign
                    name="delete"
                    style={styles.trashIcon}
                    onPress={this.handleConfirmMessage}
                  />
                </View>
              )}
              <Image style={styles.teamLogo} source={imgSource} />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootAction, unknown, AnyAction>
): TeamOptionDispatchProps => ({
  onSelectAndLoadTeam: (teamId: number, onSuccess: () => void, onError: (error: string) => void) =>
    dispatch(selectAndLoadTeam(teamId, onSuccess, onError)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamOption)
