import React, { Component } from 'react'
import { View } from 'react-native'
import { getHamburgerIconStyles } from '../../src/Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../../src/Styles/Utilities'

interface HamburgerIconProps {
  style: object
}

interface HamburgerIconState {
  styleParameters: StyleParameters
}

class HamburgerIcon extends Component<HamburgerIconProps, HamburgerIconState> {
  constructor(props: HamburgerIconProps) {
    super(props)
    this.state = {
      styleParameters: getStyleParameters(),
    }
  }

  render() {
    const { styleParameters } = this.state

    return (
      <>
        <View style={getHamburgerIconStyles(styleParameters).hamburgerLineWrapper}>
          <View style={getHamburgerIconStyles(styleParameters).hamburgerLine} />
        </View>
        <View style={getHamburgerIconStyles(styleParameters).hamburgerLineWrapper}>
          <View style={getHamburgerIconStyles(styleParameters).hamburgerLineMiddle} />
        </View>
        <View style={getHamburgerIconStyles(styleParameters).hamburgerLineWrapper}>
          <View style={getHamburgerIconStyles(styleParameters).hamburgerLine} />
        </View>
      </>
    )
  }
}

export default HamburgerIcon
