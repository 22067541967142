import { UserRole } from '@taktik/src/DataTypes/UserTypes'
import translator from '../../config/Translation/languages'

export enum GroupNames {
  Offense = 'offense',
  Defense = 'defense',
  Special = 'special',
}

export const getUserRoleTranslation = (userRole: UserRole) => {
  switch (userRole) {
    case UserRole.Coach:
      return translator.t('coach')
    case UserRole.Player:
      return translator.t('player')
    default:
      return ''
  }
}
