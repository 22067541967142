import React, { Component } from 'react'

import { notEmpty } from '../Utilitary/Utils'
import {
  OnlyNumberValidation,
  InputFieldValidation,
  GenericValidation,
} from '../DataTypes/ValidationType'
import GenericField from './GenericField'
import { StyleParameters } from '../Styles/Utilities'
import { KeyboardTypeOptions } from 'react-native'

interface OnlyNumberFieldProps {
  styleParameters: StyleParameters
  placeholder: string
  keyboardType?: KeyboardTypeOptions
  maxLength?: number
  secureTextEntry?: boolean
  onValidationChange: (text: string, errors: InputFieldValidation[]) => void
  onComplete: () => void
}

export default class OnlyNumberField extends Component<OnlyNumberFieldProps> {
  isOnlyNumber = (text: string) => {
    return /^\d+$/.test(text)
  }
  render() {
    const {
      styleParameters,
      onValidationChange,
      onComplete,
      secureTextEntry,
      keyboardType,
      maxLength,
      placeholder,
    } = this.props
    return (
      <GenericField
        extraValidators={{
          [OnlyNumberValidation.IS_ONLY_NUMBER]: (input) => this.isOnlyNumber(input),
          [GenericValidation.NOT_EMPTY]: (input) => notEmpty(input),
        }}
        onValidationChange={(text, errors) => onValidationChange(text, errors)}
        onComplete={() => onComplete()}
        styleParameters={styleParameters}
        placeholder={placeholder}
        maxLength={maxLength ?? 200}
        keyboardType={keyboardType}
        secureTextEntry={secureTextEntry}
      />
    )
  }
}
