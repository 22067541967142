import { StatusBar } from 'expo-status-bar'
import React from 'react'
import axios from 'axios'
import translator, { setLanguageValues } from '@taktik/config/Translation/languages'
import NavigationModule from './src/NavigationModule'
import {
  configureLocalAuthorizationToken,
  forceLogoutIfNotAuthenticated,
  setBaseUrl,
} from './src/Utilitary/ConnectionUtils'
import { requestDatabase } from './src/Utilitary/StorageUtils'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { NavigationContainer } from '@react-navigation/native'
import SnackBar from 'rn-snackbar'
import { TSnackBar } from './src/components/snackbar/TSnackBar'
import { SnackBarType } from './src/components/snackbar/snack-bar-type'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './src/redux/store'
import { logger } from './lib/logger'
import { Modal } from './src/components/ModalImport'
import { Platform, View } from 'react-native'
import AppDownloadNotification from './src/components/AppDownloadNotification'

export default function App() {
  // Locale language support for Android for certain parts
  if (Platform.OS === 'android') {
    require('intl')
    require('intl/locale-data/jsonp/en-US')
    require('intl/locale-data/jsonp/fr-FR')
    // require('date-time-format-timezone') //enable this if we use timezone
    // Intl.__disableRegExpRestore() /*For syntaxerror invalid regular expression unmatched parentheses*/
  }
  configureLocalAuthorizationToken().then((hasToken) => {
    forceLogoutIfNotAuthenticated(hasToken).then()
  })
  setLanguageValues()
  setBaseUrl()
  requestDatabase().then()
  configureAxiosErrorHandler()
  setAppElement()
  disableUnnecessaryWarnings()

  return (
    <View style={{ flex: 1 }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SafeAreaProvider>
            <AppDownloadNotification>
              <NavigationContainer>
                <NavigationModule />
              </NavigationContainer>
              <StatusBar />
            </AppDownloadNotification>
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    </View>
  )
}

function disableUnnecessaryWarnings() {
  // eslint-disable-next-line no-console
  const backup = console.warn

  // eslint-disable-next-line no-console
  console.warn = function filterWarnings(...args) {
    const [message] = args

    const supressedWarnings = [
      'Warning: componentWillMount has been renamed',
      'Warning: componentWillReceiveProps has been renamed',
    ]

    if (!supressedWarnings.some((entry) => message.includes(entry))) {
      backup.apply(console, args)
    }
  }
}

function setAppElement() {
  const OS = Platform.OS
  const isTablet = OS === 'ios' || OS === 'android'

  if (!isTablet) {
    Modal.setAppElement('body')
  }
}

function configureAxiosErrorHandler() {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response.status
      if (status === 500) {
        logger.warn(error.response)
        const errorMessage = translator.t('errors.unexpected')
        SnackBar.show('', {
          position: 'top',
          renderContent: () => (
            <TSnackBar
              message={errorMessage}
              type={SnackBarType.ERROR}
              onDismiss={() => SnackBar.dismiss()}
            />
          ),
        })
      }
      return Promise.reject(error.response)
    }
  )
}
