import { Platform, Alert, AlertButton } from 'react-native'

export module TaktikAlert {
  export const alert = (
    title: string,
    message?: string | undefined,
    onConfirm?: AlertButton,
    onCancel?: AlertButton,
    dismissable = false
  ) => {
    if (Platform.OS !== 'web') {
      const buttons = [onConfirm, onCancel].filter((button) => !!button) as AlertButton[]
      const options = { cancelable: dismissable, onDismiss: onCancel?.onPress }
      return Alert.alert(title, message, buttons, options)
    }

    const content = `${title}${message ? `\n${message}` : ''}`
    const alertFunction: (message?: string) => boolean | void = onCancel
      ? window.confirm
      : window.alert

    const userConfirmed = alertFunction(content) ?? true
    const callbackFunction = userConfirmed ? onConfirm?.onPress : onCancel?.onPress
    if (callbackFunction) {
      callbackFunction()
    }
  }
}
