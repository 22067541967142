import React, { Component } from 'react'
import { Dimensions, TouchableOpacity, View, Text } from 'react-native'
import RightArrowIcon from '../../assets/svg/RightArrowIcon'
import { NavigationInterface } from './PageInfo'
import { getPlaybookStyles, updateStyleParameters } from '../Styles/AppStyle'
import { StyleParameters, getStyleParameters } from '../Styles/Utilities'
import { FolderPlaybook } from './FolderPlaybook'
import { PlaybookFolder } from './PlaybookFolder'
import { getScreenRatioStyle } from '../Styles/Peculiar/PlaybooksRatiosStyle'
import { GroupNames } from '@taktik/config/Translation/utilities'

interface PlaybookGroupProps extends NavigationInterface {
  groupName: GroupNames
  folderId: number
  parentFolderId: number
  foldersToRender: any[]
  playbooksToRender: any[]
  PlaybookList: any[]
  changeGroupFolderFunction: (group: GroupNames, folderId: number) => void
  allFolders: any[]
}

interface PlaybookGroupState {
  styleParameters: StyleParameters
}

export default class PlaybookGroup extends Component<PlaybookGroupProps, PlaybookGroupState> {
  constructor(props: PlaybookGroupProps) {
    super(props)
    this.state = {
      styleParameters: getStyleParameters(),
    }
  }
  render() {
    const { groupName, folderId, parentFolderId, playbooksToRender, foldersToRender } = this.props
    return (
      <View style={getPlaybookStyles(this.state.styleParameters).PlaybookGroupContainer}>
        {folderId !== 0 ? this.renderReturnButton(groupName, parentFolderId) : null}
        <View>{this.renderFolders(foldersToRender)}</View>
        <View>{this.renderPlaybooks(playbooksToRender)}</View>
      </View>
    )
  }

  onChange = () => {
    const newStyleParameters = updateStyleParameters(this.state)
    if (newStyleParameters !== null) {
      this.setState({ styleParameters: newStyleParameters })
    }
  }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', this.onChange)
  }

  renderReturnButton(returnGroup: GroupNames, returnFolderId: number) {
    const { allFolders, folderId } = this.props
    const { styleParameters } = this.state

    const currentFolderName = allFolders
      .filter((folder) => {
        return folder.id === folderId
      })
      .map((folder) => {
        return folder.name
      })[0]
    return (
      <View style={getPlaybookStyles(styleParameters).BackArrowContainer}>
        <TouchableOpacity
          onPress={() => this.props.changeGroupFolderFunction(returnGroup, returnFolderId)}
          style={{ width: '100%' }}
        >
          <RightArrowIcon style={getPlaybookStyles(styleParameters).BackArrowIcon} />
          <Text
            adjustsFontSizeToFit
            style={getPlaybookStyles(styleParameters).FolderBackTitle}
            numberOfLines={1}
          >
            {currentFolderName.toUpperCase()}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderFolders(foldersToRender: any[]) {
    const { PlaybookList, changeGroupFolderFunction } = this.props
    const { styleParameters } = this.state

    const nbFolderPerRow = getScreenRatioStyle(styleParameters).FolderPerRow
    const foldersToRenderTab = []
    let folderRow = []
    let nbFolderIntheRow = 0
    const nbRowComplete = Math.floor(foldersToRender.length / nbFolderPerRow)
    for (let folderIndex = 0; folderIndex < foldersToRender.length; folderIndex++) {
      if (folderIndex < nbRowComplete * nbFolderPerRow) {
        nbFolderIntheRow = nbFolderPerRow
      } else {
        nbFolderIntheRow = foldersToRender.length % nbFolderPerRow
      }
      folderRow.push(
        <PlaybookFolder
          key={foldersToRender[folderIndex].id}
          id={foldersToRender[folderIndex].id}
          group={foldersToRender[folderIndex].group}
          name={foldersToRender[folderIndex].name}
          parentFolderID={foldersToRender[folderIndex].parentFolderID}
          folders={foldersToRender[folderIndex].folders}
          playbooks={foldersToRender[folderIndex].playbooks}
          PlaybookList={PlaybookList}
          changeFolderFunction={(group: GroupNames, folderId: number) =>
            changeGroupFolderFunction(group, folderId)
          }
          nbFolderInRow={nbFolderIntheRow}
        />
      )

      const isANewLine =
        (folderIndex !== 0 && folderIndex % nbFolderPerRow === nbFolderPerRow - 1) ||
        folderIndex === foldersToRender.length - 1

      if (isANewLine) {
        const nbFolderInTheRow = folderRow.length
        const folderWidth = 100 / nbFolderPerRow

        foldersToRenderTab.push(
          <View
            key={foldersToRender[folderIndex].id}
            style={[
              getPlaybookStyles(styleParameters).foldersContainer,
              {
                width:
                  nbFolderInTheRow === nbFolderPerRow
                    ? '98%'
                    : '' + nbFolderInTheRow * folderWidth + '%',
              },
            ]}
          >
            {folderRow}
          </View>
        )
        folderRow = []
      }
    }

    return foldersToRenderTab
  }

  renderPlaybooks(playbooksToRender: any[]) {
    const { styleParameters } = this.state

    const playbooksToRenderTab = []
    const nbPlaybookPerRow = getScreenRatioStyle(styleParameters).PlaybookPerRow
    const playbookIDs: number[] = playbooksToRender.map((playbook) => {
      return playbook.id
    })
    let playbooksRow = []

    for (let playbookIndex = 0; playbookIndex < playbooksToRender.length; playbookIndex++) {
      playbooksRow.push(
        <FolderPlaybook
          key={playbooksToRender[playbookIndex].id}
          id={playbooksToRender[playbookIndex].id}
          title={playbooksToRender[playbookIndex].title}
          snapshot={playbooksToRender[playbookIndex].snapshot}
          navigation={this.props.navigation}
          playbookIDs={playbookIDs}
        />
      )

      const isANewLine =
        (playbookIndex !== 0 && playbookIndex % nbPlaybookPerRow === nbPlaybookPerRow - 1) ||
        playbookIndex === playbooksToRender.length - 1

      if (isANewLine) {
        const playbookWidth =
          getScreenRatioStyle(styleParameters).PlaybookGroupPlaybooksContainer_width /
          nbPlaybookPerRow

        playbooksToRenderTab.push(
          <View
            key={playbooksToRender[playbookIndex].id}
            style={[
              getPlaybookStyles(styleParameters).playbooksContainer,
              {
                width:
                  playbookWidth * playbooksRow.length -
                  (nbPlaybookPerRow === playbooksRow.length ? 0 : playbookWidth / nbPlaybookPerRow),
              },
            ]}
          >
            {playbooksRow}
          </View>
        )
        playbooksRow = []
      }
    }

    return playbooksToRenderTab
  }
}
